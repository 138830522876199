import {IStore, ISetProduce} from '../store'
import {GetState} from 'zustand'

export const createClothingRailSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const clothingRails = {
    toggleClothingRail: (colNum: number, boardNum: number) => {
      setProduce((store) => {
        const indexOf = store.config.clothingRails.list.findIndex((el) => el.pos.x === colNum && el.pos.y === boardNum)
        indexOf !== -1 ? store.config.clothingRails.list.splice(indexOf, 1) : store.config.clothingRails.list.push({pos: {x: colNum, y: boardNum}})
      })
    },

    setEditClothingRails: (edit: boolean) => {
      setProduce((state) => {
        state.view.clothingRails.edit = edit
      })
    },
  }

  return {
    clothingRails,
  }
}
