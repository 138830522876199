import {useStore} from 'products/metrum/store/store'
import {shelfMaterials} from 'products/metrum/store/datamodels'
import {IMetrumConfig} from '../store/initialConfig'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedMetrumColumnList = () => {
  const [columnList, height, depth, grid, materialID, doors] = useStore(
    useShallow((store) => [store.config.columns.list, store.config.main.height, store.config.main.depth, store.config.main.grid, store.config.main.materialID, store.config.doors.list])
  )
  return MetrumColumnList(columnList, height, depth, grid, materialID, doors)
}

export const MetrumColumnList = (
  columnList: IMetrumConfig['columns']['list'],
  height: IMetrumConfig['main']['height'],
  depth: IMetrumConfig['main']['depth'],
  grid: IMetrumConfig['main']['grid'],
  materialID: IMetrumConfig['main']['materialID'],
  doors: IMetrumConfig['doors']['list']
) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const columnHeight = height
  const yPos = 0
  const columns = [{key: 0, xPos: startPos, yPos: yPos, zPos: 0, length: columnHeight, depth: depth, thickness: thickness, material: materialID, gapHeight: [] as number[], type: 'column'}]
  const colSize = (height - thickness) / grid

  columnList.forEach((el, index) => {
    startPos += el.width + thickness
    const gapHeight = [] as number[]
    const doorsLeft = doors.filter((door) => door.pos.x === index && door.side === 'left')
    const doorsRight = doors.filter((door) => door.pos.x === index + 1 && door.side === 'right' && door.pos.x < columnList.length)
    const gHeight = (Math.round(0.9 / (colSize / 2)) / 2) * colSize < height - 0.075 ? (Math.round(0.9 / (colSize / 2)) / 2) * colSize : thickness + (colSize / 2) * (grid * 2 - 1)
    if (doorsRight.length > 0 || doorsLeft.length > 0) gapHeight.push(gHeight)
    columns.push({
      key: index + 1,
      xPos: startPos,
      yPos: yPos,
      zPos: 0,
      length: columnHeight,
      depth: depth,
      thickness: thickness,
      material: materialID,
      gapHeight: gapHeight,
      type: index + 1 === columnList.length ? 'column' : 'middleColumn',
    })
  })

  return columns
}
