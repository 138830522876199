import {useStore} from 'products/tektur/store/store'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/tektur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {ITekturConfig} from '../store/initialConfig'

export const ConnectedTekturBackpanelList = () => {
  const [backpanelList, boards, shelfWidth, shelfMaterialID, backpanelMaterialID, grid] = useStore(
    useShallow((store) => [
      store.backpanels.clearBackpanels(),
      store.config.rows.list,
      store.config.main.width,
      store.config.main.materialID,
      store.config.backpanels.materialID,
      store.config.main.grid,
    ])
  )
  const wallMounted = useStore((store) => store.config.feet.id) === 'wallMounted'
  return TekturBackpanelList(backpanelList, boards, shelfWidth, shelfMaterialID, backpanelMaterialID, grid, wallMounted)
}

export const TekturBackpanelList = (
  backpanelList: ITekturConfig['backpanels']['list'],
  boards: ITekturConfig['rows']['list'],
  shelfWidth: ITekturConfig['main']['width'],
  shelfMaterialID: ITekturConfig['main']['materialID'],
  backpanelMaterialID: ITekturConfig['backpanels']['materialID'],
  grid: ITekturConfig['main']['grid'],
  wallMounted: boolean
) => {
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - 2 * regalModel.sideInset - shelfThickness) / grid
  const backpanels: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []
  if (wallMounted) {
    let yPos = shelfThickness
    boards.forEach((board, y) => {
      yPos += board.height + shelfThickness
      return [...Array(grid)].forEach((_, x) => {
        const xPos = x * gridSize + shelfThickness
        const width = gridSize - shelfThickness + 2 * regalModel.backpanelSideInset
        backpanels.push({
          key: y + '.' + x,
          xPos: xPos,
          yPos: yPos - board.height - shelfThickness,
          zPos: regalModel.backpanelInset,
          length: width,
          depth: board.height,
          thickness: backpanelThickness,
          material: backpanelMaterialID,
          type: 'backpanel',
        })
      })
    })
  } else {
    backpanelList.forEach((el) => {
      const yPos = boards.slice(0, el.pos.y).reduce((pv, cv) => pv + cv.height + shelfThickness, shelfThickness)
      const zPos = regalModel.backpanelInset
      const height = boards[el.pos.y].height
      if (el.cover === 'full') {
        const xPos = shelfThickness - regalModel.backpanelSideInset
        const width = shelfWidth - 2 * regalModel.sideInset - shelfThickness * 2 + 2 * regalModel.backpanelSideInset
        backpanels.push({key: el.pos.y + '-full', xPos: xPos, yPos: yPos, zPos: zPos, length: width, depth: height, thickness: backpanelThickness, material: backpanelMaterialID, type: 'backpanel'})
      } else {
        const width = gridSize - shelfThickness + 2 * regalModel.backpanelSideInset
        const xPos = el.pos.x * gridSize + shelfThickness - regalModel.backpanelSideInset
        backpanels.push({
          key: el.pos.x + '-' + el.pos.y,
          xPos: xPos,
          yPos: yPos,
          zPos: zPos,
          length: width,
          depth: height,
          thickness: backpanelThickness,
          material: backpanelMaterialID,
          type: 'backpanel',
        })
      }
    })
  }

  return backpanels
}
