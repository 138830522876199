import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import shallow from 'zustand/shallow'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, shelfMaterials, shelfTyp} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedTektur} from '../../threeComponents/Tektur'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import {ShelfStructureModals} from '../Shelf/ShelfStructureModals'
import ConnectedPrintView from 'products/tektur/print/PrintView'
import {ConnectedTekturPrice} from 'products/tektur/utils/price'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {TekturPriceList} from 'products/tektur/priceList/PriceList'

export const TekturView = () => {
  document.title = 'SANKTJOHANSER | TEKTUR'
  return (
    <Routes>
      <Route path="/" element={<ConnectedTekturContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/tektur" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="tektur" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          <Route path="structure/*" element={<ShelfStructureModals />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedPrintView />} />
      <Route path="pricelist" element={<TekturPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedTekturContainer = () => {
  const shelfMaterialId = useStore((store) => store.config.main.materialID)
  const [shelfWidth, boards] = useStore((store) => [store.config.main.width, store.config.rows.list], shallow)

  const thickness = shelfMaterials[shelfMaterialId].thickness
  const wallMounted = useStore((store) => store.config.feet.id) === 'wallMounted'
  const footHeight = useStore((store) => store.config.feet.height)

  const props = {
    shelfMaterialId: shelfMaterialId,
    height: Math.round(boards.reduce((acc, col) => acc + col.height, thickness) * 1000) / 1000,
    fullHeight: Math.round((boards.reduce((acc, col) => acc + col.height + thickness, thickness) + (!wallMounted ? footHeight : 0)) * 1000) / 1000,
    footHeight: footHeight,
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'single',
    depth: useStore((store) => store.config.main.depth),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    width: shelfWidth,
    title: shelfTyp[useStore((store) => store.config.main.shelfTyp)].name,
  }

  return (
    <ViewContainer {...props} price={<ConnectedTekturPrice />}>
      <FiberScene footHeight={footHeight}>
        <ConnectedTektur />
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
