import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from '../../store/datamodels'
import {ConnectedDasregalSubColumnList} from 'products/dasregal/partLists/subColumnList'

export const ConnectedSubColumns = () => {
  const subColumnList = ConnectedDasregalSubColumnList()
  if (subColumnList.length > 0) {
    const materialID = subColumnList[0].material
    const materialReference = shelfMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  }

  return (
    <>
      {subColumnList.map((board) => {
        // return <Board {...board} material={material[Math.round(Math.random())]} scene={props.scene} rotation={{x: 0, y: 0, z: 0.5}} translate={{x: 0.5, y: 0.5, z: 0.5}}/>
        return <Board {...board} size={[board.length, board.thickness, board.depth]} material={material[Math.round(Math.random())]} rotation={[0, 0, 0.5]} translate={[0.5, 0.5, 0.5]} />
      })}
    </>
  )
}
