import {Link} from 'react-router-dom'

export function ProductOverView(props: {title: string; products: {img: any; to: string}[]; withTitle?: boolean}) {
  return (
    <section key={`section-${props.title}`} id={props.title} className="section">
      <div className="product-box" id={props.title}>
        <div className="caption-box">{props.title}</div>
        <div className="image-box">
          {props.products.map((product, y) => (
            <Link key={y} to={product.to}>
              {product.img}
              {props.withTitle && <div className="title">{product.img.props.alt}</div>}
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}
