import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {bedMaterials} from 'products/iku/store/datamodels'
import {MitreBoard} from 'common/threeComponents/MitreBoard'
import {ConnectedIkuBoardList} from 'products/iku/partLists/boardList'

export const ConnectedBoards = () => {
  const boardList = ConnectedIkuBoardList()
  const materialID = boardList[0].material
  const materialReference = bedMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]
  return (
    <>
      {boardList.map((board) => {
        return <MitreBoard {...board} size={[board.length, board.thickness, board.depth]} material={material} />
      })}
    </>
  )
}
