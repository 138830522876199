import {materialModel} from 'utils/datamodels'
import {backpanelMaterials, footModel, frontMaterials, shelfMaterials} from './datamodels'

const initialColumns = [
  {
    width: 0.764,
  },
]

export const initialConfig = {
  main: {
    grid: 8,
    height: 2.222,
    materialID: 'm18Ei' as keyof typeof shelfMaterials,
    depth: 0.41,
    treatmentID: 0,
    roomDivider: false,
  },
  boards: {
    list: [[3, 4, 5, 6, 7]],
  },
  backpanels: {
    list: [{pos: {x: 0, y: 0}, cover: 'full' as 'full' | 'fullWidth' | 'compartment', face: 'back' as 'back' | 'front'}],
    materialID: 'mdf8W' as keyof typeof backpanelMaterials,
  },
  columns: {
    list: initialColumns,
  },
  doors: {
    materialID: 'm18MW' as keyof typeof frontMaterials,
    list: [
      {
        pos: {x: 0.5, y: 0},
        size: {x: 0.5, y: 3},
        side: 'right' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 0, y: 0},
        size: {x: 0.5, y: 3},
        side: 'left' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  drawers: {
    materialID: 'm18Ei' as keyof typeof frontMaterials,
    list: [] as {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  },
  flaps: {
    materialID: 'm18MW' as keyof typeof frontMaterials,
    list: [
      {
        pos: {x: 1, y: 2},
        size: {x: 1, y: 1},
        face: 'front' as 'front' | 'back',
      },
    ] as {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  },
  subColumns: {
    list: [{pos: {x: 0.5, y: 0}}],
  },
  tabelars: {
    list: [{pos: {x: 0, y: [7]}}, {pos: {x: 0.5, y: [7]}}],
  },
  feet: {
    height: 0.018,
    id: 'shv' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
}

export type IRegalConfig = typeof initialConfig
