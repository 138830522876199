import {baseUrl, bedMaterials} from '../store/datamodels'
import {UnisonoPrice} from 'products/unisono/utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors} from 'utils/datamodels'
import {UnisonoBoardList} from '../partLists/boardList'
import {UnisonoSlattedList} from '../partLists/slattedList'
import {UnisonoAccBoardList} from '../partLists/accBoardList'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'

export const UnisonoPriceList = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(bedMaterials)
  const materials = ['m24Ah', 'm24Ki', 'm25SB', 's25Bu', 's25Ei', 's25Ki', 's25NmS', 's25NwS', 's25NoS', 'l25Ash']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof bedMaterials))

  const widths = [1.4, 1.6, 1.8, 2]

  let priceListUnisonoWithHB: number[][] = []

  materials.forEach((material, i) => {
    priceListUnisonoWithHB.push([])
    widths.forEach((width) => {
      const boardList = UnisonoBoardList(2, width, 0.175, 0.125, material as keyof typeof bedMaterials, 0.125, 1, 0.455)
      const accBoardList = UnisonoAccBoardList(0.125, 2, width, 0.175, 0.125, 0.05, 'own')
      const slattedList = UnisonoSlattedList(0.125, 2, width, 0.175, 0.125, 'own', 0.05, 0.02)
      priceListUnisonoWithHB[i].push(UnisonoPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, [...boardList, ...accBoardList], slattedList).props.children)
    })
  })

  let priceListUnisonoWithOutHB: number[][] = []

  materials.forEach((material, i) => {
    priceListUnisonoWithOutHB.push([])
    widths.forEach((width) => {
      const boardList = UnisonoBoardList(2, width, 0.175, 0.125, material as keyof typeof bedMaterials, 0.125, 0, 0.455)
      const accBoardList = UnisonoAccBoardList(0.125, 2, width, 0.175, 0.125, 0.05, 'own')
      const slattedList = UnisonoSlattedList(0.125, 2, width, 0.175, 0.125, 'own', 0.05, 0.02)
      priceListUnisonoWithOutHB[i].push(UnisonoPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, [...boardList, ...accBoardList], slattedList).props.children)
    })
  })

  let priceListUnisonoSurfacesWithOutHB: number[][] = []
  ;[2, 3].forEach((surface, i) => {
    priceListUnisonoSurfacesWithOutHB.push([])
    widths.forEach((width) => {
      const boardList = UnisonoBoardList(2, width, 0.175, 0.125, 's25Ei' as keyof typeof bedMaterials, 0.125, 0, 0.455)
      const accBoardList = UnisonoAccBoardList(0.125, 2, width, 0.175, 0.125, 0.05, 'own')
      const slattedList = UnisonoSlattedList(0.125, 2, width, 0.175, 0.125, 'own', 0.05, 0.02)
      const iPrice = UnisonoPrice(localFactor.factor, 'm24Ei', surface, [...boardList, ...accBoardList], slattedList).props.children
      const oilPrice = UnisonoPrice(localFactor.factor, 'm24Ei', 0, [...boardList, ...accBoardList], slattedList).props.children
      const extraPrice = iPrice - oilPrice
      priceListUnisonoSurfacesWithOutHB[i].push(extraPrice)
    })
  })

  let priceListUnisonoSurfacesWithHB: number[][] = []
  ;[2, 3].forEach((surface, i) => {
    priceListUnisonoSurfacesWithHB.push([])
    widths.forEach((width) => {
      const boardList = UnisonoBoardList(2, width, 0.175, 0.125, 's25Ei' as keyof typeof bedMaterials, 0.125, 1, 0.455)
      const accBoardList = UnisonoAccBoardList(0.125, 2, width, 0.175, 0.125, 0.05, 'own')
      const slattedList = UnisonoSlattedList(0.125, 2, width, 0.175, 0.125, 'own', 0.05, 0.02)
      const iPrice = UnisonoPrice(localFactor.factor, 'm24Ei', surface, [...boardList, ...accBoardList], slattedList).props.children
      const oilPrice = UnisonoPrice(localFactor.factor, 'm24Ei', 0, [...boardList, ...accBoardList], slattedList).props.children
      const extraPrice = iPrice - oilPrice
      priceListUnisonoSurfacesWithHB[i].push(extraPrice)
    })
  })
  const surfaceNames = ['Aufpreis geseift', 'Aufpreis lackiert']

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>UNISONO mit hohem Kopfteil</h1>
        <PriceTable rows={materialNames} columns={widths} prices={priceListUnisonoWithHB} />
        <PriceTable rows={surfaceNames} columns={widths} prices={priceListUnisonoSurfacesWithHB} />
      </>
      <>
        <h1>UNISONO ohne Kopfteil</h1>
        <PriceTable rows={materialNames} columns={widths} prices={priceListUnisonoWithOutHB} />
        <PriceTable rows={surfaceNames} columns={widths} prices={priceListUnisonoSurfacesWithOutHB} />
      </>
    </>
  )
}
