import * as THREE from 'three'
import MultiplexFein from 'common/static/textures/multiply-edge.jpg'
import Ash from 'common/static/textures/ash.jpg'
import Oak from 'common/static/textures/oak.jpg'
import WalnutEuro from 'common/static/textures/walnut-euro.jpg'
import Maple from 'common/static/textures/maple.jpg'
import Birch from 'common/static/textures/birch.jpg'
import Beech from 'common/static/textures/beech.jpg'
import Cherry from 'common/static/textures/cherry.jpg'
import WalnutAmi from 'common/static/textures/walnut-ami.jpg'
import WalnutBlack from 'common/static/textures/walnut-black.jpg'
import Fir from 'common/static/textures/fir.jpg'
import Pine from 'common/static/textures/pine.jpg'
import {solidWood} from './Materials6'
import {materialModel} from 'utils/datamodels'

const repeat = {
  big: {x: 0.9, y: 0.7},
  small: {x: 0.2, y: 0.7},
}

export const MaterialSingleton = (function () {
  let instance
  return {
    getMaterials: function () {
      if (!instance) {
        instance = createMaterialsObject()
      }
      return instance
    },
  }
})()

export default function createMaterialsObject() {
  let materials = {
    0: getMaterialGroup(multiplexVeneer, Maple, 'mult maple'),
    1: getMaterialGroup(multiplexVeneer, Birch, 'mult birch'),
    2: getMaterialGroup(multiplexVeneer, Beech, 'mult beech'),
    3: getMaterialGroup(multiplexVeneer, Oak, 'mult oak'),
    4: getMaterialGroup(multiplexVeneer, Ash, 'mult ash'),
    5: getMaterialGroup(multiplexVeneer, Cherry, 'mult cherry'),
    6: getMaterialGroup(multiplexVeneer, WalnutAmi, 'mult walnut'),
    7: getMaterialGroup(getMultiplex, {color: 0xebebeb}, 'hpl mult'),
    8: getMaterialGroup(getMultiplex, {color: 0xebebeb}, 'hpl mult'),
    9: getMaterialGroup(getMultiplex, {color: 0x050505}, 'hpl mult'),
    10: getMaterialGroup(getMultiplex, {color: 0x090909}, 'hpl mult'),
    11: getMaterialGroup(solidWood, Maple, 'solid maple'),
    12: getMaterialGroup(solidWood, Birch, 'solid birch'),
    13: getMaterialGroup(solidWood, Beech, 'solid beech'),
    14: getMaterialGroup(solidWood, Oak, 'solid oak'),
    15: getMaterialGroup(solidWood, Ash, 'solid ash'),
    16: getMaterialGroup(solidWood, Cherry, 'solid cherry'),
    17: getMaterialGroup(solidWood, WalnutAmi, 'solid walnut'),
    18: getMaterialGroup(solidWood, WalnutEuro, 'solid walnut'),
    19: getMaterialGroup(solidWood, WalnutBlack, 'solid walnut'),
    sTa: getMaterialGroup(solidWood, Fir, 'solid fir'),
    sZi: getMaterialGroup(solidWood, Pine, 'solid pine'),
    hpl1: createBaseMaterial({color: 0x000000}, {color: 0xefefef}, 'hpl'),
    hplAlu: getMaterialGroup(createBaseMaterial, {color: 0xa9a9a9}, {color: 0x000000}, 'aluminium'),
    hplWhite: getMaterialGroup(createBaseMaterial, {color: 0xebebeb}, {color: 0x000000}, 'hplWhite'),
    hplPureWhite: getMaterialGroup(createBaseMaterial, {color: 0xebebeb}, {color: 0xf0f0f0}, 'hplWhite'),
    hplBlack: getMaterialGroup(createBaseMaterial, {color: 0x000000}, {color: 0x131313}, 'hplBlack'),
    hplOak: getMaterialGroup(HPLVeneer, Oak, 'hplOak'),
    hplNut: getMaterialGroup(HPLVeneer, WalnutAmi, 'hplNut'),
    solidBlack: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), {color: 0x111111, name: 'solidBlack'}),
    solidWhite: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), {color: 0xffffff, name: 'solidWhite'}),
    solidAlu: getMaterialGroup((p) => new THREE.MeshPhongMaterial(p), {color: 0x999999, name: 'solidAlu'}),
    selector: new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}),
    lCharcoal: getMaterialGroup(getLinoleum, {color: materialModel.l25Charcoal.color}, 'lcharcoal'),
    lAsh: getMaterialGroup(getLinoleum, {color: materialModel.l25Ash.color}, 'lash'),
    lNero: getMaterialGroup(getLinoleum, {color: materialModel.l25Nero.color}, 'lnero'),
    lBurgundy: getMaterialGroup(getLinoleum, {color: materialModel.l25Burgundy.color}, 'lburgundy'),
    lPewter: getMaterialGroup(getLinoleum, {color: materialModel.l25Pewter.color}, 'lpewter'),
    lPearl: getMaterialGroup(getLinoleum, {color: materialModel.l25Pearl.color}, 'lpearl'),
    lSalsa: getMaterialGroup(getLinoleum, {color: materialModel.l25Salsa.color}, 'lsalsa'),
    lConifer: getMaterialGroup(getLinoleum, {color: materialModel.l25Conifer.color}, 'lconifer'),
    lPebble: getMaterialGroup(getLinoleum, {color: materialModel.l25Pebble.color}, 'lpebble'),
    lMushroom: getMaterialGroup(getLinoleum, {color: materialModel.l25Mushroom.color}, 'lmushroom'),
    lMauve: getMaterialGroup(getLinoleum, {color: materialModel.l25Mauve.color}, 'lmauve'),
    lPistachio: getMaterialGroup(getLinoleum, {color: materialModel.l25Pistachio.color}, 'lpistachio'),
    lSpringGreen: getMaterialGroup(getLinoleum, {color: materialModel.l25SpringGreen.color}, 'lspringgreen'),
    lPowder: getMaterialGroup(getLinoleum, {color: materialModel.l25Powder.color}, 'lpowder'),
    lOlive: getMaterialGroup(getLinoleum, {color: materialModel.l25Olive.color}, 'lolive'),
    lOrangeBlast: getMaterialGroup(getLinoleum, {color: materialModel.l25OrangeBlast.color}, 'lorangeblast'),
    lSmokeyBlue: getMaterialGroup(getLinoleum, {color: materialModel.l25SmokeyBlue.color}, 'lsmokeyblue'),
    lAquavert: getMaterialGroup(getLinoleum, {color: materialModel.l25Aquavert.color}, 'laquavert'),
    lIronGrey: getMaterialGroup(getLinoleum, {color: materialModel.l25IronGrey.color}, 'lirongrey'),
    lVapour: getMaterialGroup(getLinoleum, {color: materialModel.l25Vapour.color}, 'lvapour'),
    lMidnightBlue: getMaterialGroup(getLinoleum, {color: materialModel.l25MidnightBlue.color}, 'lmidnightblue'),
    lClay: getMaterialGroup(getLinoleum, {color: materialModel.l25Clay.color}, 'lclay'),
    lLeather: getMaterialGroup(getLinoleum, {color: materialModel.l25Leather.color}, 'lleather'),
    lWalnut: getMaterialGroup(getLinoleum, {color: materialModel.l25Walnut.color}, 'lwalnut'),
  }
  return materials
}

/* GROUPS
   =============================================================== */

function getMaterialGroup(func, ...param) {
  return {
    big: func(...param, repeat.big),
    small_1: func(...param, repeat.small, {x: 0.5 * Math.random(), y: 0.1}),
    small_2: func(...param, repeat.small, {x: 0.3, y: Math.random()}),
  }
}

/* SINGLE MATERIALS
   =============================================================== */

//material face directions are (r,l,t,b,f,b)
function multiplexVeneer(veneer, name = '', faceRepeat = {x: 0.9, y: 0.6}, offset = {x: 0, y: 0}) {
  const faceTexture = createBaseTexture(veneer, faceRepeat, offset)
  return getMultiplex({map: faceTexture}, name)
}

function getMultiplex(face, name = '') {
  const edgeTexture = createBaseTexture(MultiplexFein, {x: 5, y: 1})
  const edge = {map: edgeTexture}
  return createBaseMaterial(face, edge, name)
}

function getLinoleum(face, name = '') {
  const edgeTexture = createBaseTexture(Oak, {x: 5, y: 1})
  const edge = {map: edgeTexture}
  return createBaseMaterial(face, edge, name)
}

function HPLVeneer(veneer, name = '', faceRepeat = {x: 1, y: 0.6}, offset = {x: 0.1, y: 0}) {
  const faceTexture = createBaseTexture(veneer, faceRepeat, offset)
  return createBaseMaterial({map: faceTexture}, {color: 0x000000}, name)
}

// Starting to DRY
// function genericMaterial(face, edge, name) {
//     const faceMat = face.map ? { map: createBaseTexture(face.map, face.repeat = { x: 1, y: 1 }, face.offset = { x: 0, y: 0 }) } : { color: face.color }
//     const edgeMat = edge.map ? { map: createBaseTexture(edge.map, edge.repeat = { x: 1, y: 1 }) } : { color: edge.color }
//     return createBaseMaterial(faceMat, edgeMat, name)
// }

/*  BASE MATERIALS
=============================================================== */

function createBaseMaterial(face, edge, name = '', side = {color: 0xffffff}) {
  const material = []
  material.name = name
  console.log(name, side)
  material.push(new THREE.MeshLambertMaterial({...edge, name: 'right'}))
  material.push(new THREE.MeshLambertMaterial({...edge, name: 'left'}))
  material.push(new THREE.MeshLambertMaterial({...face, name: 'top'}))
  material.push(new THREE.MeshLambertMaterial({...face, name: 'bottom'}))
  material.push(new THREE.MeshLambertMaterial({...edge, name: 'front'}))
  material.push(new THREE.MeshLambertMaterial({...edge, name: 'back'}))
  return material
}

export function createBaseTexture(textureImage, repeat = {x: 1, y: 1}, offset = {x: 0, y: 0}, rotation = 0, flipX = false) {
  const loader = new THREE.TextureLoader()
  const tex = loader.load(textureImage)
  tex.colorSpace = THREE.SRGBColorSpace
  tex.anisotropy = 8
  tex.flipX = flipX
  tex.rotation = Math.PI * rotation
  tex.wrapS = tex.wrapT = THREE.MirroredRepeatWrapping
  tex.repeat.set(repeat.x, repeat.y)
  tex.offset.set(offset.x, offset.y)
  return tex
}
