import {useStore} from 'products/tektur/store/store'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/tektur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {ITekturConfig} from '../store/initialConfig'

export const ConnectedTekturColumnList = () => {
  const [width, depth, materialID, grid, boards, backpanels, drawers, shelfTyp] = useStore(
    useShallow((store) => [
      store.config.main.width,
      store.config.main.depth,
      store.config.main.materialID,
      store.config.main.grid,
      store.config.rows.list,
      store.config.backpanels,
      store.config.drawers.list,
      store.config.main.shelfTyp,
    ])
  )
  return TekturColumnList(width, depth, materialID, grid, boards, backpanels, drawers, shelfTyp)
}

export const TekturColumnList = (
  width: ITekturConfig['main']['width'],
  depth: ITekturConfig['main']['depth'],
  materialID: ITekturConfig['main']['materialID'],
  grid: ITekturConfig['main']['grid'],
  boards: ITekturConfig['rows']['list'],
  backpanels: ITekturConfig['backpanels'],
  drawers: ITekturConfig['drawers']['list'],
  shelfTyp: ITekturConfig['main']['shelfTyp']
) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = thickness
  const gridsize = (width - thickness) / grid
  const backpanelThickness = backpanelMaterials[backpanels.materialID].thickness
  const columns: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []

  boards.forEach((el, index) => {
    const fullBackpanel =
      backpanels.list.filter((obj) => {
        return obj.pos.y === index && obj.cover === 'full'
      }).length > 0
    const zPos = fullBackpanel ? (backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    columns.push({key: index + '.start', xPos: 0, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    columns.push({key: index + '.end', xPos: width - thickness, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    for (let i = 1; i < grid; i++) {
      const isDrawerBeside =
        drawers.filter((drawer) => {
          return drawer.pos.y === index && (drawer.pos.x === i || drawer.pos.x === i - 1)
        }).length > 0
      const frontInset = shelfTyp === 2 ? 0 : isDrawerBeside ? regalModel.frontInset - 0.01 : regalModel.frontInset
      const adjustedDepth = depth - zPos - frontInset
      columns.push({key: index + '.' + i, xPos: i * gridsize, yPos: startPos, zPos: zPos, length: el.height, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'middleColumn'})
    }
    startPos += el.height + thickness
  })
  return columns
}
