import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {ConnectedEuklidPrice} from 'products/euklid/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedEuklid} from '../../threeComponents/Euklid'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedEuklidPrintView from 'products/euklid/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {EuklidPriceList} from 'products/euklid/priceList/PriceList'

export const EuklidView = () => {
  document.title = 'SANKTJOHANSER | EUKLID'
  return (
    <Routes>
      <Route path="/" element={<ConnectedEuklidContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/euklid" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="euklid" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedEuklidPrintView />} />
      <Route path="pricelist" element={<EuklidPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedEuklidContainer = () => {
  const [size, height] = useStore((store) => [store.config.main.size, store.config.main.height])

  const fullHeight = Math.round(height * 1000) / 1000
  const heightText = fullHeight

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: size,
    fullHeight: heightText,
    footHeight: 0,
    depth: size,
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    title: 'EUKLID',
  }

  return (
    <ViewContainer {...props} price={<ConnectedEuklidPrice />}>
      <FiberScene minViewHeight={2.2} minViewWidth={2.2} contactShadow={{opacity: 0.6, scale: 3}}>
        {<ConnectedEuklid />}
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
