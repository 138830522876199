import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {ConnectedIkuPrice} from 'products/iku/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, bedModel, headBoardModel} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedIku} from '../../threeComponents/Iku'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedIkuPrintView from 'products/iku/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {IkuPriceList} from 'products/iku/priceList/PriceList'

export const IkuView = () => {
  document.title = 'SANKTJOHANSER | IKU'
  return (
    <Routes>
      <Route path="/" element={<ConnectedIkuContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/iku" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="iku" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          {/*<Route path="structure/*" element={<ShelfStructureModals />} />*/}
        </Route>
      </Route>
      <Route path="print" element={<ConnectedIkuPrintView />} />
      <Route path="pricelist" element={<IkuPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedIkuContainer = () => {
  const [length, width, frameHeight, footHeight, headBoardID, headBoardHeight, frameFlush, footID, slattedId, stackable] = useStore((store) => [
    store.config.main.length,
    store.config.main.width,
    store.config.main.frameHeight,
    store.config.feet.height,
    store.config.main.headBoard.id,
    store.config.main.headBoard.height,
    store.config.main.frameFlush,
    store.config.feet.id,
    store.config.slatted.id,
    store.config.main.stackable,
  ])
  const headBoard = headBoardID !== 0
  const fullHeight = Math.round((frameHeight + footHeight + (headBoard ? headBoardHeight : 0)) * 1000)
  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const upperFrameHeightStacked = Math.round((2 * frameHeight + 0.005 + footHeight) * 1000)
  const heightText = headBoard ? upperFrameHeight + '/' + fullHeight : stackable ? upperFrameHeight.toString() + '/' + upperFrameHeightStacked.toString() : upperFrameHeight.toString()
  const additionalFootTitle = footID === 'bedRct' ? 'F' : ''
  const additionalTitle =
    (headBoard ? headBoardModel[headBoardID].short + additionalFootTitle : frameFlush ? 'B' + additionalFootTitle : footID === 'bedRnd' ? 'R' : 'F') + (slattedId === 'integrated' ? '+' : '')

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: frameFlush ? length : headBoardID > 1 ? length + bedModel.nominalThickness : length + 2 * bedModel.nominalThickness,
    fullHeight: heightText,
    footHeight: footHeight,
    depth: frameFlush ? width : headBoardID > 1 ? width + bedModel.nominalThickness : width + 2 * bedModel.nominalThickness,
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    title: 'IKU ' + Math.round(frameHeight * 100) + additionalTitle,
  }

  return (
    <ViewContainer {...props} price={<ConnectedIkuPrice />}>
      <FiberScene footHeight={footHeight}>{<ConnectedIku />}</FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
