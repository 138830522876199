import './../overview.scss'
import euklid_1 from 'common/static/overview/euklid-1.jpg'
import euklid_2 from 'common/static/overview/euklid-2.jpg'
import euklid_3 from 'common/static/overview/euklid-3.jpg'
import euklid_4 from 'common/static/overview/euklid-4.jpg'
import euklid_5 from 'common/static/overview/euklid-5.jpg'
import euklid_6 from 'common/static/overview/euklid-6.jpg'
import euklid_7 from 'common/static/overview/euklid-7.jpg'
import euklid_8 from 'common/static/overview/euklid-8.jpg'
import euklid_9 from 'common/static/overview/euklid-9.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedEuklidOverView() {
  return <ProductOverView title={'euklid'} products={euklid} withTitle />
}

export const euklid = [
  {img: <img className="overview-image" src={euklid_1} alt="Euklid RL 50/33" />, to: 'get/euklid-rl5033'},
  {img: <img className="overview-image" src={euklid_2} alt="Euklid RX 60/40" />, to: 'get/euklid-rx6040'},
  {img: <img className="overview-image" src={euklid_3} alt="Euklid RT 40/47" />, to: 'get/euklid-rt4047'},
  {img: <img className="overview-image" src={euklid_4} alt="Euklid QX 50/33" />, to: 'get/euklid-qx5033'},
  {img: <img className="overview-image" src={euklid_5} alt="Euklid QL 40/40" />, to: 'get/euklid-ql4040'},
  {img: <img className="overview-image" src={euklid_6} alt="Euklid QT 60/47" />, to: 'get/euklid-qt6047'},
  {img: <img className="overview-image" src={euklid_7} alt="Euklid QX 80/72" />, to: 'get/euklid-qx8072'},
  {img: <img className="overview-image" src={euklid_8} alt="Euklid RX 80/72" />, to: 'get/euklid-rx8072'},
  {img: <img className="overview-image" src={euklid_9} alt="Euklid RX 80/110" />, to: 'get/euklid-rx80110'},
]
