import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from './../../store/datamodels'

export const ConnectedColumnSelectors = () => {
  const [columns, materialID, depth, height, visible] = useStore(
    (store) => [store.config.columns, store.config.main.materialID, store.config.main.depth, store.config.main.height, store.view.columns.edit],
    shallow
  )
  const deleteColumn = useStore((store) => store.columns.delete)
  const addColumn = useStore((store) => store.columns.create)
  if (!visible) return null
  const thickness = shelfMaterials[materialID].thickness
  const totalWidth = columns.list.reduce((acc, col) => acc + col.width + thickness, thickness)
  return (
    <>
      {columns.list.length > 1 && <Selector key={'delete'} rotation={[0, 0, 0.5]} size={[height, thickness, depth]} xPos={totalWidth} yPos={0} zPos={0} onClick={deleteColumn} />}
      <Selector key={'add'} rotation={[0, 0, 0.5]} size={[height, thickness, depth]} xPos={totalWidth + regalModel.newColumWidth} yPos={0} zPos={0} onClick={addColumn} />
    </>
  )
}
