import {useStore} from 'products/unisono/store/store'
import {Brackets} from 'common/threeComponents/Brackets'
import {Fragment} from 'react'

export const ConnectedBrackets = () => {
  const [frameHeight, length, width, sideBoardWidth, footBoardWidth, slattedInsetstore, slattedId] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.length,
    store.config.main.width,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
    store.config.slatted.inset,
    store.config.slatted.id,
  ])

  if (slattedId === 'integrated') {
    return null
  }

  const nrOfSlats = width > 1.4 ? 2 : 1
  const slattedInset = slattedInsetstore
  const xPos = [...Array(4)].map((_, i) => footBoardWidth + 0.175 + (i * (length - 0.35)) / 3)

  return (
    <>
      {xPos.map((x, i) => (
        <Fragment key={i}>
          <Brackets key={'l' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth} />

          <Brackets key={'r' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth + width} rotation={[0, 1, 0]} />
        </Fragment>
      ))}
      {nrOfSlats === 2 && <Brackets xPos={footBoardWidth} yPos={frameHeight - slattedInset - 0.002 - 0.018} zPos={sideBoardWidth + width / 2} rotation={[0, 0.5, 0]} />}
      {nrOfSlats === 2 && <Brackets xPos={footBoardWidth + length} yPos={frameHeight - slattedInset - 0.002 - 0.018} zPos={sideBoardWidth + width / 2} rotation={[0, -0.5, 0]} />}
    </>
  )
}
