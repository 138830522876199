import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from './../../store/datamodels'
import {materialModel} from 'utils/datamodels'

export const ConnectedDrawerSelectors = () => {
  const visible = useStore((store) => store.view.drawers.edit)
  const [columns, boards, tabelars, height, grid, depth] = useStore(
    (store) => [store.config.columns, store.config.boards.list, store.config.tabelars, store.config.main.height, store.config.main.grid, store.config.main.depth],
    shallow
  )
  const drawers = useStore((store) => store.config.drawers)
  const thickness = materialModel[useStore((store) => store.config.drawers.materialID)].thickness
  const gridSize = (height - thickness) / grid
  const drawerSize = useStore((store) => store.view.drawers.variant)
  const drawerHeight = drawerSize * (gridSize - thickness)
  const maxCols = grid * (Math.round((height - thickness) / (grid * 0.065)) + 1)
  let startPos = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const setDrawer = useStore((store) => store.drawers.setDrawer)

  return (
    <>
      {visible &&
        columns.list.map((el, i) => {
          startPos += el.width + thickness
          return [...Array(grid)].map((_, x) => {
            const yPos = (x + 1) * gridSize - drawerHeight
            const xPos = startPos - el.width
            if (yPos < 0) return null
            const isBoard = boards[i].includes(x + 1) || grid - 1 === x
            const width = el.width
            const posX = xPos
            const isTabelar = tabelars.list.filter((el) => el.pos.x === i && el.pos.y.includes((maxCols / grid) * (x + 1))).length > 0
            const pos = {x: i, y: x + 1 - drawerSize}
            const isDrawerAbove = drawers.list.filter((el) => el.pos.x === pos.x && el.pos.y === pos.y + drawerSize).length > 0
            if ((!isBoard && !isTabelar) || isDrawerAbove) return null
            return (
              <Selector
                size={[drawerHeight, thickness, width]}
                rotation={[0.5, 0.5, 0]}
                key={pos.x + '-' + pos.y}
                xPos={posX}
                yPos={yPos}
                zPos={depth - thickness + 0.01}
                onClick={() => setDrawer(pos, {y: drawerSize})}
              />
            )
          })
        })}
      {visible &&
        drawers.list.map((drawer, y) => {
          const yPos = drawers.list[y].pos.y * gridSize - drawerHeight
          const xPosIndex = Math.floor(drawer.pos.x)
          if (xPosIndex >= columns.list.length) return null
          const width = columns.list[xPosIndex].width
          const posX = columns.list.slice(0, xPosIndex).reduce((pv, cv) => pv + cv.width + thickness, thickness)
          if (yPos <= 0) return null
          const pos = {x: drawer.pos.x, y: drawer.pos.y - drawerSize}
          return (
            <Selector
              size={[drawerHeight, thickness, width]}
              rotation={[0.5, 0.5, 0]}
              key={pos.x + '-' + pos.y}
              xPos={posX}
              yPos={yPos}
              zPos={depth - thickness + 0.01}
              onClick={() => setDrawer(pos, {y: drawerSize})}
            />
          )
        })}
    </>
  )
}
