import {useCallback, useRef} from 'react'
import {useFrame} from '@react-three/fiber'
import * as THREE from 'three'
import {apertureAngles} from 'utils/datamodels'
import {Board, IVector, Selector} from './FiberBoard'
export interface IDoor {
  width: number
  height: number
  material: THREE.Material | THREE.Material[]
  thickness: number
  xPos: number
  yPos: number
  zPos: number
  side: 'left' | 'right'
}

export const DoorFC = (props: IDoor) => {
  const cubeRef = useRef<THREE.Mesh>()
  const isActive = useRef(false)

  let xPos = useRef(props.xPos)
  let rotation = useRef<IVector>([0, 0.5, 0.5])
  let translate = useRef<IVector>([0.5, 0.5, 0.5])
  let opentranslate = useRef<number>(apertureAngles.doors[1])
  if (props.side === 'left') {
    xPos.current = props.xPos
    rotation.current = [0, 0.5, 0.5]
    translate.current = [0.5, 0.5, 0.5]
    opentranslate.current = apertureAngles.doors[1]
  } else {
    xPos.current = props.xPos + props.width
    rotation.current = [0, -0.5, 0.5]
    translate.current = [0.5, -0.5, 0.5]
    opentranslate.current = -apertureAngles.doors[1]
  }

  const onMouseOver = useCallback(() => {
    isActive.current = true
  }, [])

  const onMouseOut = useCallback(() => {
    isActive.current = false
  }, [])

  useFrame((state, delta) => {
    const door = cubeRef.current
    if (door) {
      door.rotation.y = THREE.MathUtils.lerp(door.rotation.y, isActive.current ? opentranslate.current : rotation.current[1] * Math.PI, 0.1)
    }
  })

  return (
    <>
      <Board ref={cubeRef} {...props} xPos={xPos.current} size={[props.height, props.thickness, props.width]} translate={translate.current} rotation={rotation.current} />
      <Selector
        {...props}
        material={new THREE.MeshBasicMaterial({alphaTest: 2})}
        size={[props.height, props.thickness, props.width]}
        rotation={[0, 0.5, 0.5]}
        onPointerOver={onMouseOver}
        onPointerOut={onMouseOut}
      />
    </>
  )
}
