import {useStore} from 'products/dasregal/store/store'
import {shallow} from 'zustand/shallow'
import {backpanelMaterials, footModel, regalModel, shelfMaterials} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'
import {IPos} from 'products/analog'

export const ConnectedDasregalBackpanelList = () => {
  const [backpanelList, columns, boards, subColumns, shelfHeight, shelfMaterialID, backpanelMaterialID, grid, feetID] = useStore(
    (state) => [
      state.config.backpanels.list,
      state.config.columns.list,
      state.config.boards.list,
      state.config.subColumns.list,
      state.config.main.height,
      state.config.main.materialID,
      state.config.backpanels.materialID,
      state.config.main.grid,
      state.config.feet.id,
    ],
    shallow
  )

  return DasregalBackpanelList(backpanelList, columns, boards, subColumns, shelfHeight, shelfMaterialID, backpanelMaterialID, grid, feetID)
}

export const DasregalBackpanelList = (
  backpanelList: any[],
  columns: any[],
  boards: any[][],
  subColumns: {pos: IPos}[],
  shelfHeight: number,
  shelfMaterialID: keyof typeof shelfMaterials,
  backpanelMaterialID: keyof typeof backpanelMaterials,
  grid: number,
  feetID: keyof typeof footModel
) => {
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const wallMounted = feetID === 'wallMounted'
  const gridSize = (shelfHeight - shelfThickness) / grid
  const backpanels: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []
  const fullBackpanels = backpanelList.map((el) => {
    if (el.cover === 'full') {
      return el.pos.x
    } else {
      return null
    }
  })

  backpanelList.forEach((el) => {
    const xIndex = Math.floor(el.pos.x)
    if (xIndex < columns.length) {
      const innerXPos = el.pos.x - xIndex
      const xPos = columns.slice(0, xIndex).reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness + (columns[xIndex].width + shelfThickness) * innerXPos) - regalModel.backpanelSideInset
      const zPos = wallMounted ? regalModel.backpanelWallMountedInset : backpanelThickness < 0.016 ? regalModel.backpanelInset : 0.001
      if (el.cover === 'full') {
        const yPos = shelfThickness - regalModel.backpanelSideInset
        const width = columns[xIndex].width + shelfThickness - shelfThickness + 2 * regalModel.backpanelSideInset
        const height = shelfHeight - 2 * shelfThickness + 2 * regalModel.backpanelSideInset
        backpanels.push({key: el.pos.x + '-full', xPos: xPos, yPos: yPos, zPos: zPos, length: height, depth: width, thickness: backpanelThickness, material: backpanelMaterialID, type: 'backpanel'})
      } else {
        if (el.pos.y < grid && !fullBackpanels.includes(Math.floor(el.pos.x))) {
          const nextBoard = Math.min(...boards[Math.floor(el.pos.x)].filter((y) => y > el.pos.y), grid)
          const subColumnList = accHelper.get_SubColumn_List({x: xIndex, y: el.pos.y}, columns, boards, subColumns)
          const nextSubColumn = Math.min(
            ...subColumnList.filter((obj) => {
              return obj > innerXPos
            })
          )
          const multiplier = nextSubColumn - innerXPos
          const width =
            el.cover === 'fullWidth'
              ? columns[xIndex].width + 2 * regalModel.backpanelSideInset
              : (columns[xIndex].width + shelfThickness) * multiplier - shelfThickness + 2 * regalModel.backpanelSideInset
          const yPos = el.pos.y * gridSize + shelfThickness - regalModel.backpanelSideInset
          const height = (nextBoard - el.pos.y) * gridSize - shelfThickness + 2 * regalModel.backpanelSideInset
          backpanels.push({
            key: el.pos.x + '-' + el.pos.y,
            xPos: xPos,
            yPos: yPos,
            zPos: zPos,
            length: height,
            depth: width,
            thickness: backpanelThickness,
            material: backpanelMaterialID,
            type: 'backpanel',
          })
        }
      }
    }
  })

  return backpanels
}
