import {materialModel, materialTreatments, w, localFactors} from './../../../utils/datamodels' // @Todo check with Matthias if we can use global workprices
import {ConnectedKubaturBoardList} from '../partLists/boardList'
import {ConnectedKubaturColumnList} from '../partLists/columnList'
import {ConnectedKubaturSubColumnList} from '../partLists/subColumnList'
import {ConnectedKubaturTabelarList} from '../partLists/tabelarList'
import {doorPrice, drawerPrice, price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from '../store/store'
import {ConnectedKubaturBackpanelList} from '../partLists/backpanelList'
import {ConnectedKubaturDrawerList} from '../partLists/drawerList'
import {ConnectedKubaturFootBarList, ConnectedKubaturFootList} from '../partLists/footList'
import {footModel} from '../store/datamodels'
import {ConnectedKubaturSlidingDoorList} from '../partLists/slidingDoorList'
import {ConnectedKubaturClothingRailList} from '../partLists/clothingRailList'
import {ConnectedKubaturDoorList} from '../partLists/doorList'
import {IKubaturConfig} from '../store/initialConfig'
import {IVector} from 'common/threeComponents/FiberBoard'

export const ConnectedKubaturPrice = () => {
  const localFactor = localFactors[useCommonStore((store) => store.localFactorID)].factor
  const shelfMaterialID = useStore((state) => state.config.main.materialID)
  const treatmentID = useStore((state) => state.config.main.treatmentID)
  const partList = getPartList()
  const footList = ConnectedKubaturFootList()
  const clothingRailList = ConnectedKubaturClothingRailList()
  const doorList = ConnectedKubaturDoorList()
  const drawerList = ConnectedKubaturDrawerList()
  return KubaturPrice(localFactor, shelfMaterialID, treatmentID, partList, footList, clothingRailList, doorList, drawerList)
}

export const KubaturPrice = (
  localFactor: number,
  shelfMaterialID: IKubaturConfig['main']['materialID'],
  treatmentID: number,
  partList: any[],
  footList: {
    key: string
    pos: IVector
    size: IVector
    material: keyof typeof materialModel
    type: keyof typeof footModel
  }[],
  clothingRailList: any[],
  doorList: any[],
  drawerList: any[]
) => {
  const hasTreatment = materialModel[shelfMaterialID].treatment
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = hasTreatment ? treatment.setup : 0
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  let priceClothingRail = 0
  let priceDoors = 0
  let priceDrawers = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice)
    priceCNC += getCNCPrice(part)
  })
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })

  clothingRailList.forEach((clothingRail) => {
    priceClothingRail += w.Kleiderstange
  })

  doorList.forEach((door) => {
    priceDoors += doorPrice(door, treatmentPrice).totalPrice
  })

  drawerList.forEach((drawer) => {
    priceDrawers += drawerPrice(drawer, treatmentPrice).totalPrice
  })

  const price = Math.round(localFactor * 10 * Math.round((w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceClothingRail + priceFeet + priceDoors + priceDrawers)) / 10))
  return <>{price}</>
}

const getPartList = () => {
  const slidingDoorPartList = ConnectedKubaturSlidingDoorList()
    .map((door) => Object.values(door.parts).map((el) => el))
    .flat() as any[]
  const partList = [
    ...ConnectedKubaturBoardList(),
    ...ConnectedKubaturColumnList(),
    ...ConnectedKubaturSubColumnList(),
    ...ConnectedKubaturTabelarList(),
    ...ConnectedKubaturBackpanelList(),
    ...ConnectedKubaturFootBarList(),
    ...slidingDoorPartList,
  ]
  return partList
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'column':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
    case 'middleColumn':
    case 'subColumn':
    case 'bottom':
      const nrClamex = 4 + (part.depth > 0.4 ? Math.ceil((part.depth - 0.4) / 0.2) : 0)
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrClamex * w.ClamexBeschlag]})
      break
    case 'top':
      const nrClamexBottom = 4 + (part.depth > 0.4 ? Math.ceil((part.depth - 0.4) / 0.2) : 0)
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrClamexBottom * w.ClamexBeschlag + part.length * w.SchiebetuerSchiene * w.Verschnitt]})
      break
    case 'slidingDoorFront':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
    case 'slidingDoorBase':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [w.AusrichtBeschlag + w.SchiebetuerBeschlag]})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'top':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitSchiebetuerSchiene]})
      break
    case 'backpanel':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitRW]})
      break
    case 'slidingDoorBase':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitSchiebetuer]})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'top':
    case 'bottom':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 5, nrDrills: 20, format: true, bevel: true})
      break
    case 'board':
    case 'tabelar':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 2, nrDrills: 4, format: true, bevel: true})
      break
    case 'column':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3.4, nrDrills: 20 + 4 * Math.floor((part.length - 0.25) / 0.064), nrGrooves: 5, format: true, bevel: true})
      break
    case 'middleColumn':
    case 'subColumn':
      price = price_CNC({
        length: part.length,
        width: part.depth,
        nrSetups: 2,
        nrToolChanges: 3.4,
        nrDrills: 40 + 2 * 4 * Math.floor((part.length - 0.25) / 0.064),
        nrGrooves: 10,
        format: true,
        bevel: true,
      })
      break
    case 'backpanel':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
  }
  return price
}
