import {IStore, ISetProduce} from '../store'
import {accHelper} from 'utils/helpers'
import {frontMaterials} from '../datamodels'

export const createDoorSlice = (setProduce: ISetProduce<IStore>) => {
  const doors = {
    setDoor: function (pos: {x: number; y: number}, size: {x: number; y: number}, side: 'left' | 'right'): void {
      setProduce((state: IStore) => {
        const clearedDoorArray = state.config.doors.list.filter((door) => {
          if (accHelper.is_overlapping_with_other(pos, door.pos, size, door.size)) return false
          return true
        })
        if (state.config.doors.list.find((door) => accHelper.same_is_at_position(pos, door.pos, size, door.size, side, door.side))) {
          state.config.doors.list = clearedDoorArray
        } else {
          state.config.doors.list = [
            ...clearedDoorArray,
            {
              pos: pos,
              size: size,
              side: side,
              face: 'front',
            },
          ]
        }
        const clearedDrawerArray = state.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, size, drawer.size) && drawer.inside === false) return false
          return true
        })
        state.config.drawers.list = clearedDrawerArray
      })
    },
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.doors.visible = show
      })
    },
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.doors.variant = variant
      })
    },
    setSize: function (size: number): void {
      setProduce((state: IStore) => {
        state.view.doors.size = size
      })
    },
    setMaterialID: (newMaterialID: keyof typeof frontMaterials): void =>
      setProduce((state: IStore) => {
        state.config.doors.materialID = newMaterialID
      }),
    setEditDoors: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.doors.edit = edit
      }),
  }

  return {
    doors,
  }
}
