import {useStore} from 'products/digital/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'
import {flapMaterials} from 'products/digital/store/datamodels'

export function Flaps() {
  const flapMaterial = useStore((store) => store.config.flaps.materialID)
  const setMaterialID = useStore((store) => store.flaps.setMaterialID)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(flapMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof flapMaterials)}
  })

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={flapMaterial} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
