import {IStore, ITabelarActions} from '../..'
import {accHelper} from 'utils/helpers'
import {GetState} from 'zustand'
import {clearTabelars} from 'products/analog/utils/helpers'

export const createTablearSlice = (setProduce: any, get: GetState<IStore>) => {
  const tabelars: ITabelarActions = {
    create: function (pos: {x: number; y: number}): void {
      setProduce((state: IStore) => {
        const tabelarList = state.config.tabelars.list
        const indexOfPos = tabelarList.findIndex((tabelar) => accHelper.same_is_at_position(pos, tabelar.pos, {x: 1, y: 1}, {x: 1, y: 1}))
        indexOfPos === -1 ? state.config.tabelars.list.push({pos}) : state.config.tabelars.list.splice(indexOfPos, 1)
      })
    },
    clearTabelars: () => {
      const clearedTabelars = clearTabelars(get().config.tabelars.list, get().config.rows.list, get().config.columns.list, get().config.main.grid)
      return clearedTabelars
    },
  }
  return {
    tabelars,
  }
}
