import {useStore} from 'products/metrum/store/store'
import {regalModel, shelfMaterials, frontMaterials} from 'products/metrum/store/datamodels'
import {accHelper} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'
import {IMetrumConfig} from '../store/initialConfig'

export const ConnectedMetrumDoorList = () => {
  const [doorList, depth, shelfHeight, columns, grid, shelfMaterial, doorMaterial] = useStore(
    useShallow((store) => [
      store.config.doors.list,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.doors.materialID,
    ])
  )
  return MetrumDoorList(doorList, depth, shelfHeight, columns, grid, shelfMaterial, doorMaterial)
}

export const MetrumDoorList = (
  doorList: IMetrumConfig['doors']['list'],
  depth: IMetrumConfig['main']['depth'],
  shelfHeight: IMetrumConfig['main']['height'],
  columns: IMetrumConfig['columns']['list'],
  grid: IMetrumConfig['main']['grid'],
  shelfMaterial: IMetrumConfig['main']['materialID'],
  doorMaterial: IMetrumConfig['doors']['materialID']
) => {
  const shelfThickness = shelfMaterials[shelfMaterial].thickness
  const doorThickness = frontMaterials[doorMaterial].thickness
  const gridSize = (shelfHeight - shelfThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const doors: {
    key: string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    material: keyof typeof frontMaterials
    side: 'left' | 'right'
    type: string
  }[] = []
  doorList.forEach((door, j) => {
    if (accHelper.is_in_column(door.pos.x, columns.length)) {
      const extraHeight = door.pos.y + door.size.y === grid ? shelfThickness + shadowJoint : 0
      const height = door.size.y * gridSize - shadowJoint + extraHeight
      const width = columns[door.pos.x].width - 2 * shadowJoint
      const xPos = columns.slice(0, door.pos.x).reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness + shadowJoint)
      const yPos = door.pos.y * gridSize
      doors.push({
        key: xPos + '-' + yPos,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - doorThickness - 0.001,
        length: height,
        depth: width,
        thickness: doorThickness,
        material: doorMaterial,
        side: door.side,
        type: 'door',
      })
    }
  })

  return doors
}
