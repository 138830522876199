import {materialModel} from 'utils/datamodels'

export const tableModel = {
  minSize: 0.4,
  maxSize: 1.2,
}

export const baseUrl = '/euklid'

export const frameModel = {
  T: {id: 'T', name: 'T-Gestell', short: 'T', maxHeight: 0.55},
  L: {id: 'L', name: 'L-Gestell', short: 'L', maxHeight: 0.55},
  X: {id: 'X', name: 'X-Gestell', short: 'X', maxHeight: 1.2},
}

export const shapeModel = {
  R: {id: 'R', name: 'rund', short: 'R', baseSize: 0.26, sizeStep: 0.06, sizeFactor: 0.6},
  Q: {id: 'Q', name: 'quadratisch', short: 'Q', baseSize: 0.286, sizeStep: 0.069, sizeFactor: 0.74},
}

export const tableMaterials = {
  s25Ah: {...materialModel['s25Ah'], altMaterial: 's30Ah'},
  s25Bu: {...materialModel['s25Bu'], altMaterial: 's30Bu'},
  s25Ei: {...materialModel['s25Ei'], altMaterial: 's30Ei'},
  s25Es: {...materialModel['s25Es'], altMaterial: 's30Es'},
  s25Ki: {...materialModel['s25Ki'], altMaterial: 's30Ki'},
  s25NmS: {...materialModel['s25NmS'], altMaterial: 's30NmS'},
  s25NwS: {...materialModel['s25NwS'], altMaterial: 's30NwS'},
  s25NoS: {...materialModel['s25NoS'], altMaterial: 's30NoS'},

  s30Ah: {...materialModel['s30Ah'], altMaterial: 's25Ah'},
  s30Bu: {...materialModel['s30Bu'], altMaterial: 's25Bu'},
  s30Ei: {...materialModel['s30Ei'], altMaterial: 's25Ei'},
  s30Es: {...materialModel['s30Es'], altMaterial: 's25Es'},
  s30Ki: {...materialModel['s30Ki'], altMaterial: 's25Ki'},
  s30NmS: {...materialModel['s30NmS'], altMaterial: 's25NmS'},
  s30NwS: {...materialModel['s30NwS'], altMaterial: 's25NwS'},
  s30NoS: {...materialModel['s30NoS'], altMaterial: 's25NoS'},
}
