export function Contact() {
  return (
    <div className="contact">
      Sie haben eine Frage oder <br />
      wünschen eine Beratung?
      <br />
      <br />
      Sie möchten eine individuelle Sonderanfertigung?
      <br />
      <br />
      Kontaktieren Sie uns telefonisch <br />
      oder per Email:
      <br />
      <br />
      <a href="tel: +49 8846 914 211">+49 8846 914211</a>
      <br />
      <a href="mailto: moebel@sanktjohanser.net">moebel@sanktjohanser.net</a>
    </div>
  )
}
