import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import 'typeface-roboto'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {createTheme, ThemeProvider} from '@mui/material'

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = createRoot(container)

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          primary: '#000',
        },
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
            fontWeight: 800,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
            fontWeight: 600,
            cursor: 'pointer',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            fontWeight: 600,
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent',
            fontWeight: 800,
          },
          paddingRight: '0px',
          paddingLeft: '0px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: '33px',
          minHeight: '33px',
          padding: '0px',
          boxShadow: '0px 0px 0px 0px',
          '&.Mui-expanded': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 0px 33px 0px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 0px 0px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: '2px',
          borderRadius: '0px',
          ':hover': {
            width: '4px',
            boxShadow: '0px 0px 0px 0px',
          },
        },
        markLabel: {
          fontSize: '12px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          padding: '0px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#000 !important',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: '3px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
  },
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
//   ,
//   document.getElementById('root')
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
