import {DimensionButton} from 'components/dimension_controls/DimensionButton'
import {shelfMaterials} from 'products/dasregal/store/datamodels'
import {useStore} from 'products/dasregal/store/store'
import shallow from 'zustand/shallow'

export const ConnectedDimensionControls = () => {
  const [columns, depth, height, materialID, footID, visible] = useStore(
    (store) => [store.config.columns.list, store.config.main.depth, store.config.main.height, store.config.main.materialID, store.config.feet.id, store.view.columns.edit],
    shallow
  )
  const wallMounted = footID === 'wallMounted'
  const feetHeight = useStore((store) => store.config.feet.height)
  const footHeight = !wallMounted ? feetHeight : 0
  const shelfThickness = shelfMaterials[materialID].thickness
  const totalHeight = height + footHeight
  const totalWidth = columns.reduce((acc, cur) => acc + cur.width + shelfThickness, shelfThickness)
  let xPos = shelfThickness / 2
  return (
    <>
      {visible &&
        [...Array(columns.length)].map((_, i) => {
          xPos += columns[i].width + shelfThickness
          return (
            <DimensionButton
              key={i}
              position={[xPos - (columns[i].width + shelfThickness) / 2, 0, depth / 2]}
              bottom={100}
              url={'structure/cols/' + i}
              linktext={Math.round(columns[i].width * 1000)}
            />
          )
        })}
      {visible && <DimensionButton key={'height'} position={[totalWidth / 2, height / 2, 0]} left={50} url={'structure/height/'} linktext={Math.round(totalHeight * 1000)} />}
    </>
  )
}
