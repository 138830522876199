import {useStore} from 'products/dasregal/store/store'
import {useShallow} from 'zustand/react/shallow'
import {regalModel, shelfMaterials} from 'products/dasregal/store/datamodels'

export const ConnectedDasregalColumnList = () => {
  const [columnList, height, depth, materialID, feetID, flaps] = useStore(
    useShallow((store) => [store.config.columns.list, store.config.main.height, store.config.main.depth, store.config.main.materialID, store.config.feet.id, store.config.flaps.list])
  )

  return DasregalColumnList(columnList, height, depth, materialID, feetID, flaps)
}

export const DasregalColumnList = (
  columnList: any[],
  height: number,
  depth: number,
  materialID: keyof typeof shelfMaterials,
  feetID: string,
  flaps: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[]
) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const wallMounted = feetID === 'wallMounted'
  const columnHeight = height > 1.6 ? height : height - 2 * thickness
  const yPos = height > 1.6 ? 0 : thickness
  const columns = [
    {key: 'start' as number | string, xPos: startPos, yPos: yPos, zPos: 0, length: columnHeight, depth: depth, thickness: thickness, material: materialID, wallMounted: wallMounted, type: 'column'},
  ]

  columnList.forEach((el, index) => {
    startPos += el.width + thickness
    const isFlapInFront = flaps.filter((flap) => flap.pos.x < index + 1 && flap.pos.x + flap.size.x > index + 1).length > 0
    const adjustedDepth = isFlapInFront ? depth - regalModel.frontInset : depth
    columns.push({
      key: index + 1 === columnList.length ? 'end' : index + 1,
      xPos: startPos,
      yPos: yPos,
      zPos: 0,
      length: columnHeight,
      depth: adjustedDepth,
      thickness: thickness,
      material: materialID,
      wallMounted: wallMounted,
      type: index === 0 || index === columnList.length ? 'column' : 'middleColumn',
    })
  })

  return columns
}
