import {PerspectiveControlButton, PerspectiveControlContainer} from 'common/perspectiveControlContainer/PerspectiveControlContainer'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import DoorsIcn from './viewIcn-doors@2x.png'
import FrontIcn from './viewIcn-front@2x.png'
import IsoIcn from './viewIcn-iso@2x.png'
import SideIcn from './viewIcn-side@2x.png'

export const PerspectiveControls = () => {
  const showDoors = useStore((state) => state.doors.setVisible)
  const [setPerspective] = useCommonStore((store) => [store.setPerspective], shallow)

  return (
    <PerspectiveControlContainer>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('front')
          showDoors(false)
        }}
        iconSrc={FrontIcn}>
        ohne Schiebetüren
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('front')
          showDoors(true)
        }}
        iconSrc={DoorsIcn}>
        Frontansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('iso')
          showDoors(true)
        }}
        iconSrc={IsoIcn}>
        Schrägansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('side')
          showDoors(true)
        }}
        iconSrc={SideIcn}>
        Seitenansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="back-btn"
        altText="Icon of shelf in back view"
        onClick={() => {
          setPerspective('back')
          showDoors(true)
        }}
        iconSrc={FrontIcn}>
        Rückansicht
      </PerspectiveControlButton>
    </PerspectiveControlContainer>
  )
}
