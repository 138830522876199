import {materialModel, footModel as feets} from 'utils/datamodels'

export const bedModel = {
  minWidth: 0.7,
  maxWidth: 2.2,
  minLength: 1.6,
  maxLength: 2.7,
  nominalThickness: 0.025,
  minFrameHeight: 0.06,
  maxFrameHeight: 0.3,
  maxHeadBoardHeight: 0.7,
  standardHeadBoardHeight: 0.46,
  minSlattedHeight: 0.03,
  maxSlattedHeight: 0.15,
  integratedSlattedHeight: 0.009,
}

export const baseUrl = '/iku'

export const headBoardModel = [
  {id: 0, name: 'ohne', short: '', left: 0, right: 0, back: 0, mitreJoints: [true, true, true, true]},
  {id: 1, name: 'mit', short: 'H', left: 0, right: 1, back: 0, mitreJoints: [true, 'end', 'end', true]},
  {id: 2, name: 'winkelförmig links', short: 'HH', left: 1, right: 0, back: 1, mitreJoints: ['end', 'side', true, 'end']},
  {id: 3, name: 'winkelförmig rechts', short: 'HH', left: 0, right: 1, back: 1, mitreJoints: ['side', 'end', 'end', true]},
  {id: 4, name: 'u-förmig', short: 'HH', left: 1, right: 1, back: 1, mitreJoints: ['end', 'end', 'end', 'end']},
]

export const mattressModel = [
  {id: 0, name: 'ohne', short: ''},
  {id: 1, name: 'mit', short: 'M'},
]

export const bedMaterials = {
  m18Ah: {...materialModel['m18Ah'], altMaterial: 'm24Ah'},
  m18Bi: {...materialModel['m18Bi'], altMaterial: 'm24Bi'},
  m18Bu: {...materialModel['m18Bu'], altMaterial: 'm24Bu'},
  m18Ei: {...materialModel['m18Ei'], altMaterial: 'm24Ei'},
  m18Es: {...materialModel['m18Es'], altMaterial: 'm24Es'},
  m18Ki: {...materialModel['m18Ki'], altMaterial: 'm24Ki'},
  m18aN: {...materialModel['m18aN'], altMaterial: 'm24aN'},
  m18MW: {...materialModel['m18MW'], altMaterial: 'm24SW'},
  m18SW: {...materialModel['m18SW'], altMaterial: 'm25SW'},
  m18CB: {...materialModel['m18CB'], altMaterial: 'm25SB'},
  m18SB: {...materialModel['m18SB'], altMaterial: 'm25SB'},
  s19Ah: {...materialModel['s19Ah'], altMaterial: 's25Ah'},
  s19Bu: {...materialModel['s19Bu'], altMaterial: 's25Bu'},
  s19Ei: {...materialModel['s19Ei'], altMaterial: 's25Ei'},
  s19Es: {...materialModel['s19Es'], altMaterial: 's25Es'},
  s19Ki: {...materialModel['s19Ki'], altMaterial: 's25Ki'},
  s19NmS: {...materialModel['s19NmS'], altMaterial: 's25NmS'},
  s19NwS: {...materialModel['s19NwS'], altMaterial: 's25NwS'},
  s19NoS: {...materialModel['s19NoS'], altMaterial: 's25NoS'},
  m24Ah: {...materialModel['m24Ah'], altMaterial: 'm18Ah'},
  m24Bi: {...materialModel['m24Bi'], altMaterial: 'm18Bi'},
  m24Bu: {...materialModel['m24Bu'], altMaterial: 'm18Bu'},
  m24Ei: {...materialModel['m24Ei'], altMaterial: 'm18Ei'},
  m24Es: {...materialModel['m24Es'], altMaterial: 'm18Es'},
  m24Ki: {...materialModel['m24Ki'], altMaterial: 'm18Ki'},
  m24aN: {...materialModel['m24aN'], altMaterial: 'm18aN'},
  m25SW: {...materialModel['m25SW'], altMaterial: 'm18SW'},
  m25SB: {...materialModel['m25SB'], altMaterial: 'm18SB'},
  s25Ah: {...materialModel['s25Ah'], altMaterial: 's19Ah'},
  s25Bu: {...materialModel['s25Bu'], altMaterial: 's19Bu'},
  s25Ei: {...materialModel['s25Ei'], altMaterial: 's19Ei'},
  s25Es: {...materialModel['s25Es'], altMaterial: 's19Es'},
  s25Ki: {...materialModel['s25Ki'], altMaterial: 's19Ki'},
  s25NmS: {...materialModel['s25NmS'], altMaterial: 's19NmS'},
  s25NwS: {...materialModel['s25NwS'], altMaterial: 's19NwS'},
  s25NoS: {...materialModel['s25NoS'], altMaterial: 's19NoS'},

  l20Charcoal: {...materialModel['l20Charcoal'], altMaterial: 'l25Charcoal'},
  l20Ash: {...materialModel['l20Ash'], altMaterial: 'l25Ash'},
  l20Nero: {...materialModel['l20Nero'], altMaterial: 'l25Nero'},
  l20Burgundy: {...materialModel['l20Burgundy'], altMaterial: 'l25Burgundy'},
  l20Pewter: {...materialModel['l20Pewter'], altMaterial: 'l25Pewter'},
  l20Pearl: {...materialModel['l20Pearl'], altMaterial: 'l25Pearl'},
  l20Salsa: {...materialModel['l20Salsa'], altMaterial: 'l25Salsa'},
  l20Conifer: {...materialModel['l20Conifer'], altMaterial: 'l25Conifer'},
  l20Pebble: {...materialModel['l20Pebble'], altMaterial: 'l25Pebble'},
  l20Mushroom: {...materialModel['l20Mushroom'], altMaterial: 'l25Mushroom'},
  l20Mauve: {...materialModel['l20Mauve'], altMaterial: 'l25Mauve'},
  l20Pistachio: {...materialModel['l20Pistachio'], altMaterial: 'l25Pistachio'},
  l20SpringGreen: {...materialModel['l20SpringGreen'], altMaterial: 'l25SpringGreen'},
  l20Powder: {...materialModel['l20Powder'], altMaterial: 'l25Powder'},
  l20Olive: {...materialModel['l20Olive'], altMaterial: 'l25Olive'},
  l20OrangeBlast: {...materialModel['l20OrangeBlast'], altMaterial: 'l25OrangeBlast'},
  l20SmokeyBlue: {...materialModel['l20SmokeyBlue'], altMaterial: 'l25SmokeyBlue'},
  l20Aquavert: {...materialModel['l20Aquavert'], altMaterial: 'l25Aquavert'},
  l20IronGrey: {...materialModel['l20IronGrey'], altMaterial: 'l25IronGrey'},
  l20Vapour: {...materialModel['l20Vapour'], altMaterial: 'l25Vapour'},
  l20MidnightBlue: {...materialModel['l20MidnightBlue'], altMaterial: 'l25MidnightBlue'},
  l20Clay: {...materialModel['l20Clay'], altMaterial: 'l25Clay'},
  l20Leather: {...materialModel['l20Leather'], altMaterial: 'l25Leather'},
  l20Walnut: {...materialModel['l20Walnut'], altMaterial: 'l25Walnut'},

  l25Charcoal: {...materialModel['l25Charcoal'], altMaterial: 'l20Charcoal'},
  l25Ash: {...materialModel['l25Ash'], altMaterial: 'l20Ash'},
  l25Nero: {...materialModel['l25Nero'], altMaterial: 'l20Nero'},
  l25Burgundy: {...materialModel['l25Burgundy'], altMaterial: 'l20Burgundy'},
  l25Pewter: {...materialModel['l25Pewter'], altMaterial: 'l20Pewter'},
  l25Pearl: {...materialModel['l25Pearl'], altMaterial: 'l20Pearl'},
  l25Salsa: {...materialModel['l25Salsa'], altMaterial: 'l20Salsa'},
  l25Conifer: {...materialModel['l25Conifer'], altMaterial: 'l20Conifer'},
  l25Pebble: {...materialModel['l25Pebble'], altMaterial: 'l20Pebble'},
  l25Mushroom: {...materialModel['l25Mushroom'], altMaterial: 'l20Mushroom'},
  l25Mauve: {...materialModel['l25Mauve'], altMaterial: 'l20Mauve'},
  l25Pistachio: {...materialModel['l25Pistachio'], altMaterial: 'l20Pistachio'},
  l25SpringGreen: {...materialModel['l25SpringGreen'], altMaterial: 'l20SpringGreen'},
  l25Powder: {...materialModel['l25Powder'], altMaterial: 'l20Powder'},
  l25Olive: {...materialModel['l25Olive'], altMaterial: 'l20Olive'},
  l25OrangeBlast: {...materialModel['l25OrangeBlast'], altMaterial: 'l20OrangeBlast'},
  l25SmokeyBlue: {...materialModel['l25SmokeyBlue'], altMaterial: 'l20SmokeyBlue'},
  l25Aquavert: {...materialModel['l25Aquavert'], altMaterial: 'l20Aquavert'},
  l25IronGrey: {...materialModel['l25IronGrey'], altMaterial: 'l20IronGrey'},
  l25Vapour: {...materialModel['l25Vapour'], altMaterial: 'l20Vapour'},
  l25MidnightBlue: {...materialModel['l25MidnightBlue'], altMaterial: 'l20MidnightBlue'},
  l25Clay: {...materialModel['l25Clay'], altMaterial: 'l20Clay'},
  l25Leather: {...materialModel['l25Leather'], altMaterial: 'l20Leather'},
  l25Walnut: {...materialModel['l25Walnut'], altMaterial: 'l20Walnut'},
}

export const footModel = {
  bedRnd: feets.bedRnd,
  bedRct: feets.bedRct,
}
