import {useStore} from 'products/kubatur/store/store'
import {backpanelMaterials, regalModel, shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedKubaturBackpanelList = () => {
  const [columns, shelfHeight, shelfMaterialID, backpanelMaterialID, shelfTyp] = useStore(
    useShallow((store) => [store.config.columns.list, store.config.main.height, store.config.main.materialID, store.config.backpanels.materialID, store.config.main.shelfTyp])
  )
  return KubaturBackpanelList(columns, shelfHeight, shelfMaterialID, backpanelMaterialID, shelfTyp)
}

export const KubaturBackpanelList = (
  columns: {
    width: number
    face: 'front' | 'back' | 'side'
  }[],
  shelfHeight: number,
  shelfMaterialID: keyof typeof shelfMaterials,
  backpanelMaterialID: keyof typeof backpanelMaterials,
  shelfTyp: keyof typeof shelfTypes
) => {
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const backpanelBottomTopInset = backpanelThickness < 0.016 ? regalModel.backpanelBottomTopInset : regalModel.backpanelSideInset
  const backpanels: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []

  columns.forEach((el, i) => {
    const xPos = columns.slice(0, i).reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness) - regalModel.backpanelSideInset
    const zPos = backpanelThickness < 0.016 ? regalModel.backpanelInset : 0.001
    const yPos = shelfTypes[shelfTyp].bottomInset + shelfThickness - backpanelBottomTopInset
    const width = el.width + 2 * regalModel.backpanelSideInset
    const height = shelfHeight - 2 * shelfThickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset + 2 * backpanelBottomTopInset
    backpanels.push({key: i + '-full', xPos: xPos, yPos: yPos, zPos: zPos, length: height, depth: width, thickness: backpanelThickness, material: backpanelMaterialID, type: 'backpanel'})
  })

  return backpanels
}
