import {useEffect, useRef} from 'react'
import * as THREE from 'three'
import {CylinderGeometry} from 'three'
import {IVector} from './FiberBoard'

export const ClothingRail = (props: {pos: IVector; material: THREE.Material; size: IVector}, ref: any) => {
  const {pos, material, size} = props
  const geoRef = useRef<CylinderGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, 0.5, 0)
    geoRef.current?.rotateX(Math.PI / 2)
    //geoRef.current?.rotateZ(Math.PI / 2)
  }, [])

  return (
    <mesh position={pos || [0, 0, 0]} material={material} scale={size || [1, 1, 1]} rotation={[0, Math.PI / 2, 0]}>
      <cylinderGeometry ref={geoRef} args={[1, 1, 1, 20]} />
    </mesh>
  )
}
