import {useStore} from 'products/tektur/store/store'
import {regalModel, shelfMaterials, footModel} from 'products/tektur/store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {materialModel} from 'utils/datamodels'
import {ITekturConfig} from '../store/initialConfig'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedTekturFootList = () => {
  const [depth, width, grid, feetID, footHeight, materialID] = useStore(
    useShallow((store) => [store.config.main.depth, store.config.main.width, store.config.main.grid, store.config.feet.id, store.config.feet.height, store.config.main.materialID])
  )
  return TekturFootList(depth, width, grid, feetID, footHeight, materialID)
}

export const TekturFootList = (depth: number, width: number, grid: number, feetID: ITekturConfig['feet']['id'], footHeight: number, materialID: ITekturConfig['main']['materialID']) => {
  const foot = footModel[feetID]
  const thickness = shelfMaterials[materialID].thickness
  const feet = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel}[]
  const gridSize = (width - thickness) / grid
  const nrGridsInset = thickness / 2 + 2 * gridSize < 0.4 ? 1 : 0.5
  const xInset = width > 1.2 ? nrGridsInset * gridSize + thickness / 2 : foot.xInset || 0
  const nrFeet = Math.ceil((width - 2 * xInset) / regalModel.maxFootSpan) + 1
  switch (feetID) {
    case 's23':
      feet.push({key: '0.0', pos: [foot.xInset || 0, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      feet.push({key: '0.1', pos: [foot.xInset || 0, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      ;[...Array(grid - 1)].forEach((_, i) => {
        feet.push({key: i + 1 + '.0', pos: [thickness / 2 + (i + 1) * gridSize, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
        feet.push({key: i + 1 + '.1', pos: [thickness / 2 + (i + 1) * gridSize, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      })
      feet.push({key: grid + '.0', pos: [width - (foot.xInset || 0), 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      feet.push({key: grid + '.1', pos: [width - (foot.xInset || 0), 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      break
    case 'rnd30':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
      })
      break
    case 'rct16':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
      })
      break
  }
  return feet
}
