import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'

export const ConnectedTabelarSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, columns, width, depth, grid] = useStore((store) => [store.config.rows, store.rows.clearColumns(), store.config.main.width, store.config.main.depth, store.config.main.grid], shallow)
  const shelfThickness = shelfMaterialModel[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const setTabelar = useStore((store) => store.tabelars.create)
  let startPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          startPos += board.height + shelfThickness
          const visibleColumns: number[] = columns[y].filter((column) => column < grid)
          visibleColumns.push(0, grid)
          visibleColumns.sort((a, b) => a - b)
          const tabelarHeights: number[] = []
          const numTabHeights = Math.min(Math.floor(board.height / blueprint.minColHeight), 4)
          for (let i = 2; i <= numTabHeights; i++) {
            for (let y = 1; y < i; y++) !tabelarHeights.includes(y / i) && tabelarHeights.push(y / i)
          }
          tabelarHeights.sort((a, b) => a - b)
          return [...Array(visibleColumns.length - 1)].map((_, x) => {
            const xPos = visibleColumns[x] * gridSize + shelfThickness
            const nextColumn = Math.min(...visibleColumns.filter((el) => el > visibleColumns[x]))
            const length = (nextColumn - visibleColumns[x]) * gridSize - shelfThickness
            return tabelarHeights.map((height, i) => {
              const yPos = startPos - (board.height + shelfThickness) * (1 - height)
              return (
                <Selector
                  key={visibleColumns[x] + '.' + y + height}
                  size={[length, shelfThickness, depth - blueprint.frontInset]}
                  xPos={xPos}
                  yPos={yPos}
                  zPos={0}
                  onClick={() => setTabelar({x: visibleColumns[x], y: y + height})}
                />
              )
            })
          })
        })}
    </>
  )
}
