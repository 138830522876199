import {materialModel} from 'utils/datamodels'
import {IRow} from '..'
import {backpanelMaterials, doorMaterials, drawerMaterials, footModel, shelfMaterialModel} from './datamodels'

const rowModel = [
  {
    height: 0.345,
    inset: 'front',
  },
  {
    height: 0.345,
    inset: 'front',
  },
] as IRow[]

const doorModel = [
  {
    pos: {x: 0, y: 0},
    size: {x: 2, y: 2},
    type: 1 as 0 | 1, // 0: inline, 1: forefront
    face: 'front' as 'front' | 'back',
  },
]

const colModel = [
  [2, 4],
  [2, 3],
]

export const initialConfig = {
  main: {
    depth: 0.33,
    grid: 6,
    width: 2.203,
    materialID: 'm18Ei' as keyof typeof shelfMaterialModel,
    treatmentID: 0,
    roomDivider: false,
  },
  rows: {
    list: rowModel,
  },
  columns: {
    list: colModel,
  },
  backpanels: {
    list: [
      {
        pos: {x: 3, y: 1},
        cover: 'compartment' as 'full' | 'compartment',
        face: 'back' as 'front' | 'back',
      },
    ],
    materialID: 'mdf8W' as keyof typeof backpanelMaterials,
  },
  drawers: {
    list: [
      {
        pos: {x: 3, y: 1.5},
        size: {y: 0.5},
        face: 'front' as 'front' | 'back',
      },
    ],
    materialID: 'h2SWBC' as keyof typeof drawerMaterials,
  },
  doors: {
    list: doorModel,
    materialID: 'h2SWBC' as keyof typeof doorMaterials,
  },
  feet: {
    height: 0.023,
    id: 's23' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
  tabelars: {
    list: [] as {pos: {x: number; y: number}}[],
  },
}

export type IAnalogConfig = typeof initialConfig
