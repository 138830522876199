import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {flapMaterials, shelfMaterials} from 'products/tektur/store/datamodels'
import {Fragment} from 'react'

export const ConnectedFlapSelectors = () => {
  const visible = useStore((store) => store.view.flaps.edit)
  const [boards, width, grid, depth] = useStore((store) => [store.config.rows, store.config.main.width, store.config.main.grid, store.config.main.depth], shallow)
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const flapThickness = flapMaterials[useStore((store) => store.config.flaps.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const setFlap = useStore((store) => store.flaps.setFlap)
  let yPos = shelfThickness
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += board.height + shelfThickness
          return [...Array(grid)].map((_, x) => {
            const xPos = x * gridSize + shelfThickness
            const length = gridSize - shelfThickness
            return (
              <Fragment key={x}>
                {
                  <Selector
                    key={y + '.' + x}
                    size={[board.height, flapThickness, length]}
                    xPos={xPos}
                    yPos={yPos - board.height - flapThickness}
                    zPos={depth - flapThickness}
                    onClick={() => setFlap({x: x, y: y})}
                    rotation={[0, 0.5, 0.5]}
                    translate={[0.5, 0.5, 0.5]}
                  />
                }
              </Fragment>
            )
          })
        })}
    </>
  )
}
