import {ConnectedBezweiPrice, ConnectedBezweiSlattedPrice} from 'products/bezwei/utils/price'
import {useStore} from '../store/store'
import {localFactors, materialTreatments, materialTypes, slattedModel} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {bedMaterials, baseUrl, bedModel} from '../store/datamodels'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [length, width, bedMaterialID, shelfTreatmentID, feetHeight, uri, frameHeight, slattedId, mattressHeight, storagePlace] = useStore((state) => [
    state.config.main.length,
    state.config.main.width,
    state.config.main.materialID,
    state.config.main.treatmentID,
    state.config.main.footHeight,
    state.uri,
    state.config.main.frameHeight,
    state.config.slatted.id,
    state.config.mattress.height,
    state.config.main.storagePlace,
  ])
  const bedMaterial = bedMaterials[bedMaterialID]
  const shelfMaterialName = bedMaterial.type === 0 ? materialTypes[bedMaterial.type].name + ' ' + bedMaterial.name : bedMaterial.name + ' ' + materialTypes[bedMaterial.type].name
  const shelfTreatment = materialTreatments[shelfTreatmentID].name
  const totalLength = length + 2 * bedModel.nominalThickness + storagePlace * bedModel.storagePlaceWidth
  const totalWidth = width + 2 * bedModel.nominalThickness
  const footHeight = feetHeight
  const title = 'Bezwei'
  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const heightText = upperFrameHeight.toString()
  const slattedPrice = ConnectedBezweiSlattedPrice()

  return (
    <div className="description">
      <div className="print_title">{title}</div>
      <div className="sub_title">
        <a href={baseUrl + '/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {shelfTreatment}
          <div className="sub_title">Abmessungen</div>
          <div>
            {Math.round(totalLength * 1000)} mm x {Math.round(totalWidth * 1000)} mm x {heightText} mm{' '}
          </div>
          <div>
            für Matratzengröße: {Math.round(length * 100)} cm x {Math.round(width * 100)} cm{' '}
          </div>
        </div>
        <div className="col">
          <div className="sub_title">Lattenrost</div>
          <div style={{display: 'flow-root'}}>
            <div className="left">{slattedModel[slattedId].name}</div>
            <div className="right">{slattedPrice === 0 ? '- €' : slattedPrice + ' €'}</div>
          </div>
          {slattedModel[slattedId].description}
          <div></div>
          <div className="sub_title">Ausstattung</div>
          <div>dargestellte Matratzenhöhe {mattressHeight * 100} cm</div>
          <div className="price_title">Preis </div>
          <div className="price">
            <ConnectedBezweiPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}
