import {useState} from 'react'
import clsx from 'clsx'
import './overview.scss'
import logo from 'common/static/sanktjohanser_schriftzug.svg'
import {ConnectedAnalogOverView} from './productOverViews/ConnectedAnalogOverView'
import {ConnectedDasregalOverView} from './productOverViews/ConnectedDasregalOverView'
import {ConnectedTekturOverView} from './productOverViews/ConnectedTekturOverView'
import {ConnectedDigitalOverView} from './productOverViews/ConnectedDigitalOverView'
import {ConnectedMetrumOverView} from './productOverViews/ConnectedMetrumOverView'
import {ConnectedKubaturOverView} from './productOverViews/ConnectedKubaturOverView'
import {useLayoutEffect} from 'react'
import {AccordionElement} from 'components/material_interfaces/materialInterfaces'
import {ConnectedIkuOverView} from './productOverViews/ConnectedIkuOverView'
import {ConnectedUnisonoOverView} from './productOverViews/ConnectedUnisonoOverView'
import {ConnectedEuklidOverView} from './productOverViews/ConnectedEuklidOverView'
import {ConnectedDinadreiOverView} from './productOverViews/ConnectedDinadreiOverView'
import {ConnectedBezweiOverView} from './productOverViews/ConnectedBezweiOverView'

export function OverView() {
  const [expanded, setExpanded] = useState<string | false>(false)
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  document.title = 'SANKTJOHANSER | KONFIGURATOR'

  const products: {[key: string]: string[]} = {regale: ['analog', 'dasregal', 'tektur', 'digital', 'metrum', 'kubatur'], betten: ['iku', 'unisono', 'dinadrei', 'bezwei'], tische: ['euklid']}
  const productIds = Object.keys(products)
    .map((id) => products[id])
    .flat()
  const activeId = useScrollspy(productIds, 400) // 54 is navigation height
  const activeGroup = useScrollspy(Object.keys(products), 400)
  useLayoutEffect(() => {
    setExpanded(activeGroup)
  }, [activeGroup])
  return (
    <div>
      <div className="overview-center-box">
        <div className="overview-logo-box">
          <a href="https://www.sanktjohanser.net">
            <img src={logo} alt="Sanktjohanser" />
          </a>
        </div>
        <div className="overview-menu-box">
          {Object.keys(products).map((id) => (
            <AccordionElement id={id} title={capitalize(id)} expanded={expanded === id || activeGroup === id} handleChange={handleChange}>
              {products[id].map((id) => (
                <li key={`menu-item-${id}`} className="menu-item">
                  <a href={`#${id}`} className={clsx('menu-link', id === activeId && 'menu-link-active')}>
                    {id}
                  </a>
                </li>
              ))}
            </AccordionElement>
          ))}
        </div>
        <div id="regale">
          <section key={`section-regale`} id={'regale'} className="section">
            <ConnectedAnalogOverView />
            <ConnectedDasregalOverView />
            <ConnectedTekturOverView />
            <ConnectedDigitalOverView />
            <ConnectedMetrumOverView />
            <ConnectedKubaturOverView />
          </section>
        </div>
        <div id="betten">
          <section key={`section-betten`} id={'betten'} className="section">
            <ConnectedIkuOverView />
            <ConnectedUnisonoOverView />
            <ConnectedDinadreiOverView />
            <ConnectedBezweiOverView />
          </section>
        </div>
        <div id="tische">
          <section key={`section-tische`} id={'tische'} className="section">
            <ConnectedEuklidOverView />
          </section>
        </div>
      </div>
    </div>
  )
}

// helpers
const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.substr(1)

const clamp = (value: number) => Math.max(0, value)

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil

// hooks
const useScrollspy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('')

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset

      const position = ids
        .map((id) => {
          const element = document.getElementById(id)

          if (!element) return {id, top: -1, bottom: -1}

          const rect = element.getBoundingClientRect()
          const top = clamp(rect.top + scroll - offset)
          const bottom = clamp(rect.bottom + scroll - offset)

          return {id, top, bottom}
        })
        .find(({top, bottom}) => isBetween(scroll, top, bottom))

      setActiveId(position?.id || '')
    }

    listener()

    window.addEventListener('resize', listener)
    window.addEventListener('scroll', listener)

    return () => {
      window.removeEventListener('resize', listener)
      window.removeEventListener('scroll', listener)
    }
  }, [ids, offset])

  return activeId
}
