import {AccordionListSelector, AccordionMaterialSelector} from '../../../../components/material_interfaces/materialInterfaces'
import {useStore} from 'products/digital/store/store'
import {getMaterialName} from 'utils/helpers'
import {drawerFrontMaterials, regalModel} from 'products/digital/store/datamodels'
import {useState} from 'react'

export function Drawers() {
  const drawers = useStore((store) => store.view.drawers)

  const [boards, drawerMaterial] = useStore((store) => [store.config.rows, store.config.drawers.materialID])
  const setMaterialID = useStore((store) => store.drawers.setMaterialID)
  const setEditSize = useStore((store) => store.drawers.setVariant)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(drawerFrontMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof drawerFrontMaterials)}
  })

  const drawerHeights: {name: string; callbackValue: number}[] = []
  boards.list.forEach((board, i) => {
    const numDrawerHeights = Math.min(Math.floor(board.height / regalModel.minDrawerHeight), 4)
    for (let i = 1; i <= numDrawerHeights; i++) {
      for (let y = 1; y <= i; y++)
        !drawerHeights.some((e) => e.callbackValue === y / i) && (y / i) * board.height < regalModel.maxDrawerHeight && drawerHeights.push({name: y + '/' + i + ' Höhe', callbackValue: y / i})
    }
    drawerHeights.sort((a, b) => b.callbackValue - a.callbackValue)
  })
  const drawerHeightOptions = drawerHeights.map((e, i) => ({id: i, name: e.name, callbackValue: e.callbackValue}))
  const selected = drawerHeightOptions.findIndex((e) => e.callbackValue === drawers.variant)

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Front'} value={drawerMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector
        options={drawerHeightOptions}
        onClick={(id: number) => setEditSize(drawerHeightOptions.find((option) => option.id === id)?.callbackValue as number)}
        title={'Größe'}
        value={selected}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
