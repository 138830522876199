import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {Selector} from 'common/threeComponents/FiberBoard'

export const ConnectedBoardSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, depth, width] = useStore((store) => [store.config.rows, store.config.main.depth, store.config.main.width], shallow)
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const deleteBoard = useStore((store) => store.boards.delete)
  const addBoard = useStore((store) => store.boards.create)
  if (!visible) return null
  const totalHeight = boards.list.reduce((acc, board) => acc + board.height + thickness, thickness)
  return (
    <>
      {boards.list.length > 1 && <Selector size={[width, thickness, depth]} key={0} xPos={0} yPos={totalHeight - thickness} zPos={0} onClick={() => deleteBoard()} />}
      {regalModel.heightList.map((height, i) => {
        return <Selector size={[width, thickness, depth]} key={i} xPos={0} yPos={totalHeight + height} zPos={0} onClick={() => addBoard(height)} />
      })}
    </>
  )
}
