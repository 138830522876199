import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {drawerFrontMaterials, regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {Fragment} from 'react'

export const ConnectedDrawerSelectors = () => {
  const visible = useStore((store) => store.view.drawers.edit)
  const [boards, columns, drawers, width, grid, depth] = useStore(
    (store) => [store.config.rows, store.config.columns.list, store.config.drawers.list, store.config.main.width, store.config.main.grid, store.config.main.depth],
    shallow
  )
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const drawerFrontThickness = drawerFrontMaterials[useStore((store) => store.config.drawers.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const setDrawer = useStore((store) => store.drawers.setDrawer)
  const size = useStore((store) => store.view.drawers.variant)
  let yPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += board.height + shelfThickness
          const visibleColumns: number[] = [...columns[y]].filter((el) => el < grid)
          visibleColumns.push(0, grid)
          visibleColumns.sort((a, b) => a - b)
          return [...Array(visibleColumns.length - 1)].map((_, x) => {
            const xPos = visibleColumns[x] * gridSize + shelfThickness
            const nextColumn = Math.min(...visibleColumns.filter((el) => el > visibleColumns[x]))
            const length = (nextColumn - visibleColumns[x]) * gridSize - shelfThickness
            const height = size * (board.height - shadowJoint) - shadowJoint
            return (
              <Fragment key={x}>
                <Selector
                  key={y + '.' + x}
                  size={[height, drawerFrontThickness, length]}
                  xPos={xPos}
                  yPos={yPos - height - shadowJoint}
                  zPos={depth - drawerFrontThickness}
                  onClick={() => setDrawer({x: visibleColumns[x], y: y + 1 - size}, {y: size})}
                  rotation={[0, 0.5, 0.5]}
                />
              </Fragment>
            )
          })
        })}
      {visible &&
        drawers.map((drawer, i) => {
          const yIndex = Math.abs(drawer.pos.y - size - Math.round(drawer.pos.y - size)) < 0.01 ? Math.round(drawer.pos.y - size) : drawer.pos.y - size
          const boardIndex = Math.floor(yIndex)
          if (boardIndex >= 0 && boardIndex < boards.list.length) {
            const visibleColumns: number[] = [0, ...columns[boardIndex]].filter((el) => el < grid)
            if (boardIndex === Math.floor(drawer.pos.y) && visibleColumns.includes(drawer.pos.x) && boardIndex < boards.list.length) {
              const xPos = drawer.pos.x * gridSize + shelfThickness
              const yPos =
                boards.list.slice(0, Math.floor(yIndex) + 1).reduce((a, e) => a + e.height + shelfThickness, 0) - (size + drawer.size.y) * (boards.list[boardIndex].height - shadowJoint) - shadowJoint
              visibleColumns.sort((a, b) => a - b)
              visibleColumns.push(0, grid)
              const nextColumn = Math.min(...visibleColumns.filter((el) => el > drawer.pos.x))
              const length = (nextColumn - drawer.pos.x) * gridSize - shelfThickness
              const height = size * (boards.list[boardIndex].height - shadowJoint) - shadowJoint
              return (
                <Fragment key={i}>
                  <Selector
                    key={boardIndex + '.' + drawer.pos.x}
                    size={[height, drawerFrontThickness, length]}
                    xPos={xPos}
                    yPos={yPos}
                    zPos={depth - drawerFrontThickness}
                    onClick={() => setDrawer({x: drawer.pos.x, y: yIndex}, {y: size})}
                    rotation={[0, 0.5, 0.5]}
                  />
                </Fragment>
              )
            } else {
              return null
            }
          } else {
            return null
          }
        })}
    </>
  )
}
