import './printview.scss'
import {Link} from 'react-router-dom'
import logo from 'common/static/sanktjohanser_schriftzug.svg'
import {FooterPrint} from './FooterPrint'
import {Children, ReactNode} from 'react'
import {useCommonStore} from 'common/Store/CommonStore'

export declare type IPartList = {
  material: string
  bezeichnung: string
  bemerkung: string
  länge: number
  breite: number
  stärke: number
  stk: number
  ZuschnittX: number
  ZuschnittY: number
}[]

export function PrintView(props: {reference: string; baseUrl: string; children: any}) {
  const {reference, baseUrl, children} = props
  const saveConfig = useCommonStore((state) => state.saveConfig)
  const nrOfPages = children.filter((child: any) => child).length

  return (
    <div>
      {Children.map(children, (child, index) => {
        if (!child) return null
        const style = child.props.orientation === 'landscape' ? 'dinA4 landscape' : 'dinA4'
        const isLogo = !(child.props.logo === undefined) ? child.props.logo : true
        return (
          <div className={style} id="dinA4">
            <div className={'rotate-container'}>
              {isLogo && (
                <div className="print_logo">
                  <img src={logo} alt="Sanktjohanser" />
                </div>
              )}
              {child}
              <FooterPrint page={index + 1} totalPages={nrOfPages}></FooterPrint>
            </div>
          </div>
        )
      })}
      <div className="print_controls">
        {
          <div
            onClick={async () => {
              if (await saveConfig(reference)) window.print()
            }}
            className="p_button">
            Drucken
          </div>
        }
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>
    </div>
  )
}

export const Caption = (props: {children: ReactNode}) => <div className="print_viewcaption">{props.children}</div>

export const Page = ({children, orientation = 'landscape', logo = true}: {children: React.ReactNode; orientation?: 'portrait' | 'landscape'; logo: boolean}) => <>{children}</>

export function PartList(props: {partList: IPartList}) {
  const {partList} = props
  const nrOfParts = partList.reduce((acc, curr) => acc + curr.stk, 0)
  let background = true
  return (
    <div style={{padding: '2cm'}}>
      <table className="partlist-table" contentEditable>
        <colgroup>
          <col style={{width: '5%'}} />
          <col style={{width: '15%'}} />
          <col style={{width: '15%'}} />
          <col style={{width: '22.5%'}} />
          <col style={{width: '6.5%'}} />
          <col style={{width: '6.5%'}} />
          <col style={{width: '6.5%'}} />
          <col style={{width: '5%'}} />
          <col style={{width: '10.5%'}} />
          <col style={{width: '7.5%'}} />
        </colgroup>
        <thead>
          <tr key={'header'}>
            <th rowSpan={2} className={'Pos'}>
              Pos
            </th>
            {Object.keys(partList[0])
              .splice(0, 3)
              .map((key, i) => (
                <th rowSpan={2} className={key} key={i}>
                  {key}
                </th>
              ))}
            <th colSpan={3} className={'Fertigmass'}>
              Fertigmass
            </th>
            <th rowSpan={2} className={'stk'}>
              Stk
            </th>

            <th colSpan={2} className={'Zuschnitt'}>
              Zuschnitt +4mm
            </th>
          </tr>
          <tr>
            <th className="Zuschnitt">länge</th>
            <th className="Zuschnitt">breite</th>
            <th className="Zuschnitt">stärke</th>
            <th className="Zuschnitt">länge</th>
            <th className="Zuschnitt">breite</th>
          </tr>
        </thead>
        <tbody>
          <tr key={'empty'}>
            <td colSpan={7}></td>
            <td className="stk"></td>
            <td colSpan={2} className="Zuschnitt"></td>
          </tr>
          {partList.map((reducedPartList, k) => {
            if (partList[k].material !== partList[k - 1]?.material || partList[k].stärke !== partList[k - 1]?.stärke) background = !background
            return (
              <tr key={k} style={{backgroundColor: background ? 'lightgrey' : ''}}>
                <td className={'Pos'}>{k + 1}</td>
                {Object.keys(reducedPartList).map((key, i) => (
                  <td className={key} key={i}>
                    {reducedPartList[key as keyof typeof reducedPartList].toLocaleString('de-DE', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 0,
                    })}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr key={'sum'}>
            <td colSpan={7}></td>
            <td className="stk" style={{textAlign: 'right', fontWeight: '900'}}>
              {nrOfParts}
            </td>
            <td colSpan={2} className="Zuschnitt"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
