import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl, shelfTypes} from '../store/datamodels'
import {ConnectedKubatur} from '../threeComponents/Kubatur'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {ConnectedKubaturDrawerList} from '../partLists/drawerList'
import {ConnectedKubaturBoardList} from '../partLists/boardList'
import {ConnectedKubaturColumnList} from '../partLists/columnList'
import {getReducedPartList} from 'utils/helpers'
import {ConnectedKubaturSubColumnList} from '../partLists/subColumnList'
import {ConnectedKubaturTabelarList} from '../partLists/tabelarList'
import {ConnectedKubaturBackpanelList} from '../partLists/backpanelList'
import {ConnectedKubaturDoorList} from '../partLists/doorList'
import {ConnectedKubaturSlidingDoorList} from '../partLists/slidingDoorList'
import {useCommonStore} from 'common/Store/CommonStore'

export default function ConnectedKubaturPrintView() {
  const shelfTyp = useStore((store) => store.config.main.shelfTyp)
  const footHeight = useStore((store) => store.config.feet.height) + shelfTypes[shelfTyp].bottomInset

  const drawerPartList = ConnectedKubaturDrawerList()
    .map((drawer) => Object.values(drawer.parts).map((el) => el))
    .flat() as any[]
  const slidingDoorPartList = ConnectedKubaturSlidingDoorList()
    .map((slidingDoor) => Object.values(slidingDoor.parts).map((el) => el))
    .flat() as any[]
  const partList = [
    ...ConnectedKubaturBoardList(),
    ...ConnectedKubaturColumnList(),
    ...ConnectedKubaturSubColumnList(),
    ...ConnectedKubaturTabelarList(),
    ...ConnectedKubaturBackpanelList(),
    ...ConnectedKubaturDoorList(),
    ...slidingDoorPartList,
    ...drawerPartList,
  ]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)

  return (
    <PrintView reference="kubatur" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedKubatur doors={true} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedKubatur doors={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne ohne Türen'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'back'}>
          <ConnectedKubatur doors={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht hinten'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}
