import {IStore, ISetProduce} from '../store'
import {footModel} from '../datamodels'

export interface IFeetSlice {
  feet: {
    setHeight: (height: number) => void
    setID: (id: keyof typeof footModel) => void
  }
}

export const createFeetSlice = (setProduce: ISetProduce<IStore>) => {
  const feet = {
    setHeight: function (newHeight: number): void {
      setProduce((state: IStore) => {
        state.config.feet.height = newHeight
      })
    },
    setID: function (ID: keyof typeof footModel): void {
      setProduce((state: IStore) => {
        state.config.feet.id = ID
        if (!footModel[ID].heights.includes(state.config.feet.height)) {
          const newHeight = footModel[ID].standardHeight || 0
          state.config.feet.height = footModel[ID].heights[newHeight]
        }
      })
    },
  }

  return {
    feet,
  }
}
