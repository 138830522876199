import {IFeetActions, IStore} from '../..'
import {footModel} from '../datamodels'

export const createFeetSlice = (setProduce: any) => {
  const feet: IFeetActions = {
    setHeight: function (newHeight: number): void {
      setProduce((state: IStore) => {
        state.config.feet.height = newHeight
      })
    },
    setID: function (ID: keyof typeof footModel): void {
      setProduce((state: IStore) => {
        state.config.feet.id = ID
        if (!footModel[ID].heights.includes(state.config.feet.height)) {
          const newHeight = footModel[ID].standardHeight || 0
          state.config.feet.height = footModel[ID].heights[newHeight]
        }
      })
    },
  }
  return {
    feet,
  }
}
