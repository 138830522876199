import { Dialog, DialogContent, DialogTitle } from "@mui/material"


interface IModalProps {
	title?: string
	children: React.ReactNode | React.ReactNode[] | undefined
	label: string
	onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined
}

export const BaseModal = (props: IModalProps) => {
	const {title, children, label, onClose = () => null} = props

	return (
		<Dialog
			open={true}
			maxWidth="sm"
			fullWidth={true}
			aria-labelledby={label}
			aria-describedby={label}
			onClose={onClose}
		>
			{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
			<DialogContent>
				{children}
			</DialogContent>
		</Dialog>
	)
}