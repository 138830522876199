import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {frontMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {DoorFC} from 'common/threeComponents/DoorFC'
import {ConnectedKubaturDoorList} from 'products/kubatur/partLists/doorList'

export const ConnectedDoors = () => {
  const doorList = ConnectedKubaturDoorList()
  const visible = useStore((store) => store.view.doors.visible)
  if (doorList.length > 0) {
    const materialID = doorList[0].material
    var materialReference = frontMaterials[materialID].matReference
    var materialObject = MaterialSingleton.getMaterials()
  }
  return (
    <>
      {visible &&
        doorList.map((door, j) => {
          const mat =
            door.length > 1.3
              ? materialObject[materialReference]?.big
              : j % 2 === 1
                ? materialObject[materialReference]?.small_1
                : materialObject[materialReference]?.small_2 || materialObject[materialReference]
          return <DoorFC key={j} material={mat} width={door.depth} height={door.length} thickness={door.thickness} xPos={door.xPos} yPos={door.yPos} zPos={door.zPos} side={door.side} />
        })}
    </>
  )
}
