import {produce} from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import {create} from 'zustand'
import {bedMaterials} from './datamodels'
import {initialConfig, IDinadreiConfig} from './initialConfig'
import {initialView, IView} from './initialView'
import {slattedModel} from 'utils/datamodels'

declare interface IFeetActions {
  setHeight: (height: number) => void
}

declare type IStoreActions = {
  feet: IFeetActions
}

export type IStore = {
  config: IDinadreiConfig
  view: IView
  uri: string
  savedConfig: IDinadreiConfig
  setMaterialSpecies: (species: keyof typeof bedMaterials) => void
  setMaterialTreatment: (treatment: number) => void
  setMaterialType: (type: number) => void
  setFrameMaterialID: (id: keyof typeof bedMaterials) => void
  setFrameHeight: (height: number) => void
  setLength: (length: number) => void
  setFootHeight: (height: number) => void
  setWidth: (width: number) => void
  setHeadBoardHeight: (height: number) => void
  setMattressVisibility: (visible: boolean) => void
  setMattressHeight: (height: number) => void
  setSlattedVisibility: (visible: boolean) => void
  setSlattedId: (id: keyof typeof slattedModel) => void
  setSlattedHeight: (height: number) => void
  setSlattedInset: (height: number) => void
  clearConfig: () => IDinadreiConfig
} & IStoreActions

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

//@ts-ignore
export const useStore = create<IStore>((set, get) => {
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  const config: IDinadreiConfig = initialConfig // set backup config
  const view: IView = initialView // set default view

  const setMaterialSpecies = (nr: keyof typeof bedMaterials) => {
    setProduce((state) => {
      if (state.config.main.materialID === state.config.main.frameMaterialID) state.config.main.frameMaterialID = nr
      state.config.main.materialID = nr
    })
  }

  const setMaterialType = (type: number) => {
    const fallback = bedMaterials[get().config.main.materialID].fallback as keyof typeof bedMaterials
    const oldType = bedMaterials[get().config.main.materialID].type
    const fallbackType = bedMaterials[fallback].type
    const altMaterial = Object.keys(bedMaterials).find((key) => bedMaterials[key as keyof typeof bedMaterials].type === type) as keyof typeof bedMaterials
    setProduce((state) => {
      if (type !== oldType && fallbackType === type) {
        state.config.main.materialID = fallback
      } else {
        state.config.main.materialID = altMaterial
      }
    })
  }

  const setFrameMaterialID = (id: keyof typeof bedMaterials) => {
    setProduce((state) => {
      state.config.main.frameMaterialID = id
    })
  }

  const setMaterialTreatment = (treatmentID: number) => {
    setProduce((state) => {
      state.config.main.treatmentID = treatmentID
    })
  }

  const setFrameHeight = (newHeight: number) => {
    setProduce((state) => {
      state.config.main.frameHeight = newHeight
    })
  }
  const setLength = (newLength: number) => {
    setProduce((state) => {
      state.config.main.length = newLength
    })
  }
  const setWidth = (newWidth: number) => {
    setProduce((state) => {
      state.config.main.width = newWidth
    })
  }
  const setFootHeight = (newHeight: number) => {
    setProduce((state) => {
      state.config.main.footHeight = newHeight
    })
  }
  const setHeadBoardHeight = (height: number) => {
    setProduce((state) => {
      state.config.main.headBoardHeight = height
    })
  }
  const setMattressVisibility = (visible: boolean) => {
    setProduce((state) => {
      state.view.mattress.visible = visible
    })
  }
  const setMattressHeight = (height: number) => {
    setProduce((state) => {
      state.config.mattress.height = height
    })
  }
  const setSlattedVisibility = (visible: boolean) => {
    setProduce((state) => {
      state.view.slatted.visible = visible
    })
  }
  const setSlattedID = (id: keyof typeof slattedModel) => {
    setProduce((state) => {
      const slattedInset = state.config.slatted.inset
      const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
      const newSlattedHeight = id === 'own' ? state.config.slatted.height : (slattedModel[id] as {height: number})?.height
      const newSlattedInset = slattedInset - oldSlattedHeight + newSlattedHeight
      state.config.slatted.id = id
      state.config.slatted.inset = newSlattedInset
    })
  }
  const setSlattedHeight = (height: number) => {
    setProduce((state) => {
      state.config.slatted.height = height
    })
  }
  const setSlattedInset = (height: number) => {
    setProduce((state) => {
      state.config.slatted.inset = height
    })
  }
  const getClearConfig = () => {
    return {...get().config}
  }

  return {
    config,
    view,
    uri: '',
    savedConfig: '',
    setMaterialType: setMaterialType,
    setMaterialSpecies: setMaterialSpecies,
    setFrameMaterialID: setFrameMaterialID,
    setMaterialTreatment: setMaterialTreatment,
    setFrameHeight: setFrameHeight,
    setLength: setLength,
    setWidth: setWidth,
    setHeadBoardHeight: setHeadBoardHeight,
    setFootHeight: setFootHeight,
    setMattressVisibility: setMattressVisibility,
    setMattressHeight: setMattressHeight,
    setSlattedVisibility: setSlattedVisibility,
    setSlattedId: setSlattedID,
    setSlattedHeight: setSlattedHeight,
    setSlattedInset: setSlattedInset,
    clearConfig: getClearConfig,
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('DinadreiStore', useStore)
}
