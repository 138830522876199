import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material'
import Grid from '@mui/material/Grid'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

export const LoadModal = ({baseUrl = ''}: {baseUrl?: string}) => {
  let navigate = useNavigate()
  const [uri, setUri] = useState('')
  const fetchConfig = useCommonStore((store) => store.fetchConfig)
  const [error, setError] = useState(false)

  const onChange = (e: any) => {
    setUri(e.target.value)
  }

  const checkID = async () => {
    await fetchConfig(uri as string).then((data) => {
      if (data) {
        navigate(`/get/${uri}`)
      } else {
        setError(true)
      }
    })
  }

  useEffect(() => {
    const keyDownHandler = (e: any) => {
      if (e.key === 'Enter') {
        setUri(e.target.value)
        checkID()
      }
    }
    document.addEventListener('keydown', keyDownHandler, true)
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true)
    }
  })

  return (
    <Dialog open={true} maxWidth="sm" fullWidth={true} aria-labelledby="Save or Load Configuration Dialog" aria-describedby="Save or Load Shelf Configuration" onClose={() => navigate(baseUrl)}>
      <DialogTitle id="alert-dialog-title">Konfiguration Laden</DialogTitle>
      <DialogContent>
        <DialogContentText>Geben Sie hier Ihre Konfigurations-ID ein</DialogContentText>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <TextField error={error} helperText={error ? 'ID konnte nicht gefunden werden' : ''} variant="standard" label="Konfigurations-ID" id="uri" name="uri" onChange={onChange} />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={3}>
          <Grid item>
            <Button disableElevation={true} onClick={() => checkID()}>
              Konfiguration Laden
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
