import {backpanelMaterials} from '../datamodels'
import {IStore, ISetProduce} from '../store'
import {GetState} from 'zustand'

export const createBackpanelSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const backpanels = {
    setMaterialID: (newMaterialID: keyof typeof backpanelMaterials): void =>
      setProduce((state: IStore) => {
        state.config.backpanels.materialID = newMaterialID
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.backpanels.variant = variant
      })
    },
    setEditBackpanels: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.backpanels.edit = edit
      }),
  }

  return {
    backpanels,
  }
}
