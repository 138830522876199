import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {Fragment} from 'react'

export const ConnectedColumnSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, columns, depth, width, grid] = useStore((store) => [store.config.rows, store.config.columns.list, store.config.main.depth, store.config.main.width, store.config.main.grid], shallow)
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (width - thickness) / grid
  const toggleColumn = useStore((store) => store.columns.toggle)
  let yPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += boards.list[y].height + thickness
          const visibleColumns = [...columns[y]]
          visibleColumns.push(0, grid)
          return [...Array(grid - 1)].map((_, x) => {
            const xPos = (x + 1) * gridSize
            const nextColumn = Math.min(...visibleColumns.filter((el) => el - 1 > x))
            const prevColumn = Math.max(...visibleColumns.filter((el) => el - 1 < x))
            const removable = (nextColumn - prevColumn) * gridSize < regalModel.maxSpan
            return (
              <Fragment key={x}>
                {removable && (
                  <Selector
                    key={y + '.' + x}
                    size={[board.height, thickness, depth]}
                    xPos={xPos}
                    yPos={yPos - board.height}
                    zPos={0}
                    onClick={() => toggleColumn(x + 1, y)}
                    rotation={[0, 0, 0.5]}
                    translate={[0.5, -0.5, 0.5]}
                  />
                )}
              </Fragment>
            )
          })
        })}
    </>
  )
}
