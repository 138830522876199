import {forwardRef, useLayoutEffect, useRef} from 'react'
import {ThreeEvent} from '@react-three/fiber'
import * as THREE from 'three'
import {BoxGeometry, CylinderGeometry, Euler, Material} from 'three'

export type IVector = [number, number, number]
export type IVector3 = {x: number; y: number; z: number}

export interface IBoard {
  size: IVector //length thickness, depth
  material?: THREE.Material | THREE.Material[]
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  onPointerOver?: (event: ThreeEvent<PointerEvent>) => void
  onPointerOut?: (event: ThreeEvent<PointerEvent>) => void
  // position: IVector
  xPos: number
  yPos: number
  zPos: number
  rotation?: IVector
  translate?: IVector
  cursor?: string
  shape?: 'round' | 'rectangle'
}

export const Board = forwardRef((props: IBoard, ref: any) => {
  // This reference will give us direct access to the mesh
  const shape = props.shape || 'rectangle'
  const position = [props.xPos, props.yPos, props.zPos] as IVector
  const box = useRef<BoxGeometry>(null!)
  const circle = useRef<CylinderGeometry>(null!)
  const geoRef = shape === 'rectangle' ? box : circle
  const rotation = props.rotation ? new Euler(props.rotation[0] * Math.PI, props.rotation[1] * Math.PI, props.rotation[2] * Math.PI) : new Euler(0, 0, 0) //check if that works

  const material = shape === 'rectangle' ? props.material : props.material instanceof Array ? [props.material[1], props.material[2], props.material[2]] : props.material
  //NEEDS TO BE LAYOUTEFFECT to make sure box is set
  useLayoutEffect(() => {
    shape === 'round' && geoRef.current.rotateY(Math.PI * 0.5)
  }, [geoRef, shape])

  useLayoutEffect(() => {
    geoRef.current.rotateY(Math.PI * Math.round(Math.random()))
    geoRef.current.center()
    props.translate ? geoRef.current.translate(...props.translate) : geoRef.current.translate(0.5, 0.5, 0.5)
  }, [geoRef, props.translate, shape])

  return (
    <mesh ref={ref} scale={props.size || [1, 1, 1]} position={position || [0, 0, 0]} material={material} rotation={rotation}>
      {shape === 'rectangle' && <boxGeometry args={[1, 1, 1]} ref={geoRef as React.MutableRefObject<BoxGeometry>} />}
      {shape === 'round' && <cylinderGeometry args={[0.5, 0.5, 1, 50]} ref={geoRef as React.MutableRefObject<CylinderGeometry>} />}
    </mesh>
  )
})
