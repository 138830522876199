import {baseUrl, bedMaterials} from '../store/datamodels'
import {DinadreiPrice} from 'products/dinadrei/utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors} from 'utils/datamodels'
import {DinadreiBoardList} from '../partLists/boardList'
import {DinadreiSlattedList} from '../partLists/slattedList'
import {DinadreiAccBoardList} from '../partLists/accBoardList'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'

export const DinadreiPriceList = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(bedMaterials)
  const materials = ['m24Ah', 'm24Ki', 'm25SB', 's25Bu', 's25Ei', 's25Ki', 's25NmS', 's25NwS', 's25NoS', 'l25Ash']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof bedMaterials))

  const widths = [1.4, 1.6, 1.8, 2]

  let priceListDinadrei: number[][] = []

  materials.forEach((material, i) => {
    priceListDinadrei.push([])
    widths.forEach((width) => {
      const boardList = DinadreiBoardList(2, width, 0.15, material as keyof typeof bedMaterials, 'm25SB', 0.175, 0.3, 0.125)
      const accBoardList = DinadreiAccBoardList(0.175, 2, width, 0.02, 'own', 0.15)
      const slattedList = DinadreiSlattedList(0.175, 2, width, 'own', 0.05, 0.02, 0.15)
      priceListDinadrei[i].push(DinadreiPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, [...boardList, ...accBoardList], slattedList).props.children)
    })
  })

  let priceListDinadreiSurfaces: number[][] = []
  ;[2, 3].forEach((surface, i) => {
    priceListDinadreiSurfaces.push([])
    widths.forEach((width) => {
      const boardList = DinadreiBoardList(2, width, 0.15, 's25Ei' as keyof typeof bedMaterials, 'm25SB', 0.175, 0.3, 0.125)
      const accBoardList = DinadreiAccBoardList(0.175, 2, width, 0.02, 'own', 0.15)
      const slattedList = DinadreiSlattedList(0.175, 2, width, 'own', 0.05, 0.02, 0.15)
      const iPrice = DinadreiPrice(localFactor.factor, 'm24Ei', surface, [...boardList, ...accBoardList], slattedList).props.children
      const oilPrice = DinadreiPrice(localFactor.factor, 'm24Ei', 0, [...boardList, ...accBoardList], slattedList).props.children
      const extraPrice = iPrice - oilPrice
      priceListDinadreiSurfaces[i].push(extraPrice)
    })
  })

  const surfaceNames = ['Aufpreis geseift', 'Aufpreis lackiert']

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>DINADREI</h1>
        <PriceTable rows={materialNames} columns={widths} prices={priceListDinadrei} />
        <PriceTable rows={surfaceNames} columns={widths} prices={priceListDinadreiSurfaces} />
      </>
    </>
  )
}
