import {forwardRef, useEffect, useRef} from 'react'
import * as THREE from 'three'
import {ExtrudeGeometry} from 'three'

export interface ISlidingDoorRailTop {
  length: number
  xPos: number
  yPos: number
  zPos: number
}

export const SlidingDoorRailTop = forwardRef((props: ISlidingDoorRailTop, ref: any) => {
  const {length, xPos, yPos, zPos} = props
  var shape = new THREE.Shape()
  shape.moveTo(0, 0)
  shape.lineTo(0, -0.0035)
  shape.lineTo(0.001, -0.0035)
  shape.lineTo(0.001, 0.021)
  shape.lineTo(0, 0.021)
  shape.lineTo(0, 0.001)
  shape.lineTo(-0.007, 0.001)
  shape.lineTo(-0.007, 0.013)
  shape.lineTo(-0.015, 0.013)
  shape.lineTo(-0.015, 0.001)
  shape.lineTo(-0.024, 0.001)
  shape.lineTo(-0.024, 0.029)
  shape.lineTo(-0.025, 0.029)
  shape.lineTo(-0.025, 0.001)
  shape.lineTo(-0.05, 0.001)
  shape.lineTo(-0.05, 0.021)
  shape.lineTo(-0.058, 0.021)
  shape.lineTo(-0.058, 0.01)
  shape.lineTo(-0.067, 0.01)
  shape.lineTo(-0.067, 0.037)
  shape.lineTo(-0.068, 0.037)
  shape.lineTo(-0.068, 0.01)
  shape.lineTo(-0.085, 0.01)
  shape.lineTo(-0.085, 0.051)
  shape.lineTo(-0.093, 0.051)
  shape.lineTo(-0.093, 0)
  shape.lineTo(0, 0)

  var extrudeSettings = {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  }

  const box = useRef<ExtrudeGeometry>(null!)

  useEffect(() => {
    box.current.translate(0, 0, -length)
  })

  return (
    <mesh ref={ref} position={[xPos, yPos, zPos]} rotation={[0, -Math.PI / 2, 0]}>
      <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} ref={box} />
      <meshStandardMaterial color={'#eeeeee'} metalness={0.2} roughness={0.4} />
    </mesh>
  )
})

export interface ISlidingDoorRailBottom {
  length: number
  xPos: number
  yPos: number
  zPos: number
}

export const SlidingDoorRailBottom = forwardRef((props: ISlidingDoorRailBottom, ref: any) => {
  const {length, xPos, yPos, zPos} = props
  var shape = new THREE.Shape()
  shape.moveTo(-0.006, 0)
  shape.lineTo(-0.055, 0)
  shape.lineTo(-0.055, -0.043)
  shape.lineTo(-0.0535, -0.043)
  shape.lineTo(-0.0535, -0.014)
  shape.lineTo(-0.0335, -0.014)
  shape.lineTo(-0.0335, -0.025)
  shape.lineTo(-0.0275, -0.025)
  shape.lineTo(-0.0275, -0.0015)
  shape.lineTo(-0.0075, -0.0015)
  shape.lineTo(-0.0075, -0.0125)
  shape.lineTo(-0.006, -0.0125)
  shape.lineTo(-0.006, 0)

  var extrudeSettings = {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  }

  const box = useRef<ExtrudeGeometry>(null!)

  useEffect(() => {
    box.current.translate(0, 0, -length)
  })

  return (
    <mesh ref={ref} position={[xPos, yPos, zPos]} rotation={[0, -Math.PI / 2, 0]}>
      <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} ref={box} />
      <meshStandardMaterial color={'#eeeeee'} metalness={0.2} roughness={0.4} />
    </mesh>
  )
})
