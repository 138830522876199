import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {Fragment} from 'react'

export const ConnectedBackpanelSelectors = () => {
  const visible = useStore((store) => store.view.backpanels.edit)
  const [boards, columns, backpanels, width, grid, variant] = useStore(
    (store) => [store.config.rows, store.config.columns.list, store.config.backpanels.list, store.config.main.width, store.config.main.grid, store.view.backpanels.variant],
    shallow
  )
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const backpanelThickness = backpanelMaterials[useStore((store) => store.config.backpanels.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const setBackpanel = useStore((store) => store.backpanels.setBackpanel)
  let yPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += board.height + shelfThickness
          if (variant === 1) {
            const removable = !(
              backpanels.find((obj) => {
                return obj.pos.y === y
              })?.cover === 'full'
            )
            return (
              <Fragment key={y}>
                {removable && (
                  <Selector
                    key={y + '.full'}
                    size={[board.height, backpanelThickness, width - 2 * shelfThickness]}
                    xPos={shelfThickness}
                    yPos={yPos - board.height}
                    zPos={regalModel.backpanelInset}
                    onClick={() => setBackpanel(0, y, 'full')}
                    rotation={[0, 0.5, 0.5]}
                  />
                )}
              </Fragment>
            )
          } else {
            const visibleColumns: number[] = [...columns[y]].filter((el) => el < grid)
            visibleColumns.push(0, grid)
            visibleColumns.sort((a, b) => a - b)
            return [...Array(visibleColumns.length - 1)].map((_, x) => {
              const xPos = visibleColumns[x] * gridSize + shelfThickness
              const nextColumn = Math.min(...visibleColumns.filter((el) => el > visibleColumns[x]))
              const length = (nextColumn - visibleColumns[x]) * gridSize - shelfThickness
              const nrOfBackpanelsInBoard = backpanels.filter((el) => el.pos.y === y).length
              const backpanelAtPos = backpanels.find((el) => el.pos.y === y && el.pos.x === visibleColumns[x] && el.cover === 'compartment')
              const fullBackpanelAtPos = backpanels.find((el) => el.pos.y === y && el.cover === 'full')
              const removable = nrOfBackpanelsInBoard > 1 || fullBackpanelAtPos || !backpanelAtPos
              return (
                <Fragment key={x}>
                  {removable && (
                    <Selector
                      key={y + '.' + x}
                      size={[board.height, backpanelThickness, length]}
                      xPos={xPos}
                      yPos={yPos - board.height}
                      zPos={regalModel.backpanelInset}
                      onClick={() => setBackpanel(visibleColumns[x], y, 'compartment')}
                      rotation={[0, 0.5, 0.5]}
                    />
                  )}
                </Fragment>
              )
            })
          }
        })}
    </>
  )
}
