import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from '../../store/datamodels'
import {ConnectedKubaturTabelarList} from 'products/kubatur/partLists/tabelarList'

export const ConnectedTabelars = () => {
  const tabelarList = ConnectedKubaturTabelarList()
  if (tabelarList.length > 0) {
    const materialID = tabelarList[0].material
    const materialReference = shelfMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  }
  return (
    <>
      {tabelarList.map((tabelar) => {
        return <Board {...tabelar} size={[tabelar.length, tabelar.thickness, tabelar.depth]} material={material[Math.round(Math.random())]} />
      })}
    </>
  )
}
