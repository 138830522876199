import {materialModel, slattedModel} from 'utils/datamodels'
import {footModel, bedMaterials, mattressModel} from './datamodels'

export const initialConfig = {
  main: {
    frameHeight: 0.14,
    frameFlush: false,
    width: 1.6,
    length: 2.0,
    materialID: 's25Ei' as keyof typeof bedMaterials,
    treatmentID: 0,
    headBoard: {
      id: 1,
      height: 0.46,
    },
    stackable: false,
  },
  feet: {
    height: 0.125,
    id: 'bedRnd' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
  mattress: {
    height: 0.2,
    id: 1 as keyof typeof mattressModel,
    color: 'white',
  },
  slatted: {
    height: 0.05,
    inset: 0.119,
    id: 'own' as keyof typeof slattedModel,
  },
  upholstery: {
    list: [],
  },
}

export type IIkuConfig = typeof initialConfig
