import {useStore} from 'products/analog/store/store'
import {shallow} from 'zustand/shallow'
import {blueprint, shelfMaterialModel, backpanelMaterials, footModel} from 'products/analog/store/datamodels'
import {IRow} from '..'

export const ConnectedAnalogColumnList = () => {
  const columnList = useStore((store) => store.rows.clearColumns(), shallow)
  const [width, depth, materialID, grid, boards, backpanels, feetID] = useStore(
    (store) => [store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.main.grid, store.config.rows.list, store.config.backpanels, store.config.feet.id],
    shallow
  )
  return AnalogColumnList(columnList, width, depth, materialID, grid, boards, backpanels.list, backpanels.materialID, feetID)
}

export const AnalogColumnList = (
  columnList: number[][],
  width: number,
  depth: number,
  materialID: keyof typeof shelfMaterialModel,
  grid: number,
  boards: IRow[],
  backpanels: {
    pos: {
      x: number
      y: number
    }
    cover: 'full' | 'compartment'
    face: 'front' | 'back'
  }[],
  backpanelMaterialID: keyof typeof backpanelMaterials,
  feetID: keyof typeof footModel
) => {
  const thickness = shelfMaterialModel[materialID].thickness
  const wallMounted = feetID === 'wallMounted'
  const backpanelInset = wallMounted ? blueprint.backpanelInset.wallMounted : blueprint.backpanelInset.standard
  let startPos = thickness
  const gridsize = (width - thickness) / grid
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const columns: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterialModel; type: string}[] = []

  boards.forEach((el, index) => {
    const fullBackpanel =
      backpanels.find((obj) => {
        return obj.pos.y === index
      })?.cover === 'full'
    const zPos = fullBackpanel ? (backpanelThickness < 0.016 ? backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    columns.push({key: index + '.start', xPos: 0, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    columns.push({key: index + '.end', xPos: width - thickness, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    columnList[index].forEach((i) => {
      const adjustedDepth = depth - zPos - blueprint.frontInset
      columns.push({
        key: index + '.' + i,
        xPos: i * gridsize,
        yPos: startPos,
        zPos: zPos,
        length: el.height,
        depth: adjustedDepth,
        thickness: thickness,
        material: materialID,
        type: 'middleColumn',
      })
    })
    startPos += el.height + thickness
  })
  return columns
}
