import {IStore, ISetProduce} from '../store'
import {accHelper} from 'utils/helpers'
import {frontMaterials} from '../datamodels'
import {GetState} from 'zustand'

export const createFlapSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const flaps = {
    setFlap: function (pos: {x: number; y: number}, size: {x: number; y: number}): void {
      setProduce((state: IStore) => {
        const clearedArray = state.config.flaps.list.filter((flap) => {
          if (accHelper.is_overlapping_with_other(pos, flap.pos, size, flap.size)) return false
          return true
        })
        if (state.config.flaps.list.find((flap) => accHelper.same_is_at_position(pos, flap.pos, size, flap.size))) {
          state.config.flaps.list = clearedArray
        } else {
          state.config.flaps.list = [
            ...clearedArray,
            {
              pos: pos,
              size: size,
              face: 'front',
            },
          ]
        }
        const clearedDoorArray = state.config.doors.list.filter((door) => {
          if (accHelper.is_overlapping_with_other(door.pos, pos, door.size, size)) return false

          return true
        })
        state.config.doors.list = clearedDoorArray
        const clearedDrawerArray = state.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, size, drawer.size)) return false
          return true
        })
        state.config.drawers.list = clearedDrawerArray
        if (pos.y > 0 && !state.config.boards.list[pos.x].includes(pos.y)) state.config.boards.list[pos.x].push(pos.y)
        if (pos.y + size.y < get().config.main.grid && !state.config.boards.list[pos.x].includes(pos.y + size.y)) {
          state.config.boards.list[pos.x].push(pos.y + size.y)
        }
      })
    },
    setMaterialID: (newMaterialID: keyof typeof frontMaterials): void =>
      setProduce((state: IStore) => {
        state.config.flaps.materialID = newMaterialID
      }),
    setEditFlaps: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.flaps.edit = edit
      }),
    setSize: (size: {x: number; y: number}): void => {
      setProduce((state: IStore) => {
        state.view.flaps.size = size
      })
    },
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.flaps.visible = show
      })
    },
  }

  return {
    flaps,
  }
}
