import {materialModel, materialTreatments, w, localFactors} from './../../../utils/datamodels' // @Todo check with Matthias if we can use global workprices
import {ConnectedMetrumBoardList} from '../partLists/boardList'
import {ConnectedMetrumColumnList} from '../partLists/columnList'
import {ConnectedMetrumTabelarList} from '../partLists/tabelarList'
import {drawerPrice, price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from '../store/store'
import {ConnectedMetrumBackpanelList} from '../partLists/backpanelList'
import {ConnectedMetrumDoorList} from '../partLists/doorList'
import {ConnectedMetrumDrawerList} from '../partLists/drawerList'
import {ConnectedMetrumFootList} from '../partLists/footList'
import {footModel, metrumWorkprices} from '../store/datamodels'
import {ConnectedMetrumClothingRailList} from '../partLists/clothingRailList'
import {shelfMaterialModel} from 'products/analog/store/datamodels'
import {ConnectedMetrumBottomBoardList} from '../partLists/bottomBoardList'
import {IVector} from 'common/threeComponents/FiberBoard'

export const ConnectedMetrumPrice = () => {
  const localFactor = localFactors[useCommonStore((store) => store.localFactorID)].factor
  const treatmentID = useStore((store) => store.config.main.treatmentID)
  const partList = getPartList()
  const footList = ConnectedMetrumFootList()
  const drawerList = ConnectedMetrumDrawerList()
  const clothingRailList = ConnectedMetrumClothingRailList()
  return MetrumPrice(localFactor, treatmentID, partList, footList, drawerList, clothingRailList)
}

export const MetrumPrice = (
  localFactor: number,
  treatmentID: number,
  partList: any[],
  footList: {
    key: string
    pos: IVector
    size: IVector
    material: string
    type: keyof typeof footModel
  }[],
  drawerList: any[],
  clothingRailList: any[]
) => {
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = treatment.setup
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  let priceDrawers = 0
  let priceClothingRail = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice)
    priceCNC += getCNCPrice(part)
  })
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })

  drawerList.forEach((drawer) => {
    priceDrawers += drawerPrice(drawer, treatmentPrice).totalPrice
  })

  clothingRailList.forEach((clothingRail) => {
    priceClothingRail += w.Kleiderstange
  })

  const price = Math.round(localFactor * 10 * Math.round((w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceClothingRail + priceFeet + priceDrawers)) / 10))
  return <>{price}</>
}

const getPartList = () => {
  const partList = [
    ...ConnectedMetrumColumnList(),
    ...ConnectedMetrumBoardList(),
    ...ConnectedMetrumTabelarList(),
    ...ConnectedMetrumBackpanelList(),
    ...ConnectedMetrumDoorList(),
    ...ConnectedMetrumBottomBoardList(),
  ]
  return partList
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'door':
      const nrDoorHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 1) / 0.5) : 0)
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrDoorHinges * w.Topfband, metrumWorkprices.StahlTuer * part.length]})
      break
    case 'board':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material as keyof typeof shelfMaterialModel})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'door':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitTuer]})
      break
    case 'backpanel':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitRW]})
      break
    case 'bottomBoard':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: false})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'top':
    case 'bottom':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
    case 'board':
    case 'tabelar':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 2.4, nrToolChanges: 3, nrDrills: 30, format: true, bevel: true})
      break
    case 'column':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3.4, nrDrills: 20 + 4 * Math.floor((part.length - 0.25) / 0.064), nrGrooves: 3, format: true, bevel: true})
      break
    case 'middleColumn':
      price = price_CNC({
        length: part.length,
        width: part.depth,
        nrSetups: 2,
        nrToolChanges: 4,
        nrDrills: 40 + 2 * 4 * Math.floor((part.length - 0.25) / 0.064),
        nrGrooves: 6,
        format: true,
        bevel: true,
      })
      break
    case 'backpanel':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 2.4, nrGrooves: 2, nrDrills: 10, format: true, bevel: true})
      break
    case 'door':
      const nrDoorHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 1) / 0.5) : 0)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 6, format: true, bevel: true, rest: [nrDoorHinges * w.CNCZeitTopfband]})
      break
    case 'bottomBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 2.4, nrDrills: 16, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
  }
  return price
}
