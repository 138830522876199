import {Vector3} from 'three'

import Ash from 'common/static/textures/ash.jpg'
import Oak from 'common/static/textures/oak.jpg'
import WalnutEuro from 'common/static/textures/walnut-euro.jpg'
import Maple from 'common/static/textures/maple.jpg'
import Birch from 'common/static/textures/birch.jpg'
import Beech from 'common/static/textures/beech.jpg'
import Cherry from 'common/static/textures/cherry.jpg'
import WalnutAmi from 'common/static/textures/walnut-ami.jpg'
import WalnutBlack from 'common/static/textures/walnut-black.jpg'
import Fir from 'common/static/textures/fir.jpg'
import Pine from 'common/static/textures/pine.jpg'

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://back.sanktjohanser.net'

export const localFactors = [
  {id: 0, factor: 1.0, name: 'DE', fullname: 'Deutschland', vat: 'netto'},
  {id: 1, factor: 1.19, name: 'DE', fullname: 'Deutschland', vat: 'inkl. 19% Mwst'},
  {id: 2, factor: 1.0, name: 'CH', fullname: 'Schweiz', vat: 'netto'},
  {id: 3, factor: 1.0, name: 'AT', fullname: 'Österreich', vat: 'netto'},
  {id: 4, factor: 1.0, name: 'IT', fullname: 'Italien', vat: 'netto'},
  {id: 5, factor: 1.1, name: 'EXPORT', fullname: 'Export', vat: 'netto'},
]

const baseMaterialModel = {
  mAh: {matReference: 0, treatment: true, type: 0, name: 'ahorn', src: Maple},
  mBi: {matReference: 1, treatment: true, type: 0, name: 'birke', src: Birch},
  mBu: {matReference: 2, treatment: true, type: 0, name: 'buche', src: Beech},
  mEi: {matReference: 3, treatment: true, type: 0, name: 'eiche', src: Oak},
  mEs: {matReference: 4, treatment: true, type: 0, name: 'esche', src: Ash},
  mKi: {matReference: 5, treatment: true, type: 0, name: 'kirschbaum', src: Cherry},
  mN: {matReference: 6, treatment: true, type: 0, name: 'nussbaum', src: WalnutAmi},

  sAh: {matReference: 11, treatment: true, type: 1, name: 'ahorn', src: Maple},
  sBi: {matReference: 12, treatment: true, type: 1, name: 'birke', src: Birch},
  sBu: {matReference: 13, treatment: true, type: 1, name: 'buche', src: Beech},
  sEi: {matReference: 14, treatment: true, type: 1, name: 'eiche', src: Oak},
  sEs: {matReference: 15, treatment: true, type: 1, name: 'esche', src: Ash},
  sKi: {matReference: 16, treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  sN: {matReference: 17, treatment: true, type: 1, name: 'nussbaum', src: WalnutAmi},
  sTa: {matReference: 'sTa', treatment: true, type: 1, name: 'Tanne', src: Fir},
  sZi: {matReference: 'sZi', treatment: true, type: 1, name: 'Zirbe', src: Pine},
}

// prettier-ignore
export const linoleumMaterials = {
  l20Charcoal: {id: 'l20Charcoal', matReference: 'lCharcoal', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'charcoal, kante eiche', color:'#3c3c3c'},
  l20Ash: {id: 'l20Ash', matReference: 'lAsh', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'ash, kante eiche', color:'#bfbfbf'},
  l20Nero: {id: 'l20Nero', matReference: 'lNero', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'nero, kante eiche', color:'#000000'},
  l20Burgundy: {id: 'l20Burgundy', matReference: 'lBurgundy', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'burgundy, kante eiche', color:'#4b2123'},
  l20Pewter: {id: 'l20Pewter', matReference: 'lPewter', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pewter, kante eiche', color:'#4b5356'},
  l20Pearl: {id: 'l20Pearl', matReference: 'lPearl', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pearl, kante eiche', color:'#f2edd4'},
  l20Salsa: {id: 'l20Salsa', matReference: 'lSalsa', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'salsa, kante eiche', color:'#992824'},
  l20Conifer: {id: 'l20Conifer', matReference: 'lConifer', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'conifer, kante eiche', color:'#073125'},
  l20Pebble: {id: 'l20Pebble', matReference: 'lPebble', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pebble, kante eiche', color:'#c2c2c0'},
  l20Mushroom: {id: 'l20Mushroom', matReference: 'lMushroom', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'mushroom, kante eiche', color:'#d5d1c6'},
  l20Mauve: {id: 'l20Mauve', matReference: 'lMauve', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'mauve, kante eiche', color:'#5c4e4b'},
  l20Pistachio: {id: 'l20Pistachio', matReference: 'lPistachio', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pistachio, kante eiche', color:'#d3dbc6'},
  l20SpringGreen: {id: 'l20SpringGreen', matReference: 'lSpringGreen', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'spring green, kante eiche', color:'#d3d56f'},
  l20Powder: {id: 'l20Powder', matReference: 'lPowder', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'powder, kante eiche', color:'#efdfd9'},
  l20Olive: {id: 'l20Olive', matReference: 'lOlive', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'olive, kante eiche', color:'#6a6d5a'},
  l20OrangeBlast: {id: 'l20OrangeBlast', matReference: 'lOrangeBlast', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'orange blast, kante eiche', color:'#ec5b30'},
  l20SmokeyBlue: {id: 'l20SmokeyBlue', matReference: 'lSmokeyBlue', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'smokey blue, kante eiche', color:'#3c455a'},
  l20Aquavert: {id: 'l20Aquavert', matReference: 'lAquavert', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'aquavert, kante eiche', color:'#a3d5dd'},
  l20IronGrey: {id: 'l20IronGrey', matReference: 'lIronGrey', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'iron grey, kante eiche', color:'#535154'},
  l20Vapour: {id: 'l20Vapour', matReference: 'lVapour', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'vapour, kante eiche', color:'#ceced1'},
  l20MidnightBlue: {id: 'l20MidnightBlue', matReference: 'lMidnightBlue', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'midnight blue, kante eiche', color:'#3b59a0'},
  l20Clay: {id: 'l20Clay', matReference: 'lClay', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'clay, kante eiche', color:'#d5aa77'},
  l20Leather: {id: 'l20Leather', matReference: 'lLeather', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'leather, kante eiche', color:'#cc904e'},
  l20Walnut: {id: 'l20Walnut', matReference: 'lWalnut', thickness: 0.02, price: 297, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'walnut, kante eiche', color:'#ab7a57'},

  l25Charcoal: {id: 'l25Charcoal', matReference: 'lCharcoal', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'charcoal, kante eiche', color:'#3c3c3c'},
  l25Ash: {id: 'l25Ash', matReference: 'lAsh', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'ash, kante eiche', color:'#a4a6a6'},
  l25Nero: {id: 'l25Nero', matReference: 'lNero', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'nero, kante eiche', color:'#000000'},
  l25Burgundy: {id: 'l25Burgundy', matReference: 'lBurgundy', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'burgundy, kante eiche', color:'#4b2123'},
  l25Pewter: {id: 'l25Pewter', matReference: 'lPewter', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pewter, kante eiche', color:'#4b5356'},
  l25Pearl: {id: 'l25Pearl', matReference: 'lPearl', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pearl, kante eiche', color:'#f2edd4'},
  l25Salsa: {id: 'l25Salsa', matReference: 'lSalsa', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'salsa, kante eiche', color:'#992824'},
  l25Conifer: {id: 'l25Conifer', matReference: 'lConifer', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'conifer, kante eiche', color:'#073125'},
  l25Pebble: {id: 'l25Pebble', matReference: 'lPebble', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pebble, kante eiche', color:'#c2c2c0'},
  l25Mushroom: {id: 'l25Mushroom', matReference: 'lMushroom', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'mushroom, kante eiche', color:'#d5d1c6'},
  l25Mauve: {id: 'l25Mauve', matReference: 'lMauve', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'mauve, kante eiche', color:'#5c4e4b'},
  l25Pistachio: {id: 'l25Pistachio', matReference: 'lPistachio', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'pistachio, kante eiche', color:'#d3dbc6'},
  l25SpringGreen: {id: 'l25SpringGreen', matReference: 'lSpringGreen', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'spring green, kante eiche', color:'#d3d56f'},
  l25Powder: {id: 'l25Powder', matReference: 'lPowder', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'powder, kante eiche', color:'#efdfd9'},
  l25Olive: {id: 'l25Olive', matReference: 'lOlive', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'olive, kante eiche', color:'#6a6d5a'},
  l25OrangeBlast: {id: 'l25OrangeBlast', matReference: 'lOrangeBlast', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'orange blast, kante eiche', color:'#ec5b30'},
  l25SmokeyBlue: {id: 'l25SmokeyBlue', matReference: 'lSmokeyBlue', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'smokey blue, kante eiche', color:'#3c455a'},
  l25Aquavert: {id: 'l25Aquavert', matReference: 'lAquavert', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'aquavert, kante eiche', color:'#a3d5dd'},
  l25IronGrey: {id: 'l25IronGrey', matReference: 'lIronGrey', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'iron grey, kante eiche', color:'#535154'},
  l25Vapour: {id: 'l25Vapour', matReference: 'lVapour', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'vapour, kante eiche', color:'#ceced1'},
  l25MidnightBlue: {id: 'l25MidnightBlue', matReference: 'lMidnightBlue', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'midnight blue, kante eiche', color:'#3b59a0'},
  l25Clay: {id: 'l25Clay', matReference: 'lClay', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'clay, kante eiche', color:'#d5aa77'},
  l25Leather: {id: 'l25Leather', matReference: 'lLeather', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'leather, kante eiche', color:'#cc904e'},
  l25Walnut: {id: 'l25Walnut', matReference: 'lWalnut', thickness: 0.025, price: 297, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: false, type: 6, name: 'walnut, kante eiche', color:'#ab7a57'},  
}

// prettier-ignore
export const materialModel = {
  h2SWBC: {id: 'h2SWBC', price: 71, matReference: 'hplWhite', thickness: 0.002, treatment: false, type: 2, name: 'weiss', color: '#ebebeb', insideFront: true},
  h2SB: {id: 'h2SB', price: 71, matReference: 'hplBlack', thickness: 0.002, treatment: false, type: 2, name: 'schwarz', color: '#000000', insideFront: true},
  h2Al: {id: 'h2Al', price: 78, matReference: 'hplAlu', thickness: 0.002, treatment: false, type: 2, name: 'Aluminium', color: '#a9a9a9', insideFront: true},
  h3Ei: {id: 'h3Ei', price: 155, matReference: 'hplOak', thickness: 0.003, treatment: true, type: 2, name: 'Eiche furniert', src: Oak, insideFront: true},
  h3aN: {id: 'h3aN', price: 155, matReference: 'hplNut', thickness: 0.003, treatment: true, type: 2, name: 'Nussbaum furniert', src: WalnutAmi, insideFront: true},

  h6TWBC: {id: 'h6TWBC', matReference: 'hplWhite', thickness: 0.006, price: 67, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss, schw. Kern', color: '#ebebeb'}, //
  h6SWBC: {id: 'h6SWBC', matReference: 'hplWhite', thickness: 0.006, price: 110, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss, schw. Kern', color: '#ebebeb'}, //
  h6TB: {id: 'h6TB', matReference: 'hplBlack', thickness: 0.006, price: 67, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  h6TW: {id: 'h6TW', matReference: 'hplPureWhite', thickness: 0.006, price: 102, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss', color: '#ebebeb'},
  h6SB: {id: 'h6SB', matReference: 'hplBlack', thickness: 0.006, price: 110, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h6SW: {id: 'h6SW', matReference: 'hplPureWhite', thickness: 0.006, price: 136, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},

  mdf8W: {id: 'mdf8W', matReference: 7, thickness: 0.008, price: 10, maxWidth: 2.8, treatment: false, type: 4, name: 'melamin weiss', shortName:'weiss melam.', color: '#ebebeb'},
  h8TB: {id: 'h8TB', matReference: 'hplBlack', thickness: 0.008, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  f9Ah: {id: 'f9Ah', price: 65, matReference: 0, thickness: 0.008, treatment: true, shelfReference: ['m18Ah', 's19Ah'], type:5, name: 'Ahorn funiert', src: Maple},
  f9Bi: {id: 'f9Bi', price: 65, matReference: 1, thickness: 0.008, treatment: true, shelfReference: ['m18Bi', 's19Bi'], type:5, name: 'Birke funiert', src: Birch},
  f9Bu: {id: 'f9Bu', price: 65, matReference: 2, thickness: 0.008, treatment: true, shelfReference: ['m18Bu', 's19Bu'], type:5, name: 'Buche funiert', src: Beech},
  f9Ei: {id: 'f9Ei', price: 65, matReference: 3, thickness: 0.008, treatment: true, shelfReference: ['m18Ei', 's19Ei'], type:5, name: 'Eiche funiert', src: Oak},
  f9Es: {id: 'f9Es', price: 65, matReference: 4, thickness: 0.008, treatment: true, shelfReference: ['m18Es', 's19Es'], type:5, name: 'Esche funiert', src: Ash},
  f9Ki: {id: 'f9Ki', price: 75, matReference: 5, thickness: 0.008, treatment: true, shelfReference: ['m18Ki', 's19Ki'], type:5, name: 'Kirschbaum funiert', src: Cherry},
  f9aN: {id: 'f9aN', price: 75, matReference: 6, thickness: 0.008, treatment: true, shelfReference: ['m18aN', 's19aN', 's19NwS'], type:5, name: 'Nussbaum funiert', src: WalnutAmi},
  m9Bi: {id: 'm9Bi', matReference: 1, thickness: 0.009, price: 25.23, maxWidth: 3, treatment: false, type: 0, name: 'birke'},

  h10TWBC: {id: 'h10TWBC', matReference: 'hplWhite', thickness: 0.010, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss mit schwarzem Kern', color: '#ebebeb'},
  h10SWBC: {id: 'h10SWBC', matReference: 'hplWhite', thickness: 0.010, price: 184, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss mit schwarzem Kern', color: '#ebebeb'},
  h10TB: {id: 'h10TB', matReference: 'hplBlack', thickness: 0.010, eModul: 14000, weight: 1400, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  h10TW: {id: 'h10TW', matReference: 'hplPureWhite', thickness: 0.010, eModul: 14000, weight: 1400, price: 156, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss', color: '#ebebeb'},
  h10SB: {id: 'h10SB', matReference: 'hplBlack', thickness: 0.010, eModul: 14000, weight: 1400, price: 184, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h10SW: {id: 'h10SW', matReference: 'hplPureWhite', thickness: 0.010, eModul: 14000, weight: 1400, price: 267, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},
  h12SB: {id: 'h12SB', matReference: 'hplBlack', thickness: 0.012, eModul: 14000, weight: 1400, price: 221, maxWidth: 4, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h12SW: {id: 'h12SW', matReference: 'hplPureWhite', thickness: 0.012, eModul: 14000, weight: 1400, price: 321, maxWidth: 4, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},

  m12Ei: {id: 'm12Ei', thickness: 0.012, eModul: 10720, weight: 680, price: 110, maxWidth: 3.58, fallback: 's13Ei', drawerInnerMaterial: 'm12Ei', ...baseMaterialModel.mEi},
  m12MW: {id: 'm12MW', matReference: 7, thickness: 0.012, eModul: 10720, weight: 680, price: 54, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'm12MW', treatment: false, type: 0, name: 'melamin weiss', shortName:'weiss melam.', color: '#ebebeb'},
  m12PB: {id: 'm12PB', matReference: 9, thickness: 0.012, eModul: 10720, weight: 680, price: 37, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'm12PB', treatment: false, type: 0, name: 'phenol schwarz', color: '#050505'},
  m12CB: {id: 'm12CB', matReference: 9, thickness: 0.012, eModul: 10720, weight: 680, price: 85, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'cpl schwarz', color: '#050505'},
  s13Ah: {id: 's13Ah', matReference: 11, thickness: 0.013, price: 160, maxWidth: 2.98, fallback: 'm12Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},
  s13Bi: {id: 's13Bi', matReference: 12, thickness: 0.013, price: 114, maxWidth: 2.98, fallback: 'm12Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 1, name: 'birke', src: Birch},
  s13Bu: {id: 's13Bu', matReference: 13, thickness: 0.013, price: 114, maxWidth: 2.98, fallback: 'm12Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s13Ei: {id: 's13Ei', matReference: 14, thickness: 0.013, eModul: 13000, weight: 710, price: 160, maxWidth: 2.98, fallback: 'm12Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s13Es: {id: 's13Es', matReference: 15, thickness: 0.013, price: 160, maxWidth: 2.98, fallback: 'm12Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s13Ki: {id: 's13Ki', matReference: 16, thickness: 0.013, price: 188, maxWidth: 2.98, fallback: 'm12Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  s13NmS: {id: 's13NmS', matReference: 18, thickness: 0.013, price: 235, maxWidth: 2.98, fallback: 'm12aN', drawerInnerMaterial: 's13NmS', treatment: true, type: 1, name: 'nussbaum mit Splint', src: WalnutEuro},
  s13NwS: {id: 's13NwS', matReference: 17, thickness: 0.013, price: 278, maxWidth: 2.98, fallback: 'm12aN', drawerInnerMaterial: 's13NwS', treatment: true, type: 1, name: 'nussbaum mit wenig Splint', src: WalnutAmi},
  s13NoS: {id: 's13NoS', matReference: 19, thickness: 0.013, price: 375, maxWidth: 2.98, fallback: 'm12aN', drawerInnerMaterial: 's13NoS', treatment: true, type: 1, name: 'nussbaum Splintfrei', src: WalnutBlack},
  
  m18Mpx: {id: 'm18Bi', matReference: 1, thickness: 0.018, price: 42.74, maxWidth: 3.58, fallback: 's19Ei', drawerInnerMaterial: 's13Bi', treatment: true, type: 0, name: 'birke', src: Birch},
  mdf19W: {id: 'mdf19W', matReference: 7, thickness: 0.019, price: 18, maxWidth: 2.8, treatment: false, type: 4, name: 'melamin weiss', color: '#ebebeb'},
  m18Ah: {id: 'm18Ah', matReference: 0, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 0, name: 'ahorn', src: Maple},
  m18Bi: {id: 'm18Bi', matReference: 1, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ei', drawerInnerMaterial: 's13Bi', treatment: true, type: 0, name: 'birke', src: Birch},
  m18Bu: {id: 'm18Bu', matReference: 2, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 0, name: 'buche', src: Beech},
  m18Ei: {id: 'm18Ei', matReference: 3, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: true, type: 0, name: 'eiche', src: Oak},
  m18Es: {id: 'm18Es', matReference: 4, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Es', drawerInnerMaterial: 's13Es', treatment: true, type: 0, name: 'esche', src: Ash},
  m18Ki: {id: 'm18Ki', matReference: 5, thickness: 0.018, price: {2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 0, name: 'kirschbaum', src: Cherry},
  m18aN: {id: 'm18aN', matReference: 6, thickness: 0.018, price: {2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19NwS', drawerInnerMaterial: 's13NwS', treatment: true, type: 0, name: 'amerik. nussbaum', src: WalnutEuro},
  m18MW: {id: 'm18MW', matReference: 7, thickness: 0.018, price: 65, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12MW', treatment: false, type: 0, name: 'melamin weiss', shortName: 'mel weiss', color: '#ebebeb'},
  m18SW: {id: 'm18SW', matReference: 8, thickness: 0.019, price: 122, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'solidWhite', treatment: false, type: 0, name: 'hpl softtouch weiss', color: '#ebebeb'},
  m18CB: {id: 'm18CB', matReference: 9, thickness: 0.018, price: 68, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'cpl schwarz', color: '#050505'},
  m18SB: {id: 'm18SB', matReference: 10, thickness: 0.019, price: 122, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'hpl softtouch schwarz', color: '#090909'},
  s19Ah: {id: 's19Ah', matReference: 11, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},
  s19Bu: {id: 's19Bu', matReference: 13, thickness: 0.019, price: {2.99: 84, 3.65: 205}, maxWidth: 3.5, fallback: 'm18Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s19Ei: {id: 's19Ei', matReference: 14, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s19Es: {id: 's19Es', matReference: 15, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s19Ki: {id: 's19Ki', matReference: 16, thickness: 0.019, price: 153, maxWidth: 2.98, fallback: 'm18Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  s19NmS: {id: 's19NmS', matReference: 18, thickness: 0.019, price: 200, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13NmS', treatment: true, type: 1, name: 'nussbaum mit Splint', src: WalnutEuro},
  s19NwS: {id: 's19NwS', matReference: 17, thickness: 0.019, price: 243, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13NwS', treatment: true, type: 1, name: 'nussbaum mit wenig Splint', src: WalnutAmi},
  s19NoS: {id: 's19NoS', matReference: 19, thickness: 0.019, price: 340, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13NoS', treatment: true, type: 1, name: 'nussbaum Splintfrei', src: WalnutBlack},
  s19Ta: {id: 's19Ta', matReference: 'sTa', thickness: 0.019, price: 100, maxWidth: 2.98, fallback: 'm18Ei', drawerInnerMaterial: 's19Ta', treatment: true, type: 1, name: 'Tanne', src: Fir},
  s19Zi: {id: 's19Zi', matReference: 'sZi', thickness: 0.019, price: 162, maxWidth: 2.98, fallback: 'm18Ei', drawerInnerMaterial: 's19Zi', treatment: true, type: 1, name: 'Zirbe', src: Pine},
  
  m24Ah: {id: 'm24Ah', matReference: 0, thickness: 0.024, price: 130, maxWidth: 3.58, fallback: 's25Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 0, name: 'ahorn', src: Maple},
  m24Bi: {id: 'm24Bi', matReference: 1, thickness: 0.024, price: 130, maxWidth: 3.58, fallback: 's25Ei', drawerInnerMaterial: 's13Bi', treatment: true, type: 0, name: 'birke', src: Birch},
  m24Bu: {id: 'm24Bu', matReference: 2, thickness: 0.024, price: 130, maxWidth: 3.58, fallback: 's25Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 0, name: 'buche', src: Beech},
  m24Ei: {id: 'm24Ei', matReference: 3, thickness: 0.024, price: 130, maxWidth: 3.58, fallback: 's25Ei', drawerInnerMaterial: 'm12Ei', treatment: true, type: 0, name: 'eiche', src: Oak},
  m24Es: {id: 'm24Es', matReference: 4, thickness: 0.024, price: 130, maxWidth: 3.58, fallback: 's25Es', drawerInnerMaterial: 's13Es', treatment: true, type: 0, name: 'esche', src: Ash},
  m24Ki: {id: 'm24Ki', matReference: 5, thickness: 0.024, price: 145, maxWidth: 3.58, fallback: 's25Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 0, name: 'kirschbaum', src: Cherry},
  m24aN: {id: 'm24aN', matReference: 6, thickness: 0.024, price: 145, maxWidth: 3.58, fallback: 's25aN', drawerInnerMaterial: 's13NwS', treatment: true, type: 0, name: 'amerik. nussbaum', src: WalnutEuro},
  m25SW: {id: 'm25SW', matReference: 8, thickness: 0.025, price: 182, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'solidWhite', treatment: false, type: 0, name: 'hpl softtouch weiss', color: '#ebebeb'},
  m25SB: {id: 'm25SB', matReference: 10, thickness: 0.025, price: 182, maxWidth: 2.98, fallback: 's25Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'hpl softtouch schwarz', color: '#090909'},
  
  // SOLID WOOD 25mm
  s25Ah: {id: 's25Ah', matReference: 11, thickness: 0.025, price: 148, maxWidth: 2.98, fallback: 'm24Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},  
  s25Bi: {id: 's25Bi', matReference: 12, thickness: 0.025, price: 97, maxWidth: 2.98, fallback: 'm24Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 1, name: 'birke', src: Birch},
  s25Bu: {id: 's25Bu', matReference: 13, thickness: 0.025, price: 97, maxWidth: 2.98, fallback: 'm24Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s25Ei: {id: 's25Ei', matReference: 14, thickness: 0.025, price: 148, maxWidth: 2.98, fallback: 'm24Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s25Es: {id: 's25Es', matReference: 15, thickness: 0.025, price: 148, maxWidth: 2.98, fallback: 'm24Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s25Ki: {id: 's25Ki', matReference: 16, thickness: 0.025, price: 231, maxWidth: 2.98, fallback: 'm24Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},  
  s25NmS: {id: 's25NmS', matReference: 18, thickness: 0.025, price: 260, maxWidth: 2.98, fallback: 'm24aN', drawerInnerMaterial: 's13NmS', treatment: true, type: 1, name: 'nussbaum mit Splint', src: WalnutEuro},
  s25NwS: {id: 's25NwS', matReference: 17, thickness: 0.025, price: 305, maxWidth: 2.98, fallback: 'm24aN', drawerInnerMaterial: 's13NwS', treatment: true, type: 1, name: 'nussbaum mit wenig Splint', src: WalnutAmi},
  s25NoS: {id: 's25NoS', matReference: 19, thickness: 0.025, price: 405, maxWidth: 2.98, fallback: 'm24aN', drawerInnerMaterial: 's13NoS', treatment: true, type: 1, name: 'nussbaum Splintfrei', src: WalnutBlack},  
  
  // SOLID WOOD 30mm
  s30Ah: {id: 's30Ah', matReference: 11, thickness: 0.03, price: 192, maxWidth: 2.98, fallback: 'm30Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},  
  s30Bu: {id: 's30Bu', matReference: 13, thickness: 0.03, price: 126, maxWidth: 2.98, fallback: 'm30Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s30Ei: {id: 's30Ei', matReference: 14, thickness: 0.03, price: 192, maxWidth: 2.98, fallback: 'm30Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s30Es: {id: 's30Es', matReference: 15, thickness: 0.03, price: 192, maxWidth: 2.98, fallback: 'm30Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s30Ki: {id: 's30Ki', matReference: 16, thickness: 0.03, price: 249, maxWidth: 2.98, fallback: 'm30Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  s30NmS: {id: 's30NmS', matReference: 18, thickness: 0.03, price: 351, maxWidth: 2.98, fallback: 'm30aN', drawerInnerMaterial: 's13NmS', treatment: true, type: 1, name: 'nussbaum mit Splint', src: WalnutEuro},
  s30NwS: {id: 's30NwS', matReference: 17, thickness: 0.03, price: 413, maxWidth: 2.98, fallback: 'm30aN', drawerInnerMaterial: 's13NwS', treatment: true, type: 1, name: 'nussbaum mit wenig Splint', src: WalnutAmi},
  s30NoS: {id: 's30NoS', matReference: 19, thickness: 0.03, price: 513, maxWidth: 2.98, fallback: 'm30aN', drawerInnerMaterial: 's13NoS', treatment: true, type: 1, name: 'nussbaum Splintfrei', src: WalnutBlack},
  
  // LINO Materials
  ...linoleumMaterials,
  
  // extra Materials
  solidWhite: {id: 'solidWhite', matReference: 'solidWhite', thickness: 0.013, price: 0, maxWidth: 2.98, fallback: 'm18MW', drawerInnerMaterial: 'm12MW', treatment: false, type: 2, name: 'weiss'},
  solidBlack: {id: 'solidBlack', matReference: 'solidBlack', thickness: 0.013, price: 0, maxWidth: 2.98, fallback: 'm18CB', drawerInnerMaterial: 'm12CB', treatment: false, type: 2, name: 'schwarz'},
  
  
  // ARCHIVED
  s13aN: {id: 's13aN', matReference: 17, thickness: 0.013, price: NaN, maxWidth: 2.98, fallback: 'm12aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum amerikanisch', src: WalnutAmi, archived : true},
  s19aN: {id: 's19aN', matReference: 17, thickness: 0.019, price: NaN, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum amerikanisch', src: WalnutAmi, archived : true},
  s19eN: {id: 's19eN', matReference: 18, thickness: 0.019, price: NaN, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum europäisch', src: WalnutEuro, archived : true},
  s19sN: {id: 's19sN', matReference: 19, thickness: 0.019, price: NaN, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'schwarznuss', src: WalnutBlack, archived : true},
  s19Bi: {id: 's19Bi', matReference: 12, thickness: 0.019, price: NaN, maxWidth: 3.5, fallback: 'm18Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 1, name: 'birke', src: Birch, archived : true},
}

export const materialTypes = [
  {id: 0, name: 'multiplex', shortName: 'mpx'},
  {id: 1, name: 'massiv', shortName: ''},
  {id: 2, name: 'hpl'},
  {id: 3, name: '3-Schicht', shortName: '3S'},
  {id: 4, name: 'mdf'},
  {id: 5, name: ''},
  {id: 6, name: 'Linoleum'},
]

export const drawerLengths = [0.25, 0.3, 0.32, 0.35, 0.38, 0.4, 0.42, 0.45, 0.48, 0.5, 0.52, 0.55, 0.58, 0.6]
export const drawerSideInset = 0.0205

export const materialTreatments = [
  {id: 0, price: 25, setup: 0, name: 'geölt'},
  {id: 1, price: 25, setup: 0, name: 'hell geölt'},
  {id: 2, price: 32.5, setup: 10, name: 'geseift'},
  {id: 3, price: 35, setup: 30, name: 'lackiert'},
]

// geometry: [{type: "round" | "rect" | "castor", dia?: number, sizeX?: number, sizeZ?:number, height: number | "variable", matReference:"materialSingleton", yPos?: number | -number (sticking on top)}
export const footTypeModel = [
  {id: 0, geometry: [{type: 'round', dia: 0.055, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rund'},
  {id: 1, geometry: [{type: 'round', dia: 0.03, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rund'},
  {id: 2, geometry: [{type: 'rect', sizeX: 0.01, sizeZ: 0.06, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rechteckig'},
  {
    id: 10,
    geometry: [
      {type: 'rect', sizeX: 0.012, sizeZ: 0.018, height: 0.003, matReference: 'solidBlack'},
      {type: 'rect', sizeX: 0.012, sizeZ: 0.018, height: 0.0015, yPos: 0.003, matReference: 'solidAlu'},
      {type: 'round', dia: 0.012, height: 'variable', yPos: 0.005, matReference: 'solidAlu'},
    ],
    name: 'Stellfuss rund',
  },
  {
    id: 11,
    geometry: [
      {type: 'round', dia: 0.03, height: 0.005, matReference: 'solidBlack'},
      {type: 'round', dia: 0.008, height: 'variable', yPos: 0.005, matReference: 'solidAlu'},
      {type: 'rect', sizeX: 0.03, sizeZ: 0.019, height: 0.025, yPos: -0.025, matReference: 'solidBlack'},
    ],
    name: 'Stellfuss rund',
  },
  {id: 20, geometry: [{type: 'castor', sizeX: 0.03, sizeZ: 0.03, yPos: 1, height: 'variable', matReference: 'solidBlack'}], name: 'Rolle'},
]

export const footModel: {[key: string]: {heights: number[]; price: number; range?: number[]; standardHeight?: number; xInset?: number; yInset?: number; sizeXY?: [number, number]; name: string}} = {
  wallMounted: {heights: [0.5], price: 0, name: 'wandmontiert'}, // wallmounted
  s23: {heights: [0.023], range: [-0.004, 0.004], xInset: 0.05, yInset: 0.05, sizeXY: [0.0275, 0.0275], price: 0, name: 'Stellfuss rund'}, // Standard Stellfuss von Häfele
  u308: {heights: [0.043], range: [-0.007, 0.007], xInset: 0.05, price: 0, name: 'Stellfuss rund'}, // Stellfuss in Gewindebuchse für U-Profile
  gn832: {heights: [0.03], range: [-0.006, 0.006], xInset: 0.06, price: 0, name: 'Stellfuss rund'}, // Gelenkfuss schwarz von Ganter
  gn840: {heights: [0.025], range: [-0.01, 0.01], xInset: 0.027, price: 0, name: 'Stellfuss rund'}, // Gelenkfuss schwarz von Ganter
  shv: {heights: [0.018], range: [-0.012, 0.012], xInset: 0.05, price: 0, name: 'Stellfuss'}, // Sockelhöhenversteller von Häfele / Würth
  rnd30: {heights: [0.1, 0.15, 0.2], standardHeight: 1, range: [0, 0.004], xInset: 0.1, price: 12.12, name: 'Rundfuss'}, // Rundfuss von Opo
  rct16: {heights: [0.1, 0.15, 0.2], standardHeight: 1, range: [0, 0.004], xInset: 0.1, price: 12.12, name: 'Rechteckfuss'}, // Rechteckfuss
  castor43: {heights: [0.043], xInset: 0.05, yInset: 0.03, sizeXY: [0.0175, 0.0175], price: 5, name: 'Lenkrolle'}, // Rolle
  bedRnd: {heights: [0.125, 0.2], standardHeight: 0, price: 45.97, name: 'Rundfuss'}, // Bettfuss Merkt
  bedRct: {heights: [0.105], price: 36.77, name: 'Rechteckfuss'}, // HPL Rechteckfuss
  bedStackableRnd: {heights: [0.125], standardHeight: 0, price: 9.23, name: 'Rundfuss Stapelbett'}, // Stapelbarer Rundfuss
}

export const slattedModel = {
  standard: {id: 'standard', height: 0.05, price: 0, name: 'Standard', description: 'Schichtholzrahmen, Bauhöhe 5cm, ohne Verstellung, mit Mittelzonenverstärkung'}, // Standard Lattenrost
  own: {id: 'own', price: 0, name: 'Individuell', description: 'nicht inkludiert'}, // Eigener Lattenrost
  integrated: {id: 'integrated', price: 0, height: 0, name: 'Integriert', description: 'fest integrierter Rost für guten Sitz- und Liegekomfort'}, // Integrierter Lattenrost
}

export const cameraPosition = new Vector3(0, 1.25, 8)

export const perspectives = {
  front: {x: 0, y: 0},
  iso: {x: -30, y: 0},
  side: {x: -90, y: 0},
  back: {x: 180, y: 0},
}

export const apertureAngles = {
  doors: [0, 0],
  flaps: [0, -1],
}

const workPrices = {
  Faktor: 3.4,
  Oelen: 25,
  Kante: 1.5,
  Lohn: 70,
  Verschnitt: 1.2,
  Handling: 3.5,
  Zuschnitt: 3.5,
  CNC: 110,
  Werkstueckwechsel: 120,
  Setup: 15,
  Werkzeugwechsel: 25,
  Bohrloch: 1.5,
  Formatieren: 12,
  Fasen: 12,
  Schleifen: 25,
  Nuten: 4,
  KnappBeschlag: 0.4,
  ClamexBeschlag: 1,
  AluZ: 8.1,
  AluFlach: 4,
  Klebeband: 0.8,
  ZeitSTVL: 20,
  ZeitIL: 8,
  BeschlagSK: 27,
  Topfband: 13,
  Klappenband: 5.1,
  SeilzugKlappenHalter: 48,
  ZeitTuer: 50,
  ZeitKlappe: 30,
  CNCZeitTopfband: 45,
  CNCZeitKlappenband: 45,
  ZeitRW: 8,
  ZeitSK: 0,
  WandRegalBoden: 12,
  WandRegalSeite: 6,
  Stahlprofil: 10,
  Kleiderstange: 100 / 3.4,
  AusrichtBeschlag: 28,
  SchiebetuerBeschlag: 95,
  ZeitSchiebetuer: 30,
  SchiebetuerSchiene: 55,
  ZeitSchiebetuerSchiene: 25,
}

export const partNames = {
  top: 'Boden',
  bottom: 'Boden',
  bottomBoard: 'Doppelter Boden',
  board: 'Boden',
  column: 'Aussenseite',
  middleColumn: 'Mittelseite',
  subColumn: 'Zwischenseite',
  backpanel: 'Rückwand',
  door: 'Tür',
  offdoor: 'Schiebetür VL',
  innerdoor: 'Schiebetür IL',
  slidingDoor: 'Schiebetür',
  slidingDoorBase: 'Schiebetür UK',
  slidingDoorFront: 'Schiebetür Front',
  flapFront: 'Klappe',
  tabelar: 'Fachboden',
  drawerInsideFront: 'SK Vorne / Hinten',
  drawerBack: 'SK Vorne / Hinten',
  drawerSide: 'SK Seite',
  drawerBottom: 'SK Boden',
  drawerFront: 'SK Front',
  bedSide: 'Seite',
  footBoard: 'Kopf-/Fussteil',
  headBoard: 'Kopf-/Fussteil',
  middleBoard: 'Mittelstrebe',
}

export {workPrices as w}
