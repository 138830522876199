import {Route, Routes} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {HeightModal, ModalInput} from '../../../../components/material_interfaces/material_interfaces'
import {regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {useStore} from '../../store/store'

export function ShelfStructureModals() {
  const {width, depth} = useStore((store) => ({width: store.config.main.width, depth: store.config.main.depth}), shallow)
  const boards = useStore((store) => store.config.rows)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const setWidth = useStore((store) => store.setWidth)
  const thickness = shelfMaterials[shelfMaterial].thickness
  const setDepth = useStore((store) => store.setDepth)
  const setRowHeight = useStore((store) => store.boards.setHeight)
  const widthList = []
  for (let i = 1; i <= Math.floor(shelfMaterials[shelfMaterial].maxWidth / regalModel.gridSize); i++) {
    widthList.push(Math.round((i * regalModel.gridSize + thickness + 0.001) * 1000) / 1000)
  }

  return (
    <>
      <Routes>
        <Route
          path="width"
          element={
            <ModalInput
              title="Regalbreite"
              val={width * 1000}
              defaultValues={widthList}
              callback={(newVal: number) => setWidth(newVal / 1000)}
              min={regalModel.minWidth * 1000}
              max={shelfMaterials[shelfMaterial].maxWidth * 1000}
            />
          }
        />
        <Route
          path="depth"
          element={
            <ModalInput
              title="Regaltiefe"
              val={depth * 1000}
              defaultValues={regalModel.depthList}
              callback={(newVal: number) => setDepth(newVal / 1000)}
              min={regalModel.minDepth * 1000}
              max={regalModel.maxDepth * 1000}
            />
          }
        />
        <Route
          path="row/:rowNum"
          element={
            <HeightModal
              title="Fachhöhe"
              val={boards.list}
              defaultValues={regalModel.heightList}
              callback={(newVal: number, rowNum: number) => setRowHeight(newVal / 1000, rowNum)}
              min={regalModel.minColHeight * 1000}
              max={regalModel.maxColHeight * 1000}
            />
          }
        />
      </Routes>
    </>
  )
}
