import {produce} from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import {create} from 'zustand'
import {tableMaterials, frameModel, shapeModel} from './datamodels'
import {initialConfig, IEuklidConfig} from './initialConfig'

export type IStore = {
  config: IEuklidConfig
  uri: string
  savedConfig: IEuklidConfig
  setMaterialSpecies: (species: keyof typeof tableMaterials) => void
  setMaterialTreatment: (treatment: number) => void
  setMaterialType: (type: number) => void
  setSize: (size: number) => void
  setHeight: (height: number) => void
  setShapeID: (id: keyof typeof shapeModel) => void
  setFrameID: (id: keyof typeof frameModel) => void
  clearConfig: () => IEuklidConfig
}

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

//@ts-ignore
export const useStore = create<IStore>((set, get) => {
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  const config: IEuklidConfig = initialConfig // set backup config

  const setMaterialSpecies = (nr: keyof typeof tableMaterials) => {
    setProduce((state) => {
      state.config.main.materialID = nr
    })
  }

  const setMaterialType = (type: number) => {
    const fallback = tableMaterials[get().config.main.materialID].fallback as keyof typeof tableMaterials
    const oldType = tableMaterials[get().config.main.materialID].type
    setProduce((state) => {
      if (type !== oldType) {
        state.config.main.materialID = fallback
      }
    })
  }
  const setMaterialTreatment = (treatmentID: number) => {
    setProduce((state) => {
      state.config.main.treatmentID = treatmentID
    })
  }
  const setSize = (newSize: number) => {
    setProduce((state) => {
      state.config.main.size = newSize
      if (newSize < 0.6 && state.config.main.height > 0.55) {
        state.config.main.height = 0.55
      }
    })
  }
  const setHeight = (newHeight: number) => {
    setProduce((state) => {
      state.config.main.height = newHeight
      if (newHeight > 0.55) {
        state.config.main.frameID = 'X'
        state.config.main.size < 0.6 && (state.config.main.size = 0.6)
      }
      if (newHeight > 0.55 && tableMaterials[state.config.main.materialID].thickness === 0.025) {
        state.config.main.materialID = tableMaterials[state.config.main.materialID].altMaterial as keyof typeof tableMaterials
      }
      if (newHeight <= 0.55 && tableMaterials[state.config.main.materialID].thickness === 0.03) {
        state.config.main.materialID = tableMaterials[state.config.main.materialID].altMaterial as keyof typeof tableMaterials
      }
    })
  }
  const setShapeID = (id: keyof typeof shapeModel) => {
    setProduce((state) => {
      state.config.main.shapeID = id
    })
  }
  const setFrameID = (id: keyof typeof frameModel) => {
    setProduce((state) => {
      state.config.main.frameID = id
    })
  }
  const getClearConfig = () => {
    return {...get().config}
  }

  return {
    config,
    uri: '',
    savedConfig: '',
    setMaterialType: setMaterialType,
    setMaterialSpecies: setMaterialSpecies,
    setMaterialTreatment: setMaterialTreatment,
    setSize: setSize,
    setHeight: setHeight,
    setShapeID: setShapeID,
    setFrameID: setFrameID,
    clearConfig: getClearConfig,
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('EuklidStore', useStore)
}
