import {useStore} from '../store/store'
import {footModel, materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {bedModel} from '../store/datamodels'

export type IIkuFootList = {
  key: string
  pos: IVector
  size: IVector
  material: keyof typeof materialModel
  type: keyof typeof footModel
  rotation?: number
}[]

export const ConnectedIkuFootList = (upperBed?: boolean) => {
  const [width, length, feetID, footHeight, frameFlush, slattedId, slattedHeight, slattedInsetstore, frameHeight, stackable, headBoardID] = useStore((store) => [
    store.config.main.width,
    store.config.main.length,
    store.config.feet.id,
    store.config.feet.height,
    store.config.main.frameFlush,
    store.config.slatted.id,
    store.config.slatted.height,
    store.config.slatted.inset,
    store.config.main.frameHeight,
    store.config.main.stackable,
    store.config.main.headBoard.id,
  ])
  return IkuFootList(width, length, feetID, footHeight, frameFlush, slattedId, slattedHeight, slattedInsetstore, frameHeight, stackable, headBoardID, upperBed)
}

export const IkuFootList = (
  width: number,
  length: number,
  feetID: keyof typeof footModel,
  footHeight: number,
  frameFlush: boolean,
  slattedId: string,
  slattedHeight: number,
  slattedInsetstore: number,
  frameHeight: number,
  stackable: boolean,
  headBoardID: number,
  upperBed?: boolean
) => {
  const frameLength = frameFlush ? length : headBoardID > 1 && headBoardID < 4 ? length + bedModel.nominalThickness : length + 2 * bedModel.nominalThickness
  const frameWidth = frameFlush ? width : headBoardID > 1 ? width + bedModel.nominalThickness : width + 2 * bedModel.nominalThickness

  const slattedInset = frameFlush ? slattedHeight + 0.01 : slattedInsetstore
  const footDiameter = upperBed ? 0.01 : 0.015

  const footList: IIkuFootList = []
  width > 1.4 &&
    footList.push({
      key: 'middleFoot',
      pos: [frameLength / 2, frameHeight - slattedInset - 0.001, frameWidth / 2],
      size: [0.015, footHeight + frameHeight - slattedInset - 0.001, 0.015],
      material: 'solidBlack',
      type: 'rnd30',
    })
  if ((stackable || (slattedId === 'integrated' && width <= 1.2)) && feetID === 'bedRnd') {
    footList.push({key: 'foot1.1', pos: [0.24, 0, 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.2', pos: [frameLength - 0.24, 0, 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.3', pos: [0.24, 0, frameWidth - 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.4', pos: [frameLength - 0.24, 0, frameWidth - 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
    if (frameHeight < 0.08 && frameLength > 2.09) {
      footList.push({key: 'middlefoot.1', pos: [frameLength / 2, 0, 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
      footList.push({key: 'middlefoot.2', pos: [frameLength / 2, 0, frameWidth - 0.18], size: [footDiameter, footHeight, footDiameter], material: 'solidBlack', type: 'bedStackableRnd'})
    }
  } else {
    switch (feetID) {
      case 'bedRnd':
        footList.push({key: 'foot1', pos: [0.006, 0.003, 0.006], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedRnd'})
        footList.push({key: 'foot2', pos: [frameLength - 0.006, 0.003, 0.006], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedRnd', rotation: -0.5})
        footList.push({key: 'foot3', pos: [0.006, 0.003, frameWidth - 0.006], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedRnd', rotation: 0.5})
        footList.push({key: 'foot4', pos: [frameLength - 0.006, 0.003, frameWidth - 0.006], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedRnd', rotation: 1})
        break
      case 'bedRct':
        footList.push({key: 'foot1', pos: [0.2 + 0.16, 0.03, 0.025 + 0.003], size: [0.32, footHeight + 0.03, 0.006], material: 'solidBlack', type: 'bedRct'})
        footList.push({key: 'foot2', pos: [frameLength - 0.025 - 0.003, 0.03, 0.035 + 0.16], size: [0.006, footHeight + 0.03, 0.32], material: 'solidBlack', type: 'bedRct'})
        footList.push({key: 'foot3', pos: [frameLength - 0.2 - 0.16, 0.03, frameWidth - 0.025 - 0.003], size: [0.32, footHeight + 0.03, 0.006], material: 'solidBlack', type: 'bedRct'})
        footList.push({key: 'foot4', pos: [0.025 + 0.003, 0.03, frameWidth - 0.035 - 0.16], size: [0.006, footHeight + 0.03, 0.32], material: 'solidBlack', type: 'bedRct'})
        break
    }
  }
  return footList
}
