import {Selector} from 'common/threeComponents/FiberBoard'
import {shelfMaterials} from 'products/metrum/store/datamodels'
import {useStore} from 'products/metrum/store/store'
import {Fragment} from 'react'
import shallow from 'zustand/shallow'

export const ConnectedTabelarSelectors = () => {
  const visible = useStore((store) => store.view.tabelars.edit)
  const [columns, boards, depth, height, grid] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid],
    shallow
  )
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  let startPos = thickness
  const toggleTabelar = useStore((store) => store.tabelar.toggleTabelar)
  const maxCols = grid * (Math.round((height - thickness) / (grid * 0.065)) + 1)
  const maxColGrid = (height - thickness) / maxCols
  if (!visible) return null

  return (
    <>
      {columns.map((el, i) => {
        startPos += el.width + thickness
        return [...Array(maxCols - 3)].map((_, j) => {
          const posIndex = Math.floor((j + 2) / (maxCols / grid))
          const board = (j + 2) % (maxCols / grid) === 0 && boards[i].includes(posIndex)
          return (
            <Fragment key={i + '-' + j}>
              {!board && (
                <Selector key={i + '-' + j} size={[el.width, thickness, depth]} onClick={() => toggleTabelar(i, j + 2)} xPos={startPos - el.width - thickness} yPos={(j + 2) * maxColGrid} zPos={0} />
              )}
            </Fragment>
          )
        })
      })}
    </>
  )
}
