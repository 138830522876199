import {forwardRef, useEffect, useRef} from 'react'
import * as THREE from 'three'
import {ExtrudeGeometry} from 'three'

export interface ISteelBeamProps {
  length: number
  width: number
  thickness: number
  xPos: number
  yPos: number
  zPos: number
}

export const SteelBeamFiber = forwardRef((props: ISteelBeamProps, ref: any) => {
  const {length, width, thickness, xPos, yPos, zPos} = props
  var shape = new THREE.Shape()
  shape.moveTo(-0.5 + thickness / length, -thickness / 2)
  shape.lineTo(0.5 - thickness / length, -thickness / 2)
  shape.lineTo(0.5, thickness / 2)
  shape.lineTo(-0.5, thickness / 2)
  shape.lineTo(-0.5 + thickness / length, -thickness / 2)
  var extrudeSettings = {
    steps: 1,
    depth: width,
    bevelEnabled: false,
  }

  const box = useRef<ExtrudeGeometry>(null!)

  useEffect(() => {
    box.current.translate(0.5, -thickness / 2, -width / 2)
  })

  return (
    <mesh ref={ref} position={[xPos, yPos, zPos]} scale={[length, 1, 1]}>
      <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} ref={box} />
      <meshBasicMaterial attach="material" color={0x000000} />
    </mesh>
  )
})
