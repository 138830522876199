import {Button, DialogContentText, Snackbar} from '@mui/material'
import Grid from '@mui/material/Grid'
import {FileCopyOutlined} from '@mui/icons-material'
import {useCallback, useEffect, useState} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {BaseModal} from './BaseModal'
import {useCommonStore} from 'common/Store/CommonStore'

// saves config and retrieves auto generated uri
export const SaveConfigModal = (props: {reference: string}) => {
  // const saveConfig = useStore(configSelector)
  const saveConfig = useCommonStore(useCallback((store) => store.saveConfig, []))
  const [uri, setUri] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    const isMounted = true
    console.log('Reference is ', props.reference)
    setUri(undefined)
    setError(undefined)
    if (saveConfig) {
      saveConfig(props.reference || 'analog')
        .then((data) => {
          if (isMounted) setUri(data.uri)
        })
        .catch((error) => setError(error))
    }
  }, [saveConfig, props.reference])

  if (uri) return <SuccessModal uri={uri} baseUrl={`/${props.reference}`} />
  if (error) return <ErrorModal error={error} />
  return <LoadingModal />
}

const SuccessModal = (props: {uri: string; baseUrl: string}) => {
  const {uri, baseUrl} = props
  let navigate = useNavigate()
  return (
    <BaseModal title="Speichern Erfolgreich" label="Dialog saving successful" onClose={() => navigate(baseUrl)}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <CopyField>{uri}</CopyField>
        </Grid>
      </Grid>
      <DialogContentText>
        Bitte speichern Sie Ihre ID ab.
        <br />
        Wir können diese nicht wieder herstellen.
      </DialogContentText>
      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Button disableElevation={true} href={`mailto:?body= Ihre Konfiguration finden Sie unter: ${window.location.origin}/get/${uri}`}>
            per Email versenden
          </Button>
        </Grid>
        <Grid item>
          <Button disableElevation={true} to={baseUrl} component={RouterLink}>
            Schließen
          </Button>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

const copyStyles = {
  container: {
    padding: '10px 20px',
    border: '1px solid gray',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  uri: {
    margin: '0 10px 0 0',
  },

  color: 'red',
}

const CopyField = (props: {children: any}) => {
  const {children} = props
  return (
    <div style={copyStyles.container}>
      <span style={copyStyles.uri}>{children}</span>
      <CopyToClipboardButton uri={children} />
    </div>
  )
}

export const LoadingModal = () => (
  <BaseModal title="Konfiguration wird gespeichert" label="Saving in Progress">
    <DialogContentText>...speichern</DialogContentText>
  </BaseModal>
)

const ErrorModal = (error: any) => {
  let navigate = useNavigate()
  return (
    <BaseModal title={`Fehler: ${error.name}`} onClose={() => navigate(-1)} label={''}>
      <DialogContentText>{error.message}</DialogContentText>
    </BaseModal>
  )
}

const CopyToClipboardButton = (uri: any) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(uri.uri)
  }
  return (
    <>
      <FileCopyOutlined onClick={handleClick} />
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="ID wurde in die Zwischenablage kopiert" anchorOrigin={{vertical: 'top', horizontal: 'center'}} />
    </>
  )
}
