import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import shallow from 'zustand/shallow'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, shelfMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedDigital} from '../../threeComponents/Digital'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import {ShelfStructureModals} from '../Shelf/ShelfStructureModals'
import ConnectedPrintView from 'products/digital/print/PrintView'
import {ConnectedDigitalPrice} from 'products/digital/utils/price'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {DigitalPriceList} from 'products/digital/priceList/PriceList'

export const DigitalView = () => {
  document.title = 'SANKTJOHANSER | DIGITAL'
  return (
    <Routes>
      <Route path="/" element={<ConnectedDigitalContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/digital" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="digital" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          <Route path="structure/*" element={<ShelfStructureModals />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedPrintView />} />
      <Route path="priceList" element={<DigitalPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedDigitalContainer = () => {
  // const shelfMaterialId = useStore(store => store.config.main.materialId)
  const [shelfWidth, boards] = useStore((store) => [store.config.main.width, store.config.rows.list], shallow)

  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const footHeight = useStore((store) => store.config.feet.height)

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    materialSelector: 'single',
    height: Math.round(boards.reduce((acc, col) => acc + col.height, thickness) * 1000) / 1000,
    fullHeight: Math.round((boards.reduce((acc, col) => acc + col.height, thickness) + footHeight) * 1000) / 1000,
    footHeight: footHeight,
    depth: useStore((store) => store.config.main.depth),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    width: shelfWidth,
  }

  return (
    <ViewContainer {...props} price={<ConnectedDigitalPrice />} title="Digital">
      <FiberScene footHeight={footHeight}>
        <ConnectedDigital />
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
