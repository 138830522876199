import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {ConnectedEuklid} from '../threeComponents/Euklid'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {ConnectedBoardList} from '../partLists/boardList'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'

export default function ConnectedEuklidPrintView() {
  const partList = [...ConnectedBoardList()]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)
  const viewSize = {minViewWidth: 1.2, minViewHeight: 1.2}

  return (
    <PrintView reference="euklid" baseUrl={baseUrl}>
      <>
        <FiberPrintScene perspective={'front'} {...viewSize}>
          <ConnectedEuklid />
        </FiberPrintScene>
        <Caption>{'Ansicht frontal'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene perspective={'side'} {...viewSize}>
          <ConnectedEuklid />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene perspective={'iso'} {...viewSize}>
          <ConnectedEuklid />
        </FiberPrintScene>
        <Caption>{'Schrägansicht'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}
