import {AccordionListSelector, AccordionMaterialSelector} from '../../../../components/material_interfaces/materialInterfaces'
import {useStore} from 'products/dasregal/store/store'
import {getMaterialName} from 'utils/helpers'
import {frontMaterials, regalModel, shelfMaterials} from 'products/dasregal/store/datamodels'
import {useState} from 'react'

export function Drawers() {
  const drawers = useStore((store) => store.view.drawers)

  const [height, drawerMaterial, grid] = useStore((store) => [store.config.main.height, store.config.drawers.materialID, store.config.main.grid])
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const thickness = shelfMaterials[shelfMaterial].thickness
  const setMaterialID = useStore((store) => store.drawers.setMaterialID)
  const setEditSize = useStore((store) => store.drawers.setVariant)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const gridSize = (height - thickness) / grid - thickness

  const materialOptions = [...Object.values(frontMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof frontMaterials)}
    })

  const drawerHeights: {name: string; callbackValue: number}[] = []

  for (let i = 1; i <= 4; i++) {
    for (let y = 1; y <= 3; y++) {
      const drawerHeight = (y / i) * (gridSize - regalModel.shadowJoint) - regalModel.shadowJoint
      !drawerHeights.some((e) => e.callbackValue === y / i) &&
        drawerHeight < regalModel.maxDrawerHeight &&
        drawerHeight > regalModel.minDrawerHeight &&
        drawerHeights.push({name: Math.round(drawerHeight * 1000) + ' / ' + Math.round((drawerHeight - 0.037) * 1000) + ' mm', callbackValue: y / i})
    }
  }
  drawerHeights.sort((a, b) => b.callbackValue - a.callbackValue)

  const drawerHeightOptions = drawerHeights.map((e, i) => ({id: i, name: e.name, callbackValue: e.callbackValue}))
  const selected = drawerHeightOptions.findIndex((e) => e.callbackValue === drawers.variant)

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material Front'} value={drawerMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector
        options={drawerHeightOptions}
        onClick={(id: number) => setEditSize(drawerHeightOptions.find((option) => option.id === id)?.callbackValue as number)}
        title={'Höhe Front / Nutzhöhe'}
        value={selected}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
