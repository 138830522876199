import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider} from 'components/material_interfaces/materialInterfaces'
import {tableModel, tableMaterials, frameModel, shapeModel} from 'products/euklid/store/datamodels'
import {useStore} from 'products/euklid/store/store'
import {materialTreatments, materialTypes} from 'utils/datamodels'

export function TableStructure() {
  const [tableMaterialID, treatmentID, size, height, frameID, shapeID] = useStore((store) => [
    store.config.main.materialID,
    store.config.main.treatmentID,
    store.config.main.size,
    store.config.main.height,
    store.config.main.frameID,
    store.config.main.shapeID,
  ])

  const setMaterialType = useStore((store) => store.setMaterialType)
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setSize = useStore((store) => store.setSize)
  const setHeight = useStore((store) => store.setHeight)
  const setFrameID = useStore((store) => store.setFrameID)
  const setShapeID = useStore((store) => store.setShapeID)
  const materialHasTreatment = tableMaterials[tableMaterialID].treatment

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const regalMaterialType = tableMaterials[useStore((store) => store.config.main.materialID)].type
  const materialTypeList = Object.values(tableMaterials).map((material) => material.type)
  const typeList = materialTypes.filter((type) => materialTypeList.includes(type.id))

  const materialOptions = [...Object.values(tableMaterials)].filter((material) => material.type === regalMaterialType && material.thickness === tableMaterials[tableMaterialID].thickness)
  const treatmentOptions = [...Object.values(materialTreatments)]

  const typeOptions = typeList.map((type) => {
    const thickness =
      tableMaterials[tableMaterialID].type === type.id ? tableMaterials[tableMaterialID].thickness : tableMaterials[tableMaterials[tableMaterialID].fallback as keyof typeof tableMaterials].thickness
    return {...type, name: type.name + ', ' + Math.round(thickness * 1000) + ' mm'}
  })

  const shapeOptions = Object.values(shapeModel).map((shape) => ({...shape, name: shape.name}))
  const frameOptions = Object.values(frameModel)
    .map((frame) => ({...frame, name: frame.short}))
    .filter((frame) => frame.maxHeight >= height)

  return (
    <>
      <AccordionListSelector id="type" options={typeOptions} onClick={setMaterialType} title={'Werkstoff'} value={regalMaterialType} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        id="species"
        options={materialOptions}
        onClick={setShelfMaterialSpecies}
        title={'Holzart / Farbe'}
        value={tableMaterialID}
        expanded={expanded}
        handleChange={handleChange}
      />
      {materialHasTreatment && (
        <AccordionListSelector id="treatment" options={treatmentOptions} onClick={setMaterialTreatment} title={'Oberfläche'} value={treatmentID} expanded={expanded} handleChange={handleChange} />
      )}
      <AccordionSizeSlider
        options={[0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.1, 1.2]}
        min={tableModel.minSize * 1000}
        max={tableModel.maxSize * 1000}
        onClick={setSize}
        title={'Tischgröße'}
        id={'size'}
        value={size * 1000}
        clickRange={100}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.33, 0.4, 0.47, 0.72, 1.15]}
        min={0.25 * 1000}
        max={1.15 * 1000}
        onClick={setHeight}
        title={'Tischhöhe'}
        id={'height'}
        value={height * 1000}
        clickRange={70}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionListSelector id="shape" options={shapeOptions} onClick={setShapeID} title={'Form'} value={shapeID} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector id="frameType" options={frameOptions} onClick={setFrameID} title={'Gestelltyp'} value={frameID} expanded={expanded} handleChange={handleChange} />
    </>
  )
}
