import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/dasregal/store/store'
import {Fragment, useRef} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from './../../store/datamodels'

interface IFlapSelectors {
  material: THREE.Material
  thickness: number
  zPos: number
  flapList: {pos: {x: number; y: number}; size: {x: number; y: number}; xPos: number; yPos: number; height: number; width: number}[]
  callback: (pos: {x: number; y: number}, size: {x: number; y: number}) => void
}

export const FlapSelectors = (props: IFlapSelectors) => {
  return (
    <>
      {props.flapList.map((el, i) => {
        return <Selector size={[el.height, props.thickness, el.width]} xPos={el.xPos} yPos={el.yPos} zPos={props.zPos} rotation={[0.5, 0.5, 0]} onClick={() => props.callback(el.pos, el.size)} />
      })}
    </>
  )
}

export const ConnectedFlapSelectors = () => {
  const visible = useStore((store) => store.view.flaps.edit)
  const [columns, height, grid, depth] = useStore((store) => [store.config.columns, store.config.main.height, store.config.main.grid, store.config.main.depth], shallow)
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}))
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (height - thickness) / grid
  const flapSize = useStore((store) => store.view.flaps.size)
  const flapHeight = flapSize.y * gridSize - thickness
  let startPos = thickness
  const setFlap = useStore((store) => store.flaps.setFlap)
  let list = [] as IFlapSelectors['flapList']

  return (
    <>
      {columns.list.map((el, i) => {
        if (i <= columns.list.length - flapSize.x) {
          let width = el.width
          for (let j = 1; j < flapSize.x; j++) {
            width += columns.list[i + j].width + thickness
          }
          for (let x = 0; x <= grid - flapSize.y; x++) {
            const yPos = thickness + x * gridSize
            if (width <= regalModel.maxFlapWidth) list.push({pos: {x: i, y: x}, size: {x: flapSize.x, y: flapSize.y}, xPos: startPos, yPos: yPos, height: flapHeight, width: width})
          }
        }
        startPos += el.width + thickness
        return (
          <Fragment key={i}>
            {visible && <FlapSelectors key={i} flapList={list} material={selectorMaterial.current} zPos={depth - thickness + 0.01} thickness={thickness} callback={setFlap} />}
          </Fragment>
        )
      })}
    </>
  )
}
