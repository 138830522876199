export const initialView = {
  backpanels: {
    variant: 0,
    edit: false,
  },
  boards: {
    edit: false,
  },
  columns: {
    edit: false,
  },
  drawers: {
    variant: 1,
    visible: true,
    edit: false,
  },
  flaps: {
    edit: false,
    visible: true,
  },
  feet: {},
}

export type IView = typeof initialView
