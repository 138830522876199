import shallow from 'zustand/shallow'
import {ConnectedDasregalPrice} from 'products/dasregal/utils/price'
import {useStore} from '../store/store'
import {localFactors, materialTreatments} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {shelfMaterials, footModel} from '../store/datamodels'
import {ConnectedDasregalBoardList} from '../partLists/boardList'
import {ConnectedDasregalColumnList} from '../partLists/columnList'
import {ConnectedDasregalSubColumnList} from '../partLists/subColumnList'
import {ConnectedDasregalTabelarList} from '../partLists/tabelarList'
import {ConnectedDasregalBackpanelList} from '../partLists/backpanelList'
import {ConnectedDasregalDoorList} from '../partLists/doorList'
import {ConnectedDasregalDrawerList} from '../partLists/drawerList'
import {ConnectedDasregalFlapList} from '../partLists/flapList'
import {getMaterialName} from 'utils/helpers'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [shelfMaterialID, shelfTreatmentID, columns, feetHeight, uri, feet] = useStore(
    (state) => [state.config.main.materialID, state.config.main.treatmentID, state.config.columns.list, state.config.feet.height, state.uri, state.config.feet],
    shallow
  )
  const shelfMaterial = shelfMaterials[shelfMaterialID]
  const shelfMaterialName = getMaterialName(shelfMaterialID)
  const shelfTreatment = materialTreatments[shelfTreatmentID].name
  const width = Math.round(columns.reduce((acc, col) => acc + col.width + shelfMaterial.thickness, shelfMaterial.thickness) * 1000)
  const depth = Math.round(useStore((state) => state.config.main.depth) * 1000)
  const wallMounted = feet.id === 'wallMounted'
  const footHeight = !wallMounted ? feetHeight : 0
  const fullHeight = Math.round((useStore((state) => state.config.main.height) + footHeight) * 1000)
  const footRange = footModel[feet.id].range || [0, 0]
  const fussWand = !wallMounted
    ? footModel[feet.id].name + ' ' + feet.height * 1000 + ' mm (' + Math.round((feet.height + footRange[0]) * 1000) + '-' + Math.round((feet.height + footRange[1]) * 1000) + ' mm)'
    : 'wandmontiert'

  const partList = () => {
    const drawerPartList = ConnectedDasregalDrawerList()
      .map((drawer) => Object.values(drawer.parts).map((el) => el))
      .flat() as any[]
    const flapPartList = ConnectedDasregalFlapList()
      .map((flap) => Object.values(flap.parts).map((el) => el))
      .flat() as any[]
    const partList = [
      ...ConnectedDasregalBoardList(),
      ...ConnectedDasregalColumnList(),
      ...ConnectedDasregalSubColumnList(),
      ...ConnectedDasregalTabelarList(),
      ...ConnectedDasregalBackpanelList(),
      ...ConnectedDasregalDoorList(),
      ...drawerPartList,
      ...flapPartList,
    ]
    return partList
  }

  let nrColumns = 0
  let nrBoards = 0
  let nrSubColumns = 0
  let nrTabelars = 0
  let nrBackpanels = 0
  let nrDoors = 0
  let nrDrawers = 0
  let nrFlaps = 0

  partList().forEach((part) => {
    if (part.type === 'column') nrColumns++
    if (part.type === 'middleColumn') nrColumns++
    if (part.type === 'board') nrBoards++
    if (part.type === 'bottom') nrBoards++
    if (part.type === 'top') nrBoards++
    if (part.type === 'subColumn') nrSubColumns++
    if (part.type === 'tabelar') nrTabelars++
    if (part.type === 'backpanel') nrBackpanels++
    if (part.type === 'door') nrDoors++
    if (part.type === 'drawerFront') nrDrawers++
    if (part.type === 'flapFront') nrFlaps++
  })

  return (
    <div className="description">
      <div className="print_title">Dasregal</div>
      <div className="sub_title">
        <a href={'/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {shelfTreatment}
          <div className="sub_title">Abmessungen</div>
          {width} mm x {depth} mm x {fullHeight} mm
          <div className="sub_title">lichte Fachbreiten</div>
          {columns.map((column, i) => {
            const width = column.width * 1000
            if (i + 1 < columns.length) {
              return <span key={i}>{Math.round(width)} mm, </span>
            } else {
              return <span key={i}>{Math.round(width)} mm</span>
            }
          })}
          <div className="sub_title">Fuss / Wandmontage</div>
          {fussWand}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div> {nrColumns} Seiten</div>
          <div> {nrBoards} Regalböden</div>
          <div> {nrSubColumns} Zwischenseiten</div>
          <div> {nrTabelars} Fachböden verstellbar</div>
          <div> {nrBackpanels} Rückwände, </div>
          <div className="sub_title">Ausstattung</div>
          <div> {nrDoors} Türen, </div>
          <div> {nrDrawers} Schubladen, Front </div>
          <div> {nrFlaps} Klappen </div>
          <div className="price_title">Preis </div>
          <div className="price">
            <ConnectedDasregalPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}
