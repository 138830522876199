import {Outlet} from 'react-router-dom'
import {useStore} from '../../../store/store'
import {AccordionElement, ParentButton, MenuBox} from 'components/material_interfaces/materialInterfaces'
import {ShelfStructure} from '../../Shelf/ShelfStructure'
import {Drawers} from '../../Drawers/Drawers'
import {Flaps} from '../../Flaps/Flaps'
import {Backpanels} from '../../Backpanels/Backpanels'
import {FeetHeight} from '../../Feet/FeetHeight'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect} from 'react'
import {Contact} from 'components/contact/Contact'

export const IndexView = () => {
  const boards = useStore((store) => store.config.rows.list)
  const height = boards.reduce((acc, board) => acc + board.height, 0)
  const extended = useCommonStore((state) => state.extended)

  const [expanded, setExpanded] = useCommonStore((state) => [state.expandedFirst, state.setExpandedFirst])

  const setDrawerVisibility = useStore((store) => store.drawers.setVisible)
  const setFlapVisibility = useStore((store) => store.flaps.setVisible)
  const setEditBackpanels = useStore((store) => store.backpanels.setEdit)
  const setEditDrawers = useStore((store) => store.drawers.setEdit)
  const setEditFlaps = useStore((store) => store.flaps.setEdit)
  const setEditStructure = useStore((store) => store.boards.setEdit)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    switch (expanded) {
      case 'structure':
        setDrawerVisibility(false)
        setFlapVisibility(false)
        setEditBackpanels(false)
        setEditDrawers(false)
        setEditFlaps(false)
        setEditStructure(expanded === 'structure')
        break
      case 'flaps':
        setDrawerVisibility(true)
        setFlapVisibility(true)
        setEditBackpanels(false)
        setEditDrawers(false)
        setEditFlaps(expanded === 'flaps')
        setEditStructure(false)
        break
      case 'drawers':
        setDrawerVisibility(true)
        setFlapVisibility(true)
        setEditBackpanels(false)
        setEditDrawers(expanded === 'drawers')
        setEditFlaps(false)
        setEditStructure(false)
        break
      case 'back':
        setDrawerVisibility(!expanded)
        setFlapVisibility(!expanded)
        setEditBackpanels(expanded === 'back')
        setEditDrawers(false)
        setEditFlaps(false)
        setEditStructure(false)
        break
      case 'feet':
      default:
        setDrawerVisibility(true)
        setFlapVisibility(true)
        setEditBackpanels(false)
        setEditDrawers(false)
        setEditFlaps(false)
        setEditStructure(false)
        break
    }
    return () => {
      setDrawerVisibility(true)
      setFlapVisibility(true)
      setEditBackpanels(false)
      setEditDrawers(false)
      setEditFlaps(false)
      setEditStructure(false)
    }
  }, [expanded])

  return (
    <>
      <MenuBox>
        <AccordionElement id="structure" title="Regalkorpus" expanded={expanded === 'structure'} handleChange={handleChange}>
          <ShelfStructure />
        </AccordionElement>
        <AccordionElement id="drawers" title="Schubladen" expanded={expanded === 'drawers'} handleChange={handleChange}>
          <Drawers />
        </AccordionElement>
        <AccordionElement id="flaps" title="Klappen" expanded={expanded === 'flaps'} handleChange={handleChange}>
          <Flaps />
        </AccordionElement>
        <AccordionElement id="back" title="Rückwände" expanded={expanded === 'back'} handleChange={handleChange}>
          <Backpanels />
        </AccordionElement>
        {height < 0.8 && (
          <AccordionElement id="feet" title="Stellfüße" expanded={expanded === 'feet'} handleChange={handleChange}>
            <FeetHeight />
          </AccordionElement>
        )}
        <ParentButton to="print">Drucken</ParentButton>
        {extended && <ParentButton to="priceList">Preisliste</ParentButton>}
        <ParentButton to="saveload">Speichern & Laden</ParentButton>
        <AccordionElement id="contact" title="Sonderanfertigung / Frage" expanded={expanded === 'contact'} handleChange={handleChange}>
          <Contact />
        </AccordionElement>
      </MenuBox>
      <Outlet />
    </>
  )
}
