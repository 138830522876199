import {useStore} from 'products/dinadrei/store/store'
import {materialModel} from 'utils/datamodels'

export const ConnectedDinadreiAccBoardList = () => {
  const frameHeight = useStore((store) => store.config.main.frameHeight)
  const length = useStore((store) => store.config.main.length)
  const width = useStore((store) => store.config.main.width)
  const slattedInsetstore = useStore((store) => store.config.slatted.inset)
  const slattedID = useStore((store) => store.config.slatted.id)
  const sideBoardWidth = useStore((store) => store.config.main.sideBoardWidth)
  return DinadreiAccBoardList(frameHeight, length, width, slattedInsetstore, slattedID, sideBoardWidth)
}

export const DinadreiAccBoardList = (frameHeight: number, length: number, width: number, slattedInsetstore: any, slattedID: string, sideBoardWidth: number) => {
  const slattedIntegrated = slattedID === 'integrated'
  const frameLength = length + 0.05
  const frameWidth = width + 0.05
  const usableWidth = width
  const nrOfSlats = usableWidth > (slattedIntegrated ? 1.2 : 1.4) ? 2 : 1
  const slattedWidth = Math.floor((usableWidth / nrOfSlats) * 10) / 10
  const usableLength = length
  const slattedInset = slattedInsetstore
  const middleBoardInBetween = (nrOfSlats === 2 && usableWidth - 2 * slattedWidth > 0.04) || (slattedIntegrated && nrOfSlats === 2)
  const additionalSideBoards = (!slattedIntegrated && usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0) > 0.04) || (slattedIntegrated && nrOfSlats === 2)
  const widthOfSideBoards = slattedIntegrated ? 0.025 : Math.min(Math.floor(((usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0)) / 2) * 100) / 100, 0.04)
  const yPos = slattedIntegrated ? frameHeight - slattedInset : frameHeight - 0.01

  const accboards: {
    key: number | string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    rotation?: [number, number, number]
    translate: [number, number, number]
    material: keyof typeof materialModel
    type: string
  }[] = []
  nrOfSlats === 2 &&
    !middleBoardInBetween &&
    !slattedIntegrated &&
    accboards.push({
      key: 0,
      xPos: frameLength / 2,
      yPos: frameHeight - slattedInset - 0.018,
      zPos: frameWidth / 2 + sideBoardWidth - 0.025,
      length: length - 0.02,
      depth: 0.08,
      thickness: 0.018,
      translate: [0, 0.5, 0],
      material: 'm18Mpx',
      type: 'middleBoard',
    })
  nrOfSlats === 2 &&
    middleBoardInBetween &&
    accboards.push({
      key: 1,
      xPos: frameLength / 2,
      yPos: yPos,
      zPos: frameWidth / 2,
      length: usableLength,
      depth: 0.04,
      thickness: 0.055,
      translate: [0, -0.5, 0],
      material: 's25Bi',
      type: 'middleBoard',
    })
  additionalSideBoards &&
    accboards.push({
      key: 2,
      xPos: frameLength / 2,
      yPos: yPos,
      zPos: 0.025,
      length: frameLength - 0.05 - 0.2,
      depth: widthOfSideBoards,
      thickness: 0.055,
      translate: [0, -0.5, 0.5],
      material: 's25Bi',
      type: 'sideBoard',
    })
  additionalSideBoards &&
    accboards.push({
      key: 3,
      xPos: frameLength / 2,
      yPos: yPos,
      zPos: frameWidth - 0.025,
      length: frameLength - 0.05 - 0.2,
      depth: widthOfSideBoards,
      thickness: 0.055,
      translate: [0, -0.5, -0.5],
      material: 's25Bi',
      type: 'sideBoard',
    })
  return accboards
}
