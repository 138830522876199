import {materialModel} from 'utils/datamodels'
import {backpanelMaterials, drawerFrontMaterials, shelfMaterials, flapMaterials, footModel} from './datamodels'

const initialRows = [
  {
    height: 0.374,
  },
  {
    height: 0.284,
  },
]

export const initialConfig = {
  main: {
    grid: 12,
    width: 2.395,
    materialID: 'h6TWBC' as keyof typeof shelfMaterials,
    depth: 0.41,
  },
  columns: {
    list: [
      [3, 5, 8],
      [2, 4, 6, 9],
      [3, 5, 8],
    ],
  },
  backpanels: {
    list: [
      {pos: {x: 0, y: 0}, cover: 'full' as 'full' | 'compartment', face: 'back' as 'front' | 'back'},
      {pos: {x: 6, y: 1}, cover: 'compartment' as 'full' | 'compartment', face: 'back' as 'front' | 'back'},
    ],
    materialID: 'm18MW' as keyof typeof backpanelMaterials,
  },
  rows: {
    list: initialRows,
  },
  drawers: {
    materialID: 'h6TWBC' as keyof typeof drawerFrontMaterials,
    list: [
      {
        pos: {x: 6, y: 1.5},
        size: {y: 0.5},
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 6, y: 1},
        size: {y: 0.5},
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  flaps: {
    materialID: 'h10TWBC' as keyof typeof flapMaterials,
    list: [
      {
        pos: {x: 0, y: 0},
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  feet: {
    height: 0.043,
    id: 'u308' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
}

export type IDigitalConfig = typeof initialConfig
