import * as THREE from 'three'
import {createBaseTexture} from './Materials'

export function createBaseMaterial6(props: {
  top: THREE.MeshLambertMaterialParameters
  front: THREE.MeshLambertMaterialParameters
  left?: THREE.MeshLambertMaterialParameters
  right?: THREE.MeshLambertMaterialParameters
  back?: THREE.MeshLambertMaterialParameters
  bottom?: THREE.MeshLambertMaterialParameters
  name?: string
  side?: {color: number}
}) {
  const {top, front, left = front, right = left, back = front, bottom = top, side = {color: 0xffffff}} = props
  const material: THREE.MeshLambertMaterial[] = []
  material.push(new THREE.MeshLambertMaterial({...right, name: 'right', color: side.color}))
  material.push(new THREE.MeshLambertMaterial({...left, name: 'left', color: side.color}))
  material.push(new THREE.MeshLambertMaterial({...top, name: 'top'}))
  material.push(new THREE.MeshLambertMaterial({...bottom, name: 'bottom'}))
  material.push(new THREE.MeshLambertMaterial({...front, name: 'front'}))
  material.push(new THREE.MeshLambertMaterial({...back, name: 'back'}))
  return material
}

export function solidWood(material: any, name = '', faceRepeat = {x: 0.9, y: 0.6}, offset = {x: 0.0, y: 0.0}, edgeRepeat = {x: 0.15, y: 1}) {
  const shortEdgeTex = createBaseTexture(material, edgeRepeat, offset, 0.5)
  const faceTex = createBaseTexture(material, faceRepeat, offset)
  const edgeTex = createBaseTexture(material, {x: 1, y: 0.25})
  const shortEdge = {map: shortEdgeTex}
  const face = {map: faceTex}
  const edge = {map: edgeTex, color: 0xe5e5e5}
  const side = {color: 0xd5d5d5}
  return createBaseMaterial6({top: face, front: edge, left: shortEdge, side: side, name: name})
}
