import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {ViewContainer} from '../../../../../components/ViewContainer/ViewContainer'
import {baseUrl, shelfMaterialModel} from '../../../store/datamodels'
import {localFactors} from '../../../../../utils/datamodels'
import {calcShelfHeight} from '../../../../../utils/helpers'
import {useStore} from '../../../store/store'
import {ConnectedAnalog} from '../../../three_components/Analog'
import {PerspectiveControls} from './perspective_controls/PerspectiveControls'
import {ConnectedAnalogPrice} from 'products/analog/utils/price'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'

export const ConnectedAnalogContainer = () => {
  const localFactorID = useCommonStore((store) => store.localFactorID)

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: useStore((store) => store.config.main.width),
    boards: useStore((store) => store.config.rows.list),
    depth: useStore((store) => store.config.main.depth),
    localFactor: localFactors[localFactorID],
    baseUrl: baseUrl,
  }

  // hacky way to check if footHeight is zero -> TODO: add 0 as first value in feet array
  const thickness = shelfMaterialModel[props.shelfMaterialId].thickness
  const wallMounted = useStore((store) => store.config.feet.id) === 'wallMounted'
  const footHeight = useStore((store) => store.config.feet.height)
  const fullHeight = calcShelfHeight(props.boards, thickness, !wallMounted ? footHeight : 0)

  return (
    <ViewContainer {...props} price={<ConnectedAnalogPrice />} title="Analog" footHeight={footHeight} fullHeight={fullHeight}>
      <FiberScene footHeight={footHeight}>
        <ConnectedAnalog />
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
