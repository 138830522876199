import {ISetProduce, IStore} from '../store'
import {drawerFrontMaterials} from '../datamodels'
import {GetState} from 'zustand'
import {accHelper} from 'utils/helpers'

export interface IDrawerSlice {
  drawers: {
    setDrawer: (pos: {x: number; y: number}, size: {y: number}) => void
    setEdit: (edit: boolean) => void
    setMaterialID: (newMaterial: keyof typeof drawerFrontMaterials) => void
    setVariant: (variant: number) => void
    setVisible: (show: boolean) => void
    clearDrawers: () => {pos: {x: number; y: number}; size: {y: number}; face: 'front' | 'back'}[]
  }
}

export const createDrawerSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const drawers = {
    setDrawer: (pos: {x: number; y: number}, size: {y: number}) => {
      setProduce((store) => {
        const clearedDrawerArray = store.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, {x: 1, y: size.y}, {x: 1, y: drawer.size.y})) return false
          return true
        })
        if (!store.config.drawers.list.find((drawer) => accHelper.same_is_at_position(pos, drawer.pos, {x: 1, y: size.y}, {x: 1, y: drawer.size.y}))) {
          clearedDrawerArray.push({pos, size, face: 'front'})
        }
        const newDrawers = drawers._clearDrawers(clearedDrawerArray)
        store.config.drawers.list = newDrawers
        for (let i = 0; i < store.config.flaps.list.length; i++) {
          const flapIndex = store.config.flaps.list.findIndex((flap) => flap.pos.x === pos.x && flap.pos.y === Math.floor(pos.y))
          flapIndex !== -1 && store.config.flaps.list.splice(flapIndex, 1)
        }
      })
    },
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.drawers.edit = edit
      }),
    setMaterialID: (materialID: keyof typeof drawerFrontMaterials) =>
      setProduce((store) => {
        store.config.drawers.materialID = materialID
      }),
    setVariant: (variant: number) =>
      setProduce((store) => {
        store.view.drawers.variant = variant
      }),
    setVisible: (visible: boolean) =>
      setProduce((store) => {
        store.view.drawers.visible = visible
      }),
    _clearDrawers(drawerList: {pos: {x: number; y: number}; size: {y: number}; face: 'front' | 'back'}[]) {
      let newDrawers = drawerList.filter((drawer) => {
        if (drawer.pos.x < get().config.main.grid) {
          let returnVal = false
          const isBoardAbove = Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.01
          for (let i = 0; drawerList.length > i; i++) {
            if (accHelper.is_acc_above({...drawer, size: {x: 1, y: drawer.size.y}}, {...drawerList[i], size: {x: 1, y: drawerList[i].size.y}}) || isBoardAbove) {
              returnVal = true
              break
            }
          }
          return returnVal
        } else {
          return true
        }
      })
      for (let i = 0; newDrawers.length > i; i++) {
        // eslint-disable-next-line no-loop-func
        newDrawers = newDrawers.filter((drawer) => {
          if (drawer.pos.x < get().config.main.grid) {
            let returnVal = false
            const isBoardAbove = Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.01
            for (let j = 0; newDrawers.length > j; j++) {
              if (accHelper.is_acc_above({...drawer, size: {x: 1, y: drawer.size.y}}, {...newDrawers[j], size: {x: 1, y: newDrawers[j].size.y}}) || isBoardAbove) {
                returnVal = true
                break
              }
            }
            return returnVal
          } else {
            return true
          }
        })
      }
      return newDrawers
    },
    clearDrawers: () => {
      const clearedDrawers = drawers
        ._clearDrawers(get().config.drawers.list)
        .filter(
          (drawer) =>
            drawer.pos.y < get().config.rows.list.length && drawer.pos.x < get().config.main.grid && (get().config.columns.list[Math.floor(drawer.pos.y)].includes(drawer.pos.x) || drawer.pos.x === 0)
        )
      clearedDrawers.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
      return clearedDrawers
    },
  }
  return {
    drawers,
  }
}
