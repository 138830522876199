import {Board} from 'common/threeComponents/FiberBoard'
import {ConnectedDigitalBackpanelList} from 'products/digital/partLists/backpanelList'
import {backpanelMaterials} from '../../store/datamodels'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'

export const ConnectedBackpanels = () => {
  const backpanelList = ConnectedDigitalBackpanelList()
  if (backpanelList.length > 0) {
    const materialID = backpanelList[0].material
    const materialReference = backpanelMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  }
  return (
    <>
      {backpanelList.map((backpanel) => {
        return <Board {...backpanel} size={[backpanel.length, backpanel.thickness, backpanel.depth]} material={material[Math.round(Math.random())]} rotation={[0, 0.5, 0.5]} />
      })}
    </>
  )
}
