import {GetState} from 'zustand'
import {IRow, IRowActions, IStore} from '../..'
import {clearColumns} from 'products/analog/utils/helpers'

export const createRowSlice = (setProduce: any, get: GetState<IStore>) => {
  const rows: IRowActions = {
    setHeight: (newHeight, rowNum) =>
      setProduce((S: IStore) => {
        S.config.rows.list[rowNum].height = newHeight
      }),
    toggleBoard: (yPos, xPos) =>
      setProduce((store: IStore) => {
        store.config.columns.list[yPos].includes(xPos) ? store.config.columns.list[yPos].splice(store.config.columns.list[yPos].indexOf(xPos), 1) : store.config.columns.list[yPos].push(xPos)
      }),
    clearColumns: () => {
      const clearedColumns = clearColumns(get().config.columns.list, get().config.rows.list, get().config.main.grid)
      return clearedColumns
    },
    create: (newRowHeight) =>
      setProduce((S: IStore) => {
        const newRow = {height: newRowHeight, inset: 'front'} as IRow
        const nrBoards = S.config.rows.list.length
        const newColumns = []
        if (S.config.columns.list.length <= nrBoards) {
          for (let i = 1; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && newColumns.push(i)
          }
          S.config.columns.list.push(newColumns)
        }
        if (Math.max(...S.config.columns.list[nrBoards]) + 2 < S.config.main.grid) {
          for (let i = Math.max(...S.config.columns.list[nrBoards], 0) + 2; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && S.config.columns.list[nrBoards].push(i)
          }
        }
        S.config.rows.list.push(newRow)
      }),
    delete: () =>
      setProduce((S: IStore) => {
        S.config.rows.list.pop() // #TODO: could also delete Doors etc. here
      }),
  }
  return {
    rows,
  }
}
