import {PerspectiveControlButton, PerspectiveControlContainer} from 'common/perspectiveControlContainer/PerspectiveControlContainer'
import {useCommonStore} from 'common/Store/CommonStore'
import shallow from 'zustand/shallow'
import FrontIcn from './viewIcn-front@2x.png'
import IsoIcn from './viewIcn-iso@2x.png'
import SideIcn from './viewIcn-side@2x.png'

export const PerspectiveControls = () => {
  const [setPerspective] = useCommonStore((store) => [store.setPerspective], shallow)

  return (
    <PerspectiveControlContainer>
      <PerspectiveControlButton id="front-btn" altText="Icon of shelf in front view" onClick={() => setPerspective('front')} iconSrc={FrontIcn}>
        Frontansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton id="front-btn" altText="Icon of shelf in front view" onClick={() => setPerspective('iso')} iconSrc={IsoIcn}>
        Schrägansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton id="front-btn" altText="Icon of shelf in front view" onClick={() => setPerspective('side')} iconSrc={SideIcn}>
        Seitenansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton id="front-btn" altText="Icon of shelf in front view" onClick={() => setPerspective('back')} iconSrc={FrontIcn}>
        Rückansicht
      </PerspectiveControlButton>
    </PerspectiveControlContainer>
  )
}
