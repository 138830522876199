import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterialModel} from 'products/analog/store/datamodels'
import {ConnectedAnalogTabelarList} from 'products/analog/partLists/tabelarList'

export const ConnectedTabelars = () => {
  const tabelarList = ConnectedAnalogTabelarList()
  if (tabelarList.length === 0) return null
  const materialID = tabelarList[0].material
  const materialReference = shelfMaterialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.small_2 || materialObject[materialReference]
  return (
    <>
      {tabelarList.map((board) => {
        return <Board {...board} size={[board.length, board.thickness, board.depth]} material={material} />
      })}
    </>
  )
}
