import {Route, Routes} from 'react-router-dom'
import {AnalogSlider, BezweiSlider, DasregalSlider, DigitalSlider, DinadreiSlider, EuklidSlider, IkuSlider, KubaturSlider, MetrumSlider, Slider, TekturSlider, UnisonoSlider} from './slider/Slider'

export function SliderView() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Slider />} />
        <Route path="/analog" element={<AnalogSlider />} />
        <Route path="/kubatur" element={<KubaturSlider />} />
        <Route path="/dasregal" element={<DasregalSlider />} />
        <Route path="/tektur" element={<TekturSlider />} />
        <Route path="/metrum" element={<MetrumSlider />} />
        <Route path="/digital" element={<DigitalSlider />} />
        <Route path="/dinadrei" element={<DinadreiSlider />} />
        <Route path="/bezwei" element={<BezweiSlider />} />
        <Route path="/iku" element={<IkuSlider />} />
        <Route path="/unisono" element={<UnisonoSlider />} />
        <Route path="/euklid" element={<EuklidSlider />} />
      </Routes>
    </>
  )
}
