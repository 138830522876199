import {useStore} from 'products/kubatur/store/store'
import {regalModel, shelfMaterials, slidingDoorMaterials} from 'products/kubatur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {IKubaturConfig} from '../store/initialConfig'

type Front = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof slidingDoorMaterials
}

type Base = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof shelfMaterials
}

type SlidingDoorParts = {
  front: Front
  base: Base
}

export const ConnectedKubaturSlidingDoorList = () => {
  const [shelfTyp, slidingDoors, depth, shelfHeight, columns, shelfMaterial] = useStore(
    useShallow((store) => [store.config.main.shelfTyp, store.config.slidingDoors, store.config.main.depth, store.config.main.height, store.config.columns.list, store.config.main.materialID])
  )
  return KubaturSlidingDoorList(shelfTyp, slidingDoors, depth, shelfHeight, columns, shelfMaterial)
}

export const KubaturSlidingDoorList = (
  shelfTyp: IKubaturConfig['main']['shelfTyp'],
  slidingDoors: IKubaturConfig['slidingDoors'],
  depth: IKubaturConfig['main']['depth'],
  shelfHeight: IKubaturConfig['main']['height'],
  columns: IKubaturConfig['columns']['list'],
  shelfMaterial: IKubaturConfig['main']['materialID']
) => {
  if (shelfTyp !== 1) return []
  const shelfThickness = shelfMaterials[shelfMaterial].thickness
  const doorThickness = slidingDoorMaterials[slidingDoors.materialID].thickness
  const totalWidth = columns.reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness)
  const doors: {
    key: number
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    move: number
    parts: SlidingDoorParts
    material: keyof typeof slidingDoorMaterials
    type: string
  }[] = []
  ;[...Array(slidingDoors.nr)].forEach((_, i) => {
    const width = (totalWidth + (slidingDoors.nr - 1) * shelfThickness) / slidingDoors.nr
    const xPos = i * (width - shelfThickness)
    const yPos = 0
    const zPos =
      (i === 0 && slidingDoors.inFront === 'left') || (i === slidingDoors.nr - 1 && slidingDoors.inFront === 'right') || (i !== slidingDoors.nr - 1 && i !== 0 && slidingDoors.inFront === 'middle')
        ? depth + 2 * (regalModel.slidingDoorDistance + shelfThickness + doorThickness)
        : depth + regalModel.slidingDoorDistance + shelfThickness + doorThickness
    const slidingDoorParts = getSlidingDoorParts({
      width: width,
      height: shelfHeight,
      frontMaterialID: slidingDoors.materialID,
      baseMaterialID: shelfMaterial,
    }) as unknown as SlidingDoorParts
    doors.push({
      key: i,
      xPos: xPos,
      yPos: yPos,
      zPos: zPos,
      length: shelfHeight,
      depth: width,
      move: i === 0 ? width - 2 * shelfThickness - 0.005 : -width + shelfThickness,
      thickness: doorThickness,
      material: slidingDoors.materialID,
      parts: slidingDoorParts,
      type: 'slidingDoor',
    })
  })

  return doors
}

const getSlidingDoorParts = (params: {width: number; height: number; frontMaterialID: keyof typeof slidingDoorMaterials; baseMaterialID: keyof typeof shelfMaterials}) => {
  const {width, height, frontMaterialID, baseMaterialID} = params
  const frontThickness = slidingDoorMaterials[frontMaterialID].thickness
  const baseThickness = shelfMaterials[baseMaterialID].thickness
  const front = {length: height, depth: width, thickness: frontThickness, material: frontMaterialID as keyof typeof slidingDoorMaterials, type: 'slidingDoorFront'}
  const base = {length: height, depth: width - 0.06, thickness: baseThickness, material: baseMaterialID, type: 'slidingDoorBase'}
  return {front: front, base: base}
}
