import {useStore} from 'products/bezwei/store/store'
import {Matress} from 'common/threeComponents/Matress'
import {slattedModel} from 'utils/datamodels'
import {bedModel} from 'products/bezwei/store/datamodels'

export const ConnectedMattress = () => {
  const [frameHeight, slattedHeightInStore, slattedId, length, width, mattressHeight, storagePlace] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.slatted.height,
    store.config.slatted.id,
    store.config.main.length,
    store.config.main.width,
    store.config.mattress.height,
    store.config.main.storagePlace,
  ])
  const frameLength = length + 0.05
  const frameWidth = width + 0.05
  const slattedHeight = slattedId !== 'own' ? slattedModel[slattedId].height : slattedHeightInStore
  const slattedInsetstore = useStore((store) => store.config.slatted.inset)
  const slattedInset = slattedInsetstore
  const yPos = frameHeight - slattedInset + slattedHeight

  return (
    <>
      <Matress size={[length, mattressHeight, width]} xPos={frameLength / 2 + (storagePlace > 1 ? bedModel.storagePlaceWidth : 0)} yPos={yPos} zPos={frameWidth / 2} />
    </>
  )
}
