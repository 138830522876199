import {useStore} from 'products/unisono/store/store'
import {Matress} from 'common/threeComponents/Matress'
import {slattedModel} from 'utils/datamodels'

export const ConnectedMattress = () => {
  const frameHeight = useStore((store) => store.config.main.frameHeight)
  const slattedHeightInStore = useStore((store) => store.config.slatted.height)
  const slattedId = useStore((store) => store.config.slatted.id)
  const length = useStore((store) => store.config.main.length)
  const width = useStore((store) => store.config.main.width)
  const mattressHeight = useStore((store) => store.config.mattress.height)
  const slattedHeight = slattedId !== 'own' ? slattedModel[slattedId].height : slattedHeightInStore
  const slattedInsetstore = useStore((store) => store.config.slatted.inset)
  const slattedInset = slattedInsetstore
  const yPos = frameHeight - slattedInset + slattedHeight
  const sideBoardWidth = useStore((store) => store.config.main.sideBoardWidth)
  const foodBoardWidth = useStore((store) => store.config.main.footBoardWidth)

  return (
    <>
      <Matress size={[length, mattressHeight, width]} xPos={foodBoardWidth + length / 2} yPos={yPos} zPos={sideBoardWidth + width / 2} />
    </>
  )
}
