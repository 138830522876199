import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {ConnectedIku} from '../threeComponents/Iku'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {ConnectedIkuBoardList} from '../partLists/boardList'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'
import {ConnectedIkuAccBoardList} from '../partLists/accBoardList'

export default function ConnectedIkuPrintView() {
  const footHeight = useStore((store) => store.config.feet.height)

  const partList = [...ConnectedIkuBoardList(), ...ConnectedIkuAccBoardList()]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)
  const stackable = useStore((store) => store.config.main.stackable)

  return (
    <PrintView reference="iku" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedIku stacked mattress slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedIku stacked mattress={false} slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'side'}>
          <ConnectedIku stacked mattress slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht von hinten'}</Caption>
        <InfoBoxPrint />
      </>
      {stackable && (
        <>
          <FiberPrintScene footHeight={footHeight} perspective={'side'}>
            <ConnectedIku mattress stacked={false} slatted />
          </FiberPrintScene>
          <Caption>{'Ansicht von hinten'}</Caption>
          <InfoBoxPrint />
        </>
      )}
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'iso'}>
          <ConnectedIku mattress slatted />
        </FiberPrintScene>
        <Caption>{'Schrägansicht'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}
