import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {ConnectedDasregalBoardList} from '../../partLists/boardList'
import {shelfMaterials} from 'products/dasregal/store/datamodels'

export const ConnectedBoards = () => {
  // const boardList = BoardList()
  const boardList = ConnectedDasregalBoardList()
  const materialID = boardList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  return (
    <>
      {/* <Board size={[1, 0.019, 0.33]} xPos={1} yPos={2.5} zPos={0} /> */}
      {boardList.map((board) => {
        const size = [board.length, board.thickness, board.depth] as IVector
        // return null
        // return <Board size={[board.length, board.thickness, board.depth]} xPos={board.xPos} yPos={1.5} zPos={0} />
        return <Board {...board} size={size} material={material[Math.round(Math.random())]} />
      })}
    </>
  )
}
