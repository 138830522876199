import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const LocaleToLocalStorage = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const local = localStorage.getItem("sj-local")
		if (!local) {
			navigate("locale", {replace:true})
		}
	},[navigate])

	return null
}