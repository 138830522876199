import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/dasregal/store/store'
import {Fragment, useRef} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from './../../store/datamodels'

interface ISubColumnSelectors {
  xPosList: number[]
  height: number
  depth: number
  callback: (xPos: number, yPos: number) => void
  material: THREE.Material
  thickness: number
  xPos: number
  yPos: number
  columnwidth: number
  column: number
  yPosIndex: number
}

export const SubColumnSelectors = (props: ISubColumnSelectors) => {
  return (
    <>
      {props.xPosList.map((el, i) => {
        const isWithinBounds = el * props.columnwidth > regalModel.minSubColumnWidth && (1 - el) * props.columnwidth > regalModel.minSubColumnWidth
        return (
          <Fragment key={i}>
            {isWithinBounds && (
              <Selector
                rotation={[0, 0, 0.5]}
                // translate={[0.5,-0.5,0.5]} //TODO: ask why added by Matthias?
                size={[props.height, props.thickness, props.depth]}
                xPos={props.xPos + el * props.columnwidth}
                yPos={props.yPos}
                zPos={0}
                onClick={() => props.callback(props.column + el, props.yPosIndex)}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export const ConnectedSubColumnSelectors = () => {
  const visible = useStore((store) => store.view.columns.edit)
  const [columns, boards, depth, height, grid] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid],
    shallow
  )
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}))
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (height - thickness) / grid
  let startPos = 0
  const xPosList = regalModel.subColumns
  const toggleSubColumn = useStore((store) => store.columns.toggleSubColumn)
  return (
    <>
      {columns.map((el, i) => {
        startPos += el.width + thickness
        let list = []
        for (let x = 0; x < grid; x++) {
          const nextBoard = Math.min(...boards[i].filter((y) => y > x), grid)
          let multiplier = nextBoard - x
          const height = multiplier * gridSize - thickness
          const yPos = x * gridSize + thickness
          list.push({key: x, yPos: yPos, height: height, yPosIndex: x})
          x += multiplier - 1
        }
        return list.map((el1, i2) => {
          return (
            <Fragment key={i2}>
              {visible && (
                <SubColumnSelectors
                  xPosList={xPosList}
                  columnwidth={el.width + thickness}
                  material={selectorMaterial.current}
                  xPos={startPos - el.width}
                  thickness={thickness}
                  height={el1.height}
                  depth={depth}
                  yPos={el1.yPos}
                  column={i}
                  yPosIndex={el1.yPosIndex}
                  callback={toggleSubColumn}
                />
              )}
            </Fragment>
          )
        })
      })}
    </>
  )
}
