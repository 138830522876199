import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from '../../store/datamodels'
import {ConnectedTekturColumnList} from 'products/tektur/partLists/columnList'

export const ConnectedColumns = () => {
  const columnList = ConnectedTekturColumnList()
  const materialID = columnList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]
  return (
    <>
      {columnList.map((column) => {
        const size = [column.length, column.thickness, column.depth] as IVector
        return <Board {...column} size={size} material={material} rotation={[0, 0, 0.5]} translate={[0.5, -0.5, 0.5]} />
      })}
    </>
  )
}
