import * as lookup from '../../store/datamodels'
import {useStore} from 'products/analog/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionListSelector, AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'

export function Backpanels() {
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const variant = useStore((store) => store.view.backpanels.variant)
  const backpanelMaterial = useStore((store) => store.config.backpanels.materialID)

  const setVariant = useStore((store) => store.backpanels.setVariant)
  const setMaterialID = useStore((store) => store.backpanels.setMaterialID)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(lookup.backpanelMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof lookup.backpanelMaterials)}
    })

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={backpanelMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector options={lookup.backpanelTypeModel} onClick={setVariant} title={'Größe'} value={variant} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
