import {GetState} from 'zustand'
import {regalModel} from '../datamodels'
import {ISetProduce, IStore} from '../store'

export interface IColumnSlice {
  columns: {
    setEdit: (edit: boolean) => void
    delete: () => void
    toggleSubColumn: (xPos: number, yPos: number) => void
    create: () => void
    setWidth: (width: number, colNum: number) => void
  }
}

export const createColumnSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const columns = {
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.columns.edit = edit
      }),
    create: () =>
      setProduce((S: IStore) => {
        const newColumn = {width: regalModel.newColumWidth, face: 'front' as 'front' | 'back' | 'side'}

        const newBoards = []
        if (S.config.boards.list.length < S.config.columns.list.length + 1) {
          for (let i = 1; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && newBoards.push(i)
          }
          S.config.boards.list.push(newBoards)
        }
        S.config.columns.list.push(newColumn)
      }),
    delete: () =>
      setProduce((store: IStore) => {
        store.config.columns.list.pop()
      }),
    setWidth: (width: number, colNum: number) =>
      setProduce((store: IStore) => {
        store.config.columns.list[colNum].width = width
      }),
  }
  return {columns}
}
