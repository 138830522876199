import {footModel as feets, materialModel} from 'utils/datamodels'

export const regalModel = {
  thickness: 0.006,
  minWidth: 0.3,
  minDepth: 0.2,
  maxDepth: 0.6,
  gridMin: 0.25,
  gridMax: 0.75,
  minColHeight: 0.15,
  maxColHeight: 0.6,
  maxSpan: 0.8,
  gridSize: 0.199,
  depthList: [0.25, 0.33, 0.41],
  heightList: [0.198, 0.268, 0.338, 0.548],
  maxDrawerHeight: 0.4,
  minDrawerHeight: 0.1,
  maxFlapHeight: 0.55,
  maxFlapWidth: 1.8,
  maxFootSpan: 0.9,
  maxFootInset: 0.41,
  frontInset: 0.03,
  sideInset: 0.0004,
  shadowJoint: 0.0025,
  backpanelInset: 0.001,
  backpanelSideInset: 0.0025,
  drawerBodyThickness: 0.008,
}

export const baseUrl = '/tektur'

export const shelfTyp = {
  1: {id: 1, name: 'TEKTUR S'},
  2: {id: 2, name: 'TEKTUR K'},
}

export const shelfMaterials = {
  m12MW: {...materialModel.m12MW},
  m12PB: {...materialModel.m12PB},
  m12CB: {...materialModel.m12CB},
  m12Ei: {...materialModel.m12Ei},
  s13Ei: {...materialModel.s13Ei},
  h10TW: {...materialModel.h10TW},
  h10SW: {...materialModel.h10SW},
  h10TB: {...materialModel.h10TB},
  h10SB: {...materialModel.h10SB},
}

export const slidingDoorMaterials = {
  m12MW: {...materialModel.m12MW},
  m12PB: {...materialModel.m12PB},
  m12CB: {...materialModel.m12CB},
  m12Ei: {...materialModel.m12Ei},
  h10TW: {...materialModel.h10TW},
  h10SW: {...materialModel.h10SW},
  h10TB: {...materialModel.h10TB},
  h10SB: {...materialModel.h10SB},
}

export const drawerFrontMaterials = {
  ...shelfMaterials,
}

export const flapMaterials = {
  ...shelfMaterials,
}

export const backpanelMaterials = {
  ...slidingDoorMaterials,
}

export const backpanelTypeModel = [
  {id: 0, name: 'fachgröße'},
  {id: 1, name: 'etagenbreite'},
]

export const drawerTypeModel = [
  {id: 0, name: 'volle Höhe', height: 1},
  {id: 1, name: 'halbe Höhe', height: 1 / 2},
  {id: 2, name: '1/3 Höhe', height: 1 / 3},
]

export const footModel = {
  s23: feets.s23,
  rnd30: {...feets.rnd30, heights: [0.125, 0.175, 0.225], price: 2 * feets.rnd30.price},
  rct16: {...feets.rct16, heights: [0.125, 0.175, 0.225], price: 2 * feets.rct16.price},
  wallMounted: feets.wallMounted,
}
