import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {backpanelMaterials} from '../../store/datamodels'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {ConnectedTekturSlidingDoorList} from 'products/tektur/partLists/slidingDoorList'
import {useStore} from 'products/tektur/store/store'

export const ConnectedSlidingDoors = () => {
  const slidingDoorList = ConnectedTekturSlidingDoorList()
  const visible = useStore((store) => store.view.slidingDoors.visible)
  if (slidingDoorList.length > 0) {
    const materialID = slidingDoorList[0].material
    const materialReference = backpanelMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  }
  return (
    <>
      {visible &&
        slidingDoorList.map((slide) => {
          const size = [slide.length, slide.thickness, slide.depth] as IVector
          return <Board {...slide} size={size} material={material[Math.round(Math.random())]} rotation={[0.5, 0, 0]} translate={[0.5, 0.5, -0.5]} />
        })}
    </>
  )
}
