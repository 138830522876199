import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider, MaterialSwitch} from 'components/material_interfaces/materialInterfaces'
import {bedModel, bedMaterials} from 'products/unisono/store/datamodels'
import {useStore} from 'products/unisono/store/store'
import {materialTreatments, materialTypes} from 'utils/datamodels'

export function BedStructure() {
  const [bedMaterial, treatmentID, frameHeight, width, length, headBoard, sideBoardWidth, footBoardWidth] = useStore((store) => [
    store.config.main.materialID,
    store.config.main.treatmentID,
    store.config.main.frameHeight,
    store.config.main.width,
    store.config.main.length,
    store.config.main.headBoard,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
  ])

  const setMaterialType = useStore((store) => store.setMaterialType)
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setFrameHeight = useStore((store) => store.setFrameHeight)
  const setLength = useStore((store) => store.setLength)
  const setWidth = useStore((store) => store.setWidth)
  const setHeadBoardID = useStore((store) => store.setHeadBoardID)
  const setHeadBoardHeight = useStore((store) => store.setHeadBoardHeight)
  const setSideBoardWidth = useStore((store) => store.setSideBoardWidth)
  const setFootBoardWidth = useStore((store) => store.setFootBoardWidth)
  const materialHasTreatment = bedMaterials[bedMaterial].treatment

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const regalMaterialType = bedMaterials[useStore((store) => store.config.main.materialID)].type
  const materialTypeList = Object.values(bedMaterials).map((material) => material.type)
  const typeList = materialTypes.filter((type) => materialTypeList.includes(type.id))

  const materialOptions = [...Object.values(bedMaterials)].filter((material) => material.type === regalMaterialType)

  const treatmentOptions = [...Object.values(materialTreatments)]

  const typeOptions = typeList.map((type) => {
    const thickness = bedMaterials[bedMaterial].type === type.id ? bedMaterials[bedMaterial].thickness : bedMaterials[bedMaterials[bedMaterial].fallback as keyof typeof bedMaterials].thickness
    return {...type, name: type.name + ', ' + Math.round(thickness * 1000) + ' mm'}
  })

  return (
    <>
      <AccordionListSelector id="type" options={typeOptions} onClick={setMaterialType} title={'Werkstoff'} value={regalMaterialType} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        id="species"
        options={materialOptions}
        onClick={setShelfMaterialSpecies}
        title={'Holzart / Farbe'}
        value={bedMaterial}
        expanded={expanded}
        handleChange={handleChange}
      />
      {materialHasTreatment && (
        <AccordionListSelector id="treatment" options={treatmentOptions} onClick={setMaterialTreatment} title={'Oberfläche'} value={treatmentID} expanded={expanded} handleChange={handleChange} />
      )}
      <AccordionSizeSlider
        options={[0.6, 0.7, 0.8, 0.9, 1, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2]}
        min={bedModel.minWidth * 1000}
        max={bedModel.maxWidth * 1000}
        onClick={setWidth}
        title={'Bettbreite'}
        id={'width'}
        value={width * 1000}
        clickRange={100}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[1.9, 2, 2.1, 2.2]}
        min={1.9 * 1000}
        max={2.2 * 1000}
        onClick={setLength}
        title={'Bettlänge'}
        id={'length'}
        value={length * 1000}
        clickRange={100}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.12, 0.14, 0.16, 0.18, 0.2, 0.22, 0.24, 0.26, 0.28, 0.3]}
        min={bedModel.minFrameHeight * 1000}
        max={bedModel.maxFrameHeight * 1000}
        onClick={setFrameHeight}
        title={'Rahmenhöhe'}
        id={'height'}
        value={frameHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.125, 0.15, 0.175, 0.2, 0.225]}
        min={0.1 * 1000}
        max={0.25 * 1000}
        onClick={setSideBoardWidth}
        title={'Breite Ablage seiltlich'}
        id={'sideBoardWidth'}
        value={sideBoardWidth * 1000}
        clickRange={10}
        step={0.025 * 1000}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.125, 0.15, 0.175, 0.2, 0.225]}
        min={0.1 * 1000}
        max={0.25 * 1000}
        onClick={setFootBoardWidth}
        title={'Breite Ablage fuß- und kopfseitig'}
        id={'footBoardWidth'}
        value={footBoardWidth * 1000}
        clickRange={5}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <MaterialSwitch title={'Kopfteil'} onClick={setHeadBoardID} value={headBoard.id === 1} />
      <AccordionSizeSlider
        disabled={headBoard.id === 0}
        options={[bedModel.standardHeadBoardHeight]}
        min={0}
        max={bedModel.maxHeadBoardHeight * 1000}
        onClick={setHeadBoardHeight}
        title={'Kopfteilhöhe'}
        id={'headBoardHeight'}
        value={headBoard.height * 1000}
        clickRange={10}
        expanded={headBoard.id === 0 ? false : expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
