import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {drawerFrontMaterials, regalModel, shelfMaterials} from 'products/tektur/store/datamodels'
import {Fragment} from 'react'

export const ConnectedDrawerSelectors = () => {
  const visible = useStore((store) => store.view.drawers.edit)
  const [boards, drawers, slidingDoors, width, grid, depth, shelfType] = useStore(
    (store) => [store.config.rows, store.config.drawers.list, store.config.slidingDoors.list, store.config.main.width, store.config.main.grid, store.config.main.depth, store.config.main.shelfTyp],
    shallow
  )
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const drawerFrontThickness = drawerFrontMaterials[useStore((store) => store.config.drawers.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const setDrawer = useStore((store) => store.drawers.setDrawer)
  const size = useStore((store) => store.view.drawers.variant)
  let yPos = shelfThickness
  return (<>
    {visible &&
      boards.list.map((board, y) => {
        yPos += board.height + shelfThickness
        const drawersInRow = Array.from(new Set(drawers.filter((drawer) => Math.floor(drawer.pos.y) === y).map((el) => el.pos.x))) as number[] // get the positions of drawers in the current board
        drawersInRow.push(0) // add the position of the left side of the board
        drawersInRow.sort((a, b) => a - b) // sort the positions
        const height = size * (board.height - shadowJoint) - shadowJoint
        return drawersInRow.map((el, x) => {
          const nextPos = drawersInRow[x + 1] !== undefined ? drawersInRow[x + 1] : grid // get the position of the next drawer or the right side of the board
          const numOfPossibleSlidingDoors = nextPos - el - (x === 0 ? 1 : 2) // calculate the number of possible sliding doors in the current part of the board
          const slidingDoorsInPart = slidingDoors.filter((slidingDoor) => slidingDoor.pos.y === y && slidingDoor.pos.x >= el && slidingDoor.pos.x < nextPos).map((el) => el.pos.x) // get the positions of sliding doors in the current part
          const length = gridSize - regalModel.shadowJoint // calculate the length of the selector
          const numOfSelectors = numOfPossibleSlidingDoors + (x > 0 ? 2 : 1) // calculate the number of selectors in the current part
          return [...Array(numOfSelectors)].map((_, i) => {
            const gridsBefore = i // calculate the number of grids before the current selector
            const gridsAfter = numOfSelectors - i - 1 // calculate the number of grids after the current selector
            const slidingDoorsBefore = slidingDoorsInPart.filter((slide) => slide < el + gridsBefore).length // calculate the number of sliding doors before the current selector
            const slidingDoorsAfter = slidingDoorsInPart.filter((slide) => slide >= el + gridsBefore).length // calculate the number of sliding doors after the current selector
            const xPos = (el + i) * gridSize + shelfThickness + regalModel.shadowJoint // calculate the x position of the selector
            const isDrawer = drawersInRow.includes(el + i) && x !== 0 // check if there is a drawer in the current position
            const isSlidingDoor = slidingDoorsInPart.includes(el + i) // check if there is a sliding door in the current position
            const enoughSpaceForDrawer = (gridsBefore - slidingDoorsBefore >= 1 || gridsBefore === 0) && (gridsAfter - slidingDoorsAfter >= 1 || gridsAfter === 0) // check if there is enough space for a drawer
            const isPossible = (numOfPossibleSlidingDoors - slidingDoorsInPart.length > 0 || numOfPossibleSlidingDoors === 0) && enoughSpaceForDrawer // check if there is still space for a drawer
            return (
              (<Fragment key={y + '.' + (x + i)}>
                {(isPossible || isSlidingDoor || isDrawer || shelfType === 2) && ( // if there is a sliding door or a drawer or there is still space for one or the shelfTyp is 2, render the selector
                  (<Selector
                    key={el + i + '.' + (y + 1 - size)}
                    size={[height, drawerFrontThickness, length]}
                    xPos={xPos}
                    yPos={yPos - height - shelfThickness - shadowJoint}
                    zPos={depth - 0.001 - drawerFrontThickness}
                    onClick={() => setDrawer({x: el + i, y: y + 1 - size}, {y: size})}
                    rotation={[0, 0.5, 0.5]}
                    translate={[0.5, 0.5, 0.5]}
                  />)
                )}
              </Fragment>)
            );
          });
        });
      })}
    {visible &&
      drawers.map((drawer, i) => {
        const yIndex = Math.abs(drawer.pos.y - size - Math.round(drawer.pos.y - size)) < 0.01 ? Math.round(drawer.pos.y - size) : drawer.pos.y - size
        const boardIndex = Math.floor(yIndex)
        if (boardIndex === Math.floor(drawer.pos.y) && boardIndex < boards.list.length && drawer.pos.x < grid) {
          const xPos = drawer.pos.x * gridSize + shelfThickness
          const yPos =
            boards.list.slice(0, boardIndex).reduce((a, e) => a + e.height + shelfThickness, shelfThickness) + (yIndex - boardIndex) * (boards.list[boardIndex].height - shadowJoint) - shadowJoint
          const length = gridSize - shelfThickness
          const height = size * (boards.list[boardIndex].height - shadowJoint) - shadowJoint
          return (
            <Fragment key={i}>
              {
                <Selector
                  key={boardIndex + '.' + drawer.pos.x}
                  size={[height, drawerFrontThickness, length]}
                  xPos={xPos}
                  yPos={yPos}
                  zPos={depth - drawerFrontThickness}
                  onClick={() => setDrawer({x: drawer.pos.x, y: yIndex}, {y: size})}
                  rotation={[0, 0.5, 0.5]}
                  translate={[0.5, 0.5, 0.5]}
                />
              }
            </Fragment>
          )
        } else {
          return null
        }
      })}
  </>);
}
