import {Route, Routes} from 'react-router-dom'
import {LocaleModal} from '../../../../components/material_interfaces/material_interfaces'
import {baseUrl} from '../../store/datamodels'
import {localFactors} from 'utils/datamodels'
import {LoadModal} from '../../../../utils/API/components/LoadModal'
import {SaveConfigModal} from '../../../../utils/API/components/SaveConfigModal'
import {SaveLoadModal} from '../../../../utils/API/components/SaveLoadModal'
import {ShelfStructureModals} from '../Shelf/ShelfStructureModals'
import {IndexView} from './components/IndexView'
import {ConnectedAnalogContainer} from './components/ConnectedAnalogContainer'
import ConnectedPrintView from 'products/analog/print/PrintView'
import ApiFetch from 'utils/API/ApiFetch'
import {AnalogPriceList} from 'products/analog/priceList/PriceList'

export const AnalogView = () => {
  document.title = 'SANKTJOHANSER | ANALOG'
  return (
    <Routes>
      <Route path="/" element={<ConnectedAnalogContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="analog" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/analog" />} />
          <Route path="structure/*" element={<ShelfStructureModals />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedPrintView />} />
      <Route path="pricelist" element={<AnalogPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}
