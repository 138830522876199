import {useStore} from 'products/digital/store/store'
import {shelfMaterials} from 'products/digital/store/datamodels'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedDigitalBoardList = () => {
  const [boardList, width, depth, materialID] = useStore(useShallow((store) => [store.config.rows.list, store.config.main.width, store.config.main.depth, store.config.main.materialID]))
  return DigitalBoardList(boardList, width, depth, materialID)
}

export const DigitalBoardList = (boardList: any[], width: number, depth: number, materialID: keyof typeof shelfMaterials) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const boards = [{key: 0, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: 'bottom'}]

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    boards.push({key: index + 1, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: index + 1 !== boardList.length ? 'board' : 'top'})
  })

  return boards
}
