export const isDoorInFront = (pos: {x: number; y: number}, doors: {pos: {x: number; y: number}; size: {y: number}}[]) => {
  let isInFront = false
  doors.forEach((door) => {
    if (pos.x === door.pos.x && pos.y >= door.pos.y && pos.y <= door.pos.y + door.size.y) {
      isInFront = true
    }
  })
  return isInFront
}

export const isDrawerInFront = (pos: {x: number; y: number}, drawers: {pos: {x: number; y: number}; size: {y: number}}[]) => {
  let isInFront = false
  drawers.forEach((drawer) => {
    const drawerYPos = Math.round(drawer.pos.y * 100) / 100
    const drawerYSize = Math.round(drawer.size.y * 100) / 100
    if (pos.x === drawer.pos.x && pos.y >= drawerYPos && pos.y <= drawerYPos + drawerYSize) {
      isInFront = true
    }
  })
  return isInFront
}

export const isDoorBottomOrTop = (pos: {x: number; y: number}, doors: {pos: {x: number; y: number}; size: {y: number}}[]) => {
  let isBottomOrTop = false
  doors.forEach((door) => {
    if (pos.x === door.pos.x && (pos.y === door.pos.y || pos.y === door.pos.y + door.size.y)) {
      isBottomOrTop = true
    }
  })
  return isBottomOrTop
}
