import './../overview.scss'
import iku_1 from 'common/static/overview/iku-01.jpg'
import iku_3 from 'common/static/overview/iku-03.jpg'
import iku_4 from 'common/static/overview/iku-04.jpg'
import iku_5 from 'common/static/overview/iku-05.jpg'
import iku_6 from 'common/static/overview/iku-06.jpg'
import iku_f from 'common/static/overview/iku-f.jpg'
import iku_hh from 'common/static/overview/iku-hh.jpg'
import iku_s from 'common/static/overview/iku-s.jpg'
import iku_hh2 from 'common/static/overview/iku-hh2.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedIkuOverView() {
  return <ProductOverView title={'iku'} products={iku} withTitle />
}

export const iku = [
  {img: <img className="overview-image" src={iku_1} alt="Iku 7B" />, to: 'get/iku-7b'},
  {img: <img className="overview-image" src={iku_f} alt="Iku 14F" />, to: 'get/iku-14f'},
  {img: <img className="overview-image" src={iku_3} alt="Iku 14R" />, to: 'get/iku-14r'},
  {img: <img className="overview-image" src={iku_4} alt="Iku 14H" />, to: 'get/iku-14h'},
  {img: <img className="overview-image" src={iku_hh} alt="Iku 14HH" />, to: 'get/iku-14hh'},
  {img: <img className="overview-image" src={iku_hh2} alt="Iku 14HH" />, to: 'get/iku-14hh-2'},
  {img: <img className="overview-image" src={iku_6} alt="Iku 14F" />, to: 'get/iku-14f-2'},
  {img: <img className="overview-image" src={iku_5} alt="Iku 7B+" />, to: 'get/iku-7+'},
  {img: <img className="overview-image" src={iku_s} alt="Iku S+" />, to: 'get/iku-s+'},
]
