import {ReactNode} from 'react'
import {Outlet} from 'react-router-dom'
import {InfoBox} from '../infobox/infobox'
import {Logo} from '../Logo/Logo'
import {ShelfContainer} from '../ShelfContainer'

type IProps = {
  title: string
  shelfMaterialId: string
  treatmentId?: number
  materialSelector: string
  width: number
  fullHeight: number | string
  footHeight: number
  depth: number | string
  localFactor: {id: number; factor: number; name: string; fullname: string; vat: string}
  children?: any
  baseUrl: string
  price?: ReactNode | null | undefined
}

export const ViewContainer = (props: IProps) => {
  return (
    <div className="center-box">
      <Logo />
      <ShelfContainer>{props.children}</ShelfContainer>
      <InfoBox
        title={props.title}
        materialId={props.shelfMaterialId}
        treatmentId={props.treatmentId || 0}
        materialSelector={props.materialSelector}
        width={props.width}
        height={props.fullHeight}
        depth={props.depth}
        vat={props.localFactor.vat}
        country={props.localFactor.name}
        baseUrl={props.baseUrl}
        price={props.price}
      />
      <Outlet />
    </div>
  )
}
