import {materialModel, footModel as feets} from 'utils/datamodels'

export const regalModel = {
  thickness: 0.019,
  minHeight: 0.2,
  minDepth: 0.2,
  maxDepth: 0.9,
  gridMin: 0.2,
  gridMax: 0.6,
  newColumWidth: 0.6,
  subColumns: [1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4],
  minSubColumnWidth: 0.199,
  minColumnWidth: 0.4,
  maxColumnWidth: 1.2,
  minDrawerHeight: 0.1,
  maxDrawerHeight: 0.4,
  maxDoorWidth: 0.75,
  minDoorWidth: 0.2,
  maxFlapHeight: 0.55,
  maxFlapWidth: 1.8,
  minDoorHeight: 0.4,
  maxFootSpan: 1.45,
  shadowJoint: 0.003,
  frontInset: 0.025,
  backpanelInset: 0.008,
  backpanelSideInset: 0.003,
  backpanelWallMountedInset: 0.017,
}

export const baseUrl = '/dasregal'

export const shelfMaterials = {
  m18Ah: materialModel['m18Ah'],
  m18Bi: materialModel['m18Bi'],
  m18Bu: materialModel['m18Bu'],
  m18Ei: materialModel['m18Ei'],
  m18Es: materialModel['m18Es'],
  m18Ki: materialModel['m18Ki'],
  m18aN: materialModel['m18aN'],
  m18MW: materialModel['m18MW'],
  m18SW: materialModel['m18SW'],
  m18CB: materialModel['m18CB'],
  m18SB: materialModel['m18SB'],
  s19Ah: materialModel['s19Ah'],
  s19Bi: materialModel['s19Bi'],
  s19Bu: materialModel['s19Bu'],
  s19Ei: materialModel['s19Ei'],
  s19Es: materialModel['s19Es'],
  s19Ki: materialModel['s19Ki'],
  s19NmS: materialModel['s19NmS'],
  s19NwS: materialModel['s19NwS'],
  s19NoS: materialModel['s19NoS'],

  // ARCHIVED
  s19aN: materialModel['s19aN'],
  s19eN: materialModel['s19eN'],
  s19sN: materialModel['s19sN'],
}

export const frontMaterials = {
  m18Ah: {...materialModel['m18Ah'], shelfReference: ['m18Ah']},
  m18Bi: {...materialModel['m18Bi'], shelfReference: ['m18Bi']},
  m18Bu: {...materialModel['m18Bu'], shelfReference: ['m18Bu']},
  m18Ei: {...materialModel['m18Ei'], shelfReference: ['m18Ei']},
  m18Es: {...materialModel['m18Es'], shelfReference: ['m18Es']},
  m18Ki: {...materialModel['m18Ki'], shelfReference: ['m18Ki']},
  m18aN: {...materialModel['m18aN'], shelfReference: ['m18aN']},
  m18MW: {...materialModel['m18MW'], shelfReference: []},
  m18SW: {...materialModel['m18SW'], shelfReference: []},
  m18CB: {...materialModel['m18CB'], shelfReference: []},
  m18SB: {...materialModel['m18SB'], shelfReference: []},
  s19Ah: {...materialModel['s19Ah'], shelfReference: ['s19Ah']},
  s19Bi: {...materialModel['s19Bi'], shelfReference: ['s19Bi']},
  s19Bu: {...materialModel['s19Bu'], shelfReference: ['s19Bu']},
  s19Ei: {...materialModel['s19Ei'], shelfReference: ['s19Ei']},
  s19Es: {...materialModel['s19Es'], shelfReference: ['s19Es']},
  s19Ki: {...materialModel['s19Ki'], shelfReference: ['s19Ki']},
  s19NmS: {...materialModel['s19NmS'], shelfReference: ['s19NmS']},
  s19NwS: {...materialModel['s19NwS'], shelfReference: ['s19NwS']},
  s19NoS: {...materialModel['s19NoS'], shelfReference: ['s19NoS']},

  // ARCHIVED
  s19aN: {...materialModel['s19aN'], shelfReference: ['s19aN']},
  s19eN: {...materialModel['s19eN'], shelfReference: ['s19eN']},
  s19sN: {...materialModel['s19sN'], shelfReference: ['s19sN']},
}

export const backpanelMaterials = {
  mdf8W: {...materialModel['mdf8W'], shelfReference: []},
  h8TB: {...materialModel.h8TB, shelfReference: []},
  m18Ah: {...materialModel['m18Ah'], shelfReference: ['m18Ah']},
  m18Bi: {...materialModel['m18Bi'], shelfReference: ['m18Bi']},
  m18Bu: {...materialModel['m18Bu'], shelfReference: ['m18Bu']},
  m18Ei: {...materialModel['m18Ei'], shelfReference: ['m18Ei']},
  m18Es: {...materialModel['m18Es'], shelfReference: ['m18Es']},
  m18Ki: {...materialModel['m18Ki'], shelfReference: ['m18Ki']},
  m18aN: {...materialModel['m18aN'], shelfReference: ['m18aN']},
  m18MW: {...materialModel['m18MW'], shelfReference: []},
  m18SW: {...materialModel['m18SW'], shelfReference: []},
  m18CB: {...materialModel['m18CB'], shelfReference: []},
  m18SB: {...materialModel['m18SB'], shelfReference: []},
}

export const backpanelTypeModel = [
  {id: 0, name: 'fachgröße'},
  {id: 1, name: 'etagenbreite'},
  {id: 2, name: 'volle Höhe'},
]

export const doorTypeModel: {id: number; name: string; sizes: number[]; side: ('left' | 'right')[]}[] = [
  {id: 0, name: 'linke Tür', sizes: [1], side: ['left']},
  {id: 1, name: 'rechte Tür', sizes: [1], side: ['right']},
  {id: 2, name: '1/2 + 1/2', sizes: [1 / 2, 1 / 2], side: ['left', 'right']},
  {id: 3, name: '1/3 + 2/3', sizes: [1 / 3, 2 / 3], side: ['left', 'right']},
  {id: 4, name: '2/3 + 1/3', sizes: [2 / 3, 1 / 3], side: ['left', 'right']},
  {id: 5, name: '1/4 + 3/4', sizes: [1 / 4, 3 / 4], side: ['left', 'right']},
  {id: 6, name: '3/4 + 1/4', sizes: [3 / 4, 1 / 4], side: ['left', 'right']},
]

export const drawerTypeModel = [
  {id: 0, name: 'volle Höhe', height: 1},
  {id: 1, name: 'halbe Höhe', height: 1 / 2},
  {id: 2, name: '1/3 Höhe', height: 1 / 3},
  {id: 3, name: '2/3 Höhe', height: 2 / 3},
  {id: 4, name: '3/4 Höhe', height: 3 / 4},
]

export const footModel = {
  s23: feets.s23,
  shv: feets.shv,
  castor43: feets.castor43,
  rnd30: {...feets.rnd30, heights: [0.105, 0.155, 0.205]},
  rct16: {...feets.rct16, heights: [0.105, 0.155, 0.205]},
  wallMounted: feets.wallMounted,
}
