import {materialModel, footModel as feets} from 'utils/datamodels'

export const regalModel = {
  minHeight: 0.3,
  minDepth: 0.3,
  maxDepth: 0.9,
  gridMin: 0.2,
  gridMax: 0.6,
  newColumWidth: 0.582,
  minColumnWidth: 0.15,
  maxColumnWidth: 0.6,
  minDoorWidth: 0.4,
  maxDoorWidth: 0.6,
  minDoorHeight: 0.4,
  minDrawerHeight: 0.1,
  maxDrawerHeight: 0.4,
  shadowJoint: 0.0025,
  frontInset: 0.025,
  bottomTopInset: 0.016,
  maxSpan: 1.4,
  backpanelInset: 0.001,
  backpanelSideInset: 0.003,
}

export const baseUrl = '/metrum'

export const shelfMaterials = {
  m12MW: materialModel['m12MW'],
  m12PB: materialModel['m12PB'],
  m12CB: materialModel['m12CB'],
  m12Ei: materialModel['m12Ei'],
  h12SW: materialModel['h12SW'],
  h12SB: materialModel['h12SB'],
}

export const frontMaterials = {
  m12MW: materialModel['m12MW'],
  m12PB: materialModel['m12PB'],
  m12CB: materialModel['m12CB'],
  m12Ei: materialModel['m12Ei'],
  h12SW: materialModel['h12SW'],
  h12SB: materialModel['h12SB'],
}

export const backpanelMaterials = {
  mdf19W: {...materialModel['mdf19W']},
  m18Ei: {...materialModel['m18Ei']},
  m18CB: {...materialModel['m18CB']},
  m18SB: {...materialModel['m18SB']},
  m18SW: {...materialModel['m18SW']},
}

export const doorTypeModel: {id: number; name: string; side: 'left' | 'right'}[] = [
  {id: 0, name: 'linke Tür', side: 'left'},
  {id: 1, name: 'rechte Tür', side: 'right'},
]

export const drawerTypeModel = [
  {id: 0, name: 'volle Höhe', height: 1},
  {id: 1, name: 'halbe Höhe', height: 1 / 2},
  {id: 2, name: '1/3 Höhe', height: 1 / 3},
  {id: 3, name: '2/3 Höhe', height: 2 / 3},
  {id: 4, name: '3/4 Höhe', height: 3 / 4},
]

export const footModel = {
  gn832: feets.gn832,
}

export const metrumWorkprices = {
  StahlTuer: 10,
}
