import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/metrum/store/store'
import {Fragment} from 'react'
import {materialModel} from 'utils/datamodels'
import shallow from 'zustand/shallow'
import {doorTypeModel, regalModel, shelfMaterials} from './../../store/datamodels'

interface IDoorSelectors {
  thickness: number
  zPos: number
  doorList: {pos: {x: number; y: number}; size: {y: number}; xPos: number; yPos: number; height: number; width: number; side: 'left' | 'right'}[]
  callback: (pos: {x: number; y: number}, size: {y: number}, side: 'left' | 'right') => void
}

export const DoorSelectors = (props: IDoorSelectors) => {
  return (
    <>
      {props.doorList.map((el, i) => {
        return (
          <Selector
            key={i}
            size={[el.height, props.thickness, el.width]}
            rotation={[0, 0.5, 0.5]}
            onClick={() => props.callback(el.pos, el.size, el.side)}
            xPos={el.xPos}
            yPos={el.yPos}
            zPos={props.zPos}
          />
        )
      })}
    </>
  )
}

export const ConnectedDoorSelectors = () => {
  const visible = useStore((store) => store.view.doors.edit)
  const [columns, height, grid, depth] = useStore((store) => [store.config.columns, store.config.main.height, store.config.main.grid, store.config.main.depth], shallow)
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const thickness = materialModel[useStore((store) => store.config.doors.materialID)].thickness
  const gridSize = (height - thickness) / grid
  const variant = useStore((store) => store.view.doors.variant)
  const doorSize = useStore((store) => store.view.doors.size)
  const doorHeight = doorSize * gridSize + shelfThickness
  const doorVariant = doorTypeModel.find((el) => el.id === variant) || doorTypeModel[0]
  const setDoor = useStore((store) => store.doors.setDoor)
  if (!visible) return null

  return (
    <>
      {columns.list.map((el, x) => {
        const xPos = columns.list.slice(0, x).reduce((acc, el) => acc + el.width + shelfThickness, shelfThickness)
        return [...Array(grid - doorSize + 1)].map((_, y) => {
          const yPos = y * gridSize
          if (el.width < regalModel.minDoorWidth || el.width > regalModel.maxDoorWidth) return null
          return (
            <Selector
              key={x + '-' + y}
              size={[doorHeight, thickness, el.width]}
              rotation={[0, 0.5, 0.5]}
              onClick={() => setDoor({x: x, y: y}, {y: doorSize}, doorVariant.side)}
              xPos={xPos}
              yPos={yPos}
              zPos={depth - thickness}
            />
          )
        })
      })}
    </>
  )
}
