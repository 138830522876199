import {useStore} from 'products/tektur/store/store'
import {shelfMaterials} from 'products/tektur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {ITekturConfig} from '../store/initialConfig'

export const ConnectedTekturBoardList = () => {
  const [boardList, width, depth, materialID] = useStore(useShallow((store) => [store.config.rows.list, store.config.main.width, store.config.main.depth, store.config.main.materialID]))
  return TekturBoardList(boardList, width, depth, materialID)
}

export const TekturBoardList = (boardList: ITekturConfig['rows']['list'], width: number, depth: number, materialID: ITekturConfig['main']['materialID']) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const boards = [{key: 0, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: 'bottom'}]

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    boards.push({key: index + 1, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: index + 1 !== boardList.length ? 'board' : 'top'})
  })
  return boards
}
