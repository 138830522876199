import {produce} from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import create from 'zustand'
import {shelfMaterials, regalModel, backpanelMaterials, frontMaterials, footModel} from './datamodels'
import {initialConfig, IRegalConfig} from './initialConfig'
import {createDoorSlice} from './slices/doorSlice'
import {createColumnSlice, IColumnSlice} from './slices/columnSlice'
import {createDrawerSlice} from './slices/drawerSlice'
import {createFlapSlice} from './slices/flapSlice'
import {createBackpanelSlice} from './slices/backpanelSlice'
import {createTabelarSlice} from './slices/tabelarSlice'
import {initialView, IView} from './initialView'
import {getMinGrid, getMaxGrid, clearDrawers} from '../../../utils/helpers'
import {createFeetSlice} from './slices/feetSlice'

declare interface IBackpanelActions {
  setMaterialID: (newMaterial: keyof typeof backpanelMaterials) => void
  toggleBackpanel: (xPos: number, yPosIndex: number, cover: 'full' | 'fullWidth' | 'compartment') => void
  setEditBackpanels: (edit: boolean) => void
  setVariant: (variant: number) => void
}
declare interface IDoorActions {
  setDoor: (pos: {x: number; y: number}, size: {x: number; y: number}, side: 'left' | 'right') => void
  setMaterialID: (newMaterial: keyof typeof frontMaterials) => void
  setEditDoors: (edit: boolean) => void
  setSize: (size: number) => void
  setVariant: (variant: number) => void
  setVisible: (show: boolean) => void
}
declare interface IDrawerActions {
  setDrawer: (pos: {x: number; y: number}, size: {x: number; y: number}) => void
  setMaterialID: (newMaterial: keyof typeof frontMaterials) => void
  setVariant: (variant: number) => void
  setGrainDirection: (variant: number) => void
  setEditDrawers: (edit: boolean) => void
  setVisible: (show: boolean) => void
}

declare interface IFlapActions {
  setFlap: (pos: {x: number; y: number}, size: {x: number; y: number}) => void
  setMaterialID: (newMaterial: keyof typeof frontMaterials) => void
  setEditFlaps: (edit: boolean) => void
  setSize: (size: {x: number; y: number}) => void
  setVisible: (show: boolean) => void
}

declare interface IFeetActions {
  setHeight: (height: number) => void
  setID: (id: keyof typeof footModel) => void
}

declare interface ITabelarActions {
  toggleTabelar: (xPos: number, yPosIndex: number) => void
}

declare type IStoreActions = {
  backpanels: IBackpanelActions
  doors: IDoorActions
  drawers: IDrawerActions
  feet: IFeetActions
  flaps: IFlapActions
  tabelar: ITabelarActions
}

export type IStore = {
  config: IRegalConfig
  view: IView
  uri: string
  savedConfig: IRegalConfig
  setMaterialSpecies: (species: keyof typeof shelfMaterials) => void
  setMaterialTreatment: (treatment: number) => void
  setMaterialType: (type: number) => void
  setHeight: (height: number) => void
  setDepth: (depth: number) => void
  setGrid: (grid: number) => void
  setEditTabelars: (edit: boolean) => void
  setEditBoards: (edit: boolean) => void
  toggleBoard: (index: number, board: number) => void
  clearConfig: () => IRegalConfig
} & IStoreActions &
  IColumnSlice

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

//@ts-ignore
export const useStore = create<IStore>((set, get) => {
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  const config: IRegalConfig = initialConfig // set backup config
  const view: IView = initialView // set default view

  const setMaterialSpecies = (nr: keyof typeof shelfMaterials) => {
    setProduce((state) => {
      state.config.main.materialID = nr
    })
  }

  const setMaterialType = (type: number) => {
    const fallback = shelfMaterials[get().config.main.materialID].fallback as keyof typeof shelfMaterials
    const oldType = shelfMaterials[get().config.main.materialID].type
    setProduce((state) => {
      if (type !== oldType) state.config.main.materialID = fallback
    })
  }

  const setMaterialTreatment = (treatmentID: number) => {
    setProduce((state) => {
      state.config.main.treatmentID = treatmentID
    })
  }
  const setHeight = (newHeight: number) => {
    const newFoot = newHeight > 1.6 ? 'shv' : get().config.feet.id === 'shv' ? 's23' : get().config.feet.id
    const wallMounted = newFoot === 'wallMounted'
    const feetHeight = wallMounted ? 0 : footModel[newFoot].heights.includes(get().config.feet.height) ? get().config.feet.height : footModel[newFoot].heights[footModel[newFoot].standardHeight || 0]
    const newShelfHeight = newHeight - feetHeight
    const minGrid = getMinGrid(newHeight, regalModel.gridMax)
    const maxGrid = getMaxGrid(newHeight, regalModel.gridMin)
    const thickness = shelfMaterials[get().config.main.materialID].thickness
    const colSize = (get().config.main.height - thickness) / get().config.main.grid
    const preferredGrid = Math.round(newHeight / colSize)
    const alternativeGrid = maxGrid - preferredGrid > preferredGrid - minGrid ? minGrid : maxGrid
    const newGrid = maxGrid >= preferredGrid && preferredGrid >= minGrid ? preferredGrid : alternativeGrid // TODO I think it should be >= -> also in UI Grid selector
    if (newGrid !== get().config.main.grid) setGrid(newGrid)
    setProduce((state) => {
      state.config.main.height = newShelfHeight
      state.config.feet.id = newFoot
      state.config.feet.height = feetHeight
    })
  }
  const setDepth = (newDepth: number) => {
    setProduce((state) => {
      state.config.main.depth = newDepth
    })
  }
  const setGrid = (newGrid: number) => {
    setProduce((state) => {
      const doors = state.config.doors.list
      const newDoors = []
      for (var i = 0; i < doors.length; i++) {
        if (doors[i].pos.y + doors[i].size.y < newGrid) {
          newDoors.push(doors[i])
        } else {
          if (doors[i].pos.y < newGrid) {
            newDoors.push({...doors[i], size: {x: doors[i].size.x, y: newGrid - doors[i].pos.y}})
          }
        }
      }
      state.config.doors.list = newDoors
      const drawers = state.config.drawers.list
      const newDrawers = []
      for (var k = 0; k < drawers.length; k++) {
        if (drawers[k].pos.y + drawers[k].size.y <= newGrid) {
          newDrawers.push(drawers[k])
        }
      }
      state.config.drawers.list = newDrawers
      const flaps = state.config.flaps.list
      const newFlaps = []
      for (var j = 0; j < flaps.length; j++) {
        if (flaps[j].size.x === 1) {
          if (flaps[j].pos.y + flaps[j].size.y < newGrid) {
            newFlaps.push(flaps[j])
          } else {
            if (flaps[j].pos.y < newGrid) {
              newFlaps.push({...flaps[j], size: {x: flaps[j].size.x, y: newGrid - flaps[j].pos.y}})
            }
          }
        } else {
          if (flaps[j].pos.y + flaps[j].size.y < newGrid) {
            newFlaps.push({...flaps[j], size: {x: 1, y: flaps[j].size.y}})
          } else {
            if (flaps[j].size.y > newGrid) {
              newFlaps.push({...flaps[j], size: {x: flaps[j].size.x, y: newGrid}})
            }
          }
        }
      }
      state.config.flaps.list = newFlaps
      state.config.boards.list.forEach((boards, y) => {
        if (Math.max(...boards) + 2 < newGrid) {
          for (let i = Math.max(...boards, 0) + 1; i < newGrid; i++) {
            i % 2 === 0 && state.config.boards.list[y].push(i)
          }
        }
      })
      state.config.main.grid = newGrid
    })
  }
  const setEditTabelars = (edit: boolean) => {
    setProduce((state) => {
      state.view.tabelars.edit = edit
    })
  }
  const setEditBoards = (edit: boolean) => {
    setProduce((state) => {
      state.view.boards.edit = edit
    })
  }
  const toggleBoard = (colNum: number, boardNum: number) => {
    const grid = get().config.main.grid
    const height = get().config.main.height
    const thickness = shelfMaterials[get().config.main.materialID].thickness
    const maxCols = grid * (Math.round((height - thickness) / (grid * 0.065)) + 1)
    const tabelarNum = ((boardNum + 1) * maxCols) / grid

    setProduce((store) => {
      store.config.tabelars.list = store.config.tabelars.list.filter((el: {pos: {x: number; y: number[]}}) => {
        if (Math.floor(el.pos.x) === colNum && el.pos.y.includes(tabelarNum)) {
          if (el.pos.y.length === 1) {
            return false
          } else {
            el.pos.y = el.pos.y.filter((el: number) => el !== tabelarNum)
            return {pos: {x: el.pos.x, y: el.pos.y}}
          }
        } else {
          return el
        }
      })
      store.config.boards.list[colNum].includes(boardNum)
        ? store.config.boards.list[colNum].splice(store.config.boards.list[colNum].indexOf(boardNum), 1)
        : store.config.boards.list[colNum].push(boardNum)
      const newDrawers = clearDrawers(store.config.drawers.list, store.config.tabelars.list, store.config.columns.list, store.config.boards.list, grid, height) as {
        pos: {x: number; y: number}
        size: {x: number; y: number}
        face: 'back' | 'front'
      }[]
      store.config.drawers.list = newDrawers
    })
  }

  const getClearConfig = () => {
    return {...get().config}
  }

  return {
    config,
    view,
    uri: '',
    savedConfig: '',
    setMaterialType: setMaterialType,
    setMaterialSpecies: setMaterialSpecies,
    setMaterialTreatment: setMaterialTreatment,
    setHeight: setHeight,
    setDepth: setDepth,
    setGrid: setGrid,
    setEditTabelars: setEditTabelars,
    setEditBoards: setEditBoards,
    toggleBoard: toggleBoard,
    clearConfig: getClearConfig,
    ...createColumnSlice(setProduce, get),
    ...createDoorSlice(setProduce),
    ...createDrawerSlice(setProduce, get),
    ...createFlapSlice(setProduce, get),
    ...createFeetSlice(setProduce),
    ...createBackpanelSlice(setProduce, get),
    ...createTabelarSlice(setProduce, get),
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('RegalStore', useStore)
}
