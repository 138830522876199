import {useStore} from 'products/unisono/store/store'
import {materialModel} from 'utils/datamodels'

export const ConnectedUnisonoAccBoardList = () => {
  const [frameHeight, length, width, sideBoardWidth, footBoardWidth, slattedInsetstore, slattedID] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.length,
    store.config.main.width,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
    store.config.slatted.inset,
    store.config.slatted.id,
  ])
  return UnisonoAccBoardList(frameHeight, length, width, sideBoardWidth, footBoardWidth, slattedInsetstore, slattedID)
}

export const UnisonoAccBoardList = (frameHeight: number, length: number, width: number, sideBoardWidth: number, footBoardWidth: number, slattedInsetstore: any, slattedID: string) => {
  const slattedIntegrated = slattedID === 'integrated'
  const frameLength = length + 0.05
  const frameWidth = width + 0.05
  const usableWidth = width
  const nrOfSlats = usableWidth > (slattedIntegrated ? 1.2 : 1.4) ? 2 : 1
  const slattedWidth = Math.floor((usableWidth / nrOfSlats) * 10) / 10
  const slattedInset = slattedInsetstore
  const middleBoardInBetween = (nrOfSlats === 2 && usableWidth - 2 * slattedWidth > 0.04) || (slattedIntegrated && nrOfSlats === 2)
  const additionalSideBoards = (!slattedIntegrated && usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0) > 0.04) || (slattedIntegrated && nrOfSlats === 2)
  const widthOfSideBoards = slattedIntegrated ? 0.025 : Math.min(Math.floor(((usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0)) / 2) * 100) / 100, 0.04)
  const yPos = slattedIntegrated ? frameHeight - slattedInset : frameHeight - 0.01

  const accboards: {
    key: number | string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    rotation?: [number, number, number]
    translate: [number, number, number]
    material: keyof typeof materialModel
    type: string
  }[] = []
  nrOfSlats === 2 &&
    !slattedIntegrated &&
    accboards.push({
      key: 0,
      xPos: footBoardWidth + length / 2,
      yPos: frameHeight - slattedInset - 0.018,
      zPos: sideBoardWidth + width / 2,
      length: length - 0.02,
      depth: 0.08,
      thickness: 0.018,
      translate: [0, 0.5, 0],
      material: 'm18Mpx',
      type: 'middleBoard',
    })
  additionalSideBoards &&
    accboards.push({
      key: 2,
      xPos: footBoardWidth + length / 2,
      yPos: yPos,
      zPos: sideBoardWidth,
      length: length - 0.2,
      depth: widthOfSideBoards,
      thickness: 0.055,
      translate: [0, -0.5, 0.5],
      material: 's25Bi',
      type: 'sideBoard',
    })
  additionalSideBoards &&
    accboards.push({
      key: 3,
      xPos: footBoardWidth + length / 2,
      yPos: yPos,
      zPos: sideBoardWidth + width,
      length: length - 0.2,
      depth: widthOfSideBoards,
      thickness: 0.055,
      translate: [0, -0.5, -0.5],
      material: 's25Bi',
      type: 'sideBoard',
    })
  if (slattedIntegrated && nrOfSlats === 1) {
    accboards.push({
      key: 'stack1',
      xPos: 0.24,
      yPos: 0,
      zPos: frameWidth / 2,
      length: usableWidth,
      depth: 0.24,
      thickness: 0.018,
      translate: [0, 0.5, 0],
      rotation: [0, -0.5, 0],
      material: 'm18Mpx',
      type: 'footSupportBoard',
    })
    accboards.push({
      key: 'stack2',
      xPos: frameLength / 2,
      yPos: 0,
      zPos: frameWidth / 2,
      length: usableWidth,
      depth: 0.24,
      thickness: 0.018,
      translate: [0, 0.5, 0],
      rotation: [0, -0.5, 0],
      material: 'm18Mpx',
      type: 'footSupportBoard',
    })
    accboards.push({
      key: 'stack3',
      xPos: frameLength - 0.24,
      yPos: 0,
      zPos: frameWidth / 2,
      length: usableWidth,
      depth: 0.24,
      thickness: 0.018,
      translate: [0, 0.5, 0],
      rotation: [0, -0.5, 0],
      material: 'm18Mpx',
      type: 'footSupportBoard',
    })
  }

  return accboards
}
