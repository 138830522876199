import {MetrumPrice} from '../utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors, API_DOMAIN} from 'utils/datamodels'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'
import {baseUrl, frontMaterials, shelfMaterials} from '../store/datamodels'
import {useState, useLayoutEffect, Fragment} from 'react'
import {MetrumBoardList} from '../partLists/boardList'
import {IMetrumConfig} from '../store/initialConfig'
import {MetrumDrawerList} from '../partLists/drawerList'
import {MetrumFootList} from '../partLists/footList'
import {useMemo} from 'react'
import {MetrumColumnList} from '../partLists/columnList'
import {MetrumBackpanelList} from '../partLists/backpanelList'
import {MetrumTabelarList} from '../partLists/tabelarList'
import {MetrumDoorList} from '../partLists/doorList'
import {MetrumClothingRailList} from '../partLists/clothingRailList'
import {MetrumBottomBoardList} from '../partLists/bottomBoardList'

export const MetrumPriceList = () => {
  let [unused, setConfig] = useState({} as IMetrumConfig)
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  const materials = Object.keys(shelfMaterials)
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof shelfMaterials))

  const ids = useMemo(() => ['metrum-9', 'metrum-6', 'metrum-8', 'metrum-1', 'metrum-11', 'metrum-7', 'metrum-10', 'metrum-4', 'metrum-5'], [])
  let configList: {id: string; config: IMetrumConfig}[] = useMemo(() => [], [])
  let priceListMetrum: number[][][] = useMemo(() => [], [])

  useLayoutEffect(() => {
    const fetchConfig = async (uri: string) => {
      const product = await fetch(`${API_DOMAIN}/product/${uri}`)
        .then((r) => r.json())
        .then((data) => {
          const config1 = JSON.parse(data.configuration)
          setConfig(config1)
          configList.push({id: uri, config: config1})
          return {config1}
        })
        .catch((err) => console.log(err))
      return product
    }
    ids.forEach((id) => fetchConfig(id))
  }, [])

  useLayoutEffect(() => {
    if (configList.length === ids.length) {
      ids.forEach((id, j) => {
        const foundConfig = configList.find((el) => el.id === id)
        priceListMetrum.push([])
        if (foundConfig) {
          const config = foundConfig.config
          materials.forEach((material, i) => {
            const configModified = {
              ...config,
              main: {...config.main, materialID: material as keyof typeof shelfMaterials},
              drawers: {...config.drawers, materialID: material as keyof typeof frontMaterials},
              doors: {...config.doors, materialID: material as keyof typeof frontMaterials},
            }
            priceListMetrum[j].push([])
            const partList = getMetrumPartList({...configModified, main: {...configModified.main, materialID: material as keyof typeof shelfMaterials}})
            const drawerList = MetrumDrawerList(
              configModified.drawers.list,
              configModified.backpanels,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.drawers.materialID
            )
            const footList = MetrumFootList(configModified.columns.list, configModified.feet.id, configModified.main.depth, material as keyof typeof shelfMaterials)
            const clothingRailList = MetrumClothingRailList(
              configModified.clothingRails.list,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials
            )
            priceListMetrum[j][i].push(MetrumPrice(localFactor.factor, treatmentID, partList, footList, drawerList, clothingRailList).props.children)
          })
        }
      })
    }
  }, [localFactor.factor, materials, priceListMetrum, configList, ids])

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>METRUM</h1>
        {priceListMetrum.length === ids.length &&
          ids.map((id, i) => (
            <Fragment key={i}>
              <h2>{id}</h2>
              <PriceTable rows={materialNames} columns={['']} prices={priceListMetrum[i]} />
            </Fragment>
          ))}
      </>
    </>
  )
}

const getMetrumPartList = (configs: IMetrumConfig) => {
  const boardList = MetrumBoardList(
    configs.columns.list,
    configs.main.height,
    configs.main.depth,
    configs.main.materialID,
    configs.main.grid,
    configs.boards.list,
    configs.doors.list,
    configs.drawers.list,
    configs.backpanels.materialID
  )
  const columnList = MetrumColumnList(configs.columns.list, configs.main.height, configs.main.depth, configs.main.grid, configs.main.materialID, configs.doors.list)

  const tabelarList = MetrumTabelarList(
    configs.tabelars.list,
    configs.main.depth,
    configs.main.height,
    configs.columns.list,
    configs.main.grid,
    configs.doors.list,
    configs.main.materialID,
    configs.backpanels.materialID
  )
  const backpanelList = MetrumBackpanelList(configs.columns.list, configs.main.height, configs.main.materialID, configs.backpanels.materialID)
  const doorList = MetrumDoorList(configs.doors.list, configs.main.depth, configs.main.height, configs.columns.list, configs.main.grid, configs.main.materialID, configs.doors.materialID)
  const bottomBoardList = MetrumBottomBoardList(configs.columns.list, configs.main.depth, configs.main.materialID)

  const partList = [...boardList, ...columnList, ...backpanelList, ...tabelarList, ...doorList, ...bottomBoardList]
  return partList
}
