import logo from '../../common/static/sanktjohanser_schriftzug.svg'
import React from 'react'

export function Logo() {
  return (
    <div className="logo-box">
      <a href="https://www.sanktjohanser.net">
        <img src={logo} alt="Sanktjohanser" />
      </a>
    </div>
  )
}
