import {materialModel} from 'utils/datamodels'
import {backpanelMaterials, slidingDoorMaterials, drawerFrontMaterials, shelfMaterials, flapMaterials, shelfTyp, footModel} from './datamodels'

const initialRows = [
  {
    height: 0.338,
  },
  {
    height: 0.338,
  },
]

export const initialConfig = {
  main: {
    grid: 5,
    width: 2.5,
    materialID: 'm12MW' as keyof typeof shelfMaterials,
    depth: 0.33,
    shelfTyp: 1 as keyof typeof shelfTyp,
    treatmentID: 0,
  },
  backpanels: {
    list: [
      {pos: {x: 0, y: 0}, cover: 'compartment' as 'full' | 'compartment', face: 'back' as 'front' | 'back'},
      {pos: {x: 4, y: 1}, cover: 'compartment' as 'full' | 'compartment', face: 'back' as 'front' | 'back'},
    ],
    materialID: 'm12MW' as keyof typeof backpanelMaterials,
  },

  rows: {
    list: initialRows,
  },

  slidingDoors: {
    materialID: 'm12MW' as keyof typeof slidingDoorMaterials,
    list: [
      {
        pos: {x: 4, y: 0},
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 0, y: 1},
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 3, y: 1},
        face: 'front' as 'front' | 'back',
      },
    ],
  },

  drawers: {
    materialID: 'm12MW' as keyof typeof drawerFrontMaterials,
    list: [
      {
        pos: {x: 1, y: 0},
        size: {y: 1},
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  flaps: {
    materialID: 'm12MW' as keyof typeof flapMaterials,
    list: [
      {
        pos: {x: 0, y: 0},
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  tabelars: {
    list: [] as {pos: {x: number; y: number}}[],
  },
  feet: {
    height: 0.023,
    id: 's23' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
}

export type ITekturConfig = typeof initialConfig
