export function PriceTable(props: {rows: string[] | number[]; columns: string[] | number[]; prices: number[][]}) {
  const {prices, rows, columns} = props
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          {columns.map((column, i) => (
            <th key={i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {prices.map((material, k) => (
          <tr key={k}>
            <td width={180}>{rows[k]}</td>
            {material.map((price, i) => (
              <td key={i} width={60} align="center">
                {price}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
