import {useStore} from 'products/metrum/store/store'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/metrum/store/datamodels'
import {isDoorInFront} from '../utils/metrumHelper'
import {IMetrumConfig} from '../store/initialConfig'

export const ConnectedMetrumTabelarList = () => {
  const [tabelarList, depth, shelfHeight, columns, grid, doors, materialID, backpanelMaterialID] = useStore((store) => [
    store.config.tabelars.list,
    store.config.main.depth,
    store.config.main.height,
    store.config.columns.list,
    store.config.main.grid,
    store.config.doors.list,
    store.config.main.materialID,
    store.config.backpanels.materialID,
  ])
  return MetrumTabelarList(tabelarList, depth, shelfHeight, columns, grid, doors, materialID, backpanelMaterialID)
}

export const MetrumTabelarList = (
  tabelarList: IMetrumConfig['tabelars']['list'],
  depth: IMetrumConfig['main']['depth'],
  shelfHeight: IMetrumConfig['main']['height'],
  columns: IMetrumConfig['columns']['list'],
  grid: IMetrumConfig['main']['grid'],
  doors: IMetrumConfig['doors']['list'],
  materialID: IMetrumConfig['main']['materialID'],
  backpanelMaterialID: IMetrumConfig['backpanels']['materialID']
) => {
  const thickness = shelfMaterials[materialID].thickness
  const maxCols = grid * (Math.round((shelfHeight - thickness) / (grid * 0.065)) + 1)
  const maxColGrid = (shelfHeight - thickness) / maxCols
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness

  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []
  tabelarList.forEach((colList, i) => {
    if (colList.pos.x < columns.length) {
      const xPos = columns.slice(0, colList.pos.x).reduce((pv, cv) => pv + cv.width + thickness, thickness)
      colList.pos.y.forEach((yIndex, j) => {
        if (yIndex < maxCols) {
          const yPos = yIndex * maxColGrid
          const zPos = 0.001 + backpanelThickness
          const width = columns[colList.pos.x].width
          const position = {x: colList.pos.x, y: yIndex / (maxCols / grid)}
          const isInset = isDoorInFront(position, doors)
          const adjustedDepth = depth - zPos - (isInset ? regalModel.frontInset : 0)
          tabelars.push({key: i + '.' + yIndex, xPos: xPos, yPos: yPos, zPos: zPos, length: width, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'tabelar'})
        }
      })
    }
  })

  return tabelars
}
