import {useStore} from 'products/euklid/store/store'
import {frameModel, shapeModel, tableMaterials} from '../store/datamodels'

export type IEuklidBoardList = {
  key: number | string
  xPos: number
  yPos: number
  zPos: number
  length: number
  depth: number
  thickness: number
  rotation?: [number, number, number]
  translate?: [number, number, number]
  material: keyof typeof tableMaterials
  shape?: 'round' | 'rectangle'
  type: string
}[]

export const ConnectedBoardList = () => {
  const [size, height, materialID, frameID, shapeID] = useStore((store) => [
    store.config.main.size,
    store.config.main.height,
    store.config.main.materialID,
    store.config.main.frameID,
    store.config.main.shapeID,
  ])
  return EuklidBoardList(size, height, materialID, frameID, shapeID)
}

export const EuklidBoardList = (size: number, height: number, materialID: keyof typeof tableMaterials, frameID: keyof typeof frameModel, shapeID: keyof typeof shapeModel) => {
  const thickness = tableMaterials[materialID].thickness
  const triangleLength = shapeModel[shapeID].baseSize + ((size - 0.4) / 0.1) * shapeModel[shapeID].sizeStep
  const triangleHeight = (triangleLength * Math.sqrt(3)) / 2
  const radiusCircumcircle = (triangleLength * Math.sqrt(3)) / 3
  const crossbarDepth = height <= 0.55 ? 0.125 : 0.18
  const xSize = size * shapeModel[shapeID].sizeFactor

  const boards: IEuklidBoardList = []

  boards.push({
    key: 'tableTop',
    xPos: 0,
    yPos: height - thickness,
    zPos: 0,
    length: size,
    depth: size,
    thickness: thickness,
    translate: [0.5, 0.5, 0.5],
    material: materialID,
    shape: shapeID === 'R' ? 'round' : 'rectangle',
    type: 'tableTop',
  })

  frameID === 'T' &&
    boards.push({
      key: 'foot',
      xPos: size / 2 - thickness / 2,
      yPos: 0,
      zPos: size / 2 - radiusCircumcircle,
      length: height - thickness,
      depth: triangleHeight - thickness / 2,
      thickness: thickness,
      rotation: [0, 0, -0.5],
      translate: [-0.5, 0.5, 0.5],
      material: materialID,
      type: 'foot',
    })
  frameID === 'T' &&
    boards.push({
      key: 'crossbar',
      xPos: size / 2 - triangleLength / 2,
      yPos: 0,
      zPos: size / 2 - radiusCircumcircle + triangleHeight - thickness / 2,
      length: triangleLength,
      depth: crossbarDepth,
      thickness: thickness,
      rotation: [-0.5, 0, 0],
      translate: [0.5, -0.5, 0.5],
      material: materialID,
      type: 'crossbar',
    })

  frameID === 'L' &&
    boards.push({
      key: 'foot',
      xPos: size / 2 + radiusCircumcircle - triangleHeight + thickness / 2,
      yPos: 0,
      zPos: size / 2 - triangleLength / 2,
      length: height - thickness,
      depth: triangleLength,
      thickness: thickness,
      rotation: [0, 0, -0.5],
      translate: [-0.5, -0.5, 0.5],
      material: materialID,
      type: 'foot',
    })
  frameID === 'L' &&
    boards.push({
      key: 'crossbar',
      xPos: size / 2 + radiusCircumcircle,
      yPos: 0,
      zPos: size / 2 - thickness / 2,
      length: triangleHeight - thickness / 2,
      depth: crossbarDepth,
      thickness: thickness,
      rotation: [-0.5, 0, 0],
      translate: [-0.5, -0.5, 0.5],
      material: materialID,
      type: 'crossbar',
    })
  frameID === 'X' &&
    boards.push({
      key: 'foot',
      xPos: size / 2 - thickness / 2,
      yPos: 0,
      zPos: size / 2 - xSize / 2,
      length: height - thickness,
      depth: xSize,
      thickness: thickness,
      rotation: [0, 0, -0.5],
      translate: [-0.5, 0.5, 0.5],
      material: materialID,
      type: 'foot',
    })
  frameID === 'X' &&
    boards.push({
      key: 'crossbar',
      xPos: size / 2 - xSize / 2,
      yPos: 0,
      zPos: size / 2 - thickness / 2,
      length: xSize / 2 - thickness / 2,
      depth: crossbarDepth,
      thickness: thickness,
      rotation: [-0.5, 0, 0],
      translate: [0.5, -0.5, 0.5],
      material: materialID,
      type: 'crossbar',
    })
  frameID === 'X' &&
    boards.push({
      key: 'crossbar2',
      xPos: size / 2 + thickness / 2,
      yPos: 0,
      zPos: size / 2 - thickness / 2,
      length: xSize / 2 - thickness / 2,
      depth: crossbarDepth,
      thickness: thickness,
      rotation: [-0.5, 0, 0],
      translate: [0.5, -0.5, 0.5],
      material: materialID,
      type: 'crossbar',
    })

  return boards
}
