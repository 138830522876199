import {useStore} from 'products/unisono/store/store'
import {bedMaterials, bedModel} from '../store/datamodels'

export const ConnectedUnisonoBoardList = () => {
  const [length, width, sideBoardWidth, footBoardWidth, materialID, frameHeight, headBoardID, headBoardHeight] = useStore((store) => [
    store.config.main.length,
    store.config.main.width,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
    store.config.main.materialID,
    store.config.main.frameHeight,
    store.config.main.headBoard.id,
    store.config.main.headBoard.height,
  ])
  return UnisonoBoardList(length, width, sideBoardWidth, footBoardWidth, materialID, frameHeight, headBoardID, headBoardHeight)
}

export const UnisonoBoardList = (
  length: number,
  width: number,
  sideBoardWidth: number,
  footBoardWidth: number,
  materialID: keyof typeof bedMaterials,
  frameHeight: number,
  headBoardID: number,
  headBoardHeight: number
) => {
  const headBoard = headBoardID !== 0
  const thickness = bedMaterials[materialID].thickness

  const boards: {
    key: number | string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    rotation?: [number, number, number]
    translate?: [number, number, number]
    material: keyof typeof bedMaterials
    type: string
  }[] = []

  boards.push({
    key: 'sideRight',
    xPos: footBoardWidth,
    yPos: 0,
    zPos: sideBoardWidth + width + bedModel.nominalThickness,
    length: length,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, 0],
    translate: [0.5, 0.5, 0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'sideBoardRight',
    xPos: footBoardWidth,
    yPos: frameHeight - thickness,
    zPos: sideBoardWidth + width,
    length: length,
    depth: sideBoardWidth,
    thickness: thickness,
    translate: [0.5, 0.5, 0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'sideLeft',
    xPos: footBoardWidth,
    yPos: 0,
    zPos: sideBoardWidth - bedModel.nominalThickness,
    length: length,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, 0],
    translate: [0.5, -0.5, 0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'sideBoardLeft',
    xPos: footBoardWidth,
    yPos: frameHeight - thickness,
    zPos: sideBoardWidth,
    length: length,
    depth: sideBoardWidth,
    thickness: thickness,
    translate: [0.5, 0.5, -0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'footSide',
    xPos: footBoardWidth,
    yPos: 0,
    zPos: sideBoardWidth - bedModel.nominalThickness,
    length: width + 2 * bedModel.nominalThickness,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    translate: [0.5, -0.5, 0.5],
    material: materialID,
    type: 'footBoard',
  })
  boards.push({
    key: 'footBoard',
    xPos: 0,
    yPos: frameHeight - thickness,
    zPos: 0,
    length: width + 2 * sideBoardWidth,
    depth: footBoardWidth,
    thickness: thickness,
    rotation: [-0.5, 0.5, -0.5],
    translate: [0.5, -0.5, 0.5],
    material: materialID,
    type: 'footBoard',
  })
  boards.push({
    key: 4,
    xPos: footBoardWidth + length,
    yPos: 0,
    zPos: sideBoardWidth - bedModel.nominalThickness,
    length: width + 2 * bedModel.nominalThickness,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    material: materialID,
    type: 'headBoard',
  })
  headBoard &&
    boards.push({
      key: 5,
      xPos: footBoardWidth + length,
      yPos: frameHeight - thickness,
      zPos: 0,
      length: width + 2 * sideBoardWidth,
      depth: headBoardHeight + thickness,
      thickness: thickness,
      rotation: [-0.5, 0, -0.5],
      material: materialID,
      type: 'headBoard',
    })
  !headBoard &&
    boards.push({
      key: 6,
      xPos: footBoardWidth + length,
      yPos: frameHeight - thickness,
      zPos: 0,
      length: width + 2 * sideBoardWidth,
      depth: footBoardWidth,
      thickness: thickness,
      rotation: [-0.5, 0.5, -0.5],
      translate: [0.5, -0.5, 0.5],
      material: materialID,
      type: 'headBoard',
    })
  return boards
}
