import {ISetProduce, IStore} from '../store'

export interface IBoardSlice {
  boards: {
    setEdit: (edit: boolean) => void
    delete: () => void
    create: (height: number) => void
    setHeight: (height: number, rowNum: number) => void
  }
}

export const createBoardSlice = (setProduce: ISetProduce<IStore>) => {
  const boards = {
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.boards.edit = edit
      }),
    create: (height: number) =>
      setProduce((S: IStore) => {
        const newBoard = {height: height}
        const nrBoards = S.config.rows.list.length
        const newColumns = []
        if (S.config.columns.list.length <= nrBoards) {
          for (let i = 1; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && newColumns.push(i)
          }
          S.config.columns.list.push(newColumns)
        }
        if (Math.max(...S.config.columns.list[nrBoards]) + 2 < S.config.main.grid) {
          for (let i = Math.max(...S.config.columns.list[nrBoards], 0) + 2; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && S.config.columns.list[nrBoards].push(i)
          }
        }
        const noBackPanel = !S.config.backpanels.list.find((backpanel) => backpanel.pos.y === nrBoards && backpanel.pos.x < S.config.main.grid)
        noBackPanel && S.config.backpanels.list.push({pos: {x: 0, y: nrBoards}, cover: 'compartment', face: 'back'})

        S.config.rows.list.push(newBoard)
      }),
    delete: () =>
      setProduce((store: IStore) => {
        store.config.rows.list.pop()
      }),
    setHeight: (height: number, rowNum: number) =>
      setProduce((store: IStore) => {
        store.config.rows.list[rowNum].height = height
      }),
  }
  return {
    boards,
  }
}
