import {Fragment, forwardRef} from 'react'
import * as THREE from 'three'
import {IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from './Materials'

export interface ISlattedIntegrated {
  size: IVector
  xPos: number
  yPos: number
  zPos: number
  holePosition?: [number, number]
  nrOfSegments?: number
}

export const SlattedIntegrated = forwardRef((props: ISlattedIntegrated, ref: any) => {
  const {size, xPos, yPos, zPos, holePosition = [0.2, 0.2], nrOfSegments = 10} = props
  const materialObject = MaterialSingleton.getMaterials()
  const materialMPXBirch = [materialObject['1']?.big, materialObject['1']?.big]

  const extrudeMaterial = [materialMPXBirch[1][2], materialMPXBirch[1][0]]

  const widthOfSegment = (size[0] - 2 * holePosition[0]) / nrOfSegments

  var shape = new THREE.Shape()
  shape.moveTo(-size[2] / 2, 0)
  shape.lineTo(size[2] / 2, 0)
  shape.lineTo(size[2] / 2, holePosition[0])
  shape.lineTo(size[2] / 2 - holePosition[1] + 0.014, holePosition[0])
  shape.arc(-0.014, 0, 0.014, 0, (190.29 / 180) * Math.PI, true)
  shape.lineTo(-size[2] / 2 + holePosition[1] + 0.014, holePosition[0])
  shape.arc(-0.014, 0, 0.014, (10.29 / 180) * Math.PI, Math.PI, true)
  shape.lineTo(-size[2] / 2, holePosition[0])
  shape.lineTo(-size[2] / 2, 0)

  var shape2 = new THREE.Shape()
  shape2.moveTo(-size[2] / 2, 0)
  shape2.lineTo(-size[2] / 2 + holePosition[1] - 0.014, 0)
  shape2.arc(0.014, 0, 0.014, Math.PI, (10.29 / 180) * Math.PI, true)
  shape2.lineTo(size[2] / 2 - holePosition[1] - 0.014, 0)
  shape2.arc(0.014, 0, 0.014, (169.71 / 180) * Math.PI, 0, true)
  shape2.lineTo(size[2] / 2, 0)
  shape2.lineTo(size[2] / 2, widthOfSegment)
  shape2.lineTo(size[2] / 2 - holePosition[1] + 0.014, widthOfSegment)
  shape2.arc(-0.014, 0, 0.014, 0, (190.29 / 180) * Math.PI, true)
  shape2.lineTo(-size[2] / 2 + holePosition[1] + 0.014, widthOfSegment)
  shape2.arc(-0.014, 0, 0.014, (10.29 / 180) * Math.PI, Math.PI, true)
  shape2.lineTo(-size[2] / 2, widthOfSegment)
  shape2.lineTo(-size[2] / 2, 0)

  var extrudeSettings = {
    steps: 1,
    depth: 0.009,
    bevelEnabled: false,
  }

  return (
    <group position={[xPos, yPos, zPos]}>
      <mesh ref={ref} key={'s'} position={[0, 0.009, size[2] / 2]} material={extrudeMaterial} rotation={[0.5 * Math.PI, 0, -0.5 * Math.PI]}>
        <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} />
      </mesh>
      {[...Array(nrOfSegments)].map((_, i) => {
        return (
          <Fragment key={i}>
            <mesh ref={ref} key={i + 'm'} position={[holePosition[0] + widthOfSegment * i, 0.009, size[2] / 2]} material={extrudeMaterial} rotation={[0.5 * Math.PI, 0, -0.5 * Math.PI]}>
              <extrudeGeometry attach="geometry" args={[shape2, extrudeSettings]} />
            </mesh>
          </Fragment>
        )
      })}
      <mesh ref={ref} key={'e'} position={[size[0], 0.009, size[2] / 2]} material={extrudeMaterial} rotation={[0.5 * Math.PI, 0, 0.5 * Math.PI]}>
        <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} />
      </mesh>
    </group>
  )
})
