import {Selector} from 'common/threeComponents/FiberBoard'
import {shelfMaterials} from 'products/metrum/store/datamodels'
import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'

export const ConnectedClothingRailSelectors = () => {
  const visible = useStore((store) => store.view.clothingRails.edit)
  const [columns, boards, depth, height, grid] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid],
    shallow
  )
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const toggleClothingRail = useStore((store) => store.clothingRails.toggleClothingRail)
  const gridsize = (height - thickness) / grid
  let startPos = thickness

  return (
    <>
      {visible &&
        columns.map((el, x) => {
          startPos += el.width + thickness
          const visibleBoards = [...boards[x], grid]
          return visibleBoards.map((yPos, y) => {
            return (
              <Selector
                key={x + '-' + y}
                size={[el.width, 0.03, 0.03]}
                onClick={() => toggleClothingRail(x, yPos)}
                xPos={startPos - (el.width + thickness)}
                yPos={yPos * gridsize - 0.0475 - 0.015}
                zPos={depth / 2}
              />
            )
          })
        })}
    </>
  )
}
