import {useStore} from 'products/analog/store/store'
import {shallow} from 'zustand/shallow'
import {blueprint, shelfMaterialModel, drawerMaterials, footModel, backpanelMaterials} from 'products/analog/store/datamodels'
import {materialModel, drawerLengths, drawerSideInset} from 'utils/datamodels'
import {IBackpanel, IDrawer, IRow} from '..'
import {getDrawerParts} from 'utils/helpers'
import {IDrawer as IDrawerFC} from 'common/threeComponents/DrawerFCNew'

export const ConnectedAnalogDrawerList = () => {
  const drawerList = useStore((store) => store.drawers.clearDrawers(), shallow)
  const [backpanels, depth, shelfWidth, columns, boards, grid, shelfMaterialID, drawerMaterial, feetID] = useStore(
    (store) => [
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.width,
      store.rows.clearColumns(),
      store.config.rows.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.drawers.materialID,
      store.config.feet.id,
    ],
    shallow
  )
  return AnalogDrawerList(drawerList, backpanels, depth, shelfWidth, columns, boards, grid, shelfMaterialID, drawerMaterial, feetID)
}

export const AnalogDrawerList = (
  drawerList: IDrawer[],
  backpanels: {list: IBackpanel[]; materialID: keyof typeof backpanelMaterials},
  depth: number,
  shelfWidth: number,
  columns: any[][],
  boards: IRow[],
  grid: number,
  shelfMaterialID: keyof typeof shelfMaterialModel,
  drawerMaterial: keyof typeof drawerMaterials,
  feetID: keyof typeof footModel
) => {
  const bodyMaterialID = shelfMaterialModel[shelfMaterialID].drawerInnerMaterial as keyof typeof materialModel
  const thickness = shelfMaterialModel[shelfMaterialID].thickness
  const gridSize = (shelfWidth - thickness) / grid
  const wallMounted = feetID === 'wallMounted'
  const shadowJoint = blueprint.shadowJoint
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const frontThickness = drawerMaterials[drawerMaterial].thickness
  const bodyThickness = materialModel[bodyMaterialID].thickness
  const leftInset = drawerSideInset - bodyThickness - blueprint.shadowJoint
  const backpanelInset = blueprint.backpanelInset[wallMounted ? 'wallMounted' : 'standard']
  const drawers: IDrawerFC[] = []

  drawerList.forEach((drawer, j) => {
    const yPosIndex = Math.floor(drawer.pos.y)
    const height = drawer.size.y * (boards[yPosIndex].height - shadowJoint) - shadowJoint
    const fullBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.y === yPosIndex
      })?.cover === 'full'
    const isBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.x === drawer.pos.x && obj.pos.y === yPosIndex
      }) || fullBackpanel
    const backInset = isBackpanel ? (backpanelThickness < 0.016 ? backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    const insideFront = drawerMaterials[drawerMaterial].insideFront
    const maxDrawerDepth = depth - 0.001 - frontThickness - 0.013 - 0.008 - backInset - blueprint.frontInset
    const drawerDepth = Math.max(
      insideFront ? Math.max(...drawerLengths.filter((l) => l < maxDrawerDepth)) + frontThickness : -Infinity,
      Math.max(...drawerLengths.filter((l) => l + bodyThickness < maxDrawerDepth)) + frontThickness + bodyThickness
    )
    if (drawerDepth === -Infinity) return null
    let columnList = [grid]
    columns[yPosIndex].forEach((column) => {
      if (column > drawer.pos.x) return columnList.push(column)
    })
    const nextColumn = Math.min(...columnList)
    const multiplier = nextColumn - drawer.pos.x
    const width = multiplier * gridSize - 2 * shadowJoint - thickness
    const bodyHeight = Math.floor((height - 0.015 - 0.0175) * 200) / 200
    const topInset = height - bodyHeight - 0.015 + (shadowJoint - 0.003) / 2
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + thickness, thickness + shadowJoint) + (drawer.pos.y - yPosIndex) * (boards[yPosIndex].height - shadowJoint)
    const xPos = thickness + drawer.pos.x * gridSize + shadowJoint
    const bottomMaterialID = 'mdf8W' as keyof typeof materialModel
    const drawerParts = getDrawerParts({
      width: width,
      height: height,
      depth: drawerDepth,
      bodyHeight: bodyHeight,
      shelfInnerWidth: width + 2 * shadowJoint,
      frontMaterialID: drawerMaterial,
      bodyMaterialID: bodyMaterialID,
      bottomMaterialID: bottomMaterialID,
    })
    drawers.push({
      key: drawer.pos.x + '-' + drawer.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: depth - blueprint.frontInset,
      width: width,
      height: height,
      depth: drawerDepth,
      leftInset: leftInset,
      topInset: topInset,
      parts: drawerParts,
      type: 'drawer',
    })
  })

  return drawers
}
