import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.scss'
import {AnalogView} from './products/analog/views/AnalogView/AnalogView'
import {RegalView} from './products/dasregal/views/RegalView/RegalView'
import {DigitalView} from 'products/digital/views/DigitalView/DigitalView'
import {IkuView} from 'products/iku/views/IkuView/IkuView'
import {KubaturView} from 'products/kubatur/views/KubaturView/KubaturView'
import {MetrumView} from 'products/metrum/views/MetrumView/MetrumView'
import {TekturView} from './products/tektur/views/TekturView/TekturView'
import ApiFetch from 'utils/API/ApiFetch'
import CookieConsent, {Cookies, getCookieConsentValue} from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import {useEffect} from 'react'
import {OverView} from 'components/overView/OverView'
import {SliderView} from 'components/overView/SliderView'
import {UnisonoView} from 'products/unisono/views/UnisonoView/UnisonoView'
import {BezweiView} from 'products/bezwei/views/BezweiView/BezweiView'
import {DinadreiView} from 'products/dinadrei/views/DinadreiView/DinadreiView'
import {EuklidView} from 'products/euklid/views/EuklidView/EuklidView'
import {useCommonStore} from 'common/Store/CommonStore'

const App = () => {
  const handleAcceptCookie = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-0X89KDD5ET')
    }
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga')
    Cookies.remove('_gat')
    Cookies.remove('_gid')
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === 'true') {
      handleAcceptCookie()
    }
  }, [])

  const isSlider = window.location.pathname.startsWith('/slider')

  // add extended state Handler
  Extended()

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<OverView />} />
          <Route path="/analog/*" element={<AnalogView />} />
          <Route path="/bezwei/*" element={<BezweiView />} />
          <Route path="/dasregal/*" element={<RegalView />} />
          <Route path="/digital/*" element={<DigitalView />} />
          <Route path="/dinadrei/*" element={<DinadreiView />} />
          <Route path="/euklid/*" element={<EuklidView />} />
          <Route path="/iku/*" element={<IkuView />} />
          <Route path="/kubatur/*" element={<KubaturView />} />
          <Route path="/metrum/*" element={<MetrumView />} />
          <Route path="/tektur/*" element={<TekturView />} />
          <Route path="/unisono/*" element={<UnisonoView />} />
          <Route path="get/:id" element={<ApiFetch />} />
          <Route path="slider/*" element={<SliderView />} />
        </Routes>
      </BrowserRouter>
      {!isSlider && (
        <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie} buttonText={'Cookies erlauben'} declineButtonText={'Cookies ablehnen'}>
          Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.{' '}
          <a href="https://www.sanktjohanser.net/datenschutz/" target={'_blank'} rel="noreferrer" style={{color: '#ffffff'}}>
            Mehr erfahren
          </a>
        </CookieConsent>
      )}
    </div>
  )
}

export default App

// Event Handler for setting extended state
const Extended = () => {
  const setExtended = useCommonStore((state) => state.setExtended)

  let keys = {
    ctrl: false,
    alt: false,
    x: false,
    n: false,
  }

  window.addEventListener('keydown', (event) => {
    if (event.ctrlKey) {
      keys.ctrl = true
    }
    if (event.key === 'x') {
      keys.x = true
    }
    if (event.key === 'n') {
      keys.n = true
    }
    if (event.key === 'Alt') {
      keys.alt = true
    }
    if (keys.x && keys.ctrl && keys.alt) {
      setExtended(true)
    }
    if (keys.n && keys.ctrl && keys.alt) {
      setExtended(false)
    }
  })

  window.addEventListener('keyup', (event) => {
    if (event.ctrlKey) {
      keys.ctrl = false
    }
    if (event.key === 'x') {
      keys.x = false
    }
    if (event.key === 'Alt') {
      keys.alt = false
    }
    if (event.key === 'n') {
      keys.n = false
    }
  })

  return null
}
