import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider} from 'components/material_interfaces/materialInterfaces'
import {bedModel, bedMaterials} from 'products/dinadrei/store/datamodels'
import {useStore} from 'products/dinadrei/store/store'
import {materialTreatments, materialTypes} from 'utils/datamodels'
import {getMaterialName} from 'utils/helpers'

export function BedStructure() {
  const [bedMaterialID, frameMaterialID, treatmentID, frameHeight, width, length, headBoardHeight, footHeight] = useStore((store) => [
    store.config.main.materialID,
    store.config.main.frameMaterialID,
    store.config.main.treatmentID,
    store.config.main.frameHeight,
    store.config.main.width,
    store.config.main.length,
    store.config.main.headBoardHeight,
    store.config.main.footHeight,
  ])

  const setMaterialType = useStore((store) => store.setMaterialType)
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setFrameMaterialID = useStore((store) => store.setFrameMaterialID)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setFrameHeight = useStore((store) => store.setFrameHeight)
  const setLength = useStore((store) => store.setLength)
  const setWidth = useStore((store) => store.setWidth)
  const setHeadBoardHeight = useStore((store) => store.setHeadBoardHeight)
  const setFootHeight = useStore((store) => store.setFootHeight)
  const materialHasTreatment = bedMaterials[bedMaterialID].treatment

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const regalMaterialType = bedMaterials[useStore((store) => store.config.main.materialID)].type
  const materialTypeList = Object.values(bedMaterials).map((material) => material.type)
  const typeList = materialTypes.filter((type) => materialTypeList.includes(type.id))

  const materialOptions = [...Object.values(bedMaterials)].filter((material) => material.type === regalMaterialType)
  const frameMaterialOptions = [...Object.values(bedMaterials)]
    .filter((material) => material.id === bedMaterialID || material.id === 'm25SB' || material.id === 'm25SW')
    .map((material) => ({...material, name: getMaterialName(material.id as keyof typeof bedMaterials)}))
  const treatmentOptions = [...Object.values(materialTreatments)]

  const typeOptions = typeList.map((type) => {
    const thickness = bedMaterials[bedMaterialID].type === type.id ? bedMaterials[bedMaterialID].thickness : bedMaterials[bedMaterials[bedMaterialID].fallback as keyof typeof bedMaterials].thickness
    return {...type, name: type.name + ', ' + Math.round(thickness * 1000) + ' mm'}
  })

  return (
    <>
      <AccordionListSelector id="type" options={typeOptions} onClick={setMaterialType} title={'Werkstoff'} value={regalMaterialType} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        id="species"
        options={materialOptions}
        onClick={setShelfMaterialSpecies}
        title={'Holzart / Farbe'}
        value={bedMaterialID}
        expanded={expanded}
        handleChange={handleChange}
      />
      {materialHasTreatment && (
        <AccordionListSelector id="treatment" options={treatmentOptions} onClick={setMaterialTreatment} title={'Oberfläche'} value={treatmentID} expanded={expanded} handleChange={handleChange} />
      )}
      <AccordionSizeSlider
        options={[0.6, 0.7, 0.8, 0.9, 1, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2]}
        min={bedModel.minWidth * 1000}
        max={bedModel.maxWidth * 1000}
        onClick={setWidth}
        title={'Bettbreite'}
        id={'width'}
        value={width * 1000}
        clickRange={100}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[1.9, 2, 2.1, 2.2]}
        min={1.9 * 1000}
        max={2.2 * 1000}
        onClick={setLength}
        title={'Bettlänge'}
        id={'length'}
        value={length * 1000}
        clickRange={100}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.12, 0.14, 0.16, 0.18, 0.2, 0.22, 0.24]}
        min={bedModel.minFrameHeight * 1000}
        max={bedModel.maxFrameHeight * 1000}
        onClick={setFrameHeight}
        title={'Rahmenhöhe'}
        id={'height'}
        value={frameHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionListSelector
        id="frameMaterial"
        options={frameMaterialOptions}
        onClick={setFrameMaterialID}
        title={'Zargenmaterial'}
        value={frameMaterialID}
        expanded={expanded}
        handleChange={handleChange}
      />
      <AccordionSizeSlider
        options={[bedModel.standardHeadBoardHeight]}
        min={bedModel.minHeadBoardHeight * 1000}
        max={bedModel.maxHeadBoardHeight * 1000}
        onClick={setHeadBoardHeight}
        title={'Kopfteilhöhe'}
        id={'headBoardHeight'}
        value={headBoardHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={[0.11, 0.13, 0.15, 0.17, 0.19, 0.21]}
        min={bedModel.minFootHeight * 1000}
        max={bedModel.maxFootHeight * 1000}
        onClick={setFootHeight}
        title={'Fußhöhe'}
        id={'footHeight'}
        value={footHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
