import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {ConnectedMetrumBoardList} from '../../partLists/boardList'
import {shelfMaterials} from 'products/metrum/store/datamodels'

export const ConnectedBoards = () => {
  // const boardList = BoardList()
  const boardList = ConnectedMetrumBoardList()
  const materialID = boardList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2]
  return (
    <>
      {boardList.map((board) => {
        const size = [board.length, board.thickness, board.depth] as IVector
        return <Board {...board} size={size} material={material[Math.round(Math.random())]} />
      })}
    </>
  )
}
