import * as lookup from '../../store/datamodels'
import {useStore} from 'products/analog/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionListSelector, AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'
import {DoorIcon} from './DoorIcon'

export function SlidingDoors() {
  const doors = useStore((store) => store.view.doors)

  const [grid, doorMaterial] = useStore((store) => [store.config.main.grid, store.config.doors.materialID])
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const rows = useStore((store) => store.config.rows.list)

  const setVariant = useStore((store) => store.doors.setVariant)
  const setMaterialID = useStore((store) => store.doors.setMaterialID)
  const setEditSize = useStore((store) => store.doors.setEditSize)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(lookup.doorMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof lookup.doorMaterials)}
    })

  let editSize = 0
  const sizeOptions = [...Array(doors.variant + 1)]
    .map((_, yIndex) => {
      const y = yIndex + 1
      const maxXSize = Math.floor(grid / 2)
      return [...Array(maxXSize)].map((_, xIndex) => {
        const x: number = xIndex + 1
        if (x === doors.editSize.x && y === doors.editSize.y) {
          editSize = yIndex * maxXSize + xIndex
        }
        return {
          id: yIndex * maxXSize + xIndex,
          callbackValue: {x: x, y: y},
          name: x + ' x ' + y + (x === 1 && y === 1 ? ' Fach' : ' Fächer'),
          icon: <DoorIcon ySize={rows.length} maxXSize={maxXSize} x={x} y={y} />,
        }
      })
    })
    .flat()

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={doorMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector options={lookup.slidingDoorTypeModel} onClick={setVariant} title={'Typ'} value={doors.variant} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        options={sizeOptions}
        onClick={(id: number) => setEditSize(sizeOptions[id].callbackValue)}
        title={'Größe'}
        value={editSize}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
