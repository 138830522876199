import {materialModel, materialTreatments, w, localFactors} from './../../../utils/datamodels' // @Todo check with Matthias if we can use global workprices
import {ConnectedBoardList, IEuklidBoardList} from '../partLists/boardList'
import {price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from '../store/store'

export const ConnectedEuklidPrice = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)].factor
  const materialID = useStore((state) => state.config.main.materialID)
  const treatmentID = useStore((state) => state.config.main.treatmentID)
  const partList = [...ConnectedBoardList()]
  return <>{Math.round(Math.round(EuklidPrice(localFactor, materialID, treatmentID, partList) / 10) * 10)}</>
}

export const EuklidPrice = (localFactor: number, materialID: keyof typeof materialModel, treatmentID: number, partList: IEuklidBoardList) => {
  const hasTreatment = materialModel[materialID].treatment
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = hasTreatment ? treatment.setup : 0
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice)
    priceCNC += getCNCPrice(part)
    console.log(part.type, getMaterialPrice(part), getWorkPrice(part, treatmentPrice), getCNCPrice(part))
  })

  const price = localFactor * (w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceFeet))

  return price
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'tableTop':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [98]})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [8]})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true, sanding: true})
      break
  }
  return price
}
