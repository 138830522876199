export const initialView = {
  backpanels: {
    selectorGroup: 0,
    visible: true,
    variant: 0,
    edit: false,
  },
  boards: {
    edit: false,
  },
  columns: {
    edit: false,
  },
  doors: {
    edit: false,
    variant: 0,
    visible: true,
    size: 7,
  },
  drawers: {
    variant: 0.5,
    visible: true,
    grainDirection: 0,
    edit: false,
  },
  flaps: {
    edit: false,
    visible: true,
    size: {
      x: 1,
      y: 1,
    },
  },
  tabelars: {
    edit: false,
  },
  feet: {},
}

export type IView = typeof initialView
