import {Html} from '@react-three/drei'
import {CalculatePosition} from '@react-three/drei/web/Html'
import {useNavigate} from 'react-router-dom'
import * as THREE from 'three'
import './dimension_controls.scss'

interface IDimensionButton {
  position: [number, number, number]
  url: string
  linktext: string | number
  left?: number
  right?: number
  top?: number
  bottom?: number
}

export const DimensionButton = (props: IDimensionButton) => {
  const navigate = useNavigate()
  const {position, url, linktext, left, right, top, bottom} = props

  const v1 = new THREE.Vector3()
  const overrideCalculatePosition: CalculatePosition = (el, camera, size) => {
    const elPos = v1.setFromMatrixPosition(el.matrixWorld)
    const objectPos = new THREE.Vector3(elPos.x + position[0], elPos.y + position[1], position[2])
    objectPos.project(camera)
    const widthHalf = size.width / 2
    const heightHalf = size.height / 2
    const horizontal = []
    const vertical = []
    right && horizontal.push(size.width - right)
    left && horizontal.push(left)
    bottom && vertical.push(size.height - bottom)
    top && vertical.push(top)
    !left && !right && horizontal.push(objectPos.x * widthHalf + widthHalf)
    !bottom && !top && vertical.push(-(objectPos.y * heightHalf) + heightHalf)
    return [Math.min(...horizontal), Math.min(...vertical)]
  }

  return (
    <Html calculatePosition={overrideCalculatePosition}>
      <div className="dimension-button" onClick={() => navigate(url)}>
        {linktext} mm
      </div>
    </Html>
  )
}
