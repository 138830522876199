import {IStore, ISetProduce} from '../store'
import {GetState} from 'zustand'

export const createTabelarSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const tabelar = {
    toggleTabelar: (xPos: number, yPosIndex: number) => {
      setProduce((store: IStore) => {
        const tabelars = get().config.tabelars.list
        const newTabelars = tabelars.map((el: {pos: {x: number; y: number[]}}) => {
          if (el.pos.x === xPos) {
            if (el.pos.y.includes(yPosIndex)) {
              if (el.pos.y.length === 1) {
                return {pos: {x: xPos, y: []}}
              } else {
                return {pos: {x: xPos, y: el.pos.y.filter((y: number) => y !== yPosIndex)}}
              }
            } else {
              return {pos: {x: xPos, y: [...el.pos.y, yPosIndex]}}
            }
          } else {
            return el
          }
        })
        if (tabelars.findIndex((el: {pos: {x: number; y: number[]}}) => el.pos.x === xPos) === -1) {
          newTabelars.push({pos: {x: xPos, y: [yPosIndex]}})
        }
        store.config.tabelars.list = newTabelars.filter((el: {pos: {y: number[]}}) => el.pos.y.length > 0)
      })
    },

    setEditTabelars: (edit: boolean) => {
      setProduce((state) => {
        state.view.tabelars.edit = edit
      })
    },
  }

  return {
    tabelar,
  }
}
