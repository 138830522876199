import {useStore} from '../store/store'
import {materialModel, slattedModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'

export const ConnectedUnisonoSlattedList = () => {
  const [frameHeight, length, width, sideBoardWidth, foodBoardWidth, slattedID, slattedHeight, slattedInsetstore] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.length,
    store.config.main.width,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
    store.config.slatted.id,
    store.config.slatted.height,
    store.config.slatted.inset,
  ])
  return UnisonoSlattedList(frameHeight, length, width, sideBoardWidth, foodBoardWidth, slattedID, slattedHeight, slattedInsetstore)
}

export const UnisonoSlattedList = (
  frameHeight: number,
  length: number,
  width: number,
  sideBoardWidth: number,
  foodBoardWidth: number,
  slattedID: keyof typeof slattedModel,
  slattedHeight: number,
  slattedInsetstore: number
) => {
  const usableWidth = width
  const nrOfSlats = usableWidth > (slattedID === 'integrated' ? 1.2 : 1.4) ? 2 : 1
  const slattedWidth = slattedID === 'integrated' ? (nrOfSlats === 1 ? usableWidth + 0.018 : usableWidth / 2 - 0.001) : Math.floor((usableWidth / nrOfSlats) * 10) / 10
  const usableLength = length
  const slattedLength = slattedID === 'integrated' ? (nrOfSlats === 1 ? usableLength + 0.018 : usableLength - 0.02) : Math.floor(usableLength * 10) / 10
  const slattedInset = slattedInsetstore
  const xPos = foodBoardWidth + length / 2 - slattedLength / 2
  const middleBoardInBetween = nrOfSlats === 2 && usableWidth - 2 * slattedWidth > 0.04
  const widthOfSideBoards = Math.min(Math.floor(((usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0)) / 2) * 100) / 100, 0.04)
  const zPos1 = sideBoardWidth + widthOfSideBoards + (usableWidth - 2 * widthOfSideBoards - (middleBoardInBetween ? 0.02 : 0)) / nrOfSlats / 2 - slattedWidth / 2
  const zPos2 = sideBoardWidth + width - widthOfSideBoards - (usableWidth - 2 * widthOfSideBoards - (middleBoardInBetween ? 0.02 : 0)) / nrOfSlats / 2 - slattedWidth / 2
  const nrOfSegments = Math.floor((slattedLength - 2 * 0.2) / 0.15)

  const slattedList = [] as {
    key: string
    size: IVector
    xPos: number
    yPos: number
    zPos: number
    type: keyof typeof slattedModel
    holePosition?: [number, number]
    nrOfSegments?: number
    material: keyof typeof materialModel
  }[]

  slattedList.push({
    key: 'slatted1',
    size: [slattedLength, slattedHeight, slattedWidth],
    xPos: xPos,
    yPos: frameHeight - slattedInset,
    zPos: zPos1,
    type: slattedID,
    holePosition: [0.2, 0.15],
    nrOfSegments: nrOfSegments,
    material: 'm9Bi',
  })
  if (nrOfSlats === 2) {
    slattedList.push({
      key: 'slatted2',
      size: [slattedLength, slattedHeight, slattedWidth],
      xPos: xPos,
      yPos: frameHeight - slattedInset,
      zPos: zPos2,
      type: slattedID,
      holePosition: [0.2, 0.15],
      nrOfSegments: nrOfSegments,
      material: 'm9Bi',
    })
  }

  return slattedList
}
