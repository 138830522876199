import {DigitalPrice} from '../utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors, API_DOMAIN} from 'utils/datamodels'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'
import {baseUrl, drawerFrontMaterials, flapMaterials, shelfMaterials} from '../store/datamodels'
import {useState, useEffect, Fragment} from 'react'
import {DigitalBoardList} from '../partLists/boardList'
import {IDigitalConfig} from '../store/initialConfig'
import {DigitalDrawerList} from '../partLists/drawerList'
import {DigitalFootList} from '../partLists/footList'
import {useMemo} from 'react'
import {DigitalColumnList} from '../partLists/columnList'
import {DigitalBackpanelList} from '../partLists/backpanelList'
import {DigitalFlapList} from '../partLists/flapList'
import {clearConfig} from '../utils/helpers'

export const DigitalPriceList = () => {
  let [unused, setConfig] = useState({} as IDigitalConfig)
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const materials = Object.keys(shelfMaterials)
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof shelfMaterials))

  const ids = useMemo(() => ['digital-1', 'digital-8', 'digital-3', 'digital-6', 'digital-9', 'digital-4', 'digital-10', 'digital-5', 'digital-11'], [])
  let configList: {id: string; config: IDigitalConfig}[] = useMemo(() => [], [])
  let priceListDigital: number[][][] = useMemo(() => [], [])

  useEffect(() => {
    const fetchConfig = async (uri: string) => {
      const product = await fetch(`${API_DOMAIN}/product/${uri}`)
        .then((r) => r.json())
        .then((data) => {
          const config1 = JSON.parse(data.configuration)
          setConfig(config1)
          configList.push({id: uri, config: clearConfig(config1)})
          return {config1}
        })
        .catch((err) => console.log(err))
      return product
    }
    ids.forEach((id) => fetchConfig(id))
  }, [])

  useEffect(() => {
    if (configList.length === ids.length) {
      ids.forEach((id, j) => {
        const foundConfig = configList.find((el) => el.id === id)
        priceListDigital.push([])
        if (foundConfig) {
          const config = foundConfig.config
          materials.forEach((material, i) => {
            const drawerMaterial = material as keyof typeof drawerFrontMaterials
            const flapMaterial = material as keyof typeof flapMaterials
            const configModified = {...config, drawers: {...config.drawers, materialID: drawerMaterial}, flaps: {...config.flaps, materialID: flapMaterial}}
            priceListDigital[j].push([])
            const partList = getDigitalPartList({...configModified, main: {...configModified.main, materialID: material as keyof typeof shelfMaterials}})
            const drawerList = DigitalDrawerList(
              configModified.drawers.list,
              configModified.backpanels,
              configModified.main.depth,
              configModified.main.width,
              configModified.columns.list,
              configModified.rows.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.drawers.materialID
            )
            const footList = DigitalFootList(
              configModified.main.depth,
              configModified.columns.list,
              configModified.main.width,
              configModified.main.grid,
              configModified.feet.id,
              configModified.feet.height,
              material as keyof typeof shelfMaterials
            )
            priceListDigital[j][i].push(DigitalPrice(localFactor.factor, partList, drawerList, footList).props.children)
          })
        }
      })
    }
  }, [localFactor.factor, materials, priceListDigital, configList, ids])

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>DIGITAL</h1>
        {priceListDigital.length === ids.length &&
          ids.map((id, i) => (
            <Fragment key={i}>
              <h2>{id}</h2>
              <PriceTable rows={materialNames} columns={['']} prices={priceListDigital[i]} />
            </Fragment>
          ))}
      </>
    </>
  )
}

const getDigitalPartList = (configs: IDigitalConfig) => {
  const boardList = DigitalBoardList(configs.rows.list, configs.main.width, configs.main.depth, configs.main.materialID)
  const columnList = DigitalColumnList(configs.columns.list, configs.main.width, configs.main.depth, configs.main.materialID, configs.main.grid, configs.rows.list, configs.backpanels)
  const backpanelList = DigitalBackpanelList(
    configs.backpanels.list,
    configs.columns.list,
    configs.rows.list,
    configs.main.width,
    configs.main.materialID,
    configs.backpanels.materialID,
    configs.main.grid
  )
  const flapPartList = DigitalFlapList(
    configs.flaps.list,
    configs.backpanels,
    configs.main.depth,
    configs.main.width,
    configs.columns.list,
    configs.rows.list,
    configs.main.grid,
    configs.main.materialID,
    configs.flaps.materialID
  )
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]

  const partList = [...boardList, ...columnList, ...backpanelList, ...flapPartList]
  return partList
}
