import {useStore} from '../store/store'
import {shelfMaterials, regalModel, footModel} from 'products/dasregal/store/datamodels'
import {materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedDasregalFootList = () => {
  const [depth, columns, feetID, footHeight, materialID] = useStore(
    useShallow((store) => [store.config.main.depth, store.config.columns.list, store.config.feet.id, store.config.feet.height, store.config.main.materialID])
  )

  return DasregalFootList(depth, columns, feetID, footHeight, materialID)
}

export const DasregalFootList = (depth: number, columns: any[], feetID: keyof typeof footModel, footHeight: number, materialID: keyof typeof shelfMaterials) => {
  const thickness = shelfMaterials[materialID].thickness
  const footList = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel}[]
  const foot = footModel[feetID]
  const totalWidth = columns.reduce((acc, cur) => acc + cur.width + thickness, thickness)
  const borderMinWidth = columns[columns.length - 1].width < columns[0].width ? columns[columns.length - 1].width : columns[0].width
  const xInset = (borderMinWidth - thickness) / 2 <= 0.3 ? thickness + (borderMinWidth - thickness) / 2 : foot.xInset || 0
  const nrFeet = Math.ceil((totalWidth - 2 * xInset) / regalModel.maxFootSpan) + 1
  const footSizeX = foot.sizeXY !== undefined ? foot.sizeXY[0] : 0.015
  const footSizeY = foot.sizeXY !== undefined ? foot.sizeXY[1] : 0.015
  const footSize = [footSizeX, footHeight, footSizeY] as IVector
  switch (feetID) {
    case 'shv':
      footList.push({key: '0.0', pos: [thickness / 2, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      footList.push({key: '0.1', pos: [thickness / 2, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      ;[...Array(columns.length)].forEach((_, i) => {
        const xPos = columns.slice(0, i + 1).reduce((acc, el) => acc + el.width + thickness, thickness / 2)
        footList.push({key: i + 1 + '.0', pos: [xPos, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
        footList.push({key: i + 1 + '.1', pos: [xPos, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      })
      break
    case 's23':
    case 'castor43':
      footList.push({key: '0.0', pos: [foot.xInset || 0, 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
      footList.push({key: '0.1', pos: [foot.xInset || 0, 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      ;[...Array(columns.length - 1)].forEach((_, i) => {
        const xPos = columns.slice(0, i + 1).reduce((acc, el) => acc + el.width + thickness, thickness / 2)
        footList.push({key: i + 1 + '.0', pos: [xPos, 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
        footList.push({key: i + 1 + '.1', pos: [xPos, 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      })
      footList.push({key: columns.length + '.0', pos: [totalWidth - (foot.xInset || 0), 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
      footList.push({key: columns.length + '.1', pos: [totalWidth - (foot.xInset || 0), 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      break
    case 'rnd30':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (totalWidth - 2 * xInset)) / (nrFeet - 1)
        footList.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
        footList.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
      })
      break
    case 'rct16':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (totalWidth - 2 * xInset)) / (nrFeet - 1)
        footList.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
        footList.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
      })
      break
  }
  return footList
}
