import {useStore} from 'products/kubatur/store/store'
import {regalModel, shelfMaterials, backpanelMaterials, shelfTypes, frontMaterials} from 'products/kubatur/store/datamodels'
import {accHelper} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'
import {IKubaturConfig} from '../store/initialConfig'

export const ConnectedKubaturTabelarList = () => {
  const [tabelarList, depth, shelfHeight, columns, boards, subColumns, doors, grid, materialID, shelfTyp, backpanelMaterialID] = useStore(
    useShallow((store) => [
      store.config.tabelars.list,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.boards.list,
      store.config.subColumns.list,
      store.config.doors,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.main.shelfTyp,
      store.config.backpanels.materialID,
    ])
  )
  return KubaturTabelarList(tabelarList, depth, shelfHeight, columns, boards, subColumns, doors, grid, materialID, shelfTyp, backpanelMaterialID)
}

export const KubaturTabelarList = (
  tabelarList: IKubaturConfig['tabelars']['list'],
  depth: IKubaturConfig['main']['depth'],
  shelfHeight: IKubaturConfig['main']['height'],
  columns: IKubaturConfig['columns']['list'],
  boards: IKubaturConfig['boards']['list'],
  subColumns: IKubaturConfig['subColumns']['list'],
  doors: IKubaturConfig['doors'],
  grid: IKubaturConfig['main']['grid'],
  materialID: IKubaturConfig['main']['materialID'],
  shelfTyp: IKubaturConfig['main']['shelfTyp'],
  backpanelMaterialID: IKubaturConfig['backpanels']['materialID']
) => {
  const thickness = shelfMaterials[materialID].thickness
  const maxCols = grid * (Math.round((shelfHeight - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / (grid * 0.065)) + 1)
  const maxColGrid = (shelfHeight - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / maxCols
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const doorThickness = frontMaterials[doors.materialID].thickness
  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []
  tabelarList.forEach((colList, i) => {
    const xIndex = Math.floor(colList.pos.x)
    if (xIndex < columns.length) {
      const innerXPos = colList.pos.x - xIndex
      const xPos = columns.slice(0, xIndex).reduce((pv, cv) => pv + cv.width + thickness, thickness + (columns[xIndex].width + thickness) * innerXPos)
      const noDoorInFront = doors.list.filter((door) => Math.floor(door.pos.x) === Math.floor(colList.pos.x)).length === 0
      const extraDepth = shelfTyp === 2 && noDoorInFront ? regalModel.doorDistance + doorThickness : 0
      colList.pos.y.forEach((yIndex, j) => {
        if (yIndex < maxCols) {
          const posIndex = Math.floor(yIndex / (maxCols / grid))
          const subColumnList = accHelper.get_SubColumn_List({x: xIndex, y: posIndex}, columns, boards, subColumns)
          const nextSubColumn = Math.min(
            ...subColumnList.filter((obj) => {
              return obj > innerXPos
            })
          )
          const multiplier = nextSubColumn - innerXPos
          const yPos = yIndex * maxColGrid + shelfTypes[shelfTyp].bottomInset
          const zPos = backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness
          const width = (columns[xIndex].width + thickness) * multiplier - thickness
          const adjustedDepth = depth - zPos + extraDepth
          const isSubColumn = subColumnList.includes(innerXPos)
          isSubColumn && tabelars.push({key: i + '.' + yIndex, xPos: xPos, yPos: yPos, zPos: zPos, length: width, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'tabelar'})
        }
      })
    }
  })

  return tabelars
}
