import {FootList} from '../../../../components/material_interfaces/material_interfaces'
import * as lookup from '../../store/datamodels'
import {useStore} from '../../store/store'

export function FeetHeight() {
  const footID = useStore((store) => store.config.feet.id)
  const feetHeight = useStore((store) => store.config.feet.height)
  const shelfThickness = lookup.shelfMaterialModel[useStore((store) => store.config.main.materialID)].thickness
  const rows = useStore((store) => store.config.rows.list)
  const bodyHeight = rows.reduce((acc, el) => acc + el.height + shelfThickness, shelfThickness)
  const setFeetID = useStore((store) => store.feet.setID)
  const setFeetHeight = useStore((store) => store.feet.setHeight)

  return (
    <>
      {Object.keys(lookup.footModel).map((el, i) => {
        const id = el as keyof typeof lookup.footModel
        const foot = lookup.footModel[id]
        const maxShelfHeight = foot.maxShelfHeight || Infinity
        if (bodyHeight > maxShelfHeight) {
          return null
        }
        return (
          <FootList
            key={i}
            title={foot.name}
            val={el !== 'wallMounted' ? foot.heights : undefined}
            units="mm"
            callback={() => setFeetID(id)}
            setHeight={setFeetHeight}
            selected={footID === el}
            footHeight={feetHeight}
          />
        )
      })}
    </>
  )
}
