import {useBounds} from '@react-three/drei'
import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/dasregal/store/store'
import {useEffect} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'
import {regalModel} from '../../store/datamodels'

interface IColumnSelectors {
  list: {key: number; xPos: number; callback: () => void}[]
  material?: THREE.Material
  depth: number
  height: number
  thickness: number //TODO: why?
}

export const ColumnSelectors = (props: IColumnSelectors) => {
  // if ColumnSelectors are rendered, recalculate Bounds and adjust Camera
  const boundsApi = useBounds()
  useEffect(() => {
    // boundsApi.refresh().fit()
    // boundsApi?.refresh()
    return () => {
      // boundsApi?.refresh().fit()
    }
  }, [boundsApi])

  return (
    <>
      {props.list.map((el, i) => {
        // Matthias why added translate={{x: 0.5, y: -0.5, z: 0.5}} ?
        return <Selector key={el.key} rotation={[0, 0, 0.5]} size={[props.height, props.thickness, props.depth]} xPos={el.xPos} yPos={0} zPos={0} onClick={el.callback} />
      })}
    </>
  )
}

export const ConnectedColumnSelectors = () => {
  const [columns, depth, height, visible] = useStore((store) => [store.config.columns, store.config.main.depth, store.config.main.height, store.view.columns.edit], shallow)

  const thickness = regalModel.thickness //TODO: why do we need that?
  const totalWidth = columns.list.reduce((acc, col) => acc + col.width + thickness, thickness)
  const deleteColumn = useStore((store) => store.columns.delete)
  const addColumn = useStore((store) => store.columns.create)
  const list = [
    {key: columns.list.length, xPos: totalWidth, callback: deleteColumn},
    {key: columns.list.length + 1, xPos: totalWidth + regalModel.newColumWidth, callback: addColumn},
  ]
  if (columns.list.length < 2) list.shift()
  return <>{visible && <ColumnSelectors thickness={thickness} list={list} depth={depth} height={height} />}</>
}
