import {useStore} from 'products/kubatur/store/store'
import {shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'
import {materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {accHelper} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'
import {IKubaturConfig} from '../store/initialConfig'

export const ConnectedKubaturClothingRailList = () => {
  const [clothingRailList, depth, shelfHeight, columns, grid, materialID, boards, subColumns, shelfTyp] = useStore(
    useShallow((store) => [
      store.config.clothingRails.list,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.boards.list,
      store.config.subColumns.list,
      store.config.main.shelfTyp,
    ])
  )
  return KubaturClothingRailList(clothingRailList, depth, shelfHeight, columns, grid, materialID, boards, subColumns, shelfTyp)
}

export const KubaturClothingRailList = (
  clothingRailList: IKubaturConfig['clothingRails']['list'],
  depth: IKubaturConfig['main']['depth'],
  shelfHeight: IKubaturConfig['main']['height'],
  columns: IKubaturConfig['columns']['list'],
  grid: IKubaturConfig['main']['grid'],
  materialID: IKubaturConfig['main']['materialID'],
  boards: IKubaturConfig['boards']['list'],
  subColumns: IKubaturConfig['subColumns']['list'],
  shelfTyp: IKubaturConfig['main']['shelfTyp']
) => {
  const thickness = shelfMaterials[materialID].thickness
  const gridSize = (shelfHeight - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) / grid

  const clothingRails: {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: string}[] = []
  clothingRailList.forEach((clothingRail, i) => {
    const xIndex = Math.floor(clothingRail.pos.x)
    if (clothingRail.pos.x < columns.length && clothingRail.pos.y <= grid) {
      const innerXPos = clothingRail.pos.x - xIndex
      const xPos = columns.slice(0, xIndex).reduce((pv, cv) => pv + cv.width + thickness, thickness + (columns[xIndex].width + thickness) * innerXPos)
      const subColumnList = accHelper.get_SubColumn_List({x: xIndex, y: clothingRail.pos.y - 1}, columns, boards, subColumns)
      const nextSubColumn = Math.min(
        ...subColumnList.filter((obj) => {
          return obj > innerXPos
        })
      )
      const multiplier = nextSubColumn - innerXPos
      const yPos = clothingRail.pos.y * gridSize - 0.0475 + shelfTypes[shelfTyp].bottomInset
      const zPos = depth / 2
      const width = (columns[xIndex].width + thickness) * multiplier - thickness
      const isSubColumn = subColumnList.includes(innerXPos)
      const position = [xPos, yPos, zPos] as IVector
      const size = [0.015, 0.015, width] as IVector
      isSubColumn && clothingRails.push({key: clothingRail.pos.x + '.' + clothingRail.pos.y, pos: position, size: size, material: 's13Ei', type: 'clothingrail'})
    }
  })

  return clothingRails
}
