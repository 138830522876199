import {materialModel, materialTreatments, w, localFactors, footModel} from './../../../utils/datamodels' // @Todo check with Matthias if we can use global workprices
import {ConnectedIkuBoardList} from '../partLists/boardList'
import {price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from '../store/store'
import {ConnectedIkuFootList} from '../partLists/footList'
import {ConnectedIkuAccBoardList} from '../partLists/accBoardList'
import {ConnectedIkuSlattedList} from '../partLists/slattedList'

export const ConnectedIkuPrice = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)].factor
  const materialID = useStore((state) => state.config.main.materialID)
  const treatmentID = useStore((state) => state.config.main.treatmentID)
  const stackable = useStore((state) => state.config.main.stackable)
  const partList = getPartList()
  const footList = ConnectedIkuFootList()
  const slattedList = ConnectedIkuSlattedList()

  return IkuPrice(localFactor, materialID, treatmentID, stackable, partList, footList, slattedList)
}

export const IkuPrice = (localFactor: number, materialID: keyof typeof materialModel, treatmentID: number, stackable: boolean, boardList: any[], footList: any[], slattedList: any[]) => {
  const hasTreatment = materialModel[materialID].treatment
  const nrOfBeds = stackable ? 2 : 1
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = hasTreatment ? treatment.setup : 0
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  boardList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice)
    priceCNC += getCNCPrice(part)
    console.log(part, getMaterialPrice(part), getWorkPrice(part, treatmentPrice), getCNCPrice(part))
  })
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })
  const transformedSlattedList = slattedList.map((slatted) => ({...slatted, length: slatted.size[0], depth: slatted.size[2]}))
  const priceSlatted = IkuSlattedPrice(localFactor, nrOfBeds, transformedSlattedList)

  const price = Math.round(nrOfBeds * localFactor * 10 * Math.round((w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceFeet)) / 10)) + priceSlatted

  return <>{price}</>
}

const getPartList = () => {
  const partList = [...ConnectedIkuBoardList(), ...ConnectedIkuAccBoardList()]
  return partList
}

export const ConnectedIkuSlattedPrice = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)].factor
  const nrOfBeds = useStore((state) => (state.config.main.stackable ? 2 : 1))
  const slattedList = ConnectedIkuSlattedList().map((slatted) => ({...slatted, length: slatted.size[0], depth: slatted.size[2]}))

  return IkuSlattedPrice(localFactor, nrOfBeds, slattedList)
}

export const IkuSlattedPrice = (localFactor: number, nrOfBeds: number, slattedList: any[]) => {
  let priceSlatted = 0
  slattedList.forEach((part) => {
    priceSlatted += getMaterialPrice(part)
    priceSlatted += getWorkPrice(part, 0)
    priceSlatted += getCNCPrice(part)
  })
  const rrPrice = Math.round(nrOfBeds * localFactor * 10 * Math.round((w.Faktor * priceSlatted) / 10))
  return rrPrice
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'bedSide':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [10]})
      break
    case 'foodBoard':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
    case 'headBoard':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
    case 'middleBoard':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [10]})
      break
    case 'sideBoard':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [15]})
      break
    case 'own':
      price = 0
      break
    case 'standard':
      price = 76
      break
    case 'integrated':
      price = price_Material({length: 2.5, width: 1.25 / 1.2, materialID: part.material})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string; mitreLeft: boolean; mitreRight: boolean}, treatmentPrice: number) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'middleBoard':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: false, rest: [8 + +!!part.mitreLeft * 2.5 + +!!part.mitreRight * 2.5]})
      break
    case 'sideBoard':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: false, rest: [8 + +!!part.mitreLeft * 2.5 + +!!part.mitreRight * 2.5]})
      break
    case 'own':
      price = 0
      break
    case 'standard':
      price = 0
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [8 + +!!part.mitreLeft * 2.5 + +!!part.mitreRight * 2.5]})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'bedSide':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 5.4, nrDrills: 88, format: true, bevel: true, sanding: true})
      break
    case 'footBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 4.2, nrDrills: 30, format: true, bevel: true, sanding: true})
      break
    case 'headBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 4.2, nrDrills: 30, format: true, bevel: true, sanding: true})
      break
    case 'middleBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 4.2, nrDrills: 10, format: true, bevel: true, sanding: true})
      break
    case 'sideBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 5.2, nrDrills: 10, format: true, bevel: true, sanding: true})
      break
    case 'own':
      price = 0
      break
    case 'standard':
      price = 0
      break
    case 'integrated':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, nrGrooves: 4, format: true, bevel: true, sanding: true})
      break
    case 'footSupportBoard':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 2.2, nrDrills: 10, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true, sanding: true})
      break
  }
  return price
}
