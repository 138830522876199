import {ConnectedEuklidPrice} from 'products/euklid/utils/price'
import {useStore} from '../store/store'
import {localFactors, materialTreatments, materialTypes} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {tableMaterials, baseUrl, frameModel, shapeModel} from '../store/datamodels'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [size, height, tableMaterialID, treatmentID, frameID, shapeID, uri] = useStore((state) => [
    state.config.main.size,
    state.config.main.height,
    state.config.main.materialID,
    state.config.main.treatmentID,
    state.config.main.frameID,
    state.config.main.shapeID,
    state.uri,
  ])
  const tableMaterial = tableMaterials[tableMaterialID]
  const shelfMaterialName = tableMaterial.type === 0 ? materialTypes[tableMaterial.type].name + ' ' + tableMaterial.name : tableMaterial.name + ' ' + materialTypes[tableMaterial.type].name
  const shelfTreatment = materialTreatments[treatmentID].name
  const totalLength = size
  const totalWidth = size
  const title = 'Euklid'
  const fullHeight = Math.round(height * 1000)
  const heightText = fullHeight

  return (
    <div className="description">
      <div className="print_title">{title}</div>
      <div className="sub_title">
        <a href={baseUrl + '/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {shelfTreatment}
          <div className="sub_title">Abmessungen</div>
          <div>
            {Math.round(totalLength * 1000)} mm x {Math.round(totalWidth * 1000)} mm x {heightText} mm{' '}
          </div>
        </div>
        <div className="col">
          <div className="sub_title">Gestell</div>
          <div style={{display: 'flow-root'}}>
            <div className="left">{'Typ'}</div>
            <div className="right_up">{frameModel[frameID].short}</div>
          </div>

          <div></div>
          <div className="sub_title">Form</div>
          <div>{'Tischplatte ' + shapeModel[shapeID].name}</div>
          <div className="price_title">Preis </div>
          <div className="price">
            <ConnectedEuklidPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}
