import {forwardRef, useEffect, useRef} from 'react'
import * as THREE from 'three'
import {Euler, ExtrudeGeometry} from 'three'
import {IVector} from 'common/threeComponents/FiberBoard'
import {Plane} from '@react-three/drei'

export interface IMitreBoard {
  size: IVector
  xPos: number
  yPos: number
  zPos: number
  rotation?: IVector
  mitreLeft: boolean
  mitreRight: boolean
  material: THREE.Material | THREE.Material[]
}

export const MitreBoard = forwardRef((props: IMitreBoard, ref: any) => {
  const {size, xPos, yPos, zPos, mitreLeft, mitreRight} = props
  const rotation = props.rotation ? new Euler(props.rotation[0] * Math.PI, props.rotation[1] * Math.PI, props.rotation[2] * Math.PI) : new Euler(0, 0, 0)
  const leftMitre = mitreLeft ? size[1] / size[0] : 0
  const rightMitre = mitreRight ? size[1] / size[0] : 0
  var shape = new THREE.Shape()
  shape.moveTo(-0.5, -0.5)
  shape.lineTo(0.5, -0.5)
  shape.lineTo(0.5 - rightMitre, 0.5)
  shape.lineTo(-0.5 + leftMitre, 0.5)
  shape.lineTo(-0.5, -0.5)

  var extrudeSettings = {
    steps: 1,
    depth: 1,
    bevelEnabled: false,
  }
  //@ts-ignore
  const extrudeMaterial = [props.material[4], props.material[2], props.material[0]]

  const box = useRef<ExtrudeGeometry>(null!)

  useEffect(() => {
    box.current.translate(0.5, 0.5, 0)
  })

  return (
    <group name="columnGroup" ref={ref} rotation={rotation} position={[xPos, yPos, zPos]}>
      <mesh ref={ref} material={extrudeMaterial} scale={[size[0], size[1], size[2]]}>
        <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} ref={box} />
      </mesh>
      {!mitreLeft && <Plane args={[size[2], size[1]]} position={[-0.0001, size[1] / 2, size[2] / 2]} rotation={[0, -0.5 * Math.PI, 0]} material={extrudeMaterial[2]} />}
      {!mitreRight && <Plane args={[size[2], size[1]]} position={[size[0] + 0.0001, size[1] / 2, size[2] / 2]} rotation={[0, 0.5 * Math.PI, 0]} material={extrudeMaterial[2]} />}
    </group>
  )
})
