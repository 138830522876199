import './../overview.scss'
import bezwei_1 from 'common/static/overview/bezwei-1.jpg'
import bezwei_2 from 'common/static/overview/bezwei-2.jpg'
import bezwei_3 from 'common/static/overview/bezwei-3.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedBezweiOverView() {
  return <ProductOverView title={'bezwei'} products={bezwei} withTitle />
}

export const bezwei = [
  {img: <img className="overview-image" src={bezwei_1} alt="Bezwei" />, to: 'get/bezwei-1'},
  {img: <img className="overview-image" src={bezwei_2} alt="Bezwei" />, to: 'get/bezwei-2'},
  {img: <img className="overview-image" src={bezwei_3} alt="Bezwei" />, to: 'get/bezwei-3'},
]
