import {forwardRef, useEffect, useRef} from 'react'
import * as THREE from 'three'
import {ExtrudeGeometry} from 'three'
import {IVector, Board} from 'common/threeComponents/FiberBoard'

export interface IMetrumColumn {
  size: IVector
  xPos: number
  yPos: number
  zPos: number
  gapHeight: number[]
  material: THREE.Material | THREE.Material[]
}

export const MetrumColumn = forwardRef((props: IMetrumColumn, ref: any) => {
  const {size, gapHeight, xPos, yPos, zPos} = props
  var shape = new THREE.Shape()
  shape.moveTo(-0.5, -0.5)
  for (let x = 0; x < gapHeight.length; x++) {
    const gapStart = gapHeight[x] / size[0] - 0.15 / size[0] / 2
    const gapEnd = gapHeight[x] / size[0] + 0.15 / size[0] / 2
    const gapDepth = 0.025 / size[2]
    shape.lineTo(-0.5 + gapStart, -0.5)
    shape.lineTo(-0.5 + gapStart, -0.5 + gapDepth)
    shape.lineTo(-0.5 + gapEnd, -0.5 + gapDepth)
    shape.lineTo(-0.5 + gapEnd, -0.5)
  }
  shape.lineTo(0.5, -0.5)
  shape.lineTo(0.5, 0.5)
  shape.lineTo(-0.5, 0.5)
  shape.lineTo(-0.5, -0.5)

  var extrudeSettings = {
    steps: 1,
    depth: 1,
    bevelEnabled: false,
  }
  //@ts-ignore
  const extrudeMaterial = [props.material[2], props.material[0]]

  const box = useRef<ExtrudeGeometry>(null!)

  useEffect(() => {
    box.current.translate(0.5, -0.5, -1)
  })

  const gapMaterial = new THREE.MeshBasicMaterial({color: 0x000000, transparent: true})
  gapMaterial.opacity = 0.3

  return (
    <group name="columnGroup" ref={ref}>
      <mesh ref={ref} material={extrudeMaterial} position={[xPos, yPos, zPos]} scale={[size[0], size[2], size[1]]} rotation={[0, -Math.PI / 2, Math.PI / 2]}>
        <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} ref={box} />
      </mesh>
      {gapHeight.map((gap, index) => {
        return <Board size={[size[1], 0.15, 0.001]} xPos={xPos} yPos={gap - 0.075} zPos={size[2] - 0.024} material={gapMaterial} key={index} />
      })}
    </group>
  )
})
