import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {ConnectedUnisonoPrice} from 'products/unisono/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, bedModel, headBoardModel} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedUnisono} from '../../threeComponents/Unisono'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedUnisonoPrintView from 'products/unisono/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {UnisonoPriceList} from 'products/unisono/priceList/PriceList'

export const UnisonoView = () => {
  document.title = 'SANKTJOHANSER | UNISONO'
  return (
    <Routes>
      <Route path="/" element={<ConnectedUnisonoContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/unisono" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="unisono" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          {/*<Route path="structure/*" element={<ShelfStructureModals />} />*/}
        </Route>
      </Route>
      <Route path="print" element={<ConnectedUnisonoPrintView />} />
      <Route path="pricelist" element={<UnisonoPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedUnisonoContainer = () => {
  const [frameHeight, footHeight, headBoardID, headBoardHeight, footBoardWidth, sideBoardWidth] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.feet.height,
    store.config.main.headBoard.id,
    store.config.main.headBoard.height,
    store.config.main.footBoardWidth,
    store.config.main.sideBoardWidth,
  ])
  const headBoard = headBoardID !== 0
  const fullHeight = Math.round((frameHeight + footHeight + (headBoard ? headBoardHeight : 0)) * 1000)
  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const heightText = headBoard ? upperFrameHeight + '/' + fullHeight : upperFrameHeight.toString()

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: useStore((store) => store.config.main.length + (headBoard ? bedModel.nominalThickness + footBoardWidth : 2 * footBoardWidth)),
    fullHeight: heightText,
    footHeight: footHeight,
    depth: useStore((store) => store.config.main.width + 2 * sideBoardWidth),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    title: 'Unisono ' + headBoardModel[headBoardID].short,
  }

  return (
    <ViewContainer {...props} price={<ConnectedUnisonoPrice />}>
      <FiberScene footHeight={footHeight}>{<ConnectedUnisono />}</FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
