import {Selector} from 'common/threeComponents/FiberBoard'
import {shelfMaterials} from 'products/dasregal/store/datamodels'
import {useStore} from 'products/dasregal/store/store'
import {Fragment, useRef} from 'react'
import * as THREE from 'three'
import {accHelper} from 'utils/helpers'
import shallow from 'zustand/shallow'

interface ITabelarSelectors {
  depth: number
  width: number
  thickness: number
  material: THREE.Material
  xPos: number
  yPos: number
  callback: () => void
}

export const TabelarSelectors = (props: ITabelarSelectors) => {
  return <Selector size={[props.width, props.thickness, props.depth]} onClick={props.callback} xPos={props.xPos} yPos={props.yPos} zPos={0} />
}

export const ConnectedTabelarSelectors = () => {
  const visible = useStore((store) => store.view.tabelars.edit)
  const [columns, boards, depth, height, grid] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid],
    shallow
  )
  const subColumns = useStore((store) => store.config.subColumns.list)
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}))
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  let startPos = thickness
  const toggleTabelar = useStore((store) => store.tabelar.toggleTabelar)
  const maxCols = grid * (Math.round((height - thickness) / (grid * 0.065)) + 1)
  const maxColGrid = (height - thickness) / maxCols

  return (
    <>
      {columns.map((el, i) => {
        startPos += el.width + thickness
        return [...Array(maxCols - 3)].map((_, j) => {
          const posIndex = Math.floor((j + 2) / (maxCols / grid))
          const subColumnList = accHelper.get_SubColumn_List({x: i, y: posIndex}, columns, boards, subColumns)
          const board = (j + 2) % (maxCols / grid) === 0 && boards[i].includes(posIndex)
          return (
            <Fragment key={j}>
              {!board &&
                [...Array(subColumnList.length - 1)].map((_, k) => {
                  const width = (el.width + thickness) * (subColumnList[k + 1] - subColumnList[k]) - thickness
                  return (
                    <Fragment key={k}>
                      {visible && (
                        <TabelarSelectors
                          key={k}
                          depth={depth}
                          width={width}
                          thickness={thickness}
                          material={selectorMaterial.current}
                          xPos={startPos - (1 - subColumnList[k]) * (el.width + thickness)}
                          yPos={(j + 2) * maxColGrid}
                          callback={() => toggleTabelar(i + subColumnList[k], j + 2)}
                        />
                      )}
                    </Fragment>
                  )
                })}
            </Fragment>
          )
        })
      })}
    </>
  )
}
