import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {flapMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {FlapFC} from 'common/threeComponents/FlapFC'
import {ConnectedDigitalFlapList} from 'products/digital/partLists/flapList'

export const ConnectedFlaps = () => {
  const flapList = ConnectedDigitalFlapList()
  const visible = useStore((store) => store.view.flaps.visible)
  if (flapList.length > 0) {
    const flapmaterialID = flapList[0].parts.flapFront.material
    const flapMaterialReference = flapMaterials[flapmaterialID].matReference
    var materialObject = MaterialSingleton.getMaterials()
    var frontMat = materialObject[flapMaterialReference].small_1 || materialObject[flapMaterialReference]
  }
  return (
    <>
      {visible &&
        flapList.map((flap) => {
          return <FlapFC {...flap} material={frontMat} thickness={flap.parts.flapFront.thickness} flapShelfThickness={flap.parts.flapShelf?.thickness} flapShelfMaterial={frontMat} />
        })}
    </>
  )
}
