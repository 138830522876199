export function FooterPrint(props: {page: number; totalPages: number}) {
  const date = new Date().toLocaleString().split(',')[0]

  return (
    <>
      <div className="print_footer">
        <div className="left">sanktjohanser | +49 8846 914211 | moebel@sanktjohanser.net | www.sanktjohanser.net</div>
        <div className="right">{date}</div>
      </div>
      <div className="print_pagenumber">
        {props.page}/{props.totalPages}
      </div>
    </>
  )
}
