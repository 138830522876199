import {useStore} from 'products/bezwei/store/store'
import {bedMaterials, bedModel} from '../store/datamodels'

export const ConnectedBezweiBoardList = () => {
  const [length, width, materialID, frameHeight, footHeight, storagePlace] = useStore((store) => [
    store.config.main.length,
    store.config.main.width,
    store.config.main.materialID,
    store.config.main.frameHeight,
    store.config.main.footHeight,
    store.config.main.storagePlace,
  ])
  return BezweiBoardList(length, width, materialID, frameHeight, footHeight, storagePlace)
}

export const BezweiBoardList = (length: number, width: number, materialID: keyof typeof bedMaterials, frameHeight: number, footHeight: number, storagePlace: number) => {
  const thickness = bedMaterials[materialID].thickness
  const frameLength = length + 2 * thickness
  const sideLength = frameLength
  const frameWidth = width + 2 * thickness

  const boards: {
    key: number
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    rotation: [number, number, number]
    material: keyof typeof bedMaterials
    type: string
  }[] = []

  boards.push({
    key: 1,
    xPos: 0,
    yPos: 0,
    zPos: frameWidth,
    length: sideLength + storagePlace * bedModel.storagePlaceWidth,
    depth: frameHeight,
    thickness: thickness,
    rotation: [-0.5, 0, 0],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 2,
    xPos: sideLength + storagePlace * bedModel.storagePlaceWidth,
    yPos: 0,
    zPos: 0,
    length: sideLength + storagePlace * bedModel.storagePlaceWidth,
    depth: frameHeight,
    thickness: thickness,
    rotation: [0.5, 1, 0],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 3,
    xPos: 0,
    yPos: 0,
    zPos: thickness,
    length: width,
    depth: frameHeight,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    material: materialID,
    type: 'footBoard',
  })
  boards.push({
    key: 4,
    xPos: frameLength + storagePlace * bedModel.storagePlaceWidth,
    yPos: 0,
    zPos: frameWidth - thickness,
    length: width,
    depth: frameHeight,
    thickness: thickness,
    rotation: [0.5, 1, -0.5],
    material: materialID,
    type: 'headBoard',
  })
  if (storagePlace > 0) {
    boards.push({
      key: 5,
      xPos: sideLength + (storagePlace - 1) * bedModel.storagePlaceWidth,
      yPos: 0.02,
      zPos: frameWidth - thickness,
      length: width,
      depth: frameHeight - 0.02,
      thickness: thickness,
      rotation: [0.5, 1, -0.5],
      material: materialID,
      type: 'storageSide',
    })

    boards.push({
      key: 6,
      xPos: sideLength + (storagePlace - 1) * bedModel.storagePlaceWidth,
      yPos: 0.02,
      zPos: frameWidth - thickness,
      length: width,
      depth: bedModel.storagePlaceWidth - thickness,
      thickness: thickness,
      rotation: [0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageBottom',
    })
    boards.push({
      key: 7,
      xPos: sideLength + (storagePlace - 1) * bedModel.storagePlaceWidth - thickness + 0.0001,
      yPos: frameHeight - 0.005,
      zPos: (8 / 9) * frameWidth,
      length: frameWidth / 3,
      depth: bedModel.storagePlaceWidth + thickness - 0.0002,
      thickness: thickness,
      rotation: [0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageLid',
    })
    boards.push({
      key: 8,
      xPos: sideLength + (storagePlace - 1) * bedModel.storagePlaceWidth - thickness + 0.0001,
      yPos: frameHeight - 0.005,
      zPos: (4 / 9) * frameWidth,
      length: frameWidth / 3,
      depth: bedModel.storagePlaceWidth + thickness - 0.0002,
      thickness: thickness,
      rotation: [0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageLid',
    })
  }
  if (storagePlace > 1) {
    boards.push({
      key: 9,
      xPos: bedModel.storagePlaceWidth,
      yPos: 0.02,
      zPos: thickness,
      length: width,
      depth: frameHeight - 0.02,
      thickness: thickness,
      rotation: [-0.5, 0, -0.5],
      material: materialID,
      type: 'storageSide',
    })
    boards.push({
      key: 10,
      xPos: thickness,
      yPos: 0.02,
      zPos: thickness,
      length: width,
      depth: bedModel.storagePlaceWidth - thickness,
      thickness: thickness,
      rotation: [-0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageBottom',
    })
    boards.push({
      key: 11,
      xPos: 0.0001,
      yPos: frameHeight - 0.005,
      zPos: (8 / 9) * frameWidth,
      length: frameWidth / 3,
      depth: bedModel.storagePlaceWidth + thickness - 0.0002,
      thickness: thickness,
      rotation: [0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageLid',
    })
    boards.push({
      key: 12,
      xPos: 0.0001,
      yPos: frameHeight - 0.005,
      zPos: (4 / 9) * frameWidth,
      length: frameWidth / 3,
      depth: bedModel.storagePlaceWidth + thickness - 0.0002,
      thickness: thickness,
      rotation: [0.5, 0.5, -0.5],
      material: materialID,
      type: 'storageLid',
    })
  }
  boards.push({
    key: 13,
    xPos: bedModel.storagePlaceWidth,
    yPos: 0,
    zPos: frameWidth,
    length: width + 2 * thickness,
    depth: footHeight,
    thickness: thickness,
    rotation: [-0.5, 1, 0.5],
    material: materialID,
    type: 'foot',
  })
  boards.push({
    key: 14,
    xPos: sideLength + (storagePlace - 1) * bedModel.storagePlaceWidth,
    yPos: 0,
    zPos: 0,
    length: width + 2 * thickness,
    depth: footHeight,
    thickness: thickness,
    rotation: [0.5, 0, 0.5],
    material: materialID,
    type: 'foot',
  })
  return boards
}
