import {useCallback, useRef} from 'react'
import {useFrame} from '@react-three/fiber'
import * as THREE from 'three'
import {apertureAngles} from 'utils/datamodels'
import {Board, IVector, Selector} from './FiberBoard'

export interface IFlap {
  width: number
  height: number
  material: THREE.Material | THREE.Material[]
  flapShelfMaterial: THREE.Material | THREE.Material[]
  thickness: number
  xPos: number
  yPos: number
  zPos: number
  flapShelf: boolean
  flapShelfThickness?: number
  shadowJoint: number
  depth: number
}

export const FlapFC = (props: IFlap) => {
  const cubeRef = useRef<THREE.Mesh>()
  const isActive = useRef(false)
  const rotation = [0.5, 0, 0] as [number, number, number]
  const translate = [0.5, 0, -0.5 + props.thickness / 2 / props.height] as IVector
  const openrotate = apertureAngles.flaps[1]
  const yPosFlap = props.yPos + props.thickness / 2
  const zPosFlap = props.zPos - props.thickness / 2
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({alphaTest: 2}))

  const onMouseOver = useCallback(() => {
    isActive.current = true
  }, [])

  const onMouseOut = useCallback(() => {
    isActive.current = false
  }, [])

  useFrame((state, delta) => {
    const flap = cubeRef.current
    if (flap) {
      flap.rotation.x = THREE.MathUtils.lerp(flap.rotation.x, isActive.current ? -openrotate * Math.PI : rotation[0] * Math.PI, 0.1)
    }
  })

  return (
    <>
      <Board key={'flap'} ref={cubeRef} {...props} yPos={yPosFlap} zPos={zPosFlap} size={[props.width, props.thickness, props.height]} rotation={rotation} translate={translate} />
      {props.flapShelf && (
        <Board
          key={'flapShelf'}
          {...props}
          xPos={props.xPos - props.shadowJoint}
          yPos={props.yPos - props.shadowJoint}
          zPos={props.zPos - props.thickness - 0.002}
          size={[props.width + 2 * props.shadowJoint, props.flapShelfThickness || props.thickness, props.depth - props.thickness - 0.002]}
          material={props.flapShelfMaterial}
          translate={[0.5, 0.5, -0.5]}
        />
      )}
      <Selector
        material={selectorMaterial.current}
        xPos={props.xPos}
        yPos={yPosFlap}
        zPos={zPosFlap}
        onPointerOver={onMouseOver}
        onPointerOut={onMouseOut}
        size={[props.width, props.thickness, props.height]}
        rotation={[0.5, 0, 0]}
        translate={translate}
      />
    </>
  )
}
