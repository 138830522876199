import {accHelper} from 'utils/helpers'
import {IStore, ISetProduce} from '../store'
import {frontMaterials} from '../datamodels'
import {GetState} from 'zustand'

export const createDrawerSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const drawers = {
    setDrawer: function (pos: {x: number; y: number}, size: {y: number}): void {
      setProduce((state: IStore) => {
        const clearedDrawerArray = state.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, {x: 1, ...size}, {x: 1, ...drawer.size})) return false
          return true
        })
        if (!state.config.drawers.list.find((drawer) => accHelper.same_is_at_position(pos, drawer.pos, {x: 1, ...size}, {x: 1, ...drawer.size}))) {
          clearedDrawerArray.push({pos, size})
        }
        const newDrawers = clearedDrawerArray
        state.config.drawers.list = newDrawers
        const clearedDoorArray = state.config.doors.list.filter((door) => {
          if (accHelper.is_overlapping_with_other(door.pos, pos, {x: 1, ...door.size}, {x: 1, ...size})) return false

          return true
        })
        state.config.doors.list = clearedDoorArray
      })
    },
    setMaterialID: (newMaterialID: keyof typeof frontMaterials): void =>
      setProduce((state: IStore) => {
        state.config.drawers.materialID = newMaterialID
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.drawers.variant = variant
      })
    },
    setGrainDirection: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.drawers.grainDirection = variant
      })
    },
    setEditDrawers: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.drawers.edit = edit
      }),
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.drawers.visible = show
      })
    },
  }

  return {
    drawers,
  }
}
