import {useStore} from 'products/metrum/store/store'
import {shelfMaterials, footModel} from 'products/metrum/store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {IMetrumConfig} from '../store/initialConfig'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedMetrumFootList = () => {
  const [columns, feetID, depth, materialID] = useStore(useShallow((store) => [store.config.columns.list, store.config.feet.id, store.config.main.depth, store.config.main.materialID]))
  return MetrumFootList(columns, feetID, depth, materialID)
}

export const MetrumFootList = (columns: any[], feetID: any, depth: number, materialID: IMetrumConfig['main']['materialID']) => {
  const shelfThickness = shelfMaterials[materialID].thickness
  const feet: {key: string; pos: IVector; size: IVector; material: string; type: keyof typeof footModel}[] = []

  columns.forEach((el, index) => {
    const xPos = columns.slice(0, index).reduce((acc, el) => acc + el.width + shelfThickness, shelfThickness)
    feet.push({key: index + '.VL', pos: [xPos + 0.02 || 0, 0, depth - 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.VR', pos: [xPos + el.width - 0.02 || 0, 0, depth - 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.HL', pos: [xPos + 0.02 || 0, 0, 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.HR', pos: [xPos + el.width - 0.02 || 0, 0, 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
  })

  return feet
}
