import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {ConnectedBezweiPrice} from 'products/bezwei/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, bedModel} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedBezwei} from '../../threeComponents/Bezwei'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedBezweiPrintView from 'products/bezwei/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {BezweiPriceList} from 'products/bezwei/priceList/PriceList'

export const BezweiView = () => {
  document.title = 'SANKTJOHANSER | BEZWEI'
  return (
    <Routes>
      <Route path="/" element={<ConnectedBezweiContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/bezwei" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="bezwei" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          {/*<Route path="structure/*" element={<ShelfStructureModals />} />*/}
        </Route>
      </Route>
      <Route path="print" element={<ConnectedBezweiPrintView />} />
      <Route path="pricelist" element={<BezweiPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedBezweiContainer = () => {
  const [frameHeight, footHeight] = useStore((store) => [store.config.main.frameHeight, store.config.main.footHeight])

  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const heightText = upperFrameHeight.toString()

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: useStore((store) => store.config.main.length + 2 * bedModel.nominalThickness + store.config.main.storagePlace * bedModel.storagePlaceWidth),
    fullHeight: heightText,
    footHeight: footHeight,
    depth: useStore((store) => store.config.main.width + 2 * bedModel.nominalThickness),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    title: 'BEZWEI',
  }

  return (
    <ViewContainer {...props} price={<ConnectedBezweiPrice />}>
      <FiberScene footHeight={footHeight}>{<ConnectedBezwei />}</FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
