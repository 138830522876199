import {w, materialModel} from 'utils/datamodels'

export const price_CNC = (params: {
  length: number
  width: number
  nrSetups: number
  nrToolChanges: number
  nrGrooves?: number
  nrDrills?: number
  format?: boolean
  bevel?: boolean
  sanding?: boolean
  rest?: [...rest: any]
}) => {
  const {length, width, nrSetups, nrToolChanges, nrGrooves, nrDrills, format, bevel, sanding, rest} = params
  const setup = nrSetups * (w.Setup + w.Werkstueckwechsel) + nrToolChanges * w.Werkzeugwechsel
  const p_format = format ? ((2 * (length + width)) / w.Formatieren) * 60 : 0
  const p_bevel = bevel ? ((4 * (length + width)) / w.Fasen) * 60 : 0
  const p_sanding = sanding ? ((2 * (length + width)) / w.Schleifen) * 60 : 0
  const groove = nrGrooves ? ((nrGrooves * length) / w.Nuten) * 60 : 0
  const drilling = nrDrills ? nrDrills * w.Bohrloch : 0
  const p_rest = rest ? rest.reduce((a, b) => a + b, 0) : 0

  const price = ((setup + p_format + p_bevel + p_sanding + groove + drilling + p_rest) / 3600) * w.CNC
  return price
}

export const price_Salary = (params: {length: number; width: number; treatmentPrice: number; hasTreatment: boolean; isWallMounted?: number; rest?: [...rest: any]}) => {
  const {length, width, treatmentPrice, hasTreatment: has_treatment, isWallMounted, rest} = params
  const p_treatment = has_treatment ? 2 * length * width * treatmentPrice : 0
  const p_kante = 2 * (length + width) * w.Kante
  const p_wallMounted = isWallMounted || 0
  const p_rest = rest ? rest.reduce((a, b) => a + b, 0) : 0

  const price = ((p_treatment + p_kante + p_wallMounted + w.Handling + w.Zuschnitt + p_rest) / 60) * w.Lohn
  return price
}

export const price_Material = (params: {length: number; width: number; materialID: keyof typeof materialModel; rest?: [...rest: any]}) => {
  const {length, width, materialID, rest} = params
  const materialPrice = getMaterialsPrice(materialID, length)
  const p_material = length * width * materialPrice * w.Verschnitt
  const p_rest = rest ? rest.reduce((a, b) => a + b, 0) : 0

  const price = p_material + p_rest
  return price
}

const getMaterialsPrice = (materialID: keyof typeof materialModel, length: number) => {
  const prices = materialModel[materialID].price
  if (typeof prices === 'object') {
    const keys = Object.keys(prices) as unknown as number[]
    const key = Math.min(...keys.filter((key) => key > length)) as unknown as keyof typeof prices
    return prices[key]
  } else {
    return prices
  }
}

export const doorPrice = (door: any, treatmentPrice?: number) => {
  const nrDoorHinges = 2 + (door.length > 0.8 ? Math.round((door.length - 0.8) / 0.4) : 0)
  const materialPrice = price_Material({length: door.length, width: door.depth, materialID: door.material, rest: [nrDoorHinges * w.Topfband]})
  const cncPrice = price_CNC({length: door.length, width: door.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 2, format: true, bevel: true, rest: [nrDoorHinges * w.CNCZeitTopfband]})
  const treatmentPrice1 = treatmentPrice || 25
  const salaryPrice = price_Salary({
    length: door.length,
    width: door.depth,
    treatmentPrice: treatmentPrice1,
    hasTreatment: materialModel[door.material as keyof typeof materialModel].treatment,
    rest: [w.ZeitTuer],
  })

  const totalPrice = materialPrice + cncPrice + salaryPrice

  return {totalPrice, materialPrice, cncPrice, salaryPrice}
}

export const drawerPrice = (drawer: any, treatmentPrice: number) => {
  let boardPrice = 0
  let salaryPrice = 0
  let cncPrice = 0
  const drawerParts = Object.values(drawer.parts)
    .map((el) => el)
    .flat() as any[]
  drawerParts.forEach((part: any) => {
    const material = materialModel[part.material as keyof typeof materialModel]
    switch (part.type) {
      case 'drawerBack':
        boardPrice += price_Material({length: part.length, width: part.depth, materialID: part.material})
        salaryPrice += price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
        cncPrice += price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 1, format: true, bevel: true})
        break
      case 'drawerFront':
        boardPrice += price_Material({length: part.length, width: part.depth, materialID: part.material})
        salaryPrice += price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitSK]})
        cncPrice += price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 1, format: true, bevel: true})
        break
      case 'drawerSide':
        boardPrice += 0.5 * price_Material({length: part.length, width: part.depth, materialID: part.material})
        salaryPrice += 0.5 * price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
        cncPrice += 0.5 * price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 1, format: true, bevel: true})
        break
      case 'drawerBottom':
        boardPrice += 0
        salaryPrice += 0
        cncPrice += price_CNC({length: part.length, width: part.depth, nrSetups: 0.5, nrToolChanges: 1, format: true})
        break
      case 'drawerInsideFront':
        boardPrice += 0
        salaryPrice += 0
        cncPrice += 0
        break
      default:
        boardPrice += price_Material({length: part.length, width: part.depth, materialID: part.material})
        salaryPrice += price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
        console.log('no CNCprice for part type: ' + part.type)
        cncPrice = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
        break
    }
  })
  const materialPrice = boardPrice + w.BeschlagSK

  const totalPrice = materialPrice + cncPrice + salaryPrice

  return {totalPrice, materialPrice, cncPrice, salaryPrice}
}
