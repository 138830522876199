import {useStore} from 'products/kubatur/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'
import {backpanelMaterials} from 'products/kubatur/store/datamodels'

export function Backpanels() {
  const [shelfMaterial, backpanelMaterial] = useStore((store) => [store.config.main.materialID, store.config.backpanels.materialID])

  const setMaterialID = useStore((store) => store.backpanels.setMaterialID)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(backpanelMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof backpanelMaterials)}
    })

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={backpanelMaterial} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
