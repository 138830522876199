import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {bedMaterials} from 'products/dinadrei/store/datamodels'
import {ConnectedDinadreiBoardList} from 'products/dinadrei/partLists/boardList'
import {Board} from 'common/threeComponents/FiberBoard'

export const ConnectedBoards = () => {
  const boardList = ConnectedDinadreiBoardList()
  const materialObject = MaterialSingleton.getMaterials()
  return (
    <>
      {boardList.map((board) => {
        const materialID = board.material
        const materialReference = bedMaterials[materialID].matReference
        const material = materialObject[materialReference]?.big || materialObject[materialReference]
        return <Board {...board} size={[board.length, board.thickness, board.depth]} material={material} />
      })}
    </>
  )
}
