import {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import {useStore} from '../store/store'
import {ConnectedBoards} from './boards/Boards'
import {ConnectedBrackets} from './brackets/Brackets'
import {bedModel} from '../store/datamodels'
import {ConnectedFeet} from './feet/Feet'
import {ConnectedSlatteds} from './slatted/Slatted'
import {ConnectedMattress} from './mattress/Mattress'
import {ConnectedAccBoards} from './boards/AccBoards'
import {ConnectedShadowBox} from 'common/threeComponents/ShadowBox'

export const Iku = (props: any) => {
  const [stackable, stackedStore, frameHeight, mattressVisibility, slattedVisibility] = useStore((store) => [
    store.config.main.stackable,
    store.view.stackable.stacked,
    store.config.main.frameHeight,
    store.view.mattress.visible,
    store.view.slatted.visible,
  ])
  const {mattress = mattressVisibility, slatted = slattedVisibility} = props
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())
  const fullGroupRef1 = useRef<THREE.Group>(new THREE.Group())
  const stacked = props.stacked === undefined ? stackedStore : props.stacked

  useLayoutEffect(() => {
    !stackable && fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
    stackable && !stacked && fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth - 0.0025)
    stackable && stacked && fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
    if (stackable) {
      stacked && fullGroupRef1.current.position.set(0, frameHeight + 0.005, 0)
      !stacked && fullGroupRef1.current.position.set(0, 0, props.depth + 0.005)
    }
  })

  return (
    <>
      <group ref={fullGroupRef}>
        <ConnectedBoards />
        <ConnectedAccBoards />
        <ConnectedFeet />
        <ConnectedBrackets />
        {slatted && <ConnectedSlatteds />}
        {(!stacked || !stackable) && mattress && <ConnectedMattress />}
        <ConnectedShadowBox length={props.width as number} width={props.depth as number} />
        {stackable && (
          <group ref={fullGroupRef1}>
            <ConnectedBoards />
            <ConnectedAccBoards />
            <ConnectedFeet upperBed />
            <ConnectedBrackets />
            {slatted && <ConnectedSlatteds />}
            {mattress && <ConnectedMattress />}
            <ConnectedShadowBox length={props.width as number} width={props.depth as number} />
          </group>
        )}
      </group>
    </>
  )
}

export const ConnectedIku = (props: any) => {
  const width = useStore((store) => store.config.main.length)
  const depth = useStore((store) => store.config.main.width)
  const frameFlush = useStore((store) => store.config.main.frameFlush)
  const headBoardID = useStore((store) => store.config.main.headBoard.id)

  const params = {
    width: frameFlush ? width : headBoardID > 1 ? width + bedModel.nominalThickness : width + 2 * bedModel.nominalThickness,
    depth: frameFlush ? depth : headBoardID > 1 ? depth + bedModel.nominalThickness : depth + 2 * bedModel.nominalThickness,
    footHeight: useStore((store) => store.config.feet.height),
    ...props,
  }

  return <Iku {...params} />
}
