import {useStore} from 'products/dasregal/store/store'
import {shelfMaterials, regalModel, backpanelMaterials, footModel} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedDasregalSubColumnList = () => {
  const [subColumnList, shelfHeight, depth, columns, boards, backpanels, doors, drawers, flaps, grid, materialID, feetID] = useStore(
    useShallow((store) => [
      store.config.subColumns.list,
      store.config.main.height,
      store.config.main.depth,
      store.config.columns.list,
      store.config.boards.list,
      store.config.backpanels,
      store.config.doors.list,
      store.config.drawers.list,
      store.config.flaps.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.feet.id,
    ])
  )

  return DasregalSubColumnList(subColumnList, shelfHeight, depth, columns, boards, backpanels.list, doors, drawers, flaps, grid, materialID, feetID, backpanels.materialID)
}

export const DasregalSubColumnList = (
  subColumnList: any[],
  shelfHeight: number,
  depth: number,
  columns: any[],
  boards: any[][],
  backpanels: any[],
  doors: {pos: {x: number; y: number}; size: {x: number; y: number}; side: 'left' | 'right'; face: 'front' | 'back'}[],
  drawers: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  flaps: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  grid: number,
  materialID: keyof typeof shelfMaterials,
  feetID: keyof typeof footModel,
  backpanelMaterialID: keyof typeof backpanelMaterials
) => {
  const thickness = shelfMaterials[materialID].thickness
  const gridSize = (shelfHeight - thickness) / grid
  const wallMounted = feetID === 'wallMounted'
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const subColumns: {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []
  subColumnList.forEach((el, i) => {
    const innerXPos = el.pos.x - Math.floor(el.pos.x)
    if (el.pos.y < grid && el.pos.x < columns.length) {
      const isBoard = el.pos.y === 0 ? true : boards[Math.floor(el.pos.x)].includes(el.pos.y)
      if ((0.5 - Math.abs(0.5 - innerXPos)) * columns[Math.floor(el.pos.x)].width > regalModel.minSubColumnWidth && isBoard) {
        const nextBoard = Math.min(...boards[Math.floor(el.pos.x)].filter((y) => y > el.pos.y), grid)
        let multiplier = nextBoard - el.pos.y
        const accHeightInFront = accHelper.get_acc_beside(el.pos, {x: 0, y: multiplier}, doors, drawers).height
        const doorinFront = accHeightInFront === 2 * multiplier
        const isInset = accHelper.acc_is_in_front(el.pos, {x: 0, y: multiplier}, doors, flaps) || doorinFront
        const height = multiplier * gridSize - thickness
        const yPos = el.pos.y * gridSize + thickness
        const fullBackpanel =
          backpanels.find((obj) => {
            return obj.pos.x === Math.floor(el.pos.x)
          })?.cover === 'full'
        const fullBackpanelX =
          backpanels.find((obj) => {
            return obj.pos.x === Math.floor(el.pos.x) && obj.pos.y === el.pos.y
          })?.cover === 'fullWidth'
        const isBackpanel = fullBackpanel || fullBackpanelX
        const zPos = wallMounted
          ? regalModel.backpanelWallMountedInset + backpanelThickness
          : isBackpanel
            ? backpanelThickness < 0.016
              ? regalModel.backpanelInset + backpanelThickness
              : 0.001 + backpanelThickness
            : 0
        const adjustedDepth = depth - zPos - (isInset ? regalModel.frontInset : 0)
        const xPos = columns
          .slice(0, Math.floor(el.pos.x))
          .reduce((pv, cv) => pv + cv.width + thickness, thickness + (columns[Math.floor(el.pos.x)].width + thickness) * (el.pos.x - Math.floor(el.pos.x)))
        subColumns.push({key: i, xPos: xPos, yPos: yPos, zPos: zPos, length: height, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'subColumn'})
      }
    }
  })
  return subColumns
}
