import {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import {useStore} from '../store/store'
import {ConnectedBoards} from './boards/Boards'
import {ConnectedColumns} from './columns/Columns'
import {ConnectedBackpanels} from './backpanels/Backpanels'
import {ConnectedDrawers} from './drawers/Drawers'
import {ConnectedFlaps} from './flaps/Flaps'
import {ConnectedBoardSelectors} from 'products/digital/threeComponents/boards/BoardSelectors'
import {ConnectedColumnSelectors} from './columns/ColumnSelectors'
import {ConnectedBackpanelSelectors} from './backpanels/BackpanelSelectors'
import {ConnectedFlapSelectors} from './flaps/FlapSelectors'
import {ConnectedDrawerSelectors} from './drawers/DrawerSelectors'
import {ConnectedSteelBeams} from './steelbeams/SteelBeams'
import {ConnectedFeet} from './feet/Feet'
import {ConnectedDimensionControls} from './dimensioncontrols/DimensionControls'

export const Digital = (props: any) => {
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())

  useLayoutEffect(() => {
    fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
  })

  return (
    <group name="RegalFiberGroup" ref={fullGroupRef}>
      <ConnectedBoards />
      <ConnectedBoardSelectors />
      <ConnectedSteelBeams />
      <ConnectedColumns />
      <ConnectedColumnSelectors />
      <ConnectedBackpanels />
      <ConnectedBackpanelSelectors />
      <ConnectedDrawers />
      <ConnectedDrawerSelectors />
      <ConnectedFlaps />
      <ConnectedFlapSelectors />
      <ConnectedFeet />
      <ConnectedDimensionControls />
    </group>
  )
}

export const ConnectedDigital = (props: any) => {
  const params = {
    width: useStore((store) => store.config.main.width),
    depth: useStore((store) => store.config.main.depth),
    footHeight: useStore((store) => store.config.feet.height),
  }

  return <Digital {...params} />
}
