import {ISetProduce, IStore} from '../store'
import {shelfMaterials, regalModel} from '../datamodels'
import {GetState} from 'zustand'
import {clearColumns} from 'products/digital/utils/helpers'

export interface IColumnSlice {
  columns: {
    setEdit: (edit: boolean) => void
    toggle: (xPos: number, yPos: number) => void
    clearColumns: () => number[][]
    checkMaxSpan: (grid: number) => boolean
  }
}

export const createColumnSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const columns = {
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.columns.edit = edit
      }),
    toggle: (xPos: number, yPos: number) =>
      setProduce((store: IStore) => {
        store.config.columns.list[yPos].includes(xPos) ? store.config.columns.list[yPos].splice(store.config.columns.list[yPos].indexOf(xPos), 1) : store.config.columns.list[yPos].push(xPos)
        if (!store.config.columns.list[yPos].includes(xPos)) {
          const nrBackPanelsinRow = store.config.backpanels.list.filter((backpanel) => backpanel.pos.y === yPos).length
          const noBackPanel = get().config.backpanels.list.find((backpanel) => backpanel.pos.y === yPos && backpanel.pos.x === xPos + 1) && nrBackPanelsinRow < 2
          noBackPanel && store.config.backpanels.list.push({pos: {x: 0, y: yPos}, cover: 'compartment', face: 'back'})
        }
      }),
    clearColumns: () => {
      const clearedColumns = clearColumns(get().config.columns.list, get().config.rows.list, get().config.main.grid)
      return clearedColumns
    },
    checkMaxSpan: (grid: number) => {
      const gridSize = (get().config.main.width - shelfMaterials[get().config.main.materialID as keyof typeof shelfMaterials].thickness) / grid
      const boards = get().config.rows.list.length
      setProduce((state) => {
        ;[...Array(boards)].forEach((_, index) => {
          const visibleColumns = [...state.config.columns.list[index]]
          visibleColumns.push(0, grid)
          visibleColumns.sort((a, b) => a - b)
          for (let i = 0; i < visibleColumns.length - 1; i++) {
            const start = visibleColumns[i]
            const end = visibleColumns[i + 1]
            if ((end - start) * gridSize > regalModel.maxSpan) {
              const nrOfColumns = Math.ceil(((end - start) * gridSize) / regalModel.maxSpan)
              for (let j = 1; j < nrOfColumns; j++) {
                state.config.columns.list[index].push(Math.round((end - start) / nrOfColumns) * j + start)
              }
            }
          }
        })
      })
    },
  }
  return {
    columns,
  }
}
