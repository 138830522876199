import {TekturPrice} from '../utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors, API_DOMAIN} from 'utils/datamodels'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'
import {baseUrl, shelfMaterials, backpanelMaterials, slidingDoorMaterials, drawerFrontMaterials, flapMaterials} from '../store/datamodels'
import {useState, useLayoutEffect, Fragment} from 'react'
import {TekturBoardList} from '../partLists/boardList'
import {ITekturConfig} from '../store/initialConfig'
import {TekturDrawerList} from '../partLists/drawerList'
import {TekturFootList} from '../partLists/footList'
import {useMemo} from 'react'
import {TekturColumnList} from '../partLists/columnList'
import {TekturBackpanelList} from '../partLists/backpanelList'
import {TekturTabelarList} from '../partLists/tabelarList'
import {TekturFlapList} from '../partLists/flapList'
import {TekturSlidingDoorList} from '../partLists/slidingDoorList'
import {clearConfig} from '../utils/helpers'

export const TekturPriceList = () => {
  let [unused, setConfig] = useState({} as ITekturConfig)
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  const materials = Object.keys(shelfMaterials)
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof shelfMaterials))

  const ids = useMemo(() => ['tektur-17', 'tektur-21', 'tektur-19', 'tektur-1', 'tektur-18', 'tektur-13', 'tektur-10', 'tektur-15', 'tektur-16', 'tektur-11', 'tektur-14', 'tektur-6'], [])
  let configList: {id: string; config: ITekturConfig}[] = useMemo(() => [], [])
  let priceListTektur: number[][][] = useMemo(() => [], [])

  useLayoutEffect(() => {
    const fetchConfig = async (uri: string) => {
      const product = await fetch(`${API_DOMAIN}/product/${uri}`)
        .then((r) => r.json())
        .then((data) => {
          const config1 = JSON.parse(data.configuration)
          setConfig(config1)
          configList.push({id: uri, config: clearConfig(config1)})
          return {config1}
        })
        .catch((err) => console.log(err))
      return product
    }
    ids.forEach((id) => fetchConfig(id))
  }, [])

  useLayoutEffect(() => {
    if (configList.length === ids.length) {
      ids.forEach((id, j) => {
        const foundConfig = configList.find((el) => el.id === id)
        priceListTektur.push([])
        if (foundConfig) {
          const config = foundConfig.config
          materials.forEach((material, i) => {
            const backpanelMaterial = (config.backpanels.materialID === 'm12MW' ? 'm12MW' : material === 's13Ei' ? 'm12Ei' : material) as keyof typeof backpanelMaterials
            const slidingDoorMaterial = material === 's13Ei' ? 'm12Ei' : (material as keyof typeof slidingDoorMaterials)
            const drawerMaterial = material as keyof typeof drawerFrontMaterials
            const flapMaterial = material as keyof typeof flapMaterials
            const configModified = {
              ...config,
              main: {...config.main, materialID: material as keyof typeof shelfMaterials},
              backpanels: {...config.backpanels, materialID: backpanelMaterial},
              slidingDoors: {...config.slidingDoors, materialID: slidingDoorMaterial},
              drawers: {...config.drawers, materialID: drawerMaterial},
              flaps: {...config.flaps, materialID: flapMaterial},
            }
            priceListTektur[j].push([])
            const partList = getTekturPartList(configModified)
            const drawerList = TekturDrawerList(
              configModified.drawers.list,
              configModified.backpanels,
              configModified.main.depth,
              configModified.main.width,
              configModified.rows.list,
              configModified.main.grid,
              configModified.main.materialID,
              configModified.drawers.materialID,
              configModified.main.shelfTyp
            )
            const footList = TekturFootList(
              configModified.main.depth,
              configModified.main.width,
              configModified.main.grid,
              configModified.feet.id,
              configModified.feet.height,
              configModified.main.materialID
            )
            priceListTektur[j][i].push(TekturPrice(localFactor.factor, treatmentID, configModified.feet.id === 'wallMounted', partList, drawerList, footList).props.children)
          })
        }
      })
    }
  }, [localFactor.factor, materials, priceListTektur, configList, ids])

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>Tektur</h1>
        {priceListTektur.length === ids.length &&
          ids.map((id, i) => (
            <Fragment key={i}>
              <h2>{id}</h2>
              <PriceTable rows={materialNames} columns={['']} prices={priceListTektur[i]} />
            </Fragment>
          ))}
      </>
    </>
  )
}

const getTekturPartList = (configs: ITekturConfig) => {
  const boardList = TekturBoardList(configs.rows.list, configs.main.width, configs.main.depth, configs.main.materialID)
  const columnList = TekturColumnList(
    configs.main.width,
    configs.main.depth,
    configs.main.materialID,
    configs.main.grid,
    configs.rows.list,
    configs.backpanels,
    configs.drawers.list,
    configs.main.shelfTyp
  )

  const tabelarList = TekturTabelarList(
    configs.tabelars.list,
    configs.backpanels,
    configs.flaps,
    configs.rows.list,
    configs.main.width,
    configs.main.depth,
    configs.main.materialID,
    configs.backpanels.materialID,
    configs.main.grid,
    configs.main.shelfTyp
  )

  const backpanelList = TekturBackpanelList(
    configs.backpanels.list,
    configs.rows.list,
    configs.main.width,
    configs.main.materialID,
    configs.backpanels.materialID,
    configs.main.grid,
    configs.feet.id === 'wallMounted'
  )

  const slidingDoorList = TekturSlidingDoorList(
    configs.slidingDoors.list,
    configs.main.depth,
    configs.main.width,
    configs.rows.list,
    configs.main.grid,
    configs.main.materialID,
    configs.slidingDoors.materialID,
    configs.main.shelfTyp
  )
  const flapPartList = TekturFlapList(
    configs.flaps.list,
    configs.backpanels,
    configs.main.depth,
    configs.main.width,
    configs.rows.list,
    configs.main.grid,
    configs.main.materialID,
    configs.flaps.materialID,
    configs.main.shelfTyp
  )
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]

  const partList = [...boardList, ...columnList, ...backpanelList, ...tabelarList, ...slidingDoorList, ...flapPartList]
  return partList
}
