import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {ConnectedDinadrei} from '../threeComponents/Dinadrei'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {ConnectedDinadreiBoardList} from '../partLists/boardList'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'
import {ConnectedDinadreiAccBoardList} from '../partLists/accBoardList'

export default function ConnectedDinadreiPrintView() {
  const footHeight = useStore((store) => store.config.main.footHeight)

  const partList = [...ConnectedDinadreiBoardList(), ...ConnectedDinadreiAccBoardList()]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)

  return (
    <PrintView reference="dinadrei" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedDinadrei mattress slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedDinadrei mattress={false} slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'side'}>
          <ConnectedDinadrei mattress slatted />
        </FiberPrintScene>
        <Caption>{'Ansicht von hinten'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'iso'}>
          <ConnectedDinadrei mattress slatted />
        </FiberPrintScene>
        <Caption>{'Schrägansicht'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}
