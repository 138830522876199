import {Selector} from 'common/threeComponents/FiberBoard'
import {regalModel, shelfMaterials} from 'products/metrum/store/datamodels'
import {useStore} from 'products/metrum/store/store'
import {Fragment} from 'react'
import shallow from 'zustand/shallow'

export const ConnectedBoardSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [columns, boards, depth, height, grid] = useStore(
    (store) => [store.config.columns, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid],
    shallow
  )
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const toggleBoard = useStore((store) => store.toggleBoard)
  if (!visible) return null

  let startPos = thickness
  return (
    <>
      {columns.list.map((el, i) => {
        startPos += el.width + thickness
        const gridsize = (height - thickness) / grid
        const xPos = startPos - el.width - thickness
        return (
          <Fragment key={i}>
            {[...Array(grid - 1)].map((_, j) => {
              const nextBoard = Math.min(...boards[i].filter((el) => el - 1 > j), grid)
              const prevBoard = Math.max(...boards[i].filter((el) => el - 1 < j), 0)
              const removable = (nextBoard - prevBoard) * gridsize < regalModel.maxSpan
              return removable && <Selector size={[el.width, thickness, depth]} key={j} xPos={xPos} yPos={(j + 1) * gridsize} zPos={0} onClick={() => toggleBoard(i, j + 1)} />
            })}
          </Fragment>
        )
      })}
    </>
  )
}
