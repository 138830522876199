import {useStore} from 'products/dinadrei/store/store'
import {Brackets} from 'common/threeComponents/Brackets'
import {Fragment} from 'react'

export const ConnectedBrackets = () => {
  const [frameHeight, length, width, slattedInsetstore, slattedId, sideBoardWidth] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.length,
    store.config.main.width,
    store.config.slatted.inset,
    store.config.slatted.id,
    store.config.main.sideBoardWidth,
  ])

  if (slattedId === 'integrated') {
    return null
  }

  const frameLength = length + 0.05
  const usableWidth = width
  const nrOfSlats = usableWidth > 1.4 ? 2 : 1
  const slattedWidth = Math.floor((usableWidth / nrOfSlats) * 10) / 10
  const slattedInset = slattedInsetstore
  const middleBoardInBetween = nrOfSlats === 2 && usableWidth - 2 * slattedWidth > 0.04

  const xPos = [...Array(4)].map((_, i) => 0.175 + (i * (frameLength - 0.35)) / 3)

  return (
    <>
      {xPos.map((x, i) => (
        <Fragment key={i}>
          <Brackets key={'l' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth} />
          {middleBoardInBetween && <Brackets key={'ml' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth + width / 2 - 0.02} rotation={[0, 1, 0]} />}
          <Brackets key={'r' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth + width} rotation={[0, 1, 0]} />
          {middleBoardInBetween && <Brackets key={'mr' + i} xPos={x} yPos={frameHeight - slattedInset - 0.002} zPos={sideBoardWidth + width / 2 + 0.02} />}
        </Fragment>
      ))}
      {nrOfSlats === 2 && !middleBoardInBetween && <Brackets xPos={0.025} yPos={frameHeight - slattedInset - 0.002 - 0.018} zPos={sideBoardWidth + width / 2} rotation={[0, 0.5, 0]} />}
      {nrOfSlats === 2 && !middleBoardInBetween && <Brackets xPos={frameLength - 0.025} yPos={frameHeight - slattedInset - 0.002 - 0.018} zPos={sideBoardWidth + width / 2} rotation={[0, -0.5, 0]} />}
    </>
  )
}
