import {Link} from 'react-router-dom'
import {analog} from '../productOverViews/ConnectedAnalogOverView'
import 'react-slideshow-image/dist/styles.css'
import {Fade} from 'react-slideshow-image'
import './slider.scss'
import {kubatur} from '../productOverViews/ConnectedKubaturOverView'
import {dasregal} from '../productOverViews/ConnectedDasregalOverView'
import {tektur} from '../productOverViews/ConnectedTekturOverView'
import {metrum} from '../productOverViews/ConnectedMetrumOverView'
import {digital} from '../productOverViews/ConnectedDigitalOverView'
import {bezwei} from '../productOverViews/ConnectedBezweiOverView'
import {dinadrei} from '../productOverViews/ConnectedDinadreiOverView'
import {iku} from '../productOverViews/ConnectedIkuOverView'
import {unisono} from '../productOverViews/ConnectedUnisonoOverView'
import {euklid} from '../productOverViews/ConnectedEuklidOverView'

export function AnalogSlider() {
  const ids = shuffle(analog)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#analog'} />
      ))}
    </Fade>
  )
}

export function KubaturSlider() {
  const ids = shuffle(kubatur)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#kubatur'} />
      ))}
    </Fade>
  )
}

export function DasregalSlider() {
  const ids = shuffle(dasregal)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#dasregal'} />
      ))}
    </Fade>
  )
}

export function TekturSlider() {
  const ids = shuffle(tektur)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#tektur'} />
      ))}
    </Fade>
  )
}

export function MetrumSlider() {
  const ids = shuffle(metrum)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#metrum'} />
      ))}
    </Fade>
  )
}

export function DigitalSlider() {
  const ids = shuffle(digital)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#digital'} />
      ))}
    </Fade>
  )
}

export function BezweiSlider() {
  const ids = shuffle(bezwei)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#bezwei'} />
      ))}
    </Fade>
  )
}

export function DinadreiSlider() {
  const ids = shuffle(dinadrei)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#dinadrei'} />
      ))}
    </Fade>
  )
}

export function IkuSlider() {
  const ids = shuffle(iku)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#iku'} />
      ))}
    </Fade>
  )
}

export function UnisonoSlider() {
  const ids = shuffle(unisono)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#unisono'} />
      ))}
    </Fade>
  )
}

export function EuklidSlider() {
  const ids = shuffle(euklid)
  return (
    <Fade indicators duration={2500} transitionDuration={500} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/#euklid'} />
      ))}
    </Fade>
  )
}

export function Slider() {
  const ids = shuffle([...analog, ...kubatur, ...dasregal, ...tektur, ...metrum, ...digital, ...bezwei, ...dinadrei, ...iku, ...unisono, ...euklid])
  return (
    <Fade indicators duration={1200} transitionDuration={250} arrows={false}>
      {ids.map((id, y) => (
        <SliderItem key={y} img={id.img} to={'/'} />
      ))}
    </Fade>
  )
}

function SliderItem(item: {img: JSX.Element; to: string}) {
  return (
    <div className="container">
      {item.img}
      <div className="middle">
        <Link to={'../' + item.to} target="_blank" rel="noopener noreferrer">
          <div className="text">zum Konfigurator</div>
        </Link>
      </div>
    </div>
  )
}

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}
