import {useStore} from 'products/dasregal/store/store'
import {regalModel, shelfMaterials, frontMaterials, backpanelMaterials, footModel} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'

type FlapShelf = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof shelfMaterials
  type: string
}

type Front = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof frontMaterials
  type: string
}

type FlapParts = {
  flapFront: Front
  flapShelf?: FlapShelf
}

export const ConnectedDasregalFlapList = () => {
  const [flapList, doorList, drawerList, backpanels, depth, shelfHeight, columns, boards, grid, shelfMaterialID, flapMaterial, feetID] = useStore(
    useShallow((store) => [
      store.config.flaps.list,
      store.config.doors.list,
      store.config.drawers.list,
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.boards.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.flaps.materialID,
      store.config.feet.id,
    ])
  )

  return DasregalFlapList(flapList, doorList, drawerList, backpanels, depth, shelfHeight, columns, boards, grid, shelfMaterialID, flapMaterial, feetID)
}

export const DasregalFlapList = (
  flapList: any[],
  doorList: {pos: {x: number; y: number}; size: {x: number; y: number}; side: 'left' | 'right'; face: 'front' | 'back'}[],
  drawerList: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  backpanels: {list: any; materialID: keyof typeof backpanelMaterials},
  depth: number,
  shelfHeight: number,
  columns: any[],
  boards: number[][],
  grid: number,
  shelfMaterialID: keyof typeof shelfMaterials,
  flapMaterial: keyof typeof frontMaterials,
  feetID: keyof typeof footModel
) => {
  const shelfMaterialThickness = shelfMaterials[shelfMaterialID].thickness
  const gridSize = (shelfHeight - shelfMaterialThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const wallMounted = feetID === 'wallMounted'
  const backpanelList = backpanels.list
  const backpanelThickness = backpanelMaterials[backpanels.materialID].thickness
  const flaps: {key: string; xPos: number; yPos: number; zPos: number; width: number; height: number; depth: number; flapShelf: boolean; shadowJoint: number; parts: FlapParts; type: string}[] = []

  flapList.forEach((flap, j) => {
    if (accHelper.is_in_column(flap.pos.x, columns.length)) {
      const totalWidthBelow = accHelper.get_acc_width_below(flap.pos, {x: 1, y: 0}, flapList, doorList, drawerList)
      const flapShelf = totalWidthBelow === 1 ? false : true
      const yOffset = flapShelf ? 0 : shelfMaterialThickness + shadowJoint
      const height = flap.size.y * gridSize - shelfMaterialThickness - 2 * shadowJoint + yOffset
      let fullWidth = (columns[flap.pos.x].width + shelfMaterialThickness) * (flap.size.x < 1 ? flap.size.x : 1)
      for (let i = 1; i < flap.size.x; i++) fullWidth += columns[flap.pos.x + i].width + shelfMaterialThickness
      const width = fullWidth - 2 * shadowJoint - shelfMaterialThickness
      const xPos = columns.slice(0, flap.pos.x).reduce((pv, cv) => pv + cv.width + shelfMaterialThickness, shelfMaterialThickness + shadowJoint)
      const yPos = flap.pos.y * gridSize + shelfMaterialThickness + shadowJoint - yOffset
      const isBackpanel = accHelper.is_Backpanel_at_pos({x: flap.pos.x, y: flap.pos.y}, backpanelList, boards)
      const backInset = wallMounted
        ? regalModel.backpanelWallMountedInset + backpanelThickness
        : isBackpanel
          ? backpanelThickness < 0.016
            ? regalModel.backpanelInset + backpanelThickness
            : 0.001 + backpanelThickness
          : 0
      const adjustedDepth = depth - 0.001 - backInset
      const flapParts = getFlapParts({
        width: width,
        height: height,
        depth: adjustedDepth,
        shadowJoint: shadowJoint,
        flapshelf: flapShelf,
        flapMaterial: flapMaterial,
        flapShelfMaterial: shelfMaterialID,
      })
      flaps.push({
        key: flap.pos.x + '-' + flap.pos.y,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - 0.001,
        width: width,
        height: height,
        depth: adjustedDepth,
        flapShelf: flapShelf,
        shadowJoint: shadowJoint,
        parts: flapParts,
        type: 'flap',
      })
    }
  })

  return flaps
}

const getFlapParts = (params: {
  width: number
  height: number
  depth: number
  shadowJoint: number
  flapshelf: boolean
  flapMaterial: keyof typeof frontMaterials
  flapShelfMaterial: keyof typeof shelfMaterials
}) => {
  const {width, height, depth, shadowJoint, flapMaterial, flapShelfMaterial} = params
  const frontThickness = frontMaterials[flapMaterial].thickness
  const shelfThickness = shelfMaterials[flapShelfMaterial].thickness
  const flapFront = {length: width, depth: height, thickness: frontThickness, material: flapMaterial, type: 'flapFront'}
  const flapShelf = {length: width + 2 * shadowJoint, depth: depth - frontThickness - 0.02, thickness: shelfThickness, material: flapShelfMaterial, type: 'flapShelf'}
  if (!params.flapshelf) return {flapFront: flapFront}
  else return {flapFront: flapFront, flapShelf: flapShelf}
}
