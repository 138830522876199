import {DimensionButton} from 'components/dimension_controls/DimensionButton'
import {shelfMaterials} from 'products/tektur/store/datamodels'
import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'

export const ConnectedDimensionControls = () => {
  const [boards, depth, width, materialID, visible] = useStore(
    (store) => [store.config.rows.list, store.config.main.depth, store.config.main.width, store.config.main.materialID, store.view.boards.edit],
    shallow
  )

  const shelfThickness = shelfMaterials[materialID].thickness
  let yPos = shelfThickness / 2
  return (
    <>
      {visible &&
        [...Array(boards.length)].map((_, i) => {
          yPos += boards[i].height
          return <DimensionButton key={i} position={[width / 2, yPos - boards[i].height / 2, 0]} left={50} url={'structure/row/' + i} linktext={Math.round(boards[i].height * 1000)} />
        })}
      {visible && <DimensionButton key={'width'} position={[width / 2, 0, depth / 2]} bottom={100} url={'structure/width/'} linktext={Math.round(width * 1000)} />}
    </>
  )
}
