import {useStore} from '../store/store'
import {shelfMaterials, footModel, shelfTypes} from 'products/kubatur/store/datamodels'
import {materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {useShallow} from 'zustand/react/shallow'
import {IKubaturConfig} from '../store/initialConfig'

export const ConnectedKubaturFootList = () => {
  const [depth, columns, feetID, footHeight, materialID] = useStore(
    useShallow((store) => [store.config.main.depth, store.config.columns.list, store.config.feet.id, store.config.feet.height, store.config.main.materialID])
  )
  return KubaturFootList(depth, columns, feetID, footHeight, materialID)
}

export const KubaturFootList = (
  depth: IKubaturConfig['main']['depth'],
  columns: IKubaturConfig['columns']['list'],
  feetID: IKubaturConfig['feet']['id'],
  footHeight: IKubaturConfig['feet']['height'],
  materialID: IKubaturConfig['main']['materialID']
) => {
  const thickness = shelfMaterials[materialID].thickness
  const footList = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel}[]
  const foot = footModel[feetID]
  const totalWidth = columns.reduce((acc, cur) => acc + cur.width + thickness, thickness)
  switch (feetID) {
    case 'gn840':
      footList.push({key: '0.0', pos: [foot.xInset || 0, 0, depth - 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      footList.push({key: '0.1', pos: [foot.xInset || 0, 0, 0.045], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      ;[...Array(columns.length - 1)].forEach((_, i) => {
        const xPos = columns.slice(0, i + 1).reduce((acc, el) => acc + el.width + thickness, thickness / 2 + 0.02)
        footList.push({key: i + 1 + '.0', pos: [xPos, 0, depth - 0.08], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
        footList.push({key: i + 1 + '.1', pos: [xPos, 0, 0.08], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      })
      footList.push({key: columns.length + '.0', pos: [totalWidth - (foot.xInset || 0), 0, depth - 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      footList.push({key: columns.length + '.1', pos: [totalWidth - (foot.xInset || 0), 0, 0.045], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      break
    case 'shv':
      footList.push({key: '0.0', pos: [thickness / 2, 0, depth - 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      footList.push({key: '0.1', pos: [thickness / 2, 0, 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      ;[...Array(columns.length - 1)].forEach((_, i) => {
        const xPos = columns.slice(0, i + 1).reduce((acc, el) => acc + el.width + thickness, thickness / 2)
        footList.push({key: i + 1 + '.0', pos: [xPos, 0, depth - 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
        footList.push({key: i + 1 + '.1', pos: [xPos, 0, 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      })
      footList.push({key: columns.length + '.0', pos: [totalWidth - thickness / 2, 0, depth - 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      footList.push({key: columns.length + '.1', pos: [totalWidth - thickness / 2, 0, 0.04], size: [0.04, footHeight, 0.04], material: 'solidBlack', type: feetID})
      break
  }
  return footList
}

export const ConnectedKubaturFootBarList = () => {
  const [depth, columns, materialID, shelfTyp] = useStore(useShallow((store) => [store.config.main.depth, store.config.columns.list, store.config.main.materialID, store.config.main.shelfTyp]))
  return KubaturFootBarList(depth, columns, materialID, shelfTyp)
}

export const KubaturFootBarList = (depth: number, columns: any[], materialID: IKubaturConfig['main']['materialID'], shelfTyp: IKubaturConfig['main']['shelfTyp']) => {
  if (shelfTyp === 2) return []

  const thickness = shelfMaterials[materialID].thickness
  const footBarList = [] as {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof materialModel; type: 'footBar'}[]
  const totalWidth = columns.reduce((acc, cur) => acc + cur.width + thickness, thickness)
  footBarList.push(
    {
      key: 'left',
      xPos: thickness,
      yPos: 0,
      zPos: 0,
      length: shelfTypes[shelfTyp].bottomInset,
      depth: depth,
      thickness: thickness,
      material: materialID,
      type: 'footBar',
    },
    {
      key: 'right',
      xPos: totalWidth - 2 * thickness,
      yPos: 0,
      zPos: 0,
      length: shelfTypes[shelfTyp].bottomInset,
      depth: depth,
      thickness: thickness,
      material: materialID,
      type: 'footBar',
    }
  )
  return footBarList
}
