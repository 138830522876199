import {AccordionMaterialSelector, AccordionSizeSlider, AccordionSlider} from 'components/material_interfaces/materialInterfaces'
import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {getMaterialName, getMaxGrid, getMinGrid} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'

export function ShelfStructure() {
  const {width, depth, grid} = useStore((store) => ({width: store.config.main.width, depth: store.config.main.depth, grid: store.config.main.grid}), shallow)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const thickness = shelfMaterials[shelfMaterial].thickness
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setWidth = useStore((store) => store.setWidth)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(shelfMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof shelfMaterials) + ', ' + Math.round(material.thickness * 1000) + 'mm'}
  })

  const minGrid = getMinGrid(width, regalModel.gridMax)
  const maxGrid = getMaxGrid(width, regalModel.gridMin)

  const gridOptions = []
  for (let i = minGrid; i <= maxGrid; i++) {
    const gridSize = (width - shelfMaterials[shelfMaterial].thickness) / i
    gridOptions.push({id: i, name: Math.round(gridSize * 1000), longName: i + ` x ${Math.round(gridSize * 1000)} mm`})
  }

  const widthList = []
  for (let i = 2; i <= Math.floor(shelfMaterials[shelfMaterial].maxWidth / regalModel.gridSize); i += 2) {
    widthList.push(Math.round((i * regalModel.gridSize + thickness + 0.001) * 1000) / 1000)
  }

  return (
    <>
      <AccordionMaterialSelector id="material" options={materialOptions} onClick={setShelfMaterialSpecies} title={'Material'} value={shelfMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionSizeSlider
        options={widthList}
        min={regalModel.minWidth * 1000}
        max={shelfMaterials[shelfMaterial].maxWidth * 1000}
        onClick={setWidth}
        title={'Regalbreite'}
        id="width"
        value={width * 1000}
        clickRange={60}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={regalModel.depthList}
        min={regalModel.minDepth * 1000}
        max={regalModel.maxDepth * 1000}
        onClick={setDepth}
        title={'Regaltiefe'}
        id="depth"
        value={depth * 1000}
        clickRange={20}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSlider options={gridOptions} min={minGrid - 0.3} max={maxGrid + 0.3} onClick={setGrid} title={'Rastermaß'} value={grid} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
