import {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import {useStore} from '../store/store'
import {ConnectedBoards} from './boards/Boards'

export const Euklid = (props: any) => {
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())

  useLayoutEffect(() => {
    fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
  })

  return (
    <>
      <group ref={fullGroupRef}>
        <ConnectedBoards />
      </group>
    </>
  )
}

export const ConnectedEuklid = (props: any) => {
  const width = useStore((store) => store.config.main.size)
  const depth = useStore((store) => store.config.main.size)
  const params = {
    width: width,
    depth: depth,
    footHeight: 0,
    ...props,
  }

  return <Euklid {...params} />
}
