export const initialView = {
  backpanels: {
    variant: 0,
    edit: false,
  },
  boards: {
    edit: false,
  },
  doors: {
    edit: false,
    editSize: {x: 1, y: 1},
    variant: 1,
    visible: true,
  },
  drawers: {
    edit: false,
    variant: 1,
    visible: true,
  },
}

export type IView = typeof initialView
