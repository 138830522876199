import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {ConnectedDinadreiPrice} from 'products/dinadrei/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, bedModel} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedDinadrei} from '../../threeComponents/Dinadrei'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedDinadreiPrintView from 'products/dinadrei/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'
import {DinadreiPriceList} from 'products/dinadrei/priceList/PriceList'

export const DinadreiView = () => {
  document.title = 'SANKTJOHANSER | DINADREI'
  return (
    <Routes>
      <Route path="/" element={<ConnectedDinadreiContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/dinadrei" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="dinadrei" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          {/*<Route path="structure/*" element={<ShelfStructureModals />} />*/}
        </Route>
      </Route>
      <Route path="print" element={<ConnectedDinadreiPrintView />} />
      <Route path="pricelist" element={<DinadreiPriceList />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedDinadreiContainer = () => {
  const [frameHeight, footHeight, headBoardHeight, sideBoardWidth] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.footHeight,
    store.config.main.headBoardHeight,
    store.config.main.sideBoardWidth,
  ])

  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const fullHeight = Math.round((frameHeight + footHeight + headBoardHeight) * 1000)
  const heightText = upperFrameHeight + '/' + fullHeight

  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: useStore((store) => store.config.main.length + 2 * bedModel.nominalThickness),
    fullHeight: heightText,
    footHeight: footHeight,
    depth: useStore((store) => store.config.main.width + 2 * sideBoardWidth),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
    title: 'DINADREI',
  }

  return (
    <ViewContainer {...props} price={<ConnectedDinadreiPrice />}>
      <FiberScene footHeight={footHeight}>{<ConnectedDinadrei />}</FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}
