import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/kubatur/store/store'
import {materialModel} from 'utils/datamodels'
import shallow from 'zustand/shallow'
import {doorTypeModel, regalModel, shelfMaterials} from './../../store/datamodels'

export const ConnectedDoorSelectors = () => {
  const visible = useStore((store) => store.view.doors.edit)
  const [columns, height, grid, depth, doorList] = useStore(
    (store) => [store.config.columns, store.config.main.height, store.config.main.grid, store.config.main.depth, store.config.doors.list],
    shallow
  )
  const thickness = materialModel[useStore((store) => store.config.doors.materialID)].thickness
  const gridSize = (height - thickness) / grid
  const variant = useStore((store) => store.view.doors.variant)
  const doorSize = useStore((store) => store.view.doors.size)
  const doorVariant = doorTypeModel.find((el) => el.id === variant) || doorTypeModel[0]
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const setDoor = useStore((store) => store.doors.setDoor)
  const doorMinSize = Math.min(...doorVariant.sizes)
  const doorMaxSize = Math.max(...doorVariant.sizes)
  const shadowJoint = regalModel.shadowJoint

  return (
    <>
      {visible &&
        columns.list.map((el, i) => {
          if (doorMinSize * el.width > regalModel.minSubColumnWidth && doorMaxSize * el.width < regalModel.maxDoorWidth) {
            console.log('doorMinSize', doorMinSize)
            return [...Array(grid - doorSize + 1)].map((_, x) => {
              const yPos = x * gridSize
              return [...Array(doorVariant.sizes.length)].map((_, y) => {
                const posX = y === 0 ? i : i + doorVariant.sizes[y - 1]
                const isDoorOnTheLeft = doorList.filter((d) => Math.abs(d.pos.x + d.size.x - i) < 0.01).length > 0
                const isDoorOnTheRight = doorList.filter((d) => Math.floor(posX + 1) === d.pos.x).length > 0
                const extraWidthLeft = i === 0 ? shelfThickness + shadowJoint : isDoorOnTheLeft ? (shelfThickness + shadowJoint) / 2 : 0
                const extraWidthRight = Math.floor(posX + 1) === columns.list.length ? shelfThickness + shadowJoint : isDoorOnTheRight ? (shelfThickness + shadowJoint) / 2 : 0
                const extraHeight = x + doorSize === grid ? shelfThickness + shadowJoint : 0
                const height = doorSize * gridSize - shadowJoint + extraHeight
                const width = (el.width - shadowJoint + extraWidthLeft + extraWidthRight) * doorVariant.sizes[y] - shadowJoint
                const xPos =
                  columns.list.slice(0, i).reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness + shadowJoint + (el.width - shadowJoint + extraWidthLeft + extraWidthRight) * (posX - i)) -
                  extraWidthLeft

                return (
                  <Selector
                    key={i + '-' + x + '-' + y}
                    size={[height, thickness, width]}
                    rotation={[0, 0.5, 0.5]}
                    onClick={() => setDoor({x: posX, y: x}, {x: doorVariant.sizes[y], y: doorSize}, doorVariant.side[y])}
                    xPos={xPos}
                    yPos={yPos}
                    zPos={depth + regalModel.doorDistance + 0.01}
                  />
                )
              })
            })
          } else {
            return null
          }
        })}
    </>
  )
}
