import {forwardRef} from 'react'
import * as THREE from 'three'
import {IVector} from 'common/threeComponents/FiberBoard'
import {RoundedBox} from '@react-three/drei'

export interface IMatress {
  size: IVector
  xPos: number
  yPos: number
  zPos: number
}

export const Matress = forwardRef((props: IMatress, ref: any) => {
  const {size, xPos, yPos, zPos} = props
  const material1 = new THREE.MeshStandardMaterial({color: 0xdddddd, metalness: 0, roughness: 10})
  const material2 = new THREE.MeshStandardMaterial({color: 0xaaaaaa, metalness: 0, roughness: 20})

  return (
    <group name="mattress" ref={ref} position={[xPos, yPos + size[1] / 2, zPos]}>
      <mesh>
        <RoundedBox material={material1} args={[size[0] - 0.005, size[1], size[2] - 0.01]} radius={0.03} position={[0.0025, 0, 0]} />
        <RoundedBox material={material2} args={[size[0] - 0.5, size[1] + 0.01, size[2]]} radius={0.035} position={[-0.25, 0, 0]} />
      </mesh>
    </group>
  )
})
