import {backpanelMaterials} from '../datamodels'
import {IStore, ISetProduce} from '../store'

export const createBackpanelSlice = (setProduce: ISetProduce<IStore>) => {
  const backpanels = {
    setMaterialID: (newMaterialID: keyof typeof backpanelMaterials): void =>
      setProduce((state: IStore) => {
        state.config.backpanels.materialID = newMaterialID
      }),
  }

  return {
    backpanels,
  }
}
