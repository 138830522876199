import {ConnectedIkuPrice, ConnectedIkuSlattedPrice} from 'products/iku/utils/price'
import {useStore} from '../store/store'
import {localFactors, materialTreatments, materialTypes, slattedModel} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {bedMaterials, baseUrl, headBoardModel, bedModel} from '../store/datamodels'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [length, width, bedMaterialID, shelfTreatmentID, feetHeight, uri, frameHeight, footID, headBoardID, headBoardHeight, frameFlush, slattedId, mattressHeight] = useStore((state) => [
    state.config.main.length,
    state.config.main.width,
    state.config.main.materialID,
    state.config.main.treatmentID,
    state.config.feet.height,
    state.uri,
    state.config.main.frameHeight,
    state.config.feet.id,
    state.config.main.headBoard.id,
    state.config.main.headBoard.height,
    state.config.main.frameFlush,
    state.config.slatted.id,
    state.config.mattress.height,
  ])
  const bedMaterial = bedMaterials[bedMaterialID]
  const shelfMaterialName = bedMaterial.type === 0 ? materialTypes[bedMaterial.type].name + ' ' + bedMaterial.name : bedMaterial.name + ' ' + materialTypes[bedMaterial.type].name
  const shelfTreatment = materialTreatments[shelfTreatmentID].name
  const totalLength = useStore((state) => state.config.main.length) + (frameFlush ? 0 : 2 * bedModel.nominalThickness)
  const totalWidth = useStore((state) => state.config.main.width) + (frameFlush ? 0 : 2 * bedModel.nominalThickness)
  const footHeight = feetHeight
  const additionalFootTitle = footID === 'bedRct' ? 'F' : ''
  const headBoard = headBoardID !== 0
  const additionalTitle =
    (headBoard ? headBoardModel[headBoardID].short + additionalFootTitle : frameFlush ? 'B' + additionalFootTitle : footID === 'bedRnd' ? 'R' : 'F') + (slattedId === 'integrated' ? '+' : '')
  const title = 'Iku ' + Math.round(frameHeight * 100) + additionalTitle
  const fullHeight = Math.round((frameHeight + footHeight + (headBoard ? headBoardHeight : 0)) * 1000)
  const upperFrameHeight = Math.round((frameHeight + footHeight) * 1000)
  const heightText = headBoard ? upperFrameHeight + '/' + fullHeight : upperFrameHeight.toString()
  const slattedPrice = ConnectedIkuSlattedPrice()

  return (
    <div className="description">
      <div className="print_title">{title}</div>
      <div className="sub_title">
        <a href={baseUrl + '/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {shelfTreatment}
          <div className="sub_title">Abmessungen</div>
          <div>
            {Math.round(totalLength * 1000)} mm x {Math.round(totalWidth * 1000)} mm x {heightText} mm{' '}
          </div>
          <div>
            für Matratzengröße: {Math.round(length * 100)} cm x {Math.round(width * 100)} cm{' '}
          </div>
        </div>
        <div className="col">
          <div className="sub_title">Lattenrost</div>
          <div style={{display: 'flow-root'}}>
            <div className="left">{slattedModel[slattedId].name}</div>
            <div className="right">{slattedPrice === 0 ? '- €' : slattedPrice + ' €'}</div>
          </div>
          {slattedModel[slattedId].description}
          <div></div>
          <div className="sub_title">Ausstattung</div>
          <div>dargestellte Matratzenhöhe {mattressHeight * 100} cm</div>
          <div className="price_title">Preis </div>
          <div className="price">
            <ConnectedIkuPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}
