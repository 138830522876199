import {regalModel} from '../store/datamodels'
import {ITekturConfig} from '../store/initialConfig'

export const getMinGrid = (width: number, gridMax: number) => Math.ceil(width / gridMax)
export const getMaxGrid = (width: number, gridMin: number) => Math.floor(width / gridMin)

export const getGridOptions = (width: number, depth: number, material: {thickness: number; eModul: number; weight: number}) => {
  const shelfThickness = material.thickness
  const eModul = material.eModul
  const weight = material.weight

  const colOptions = []
  const minGrid = Math.ceil(width / regalModel.gridMax)
  const maxGrid = Math.floor(width / regalModel.gridMin)
  for (let i = minGrid; i <= maxGrid; i++) {
    const newColSizes = (width - shelfThickness) / i
    const maxLoadkg = (0.5 * 48 * ((Math.pow(shelfThickness * 1000, 3) * depth * 1000) / 12) * eModul) / Math.pow(newColSizes * 1000, 3) / 10
    const ownWeight = ((shelfThickness * depth * newColSizes) / 1000) * weight
    const maxLoad = Math.round((maxLoadkg - ownWeight) * 0.75)
    colOptions[i - minGrid] = {col: i, maxLoad: maxLoad}
  }
  return colOptions
}

export const clearConfig = (config: ITekturConfig) => {
  const clearedBackpanels = clearBackpanels(config.backpanels.list, config.rows.list, config.main.grid)
  const clearedslidingDoors = clearSlidingDoors(config.slidingDoors.list, config.rows.list, config.main.grid, config.main.shelfTyp)
  const clearedDrawers = clearDrawers(config.drawers.list, config.rows.list, config.main.grid)
  const clearedFlaps = clearFlaps(config.flaps.list, config.rows.list, config.main.grid, config.main.shelfTyp)
  const clearedTabelars = clearTabelars(config.tabelars.list, config.rows.list, config.main.grid)
  const clearedConfig = {
    ...config,
    backpanels: {materialID: config.backpanels.materialID, list: clearedBackpanels},
    slidingDoors: {materialID: config.slidingDoors.materialID, list: clearedslidingDoors},
    drawers: {materialID: config.drawers.materialID, list: clearedDrawers},
    flaps: {materialID: config.flaps.materialID, list: clearedFlaps},
    tabelars: {list: clearedTabelars},
  }
  return clearedConfig
}

export const clearBackpanels = (backpanel: ITekturConfig['backpanels']['list'], rows: ITekturConfig['rows']['list'], grid: ITekturConfig['main']['grid']) => {
  const clearedBackpanels = backpanel.filter((backpanel) => backpanel.pos.y < rows.length && backpanel.pos.x < grid)
  clearedBackpanels.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedBackpanels
}

export const clearSlidingDoors = (
  slidingDoors: ITekturConfig['slidingDoors']['list'],
  boards: ITekturConfig['rows']['list'],
  grid: ITekturConfig['main']['grid'],
  shelfTyp: ITekturConfig['main']['shelfTyp']
) => {
  const clearedslidingDoors = slidingDoors.filter((slidingDoor) => slidingDoor.pos.y < boards.length && slidingDoor.pos.x < grid)
  clearedslidingDoors.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  shelfTyp === 2 && clearedslidingDoors.splice(0, 0)
  return clearedslidingDoors
}

export const clearDrawers = (drawers: ITekturConfig['drawers']['list'], boards: ITekturConfig['rows']['list'], grid: ITekturConfig['main']['grid']) => {
  const clearedDrawers = drawers.filter((drawer) => drawer.pos.y < boards.length && drawer.pos.x < grid)
  clearedDrawers.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedDrawers
}

export const clearFlaps = (flaps: ITekturConfig['flaps']['list'], boards: ITekturConfig['rows']['list'], grid: ITekturConfig['main']['grid'], shelfTyp: ITekturConfig['main']['shelfTyp']) => {
  const clearedFlaps = flaps.filter((flap) => flap.pos.y < boards.length && flap.pos.x < grid)
  clearedFlaps.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  shelfTyp === 1 && clearedFlaps.splice(0, 0)
  return clearedFlaps
}

export const clearTabelars = (tabelars: ITekturConfig['tabelars']['list'], rows: ITekturConfig['rows']['list'], grid: ITekturConfig['main']['grid']) => {
  const clearedTabelars = tabelars.filter((tabelar) => {
    const innerYPos = tabelar.pos.y - Math.floor(tabelar.pos.y)
    const tabelarIsInShelf = tabelar.pos.y < rows.length && tabelar.pos.x < grid
    const tabelarminHeight = tabelarIsInShelf && rows[Math.floor(tabelar.pos.y)].height * (0.5 - Math.abs(0.5 - innerYPos)) > regalModel.minColHeight
    if (tabelarIsInShelf && tabelarminHeight) return true
    return false
  })
  clearedTabelars.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedTabelars
}
