import {useBounds} from '@react-three/drei'
import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/kubatur/store/store'
import {useEffect} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, slidingDoorMaterials} from '../../store/datamodels'

interface IColumnSelectors {
  list: {key: number; xPos: number; callback: () => void}[]
  material?: THREE.Material
  depth: number
  height: number
  thickness: number //TODO: why?
}

export const ColumnSelectors = (props: IColumnSelectors) => {
  // if ColumnSelectors are rendered, recalculate Bounds and adjust Camera
  const boundsApi = useBounds()
  useEffect(() => {
    // boundsApi.refresh().fit()
    // boundsApi?.refresh()
    return () => {
      // boundsApi?.refresh().fit()
    }
  }, [boundsApi])

  return (
    <>
      {props.list.map((el, i) => {
        return <Selector key={el.key} rotation={[0, 0, 0.5]} translate={[0.5, -0.5, 0.5]} size={[props.height, props.thickness, props.depth]} xPos={el.xPos} yPos={0} zPos={0} onClick={el.callback} />
      })}
    </>
  )
}

export const ConnectedColumnSelectors = () => {
  const [columns, depth, height, materialID, slidingDoorMaterialID, visible] = useStore(
    (store) => [store.config.columns, store.config.main.depth, store.config.main.height, store.config.main.materialID, store.config.slidingDoors.materialID, store.view.colums.edit],
    shallow
  )

  const thickness = shelfMaterials[materialID].thickness
  const doorThickness = slidingDoorMaterials[slidingDoorMaterialID].thickness
  const xPos = columns.list.reduce((acc, col) => acc + col.width + thickness, 0)
  const deleteColumn = useStore((store) => store.columns.delete)
  const addColumn = useStore((store) => store.columns.create)
  const list = [
    {key: columns.list.length, xPos: xPos, callback: deleteColumn},
    {key: columns.list.length + 1, xPos: xPos + regalModel.newColumWidth, callback: addColumn},
  ]
  if (columns.list.length < 2) list.shift()
  return <>{visible && <ColumnSelectors thickness={thickness} list={list} depth={depth + 2 * (regalModel.slidingDoorDistance + thickness) + doorThickness - 0.01} height={height} />}</>
}
