import {backpanelMaterials} from '../datamodels'
import {IStore, ISetProduce} from '../store'
import {GetState} from 'zustand'

export const createBackpanelSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const backpanels = {
    setMaterialID: (newMaterialID: keyof typeof backpanelMaterials): void =>
      setProduce((state: IStore) => {
        state.config.backpanels.materialID = newMaterialID
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.backpanels.variant = variant
      })
    },
    setEditBackpanels: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.backpanels.edit = edit
      }),
    toggleBackpanel: (xPos: number, yPosIndex: number, cover: 'full' | 'fullWidth' | 'compartment') => {
      const backpanels = get().config.backpanels.list
      setProduce((store: IStore) => {
        const clearedBackpanelArray = backpanels.filter((backpanel: {pos: {x: number; y: number}; cover: 'full' | 'fullWidth' | 'compartment'; face: 'front' | 'back'}) => {
          if (backpanel.pos.x === xPos && (backpanel.pos.y === yPosIndex || cover === 'full' || backpanel.cover === 'full')) return false
          if (Math.floor(backpanel.pos.x) === Math.floor(xPos) && backpanel.pos.y === yPosIndex && (cover === 'fullWidth' || backpanel.cover === 'fullWidth')) return false
          return true
        })
        if (store.config.backpanels.list.find((backpanel) => backpanel.pos.x === xPos && backpanel.pos.y === yPosIndex && cover === backpanel.cover)) {
          store.config.backpanels.list = clearedBackpanelArray
        } else {
          store.config.backpanels.list = [...clearedBackpanelArray, {pos: {x: xPos, y: yPosIndex}, cover: cover, face: 'back'}]
        }
      })
    },
  }

  return {
    backpanels,
  }
}
