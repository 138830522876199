export const Lights = () => {
  return (
    <>
      <pointLight intensity={75} distance={16.5} position={[0, 10, 0]} name="top pointlight" />
      <pointLight intensity={35} distance={7.5} position={[0, 1.5, 6]} name="front pointlight" />
      <pointLight intensity={33} distance={9} position={[4, 1.5, 4]} name="right pointlight" />
      <pointLight intensity={33} distance={9} position={[-4, 1.5, 4]} name="left pointlight" />
      <pointLight intensity={33} distance={9} position={[4, 1.5, -4]} name="right rear pointlight" />
      <pointLight intensity={33} distance={9} position={[-4, 1.5, -4]} name="left rear pointlight" />
      <ambientLight intensity={2} />
      {/* 
      <pointLight intensity={65} distance={10.5} position={[0, 10, 0]} name="top pointlight" />
      <pointLight intensity={35} distance={6.5} position={[0, 1.5, 6]} name="front pointlight" />
      <pointLight intensity={28} distance={6} position={[3, 1.5, 3]} name="right pointlight" />
      <pointLight intensity={28} distance={6} position={[-3, 1.5, 3]} name="left pointlight" />
      <pointLight intensity={33} distance={6} position={[3, 1.5, -3]} name="right rear pointlight" />
      <pointLight intensity={33} distance={6} position={[-3, 1.5, -3]} name="left rear pointlight" />
      <ambientLight intensity={1.5} /> */}
    </>
  )
}
