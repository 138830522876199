import {useStore} from 'products/digital/store/store'
import {regalModel, shelfMaterials, drawerFrontMaterials, backpanelMaterials} from 'products/digital/store/datamodels'
import {materialModel, drawerLengths, drawerSideInset} from 'utils/datamodels'
import {getDrawerParts} from 'utils/helpers'
import {IDrawer} from 'common/threeComponents/DrawerFCNew'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedDigitalDrawerList = () => {
  const [drawerList, backpanels, depth, shelfWidth, columns, boards, grid, shelfMaterialID, drawerMaterial] = useStore(
    useShallow((store) => [
      store.drawers.clearDrawers(),
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.width,
      store.config.columns.list,
      store.config.rows.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.drawers.materialID,
    ])
  )
  return DigitalDrawerList(drawerList, backpanels, depth, shelfWidth, columns, boards, grid, shelfMaterialID, drawerMaterial)
}

export const DigitalDrawerList = (
  drawerList: any[],
  backpanels: {
    list: {
      pos: {
        x: number
        y: number
      }
      cover: 'full' | 'compartment'
      face: 'front' | 'back'
    }[]
    materialID: keyof typeof backpanelMaterials
  },
  depth: number,
  shelfWidth: number,
  columns: number[][],
  boards: any[],
  grid: number,
  shelfMaterialID: keyof typeof shelfMaterials,
  drawerMaterial: keyof typeof drawerFrontMaterials
) => {
  const bodyMaterialID = shelfMaterials[shelfMaterialID].drawerInnerMaterial as keyof typeof materialModel
  const thickness = shelfMaterials[shelfMaterialID].thickness
  const bodyThickness = materialModel[bodyMaterialID].thickness
  const gridSize = (shelfWidth - thickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const leftInset = drawerSideInset - bodyThickness - shadowJoint
  const drawers: IDrawer[] = []

  drawerList.forEach((drawer, j) => {
    const yPosIndex = Math.floor(drawer.pos.y)
    const frontThickness = drawerFrontMaterials[drawerMaterial].thickness
    const fullBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.y === yPosIndex
      })?.cover === 'full'
    const isBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.x === drawer.pos.x && obj.pos.y === yPosIndex
      }) || fullBackpanel
    const backpanelInset = isBackpanel ? (backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    const drawerDepth = Math.max(...drawerLengths.filter((l) => l < depth - 0.001 - frontThickness - 0.013 - 0.008 - backpanelInset)) + frontThickness
    if (drawerDepth === -Infinity) return null
    const height = drawer.size.y * (boards[yPosIndex].height - shadowJoint) - shadowJoint
    let columnList = [grid]
    columns[yPosIndex].forEach((column) => {
      if (column > drawer.pos.x) return columnList.push(column)
    })
    const nextColumn = Math.min(...columnList)
    const multiplier = nextColumn - drawer.pos.x
    const width = multiplier * gridSize - 2 * shadowJoint - thickness
    const bodyHeight = Math.floor((height - 0.015 - 0.0175) * 200) / 200
    const topInset = height - bodyHeight - 0.015 + (shadowJoint - 0.003) / 2
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + thickness, thickness + shadowJoint) + (drawer.pos.y - yPosIndex) * (boards[yPosIndex].height - shadowJoint)
    const xPos = thickness + drawer.pos.x * gridSize + shadowJoint
    const bottomMaterialID = 'mdf8W' as keyof typeof materialModel
    const drawerParts = getDrawerParts({
      width: width,
      height: height,
      depth: drawerDepth,
      bodyHeight: bodyHeight,
      shelfInnerWidth: width + 2 * shadowJoint,
      frontMaterialID: drawerMaterial,
      bodyMaterialID: bodyMaterialID,
      bottomMaterialID: bottomMaterialID,
    })
    drawers.push({
      key: drawer.pos.x + '-' + drawer.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: depth - 0.001,
      width: width,
      height: height,
      depth: drawerDepth,
      leftInset: leftInset,
      topInset: topInset,
      parts: drawerParts,
      type: 'drawer',
    })
  })

  return drawers
}
