import { ReactNode } from "react";
import './perspectiveControlContianer.scss'

export const PerspectiveControlContainer = ({children}:{children:ReactNode}) => <div className="perspectiveControlContainer">{children}</div>

type IPerspectiveControlButton = {
	id: string
	altText: string
	children: ReactNode
	onClick: (e?:any) => void
	iconSrc: string
}

export const PerspectiveControlButton = (props:IPerspectiveControlButton) => {
	return <span id={props.id} onClick={props.onClick}><div className="tooltip"><img alt={props.altText} src={props.iconSrc} /><span className="tooltiptext">{props.children}</span></div></span>
}