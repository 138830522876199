import {useStore} from 'products/iku/store/store'
import {Matress} from 'common/threeComponents/Matress'
import {slattedModel} from 'utils/datamodels'

export const ConnectedMattress = () => {
  const frameHeight = useStore((store) => store.config.main.frameHeight)
  const slattedHeightInStore = useStore((store) => store.config.slatted.height)
  const slattedId = useStore((store) => store.config.slatted.id)
  const length = useStore((store) => store.config.main.length)
  const width = useStore((store) => store.config.main.width)
  const mattressHeight = useStore((store) => store.config.mattress.height)
  const frameFlush = useStore((store) => store.config.main.frameFlush)
  const headBoardID = useStore((store) => store.config.main.headBoard.id)
  const frameLength = frameFlush ? length : headBoardID > 1 && headBoardID < 4 ? length + 0.025 : length + 2 * 0.025
  const frameWidth = frameFlush ? width : headBoardID > 1 ? width + 0.025 : width + 2 * 0.025
  const slattedHeight = slattedId !== 'own' ? slattedModel[slattedId].height : slattedHeightInStore
  const slattedInsetstore = useStore((store) => store.config.slatted.inset)
  const slattedInset = frameFlush ? slattedHeight + 0.01 : slattedInsetstore
  const xPos = headBoardID < 2 || headBoardID > 3 ? frameLength / 2 : headBoardID === 2 ? frameLength / 2 + 0.0125 : frameLength / 2 - 0.0125
  const yPos = frameFlush || headBoardID > 1 ? frameHeight - 0.01 : frameHeight - slattedInset + slattedHeight
  const zPos = headBoardID > 1 ? (frameWidth + 0.025) / 2 : frameWidth / 2

  return (
    <>
      <Matress size={[length, mattressHeight, width]} xPos={xPos} yPos={yPos} zPos={zPos} />
    </>
  )
}
