import {useStore} from 'products/analog/store/store'
import {shallow} from 'zustand/shallow'
import {blueprint, shelfMaterialModel, footModel} from 'products/analog/store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {materialModel} from 'utils/datamodels'

export const ConnectedAnalogFootList = () => {
  const [feetID, footHeight, depth, columns, width, grid, materialID] = useStore(
    (store) => [store.config.feet.id, store.config.feet.height, store.config.main.depth, store.rows.clearColumns(), store.config.main.width, store.config.main.grid, store.config.main.materialID],
    shallow
  )
  return AnalogFootList(feetID, footHeight, depth, columns, width, grid, materialID)
}

export const AnalogFootList = (feetID: keyof typeof footModel, footHeight: number, depth: number, columns: number[][], width: number, grid: number, materialID: keyof typeof shelfMaterialModel) => {
  const foot = footModel[feetID]
  const thickness = shelfMaterialModel[materialID].thickness
  const feet = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel}[]
  const gridSize = (width - thickness) / grid
  const nrGridsInset = thickness / 2 + 2 * gridSize < 0.4 ? 2 : 1
  const xInset = width > 1.2 ? nrGridsInset * gridSize + thickness / 2 : foot.xInset || 0
  const nrFeet = Math.ceil((width - 2 * xInset) / blueprint.maxFootSpan) + 1
  const footSizeX = foot.sizeXY !== undefined ? foot.sizeXY[0] : 0.015
  const footSizeY = foot.sizeXY !== undefined ? foot.sizeXY[1] : 0.015
  const footSize = [footSizeX, footHeight, footSizeY] as IVector
  switch (feetID) {
    case 's23':
    case 'castor43':
      feet.push({key: '0.0', pos: [foot.xInset || 0, 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
      feet.push({key: '0.1', pos: [foot.xInset || 0, 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      columns[0].forEach((el) => {
        feet.push({key: el + '.0', pos: [thickness / 2 + el * gridSize, 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
        feet.push({key: el + '.1', pos: [thickness / 2 + el * gridSize, 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      })
      feet.push({key: grid + '.0', pos: [width - (foot.xInset || 0), 0, depth - (foot.yInset || 0)], size: footSize, material: 'solidBlack', type: feetID})
      feet.push({key: grid + '.1', pos: [width - (foot.xInset || 0), 0, foot.yInset || 0], size: footSize, material: 'solidBlack', type: feetID})
      break
    case 'rnd30':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
      })
      break
    case 'rct16':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
      })
      break
  }
  return feet
}
