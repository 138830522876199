import {AccordionListSelector, AccordionMaterialSelector} from '../../../../components/material_interfaces/materialInterfaces'
import {useStore} from 'products/dasregal/store/store'
import {getMaterialName} from 'utils/helpers'
import {frontMaterials, regalModel, shelfMaterials} from 'products/dasregal/store/datamodels'
import {useState} from 'react'

export function Flaps() {
  const flaps = useStore((store) => store.view.flaps)

  const [columns, height, flapMaterial, grid] = useStore((store) => [store.config.columns.list, store.config.main.height, store.config.flaps.materialID, store.config.main.grid])
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const thickness = shelfMaterials[shelfMaterial].thickness
  const setMaterialID = useStore((store) => store.flaps.setMaterialID)
  const setEditSize = useStore((store) => store.flaps.setSize)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const gridSize = (height - thickness) / grid - thickness

  const materialOptions = [...Object.values(frontMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof frontMaterials)}
    })

  const flapSizes = []
  for (let i = 1; i <= Math.floor(regalModel.maxFlapHeight / gridSize); i++) {
    flapSizes.push({name: 1 + ' Element breit, Höhe: ' + Math.round(i * gridSize * 1000) + ' mm', callbackValue: {x: 1, y: i}})
  }
  if (height - thickness <= regalModel.maxFlapHeight) {
    for (let i = 2; i <= columns.length; i++) {
      for (let j = 0; j <= columns.length - i; j++) {
        let width = -thickness
        for (let k = j; k < i; k++) {
          width += columns[k].width
        }
        if (width <= regalModel.maxFlapWidth) {
          flapSizes.push({name: i + ' Element breit, Höhe: ' + Math.round(grid * gridSize * 1000) + ' mm', callbackValue: {x: i, y: grid}})
          break
        }
      }
    }
  }

  const flapOptions = flapSizes.map((e, i) => ({id: i, name: e.name, callbackValue: e.callbackValue}))
  const selected = flapOptions.findIndex((e) => e.callbackValue.x === flaps.size.x && e.callbackValue.y === flaps.size.y)

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={flapMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector
        options={flapOptions}
        onClick={(id: number) => setEditSize(flapOptions.find((option) => option.id === id)?.callbackValue as {x: number; y: number})}
        title={'Größe'}
        value={selected}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
