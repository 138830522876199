import {materialModel, slattedModel} from 'utils/datamodels'
import {footModel, bedMaterials} from './datamodels'

export const initialConfig = {
  main: {
    frameHeight: 0.125,
    width: 1.6,
    length: 2.0,
    materialID: 'm24Ei' as keyof typeof bedMaterials,
    treatmentID: 0,
    headBoard: {
      id: 1,
      height: 0.455,
    },
    sideBoardWidth: 0.175,
    footBoardWidth: 0.125,
  },
  feet: {
    height: 0.125,
    id: 'bedRnd' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
  mattress: {
    height: 0.2,
  },
  slatted: {
    height: 0.05,
    inset: 0.091,
    id: 'own' as keyof typeof slattedModel,
  },
}

export type IUnisonoConfig = typeof initialConfig
