import {useStore} from 'products/dinadrei/store/store'
import {bedMaterials, bedModel} from '../store/datamodels'

export const ConnectedDinadreiBoardList = () => {
  const length = useStore((store) => store.config.main.length)
  const width = useStore((store) => store.config.main.width)
  const sideBoardWidth = useStore((store) => store.config.main.sideBoardWidth)
  const materialID = useStore((store) => store.config.main.materialID)
  const frameMaterialID = useStore((store) => store.config.main.frameMaterialID)
  const frameHeight = useStore((store) => store.config.main.frameHeight)
  const headBoardHeight = useStore((store) => store.config.main.headBoardHeight)
  const footHeight = useStore((store) => store.config.main.footHeight)
  return DinadreiBoardList(length, width, sideBoardWidth, materialID, frameMaterialID, frameHeight, headBoardHeight, footHeight)
}

export const DinadreiBoardList = (
  length: number,
  width: number,
  sideBoardWidth: number,
  materialID: keyof typeof bedMaterials,
  frameMaterialID: keyof typeof bedMaterials,
  frameHeight: number,
  headBoardHeight: number,
  footHeight: number
) => {
  const thickness = bedMaterials[materialID].thickness

  const boards: {
    key: number | string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    rotation?: [number, number, number]
    translate?: [number, number, number]
    material: keyof typeof bedMaterials
    type: string
  }[] = []

  boards.push({
    key: 'sideRight',
    xPos: thickness,
    yPos: 0,
    zPos: sideBoardWidth + width + bedModel.nominalThickness,
    length: length,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, 0],
    translate: [0.5, 0.5, 0.5],
    material: frameMaterialID,
    type: 'frame',
  })
  boards.push({
    key: 'sideBoardRight',
    xPos: thickness,
    yPos: frameHeight - thickness,
    zPos: sideBoardWidth + width,
    length: length,
    depth: sideBoardWidth,
    thickness: thickness,
    translate: [0.5, 0.5, 0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'sideLeft',
    xPos: thickness,
    yPos: 0,
    zPos: sideBoardWidth - bedModel.nominalThickness,
    length: length,
    depth: frameHeight - thickness,
    thickness: thickness,
    rotation: [-0.5, 0, 0],
    translate: [0.5, -0.5, 0.5],
    material: frameMaterialID,
    type: 'frame',
  })
  boards.push({
    key: 'sideBoardLeft',
    xPos: thickness,
    yPos: frameHeight - thickness,
    zPos: sideBoardWidth,
    length: length,
    depth: sideBoardWidth,
    thickness: thickness,
    translate: [0.5, 0.5, -0.5],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'footSide',
    xPos: 0,
    yPos: 0 - footHeight,
    zPos: 0,
    length: width + 2 * sideBoardWidth,
    depth: frameHeight + footHeight,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    translate: [0.5, 0.5, 0.5],
    material: materialID,
    type: 'footBoard',
  })
  boards.push({
    key: 4,
    xPos: thickness + length,
    yPos: -footHeight,
    zPos: sideBoardWidth - bedModel.nominalThickness,
    length: width + 2 * bedModel.nominalThickness,
    depth: frameHeight - thickness + footHeight,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    material: materialID,
    type: 'headBoard',
  })
  boards.push({
    key: 5,
    xPos: thickness + length,
    yPos: frameHeight - thickness,
    zPos: 0,
    length: width + 2 * sideBoardWidth,
    depth: headBoardHeight + thickness,
    thickness: thickness,
    rotation: [-0.5, 0, -0.5],
    material: materialID,
    type: 'headBoard',
  })
  return boards
}
