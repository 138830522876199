import {Slatted} from 'common/threeComponents/Slatted'
import {SlattedIntegrated} from 'common/threeComponents/SlattedIntegrated'
import {ConnectedBezweiSlattedList} from 'products/bezwei/partLists/slattedList'

export const ConnectedSlatteds = () => {
  const slattedList = ConnectedBezweiSlattedList()

  return (
    <>
      {slattedList.map((slatted) => {
        switch (slatted.type) {
          case 'own':
          case 'standard':
            return <Slatted {...slatted} />
          case 'integrated':
            return <SlattedIntegrated {...slatted} />
          default:
            return <Slatted {...slatted} />
        }
      })}
    </>
  )
}
