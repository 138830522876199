import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import Grid from '@mui/material/Grid'
import {Link} from 'react-router-dom'
import {Link as RouterLink} from 'react-router-dom'

export const SaveLoadModal = ({
  toSave,
  toLoad,
  toAbort,
  onClose = () => null,
}: {
  toSave: any
  toLoad: any
  toAbort: any
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined
}) => (
  <Dialog open={true} aria-labelledby="Save or Load Configuration Dialog" aria-describedby="Save or Load Shelf Configuration" onClose={onClose}>
    <DialogTitle>Wollen Sie eine Konfiguration laden oder speichern?</DialogTitle>
    <DialogContent>
      <Grid container justifyContent="space-evenly">
        <Button disableElevation={true} to={toSave} component={RouterLink}>
          Konfiguration Speichern
        </Button>
        <Button disableElevation={true} to={toLoad} component={RouterLink}>
          Konfiguration Laden
        </Button>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Link to={toAbort}>
        <Button>Abbrechen</Button>
      </Link>
    </DialogActions>
  </Dialog>
)

export const loadConfig = async (uri: string) => {
  //fetch from uri
  await fetch(uri)
    .then((r) => r.json())
    .then((r) => {
      // parse json
      const pData = JSON.parse(r)
      // check if valid json
      if (pData?.depth) {
        // return json
        return pData
      }
    })
    .catch((e) => console.log(e))
  return null
}

interface IData {
  reference: string
  configuration: any
}

export const saveConfig = async (uri: string, data: IData) => {
  const body = JSON.stringify(data, null, 2)
  const requestOptions: any = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body,
  }
  const returnedData = await fetch(uri, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      // const newUri = data.uri //get uri from response
      // set({uri: newUri}) // set uri in state
      return data // return data to tmpData for further use
    })
    .catch((err) => console.log(err))
  return returnedData // return data including data.uri
}
