import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider, AccordionSlider} from 'components/material_interfaces/materialInterfaces'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {materialTreatments, materialTypes} from 'utils/datamodels'
import {getMaxGrid, getMinGrid} from 'utils/helpers'
import {blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'

export function ShelfStructure() {
  const {width, depth, grid} = useStore((store) => ({width: store.config.main.width, depth: store.config.main.depth, grid: store.config.main.grid}), shallow)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const materialTreatment = useStore((store) => store.config.main.treatmentID)
  const materialHasTreatment = shelfMaterialModel[shelfMaterial].treatment
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialType = useStore((store) => store.setMaterialType)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setDepth = useStore((store) => store.setDepth)
  const setWidth = useStore((store) => store.setWidth)
  const setGrid = useStore((store) => store.setGrid)

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const regalMaterialType = shelfMaterialModel[useStore((store) => store.config.main.materialID)].type
  const materialTypeList = Object.values(shelfMaterialModel).map((material) => material.type)
  const typeList = materialTypes.filter((type) => materialTypeList.includes(type.id))

  const materialOptions = [...Object.values(shelfMaterialModel)].filter((material) => material.type === regalMaterialType && material.hasOwnProperty('archived') === false)

  const treatmentOptions = [...Object.values(materialTreatments)]

  const minGrid = getMinGrid(width, blueprint.gridMax)
  const maxGrid = getMaxGrid(width, blueprint.gridMin)

  const gridOptions = []
  for (let i = minGrid; i <= maxGrid; i++) {
    const gridSize = (width - shelfMaterialModel[shelfMaterial].thickness) / i
    gridOptions.push({id: i, name: i + `x${Math.round(gridSize * 1000)}`, longName: i + ` x ${Math.round(gridSize * 1000)} mm`})
  }

  const typeOptions = typeList.map((type) => {
    const thickness =
      shelfMaterialModel[shelfMaterial].type === type.id
        ? shelfMaterialModel[shelfMaterial].thickness
        : shelfMaterialModel[shelfMaterialModel[shelfMaterial].fallback as keyof typeof shelfMaterialModel].thickness
    return {...type, name: type.name + ', ' + Math.round(thickness * 1000) + ' mm'}
  })

  return (
    <>
      <AccordionListSelector id="type" options={typeOptions} onClick={setMaterialType} title={'Werkstoff'} value={regalMaterialType} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        id="species"
        options={materialOptions}
        onClick={setShelfMaterialSpecies}
        title={'Holzart / Farbe'}
        value={shelfMaterial}
        expanded={expanded}
        handleChange={handleChange}
      />
      {materialHasTreatment && (
        <AccordionListSelector
          id="treatment"
          options={treatmentOptions}
          onClick={setMaterialTreatment}
          title={'Oberfläche'}
          value={materialTreatment}
          expanded={expanded}
          handleChange={handleChange}
        />
      )}
      <AccordionSizeSlider
        options={blueprint.width}
        min={blueprint.minWidth * 1000}
        max={shelfMaterialModel[shelfMaterial].maxWidth * 1000}
        onClick={setWidth}
        title={'Regalbreite'}
        id="width"
        value={width * 1000}
        clickRange={60}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={blueprint.depth}
        min={blueprint.minDepth * 1000}
        max={blueprint.maxDepth * 1000}
        onClick={setDepth}
        title={'Regaltiefe'}
        id="depth"
        value={depth * 1000}
        clickRange={20}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSlider options={gridOptions} min={minGrid - 0.3} max={maxGrid + 0.3} onClick={setGrid} title={'Rastermaß'} value={grid} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
