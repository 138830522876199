import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials} from 'products/tektur/store/datamodels'

export const ConnectedTabelarSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, width, depth, grid] = useStore((store) => [store.config.rows, store.config.main.width, store.config.main.depth, store.config.main.grid], shallow)
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (width - shelfThickness) / grid
  const setTabelar = useStore((store) => store.tabelars.create)
  let startPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          startPos += board.height + shelfThickness
          const tabelarHeights: number[] = []
          const numTabHeights = Math.min(Math.floor(board.height / regalModel.minColHeight), 4)
          for (let i = 2; i <= numTabHeights; i++) {
            for (let y = 1; y < i; y++) !tabelarHeights.includes(y / i) && tabelarHeights.push(y / i)
          }
          tabelarHeights.sort((a, b) => a - b)
          return [...Array(grid)].map((_, x) => {
            const xPos = x * gridSize + shelfThickness
            const length = gridSize - shelfThickness
            return tabelarHeights.map((height, i) => {
              const yPos = startPos - (board.height + shelfThickness) * (1 - height)
              return <Selector key={x + '.' + y + height} size={[length, shelfThickness, depth]} xPos={xPos} yPos={yPos} zPos={0} onClick={() => setTabelar({x: x, y: y + height})} />
            })
          })
        })}
    </>
  )
}
