import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/dasregal/store/store'
import {accHelper} from 'utils/helpers'
import shallow from 'zustand/shallow'
import {shelfMaterials} from '../../store/datamodels'

export const ConnectedBackpanelSelectors = () => {
  const [visible, columns, boards, subColumns, backpanels, height, grid, materialID, variant, toggleBackpanel] = useStore(
    (store) => [
      store.view.backpanels.edit,
      store.config.columns,
      store.config.boards.list,
      store.config.subColumns.list,
      store.config.backpanels.list,
      store.config.main.height,
      store.config.main.grid,
      store.config.main.materialID,
      store.view.backpanels.variant,
      store.backpanels.toggleBackpanel,
    ],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  const gridSize = (height - thickness) / grid
  let startPos = thickness
  const nrFullBackpanels = backpanels.filter((el) => el.cover === 'full').length

  if (!visible) return null
  return (
    <>
      {columns.list.map((el, x) => {
        startPos += el.width + thickness
        if (variant === 2) {
          const isFullBackpanel = backpanels.filter((el) => el.cover === 'full' && el.pos.x === x).length > 0
          //if (nrFullBackpanels === 0 && !isFullBackpanel) return null
          const removable = !(nrFullBackpanels === 1 && isFullBackpanel)
          if (!removable) return null
          return (
            <Selector
              size={[height - 2 * thickness, 0.03, el.width]}
              rotation={[0.5, 0.5, 0]}
              key={'full-' + x}
              xPos={startPos - el.width - thickness}
              yPos={thickness}
              zPos={0}
              onClick={() => toggleBackpanel(x, 0, 'full')}
            />
          )
        } else {
          const visibleBoards = boards[x].filter((el) => el < grid)
          visibleBoards.push(0, grid)
          visibleBoards.sort((a, b) => a - b)
          return [...Array(visibleBoards.length - 1)].map((_, y) => {
            let multiplier = visibleBoards[y + 1] - visibleBoards[y]
            const height = multiplier * gridSize - thickness
            const yPos = visibleBoards[y] * gridSize + thickness
            const isFullBackpanel = backpanels.filter((el) => el.cover === 'full' && el.pos.x === x).length > 0
            const nrBackpanelsinColumn = backpanels.filter((el) => Math.floor(el.pos.x) === x).length
            if (variant === 0) {
              const subColumnList = accHelper.get_SubColumn_List({x: x, y: visibleBoards[y]}, columns.list, boards, subColumns)
              return [...Array(subColumnList.length - 1)].map((_, k) => {
                const isBackpanel = backpanels.filter((el) => el.pos.x === x + subColumnList[k] && el.pos.y === visibleBoards[y] && el.cover === 'compartment').length > 0
                const removable = nrFullBackpanels > 1 || (nrFullBackpanels === 1 && !isFullBackpanel) || nrBackpanelsinColumn > 1 || !isBackpanel
                if (!removable) return null
                const xPos = startPos - (1 - subColumnList[k]) * (el.width + thickness)
                const width = (el.width + thickness) * (subColumnList[k + 1] - subColumnList[k]) - thickness
                return (
                  <Selector
                    size={[height, 0.03, width]}
                    rotation={[0.5, 0.5, 0]}
                    key={x + '-' + y + '-' + k}
                    xPos={xPos}
                    yPos={yPos}
                    zPos={0}
                    onClick={() => toggleBackpanel(x + subColumnList[k], visibleBoards[y], 'compartment')}
                  />
                )
              })
            } else {
              const isBackpanel = backpanels.filter((el) => el.pos.x === x && el.pos.y === visibleBoards[y] && el.cover === 'fullWidth').length > 0
              const removable = nrFullBackpanels > 1 || (nrFullBackpanels === 1 && !isFullBackpanel) || nrBackpanelsinColumn > 1 || !isBackpanel
              if (!removable) return null
              return (
                <Selector
                  size={[height, 0.03, el.width]}
                  rotation={[0.5, 0.5, 0]}
                  key={x + '-' + y}
                  xPos={startPos - el.width - thickness}
                  yPos={yPos}
                  zPos={0}
                  onClick={() => toggleBackpanel(x, visibleBoards[y], 'fullWidth')}
                />
              )
            }
          })
        }
      })}
    </>
  )
}
