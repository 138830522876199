import {useStore} from 'products/dinadrei/store/store'
import {Matress} from 'common/threeComponents/Matress'
import {slattedModel} from 'utils/datamodels'

export const ConnectedMattress = () => {
  const [frameHeight, sideBoardWidth, slattedHeightInStore, slattedId, length, width, mattressHeight] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.sideBoardWidth,
    store.config.slatted.height,
    store.config.slatted.id,
    store.config.main.length,
    store.config.main.width,
    store.config.mattress.height,
  ])
  const frameLength = length + 0.05
  const frameWidth = width + 2 * sideBoardWidth
  const slattedHeight = slattedId !== 'own' ? slattedModel[slattedId].height : slattedHeightInStore
  const slattedInsetstore = useStore((store) => store.config.slatted.inset)
  const slattedInset = slattedInsetstore
  const yPos = frameHeight - slattedInset + slattedHeight

  return (
    <>
      <Matress size={[length, mattressHeight, width]} xPos={frameLength / 2} yPos={yPos} zPos={frameWidth / 2} />
    </>
  )
}
