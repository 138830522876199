import {IDigitalConfig} from '../store/initialConfig'

export const clearConfig = (config: IDigitalConfig) => {
  const clearedColumns = clearColumns(config.columns.list, config.rows.list, config.main.grid)
  const clearedBackpanels = clearBackpanels(config.backpanels.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedDrawers = clearDrawers(config.drawers.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedFlaps = clearFlaps(config.flaps.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedConfig = {
    ...config,
    columns: {list: clearedColumns},
    backpanels: {materialID: config.backpanels.materialID, list: clearedBackpanels},
    drawers: {materialID: config.drawers.materialID, list: clearedDrawers},
    flaps: {materialID: config.flaps.materialID, list: clearedFlaps},
  }
  return clearedConfig
}

export const clearColumns = (columns: IDigitalConfig['columns']['list'], rows: IDigitalConfig['rows']['list'], grid: IDigitalConfig['main']['grid']) => {
  const clearedColumns = columns.slice(0, rows.length).map((column) => column.filter((xPos) => xPos < grid).sort((a, b) => a - b))
  return clearedColumns
}

export const clearBackpanels = (
  backpanels: IDigitalConfig['backpanels']['list'],
  rows: IDigitalConfig['rows']['list'],
  columns: IDigitalConfig['columns']['list'],
  grid: IDigitalConfig['main']['grid']
) => {
  const clearedBackpanels = backpanels.filter((backpanel) => backpanel.pos.y < rows.length && backpanel.pos.x < grid && (columns[backpanel.pos.y].includes(backpanel.pos.x) || backpanel.pos.x === 0))
  clearedBackpanels.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedBackpanels
}

export const clearDrawers = (drawers: IDigitalConfig['drawers']['list'], rows: IDigitalConfig['rows']['list'], columns: IDigitalConfig['columns']['list'], grid: IDigitalConfig['main']['grid']) => {
  const clearedDrawers = drawers.filter((drawer) => drawer.pos.y < rows.length && drawer.pos.x < grid && (columns[drawer.pos.y].includes(drawer.pos.x) || drawer.pos.x === 0))
  clearedDrawers.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedDrawers
}

export const clearFlaps = (flaps: IDigitalConfig['flaps']['list'], rows: IDigitalConfig['rows']['list'], columns: IDigitalConfig['columns']['list'], grid: IDigitalConfig['main']['grid']) => {
  const clearedFlaps = flaps.filter((flap) => flap.pos.y < rows.length && flap.pos.x < grid && (columns[flap.pos.y].includes(flap.pos.x) || flap.pos.x === 0))
  clearedFlaps.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedFlaps
}
