import {useStore} from 'products/tektur/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'
import {slidingDoorMaterials} from 'products/tektur/store/datamodels'

export function SlidingDoors() {
  const doorMaterial = useStore((store) => store.config.slidingDoors.materialID)
  const setMaterialID = useStore((store) => store.slidingDoors.setMaterialID)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(slidingDoorMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof slidingDoorMaterials)}
  })

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={doorMaterial} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
