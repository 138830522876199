import {IBackpanelActions, IStore} from '../..'
import {accHelper} from 'utils/helpers'
import {backpanelMaterials} from '../datamodels'
import {GetState} from 'zustand'
import {clearBackpanels} from 'products/analog/utils/helpers'

export const createBackpanelSlice = (setProduce: any, get: GetState<IStore>) => {
  const backpanels: IBackpanelActions = {
    setEdit: (edit: boolean) =>
      setProduce((store: IStore) => {
        store.view.backpanels.edit = edit
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.backpanels.variant = variant
      })
    },
    create: (xPos: number, yPos: number, cover: 'full' | 'compartment') =>
      setProduce((store: IStore) => {
        const clearedBackpanels = store.config.backpanels.list.filter((backpanel) => {
          const otherXSize = backpanel.cover === 'full' ? store.config.main.grid : 1
          const thisXSize = cover === 'full' ? store.config.main.grid : 1
          if (accHelper.is_overlapping_with_other({x: xPos, y: yPos}, backpanel.pos, {x: thisXSize, y: 1}, {x: otherXSize, y: 1})) return false
          return true
        })
        if (!store.config.backpanels.list.find((backpanels) => backpanels.pos.x === xPos && backpanels.pos.y === yPos && backpanels.cover === cover)) {
          clearedBackpanels.push({pos: {x: xPos, y: yPos}, cover: cover, face: 'back'})
        }
        store.config.backpanels.list = clearedBackpanels
      }),
    setMaterialID: (materialID: keyof typeof backpanelMaterials) => {
      setProduce((store: IStore) => {
        store.config.backpanels.materialID = materialID
      })
    },
    clearBackpanels: () => {
      const clearedBackpanels = clearBackpanels(get().config.backpanels.list, get().config.rows.list, get().config.columns.list, get().config.main.grid)
      return clearedBackpanels
    },
  }
  return {
    backpanels,
  }
}
