import {Outlet} from 'react-router-dom'
import {AccordionElement, ParentButton, MenuBox} from 'components/material_interfaces/materialInterfaces'
import {BedStructure} from '../../Bed/BedStructure'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect} from 'react'
import {Contact} from 'components/contact/Contact'
import {SlattedMattressView} from '../../SlattedMattressView'
import {FeetHeight} from '../../Feet/FeetHeight'

export const IndexView = () => {
  const [expanded, setExpanded] = useCommonStore((state) => [state.expandedFirst, state.setExpandedFirst])
  const extended = useCommonStore((state) => state.extended)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    switch (expanded) {
      case 'structure':
        break
      default:
        break
    }
    return () => {}
  }, [expanded])

  return (
    <>
      <MenuBox>
        <AccordionElement id="structure" title="Bettrahmen" expanded={expanded === 'structure'} handleChange={handleChange}>
          <BedStructure />
        </AccordionElement>
        <AccordionElement id="matress" title="Lattenrost / Matratze" expanded={expanded === 'matress'} handleChange={handleChange}>
          <SlattedMattressView />
        </AccordionElement>
        <AccordionElement id="feet" title="Füße" expanded={expanded === 'feet'} handleChange={handleChange}>
          <FeetHeight />
        </AccordionElement>
        <ParentButton to="print">Drucken</ParentButton>
        {extended && <ParentButton to="pricelist">Preisliste</ParentButton>}
        <ParentButton to="saveload">Speichern & Laden</ParentButton>
        <AccordionElement id="contact" title="Sonderanfertigung / Frage" expanded={expanded === 'contact'} handleChange={handleChange}>
          <Contact />
        </AccordionElement>
      </MenuBox>
      <Outlet />
    </>
  )
}
