import {blueprint} from '../store/datamodels'
import {IAnalogConfig} from '../store/initialConfig'

export const clearConfig = (config: IAnalogConfig) => {
  const clearedColumns = clearColumns(config.columns.list, config.rows.list, config.main.grid)
  const clearedBackpanels = clearBackpanels(config.backpanels.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedDoors = clearDoors(config.doors.list, config.rows.list, config.main.grid)
  const clearedDrawers = clearDrawers(config.drawers.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedTabelars = clearTabelars(config.tabelars.list, config.rows.list, clearedColumns, config.main.grid)
  const clearedConfig = {
    ...config,
    columns: {list: clearedColumns},
    backpanels: {...config.backpanels, list: clearedBackpanels},
    doors: {...config.doors, list: clearedDoors},
    drawers: {...config.drawers, list: clearedDrawers},
    tabelars: {list: clearedTabelars},
  }
  return clearedConfig
}

export const clearColumns = (columns: IAnalogConfig['columns']['list'], rows: IAnalogConfig['rows']['list'], grid: IAnalogConfig['main']['grid']) => {
  const clearedColumns = columns.slice(0, rows.length).map((column) => column.filter((xPos) => xPos < grid).sort((a, b) => a - b))
  return clearedColumns
}

export const clearBackpanels = (
  backpanels: IAnalogConfig['backpanels']['list'],
  rows: IAnalogConfig['rows']['list'],
  columns: IAnalogConfig['columns']['list'],
  grid: IAnalogConfig['main']['grid']
) => {
  const clearedBackpanels = backpanels.filter((backpanel) => backpanel.pos.y < rows.length && backpanel.pos.x < grid && (columns[backpanel.pos.y].includes(backpanel.pos.x) || backpanel.pos.x === 0))
  clearedBackpanels.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedBackpanels
}

export const clearDoors = (doors: IAnalogConfig['doors']['list'], rows: IAnalogConfig['rows']['list'], grid: IAnalogConfig['main']['grid']) => {
  const clearedDoors = doors
    .filter((door) => door.pos.y < rows.length && door.pos.x < grid)
    .map((doorObj) => {
      return {
        ...doorObj,
        size: {
          x: doorObj.pos.x + doorObj.size.x > grid ? grid - doorObj.pos.x : doorObj.size.x,
          y: doorObj.pos.y + doorObj.size.y > rows.length ? rows.length - doorObj.pos.y : doorObj.size.y,
        },
      }
    })
  return clearedDoors
}

export const clearDrawers = (drawers: IAnalogConfig['drawers']['list'], rows: IAnalogConfig['rows']['list'], columns: IAnalogConfig['columns']['list'], grid: IAnalogConfig['main']['grid']) => {
  const clearedDrawers = drawers.filter((drawer) => {
    const yPosIndex = Math.floor(drawer.pos.y)
    const isInShelf = drawer.pos.y < rows.length && drawer.pos.x < grid
    const height = isInShelf ? drawer.size.y * (rows[yPosIndex].height - blueprint.shadowJoint) - blueprint.shadowJoint : 0
    const isInSizeRange = height > blueprint.minDrawerHeight && height < blueprint.maxDrawerHeight
    const hasColumn = columns[yPosIndex].includes(drawer.pos.x) || drawer.pos.x === 0
    return isInShelf && hasColumn && isInSizeRange
  })

  clearedDrawers.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedDrawers
}

export const clearTabelars = (tabelars: IAnalogConfig['tabelars']['list'], rows: IAnalogConfig['rows']['list'], columns: IAnalogConfig['columns']['list'], grid: IAnalogConfig['main']['grid']) => {
  const clearedTabelars = tabelars.filter((tabelar) => {
    const innerYPos = tabelar.pos.y - Math.floor(tabelar.pos.y)
    const tabelarIsInShelf = tabelar.pos.y < rows.length && tabelar.pos.x < grid
    const tabelarhasColumn = columns[Math.floor(tabelar.pos.y)]?.includes(tabelar.pos.x) || tabelar.pos.x === 0
    const tabelarminHeight = tabelarIsInShelf && rows[Math.floor(tabelar.pos.y)].height * (0.5 - Math.abs(0.5 - innerYPos)) > blueprint.minColHeight
    if (tabelarIsInShelf && tabelarhasColumn && tabelarminHeight) return true
    return false
  })
  clearedTabelars.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
  return clearedTabelars
}
