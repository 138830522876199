import {materialModel} from 'utils/datamodels'
import {backpanelMaterials, footModel, frontMaterials, shelfMaterials, shelfTypes, slidingDoorMaterials} from './datamodels'

const initialColumns = [
  {
    width: 1.173,
    face: 'front' as 'front' | 'back' | 'side',
  },
  {
    width: 1.173,
    face: 'front' as 'front' | 'back' | 'side',
  },
]

export const initialConfig = {
  main: {
    grid: 5,
    height: 2.375,
    materialID: 'm18MW' as keyof typeof shelfMaterials,
    depth: 0.6,
    shelfTyp: 1 as keyof typeof shelfTypes,
    treatmentID: 1,
    roomDivider: false,
  },
  boards: {
    list: [
      [1, 2, 3, 4],
      [1, 4],
    ],
  },
  backpanels: {
    materialID: 'mdf8W' as keyof typeof backpanelMaterials,
  },
  columns: {
    list: initialColumns,
  },
  doors: {
    materialID: 'm18Ei' as keyof typeof frontMaterials,
    list: [
      {
        pos: {x: 0.5, y: 0},
        size: {x: 0.5, y: 5},
        side: 'right' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 0, y: 0},
        size: {x: 0.5, y: 5},
        side: 'left' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 1.5, y: 0},
        size: {x: 0.5, y: 5},
        side: 'right' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
      {
        pos: {x: 1, y: 0},
        size: {x: 0.5, y: 5},
        side: 'left' as 'left' | 'right',
        face: 'front' as 'front' | 'back',
      },
    ],
  },
  slidingDoors: {
    materialID: 'h2SWBC' as keyof typeof slidingDoorMaterials,
    inFront: 'right' as 'left' | 'right' | 'middle',
    nr: 2,
  },
  drawers: {
    materialID: 'm18MW',
    list: [
      {
        pos: {x: 1, y: 0},
        size: {x: 1, y: 0.5},
        face: 'front',
        inside: true,
      },
      {
        pos: {x: 1, y: 0.5},
        size: {x: 1, y: 0.5},
        face: 'front',
        inside: true,
      },
    ],
  } as IDrawers,
  clothingRails: {
    list: [{pos: {x: 1, y: 4}}],
    materialID: 's13Ei' as keyof typeof materialModel,
  },
  subColumns: {
    list: [] as {pos: {x: number; y: number}}[],
  },
  tabelars: {
    list: [] as {pos: {x: number; y: number[]}}[],
  },
  feet: {
    height: 0.025,
    id: 'gn840' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
}

export type IKubaturConfig = typeof initialConfig

type IDrawers = {
  materialID: keyof typeof frontMaterials
  list: {
    pos: {x: number; y: number}
    size: {x: number; y: number}
    face?: 'front' | 'back'
    inside?: boolean
  }[]
}
