import {useStore} from 'products/digital/store/store'
import {footModel, regalModel, shelfMaterials} from 'products/digital/store/datamodels'
import {materialModel} from 'utils/datamodels'
import {useShallow} from 'zustand/react/shallow'

export const ConnectedDigitalSteelBeamList = () => {
  const [boardList, width, depth, materialID, feetID] = useStore(
    useShallow((store) => [store.config.rows.list, store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.feet.id])
  )
  return DigitalSteelBeamList(boardList, width, depth, materialID, feetID)
}

export const DigitalSteelBeamList = (boardList: any[], width: number, depth: number, materialID: keyof typeof shelfMaterials, feetID: keyof typeof footModel) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const steelBeams: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof materialModel; type: string}[] = []

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    steelBeams.push({
      key: 'back - ' + index,
      xPos: thickness / 2,
      yPos: startPos - regalModel.steelBeamThickness,
      zPos: 0.1,
      length: width - thickness,
      depth: regalModel.steelBeamDepth,
      thickness: regalModel.steelBeamThickness,
      material: shelfMaterials[materialID].steelMaterial as keyof typeof materialModel,
      type: 'steelBeam',
    })
    steelBeams.push({
      key: 'front - ' + index,
      xPos: thickness / 2,
      yPos: startPos - regalModel.steelBeamThickness,
      zPos: depth - 0.1 - regalModel.steelBeamDepth,
      length: width - thickness,
      depth: regalModel.steelBeamDepth,
      thickness: regalModel.steelBeamThickness,
      material: shelfMaterials[materialID].steelMaterial as keyof typeof materialModel,
      type: 'steelBeam',
    })
  })

  feetID === 'u308' &&
    steelBeams.push({
      key: 'backFeetSteel',
      xPos: 0.005,
      yPos: 0,
      zPos: 0.05,
      length: width - 0.01,
      depth: 0.02,
      thickness: 0.02,
      material: 'solidBlack' as keyof typeof materialModel,
      type: 'steelBeamFeets',
    })
  feetID === 'u308' &&
    steelBeams.push({
      key: 'frontFeetSteel',
      xPos: 0.005,
      yPos: 0,
      zPos: depth - 0.05,
      length: width - 0.01,
      depth: 0.02,
      thickness: 0.02,
      material: 'solidBlack' as keyof typeof materialModel,
      type: 'steelBeamFeets',
    })

  return steelBeams
}
