import {slattedModel} from 'utils/datamodels'
import {bedMaterials} from './datamodels'

export const initialConfig = {
  main: {
    frameHeight: 0.175,
    width: 1.6,
    length: 2.0,
    materialID: 'm24Ei' as keyof typeof bedMaterials,
    frameMaterialID: 'm25SB' as keyof typeof bedMaterials,
    treatmentID: 0,
    sideBoardWidth: 0.15,
    headBoardHeight: 0.3,
    footHeight: 0.125,
  },
  mattress: {
    height: 0.2,
  },
  slatted: {
    height: 0.05,
    inset: 0.119,
    id: 'own' as keyof typeof slattedModel,
  },
}

export type IDinadreiConfig = typeof initialConfig
