import {FootList} from '../../../../components/material_interfaces/material_interfaces'
import {footModel} from '../../store/datamodels'
import {useStore} from '../../store/store'

export function FeetHeight() {
  const footID = useStore((store) => store.config.feet.id)
  const feetHeight = useStore((store) => store.config.feet.height)
  const setFeetID = useStore((store) => store.feet.setID)
  const setFeetHeight = useStore((store) => store.feet.setHeight)

  return (
    <>
      {Object.keys(footModel).map((el, i) => {
        const id = el as keyof typeof footModel
        const foot = footModel[id]
        if (id === 'shv') return null
        return (
          <FootList
            key={i}
            title={foot.name}
            val={el !== 'wallMounted' ? foot.heights : undefined}
            units="mm"
            callback={() => setFeetID(id)}
            setHeight={setFeetHeight}
            selected={footID === el}
            footHeight={feetHeight}
          />
        )
      })}
    </>
  )
}
