import {produce} from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import {create} from 'zustand'
import {bedMaterials, footModel} from './datamodels'
import {initialConfig, IIkuConfig} from './initialConfig'
import {initialView, IView} from './initialView'
import {slattedModel} from 'utils/datamodels'

declare interface IFeetActions {
  setHeight: (height: number) => void
  setID: (id: keyof typeof footModel) => void
}

declare type IStoreActions = {
  feet: IFeetActions
}

export type IStore = {
  config: IIkuConfig
  view: IView
  uri: string
  savedConfig: IIkuConfig
  setMaterialSpecies: (species: keyof typeof bedMaterials) => void
  setMaterialTreatment: (treatment: number) => void
  setMaterialType: (type: number) => void
  setFrameHeight: (height: number) => void
  setFrameFlush: (flush: boolean) => void
  setLength: (length: number) => void
  setFootHeight: (height: number) => void
  setFootID: (id: keyof typeof footModel) => void
  setWidth: (width: number) => void
  setHeadBoardID: (headboard: boolean) => void
  setHeadBoardHeight: (height: number) => void
  setMattressVisibility: (visible: boolean) => void
  setMattressHeight: (height: number) => void
  setSlattedVisibility: (visible: boolean) => void
  setSlattedId: (id: keyof typeof slattedModel) => void
  setSlattedHeight: (height: number) => void
  setSlattedInset: (height: number) => void
  setStackable: (stackable: boolean) => void
  setStacked: (stacked: boolean) => void
  clearConfig: () => IIkuConfig
} & IStoreActions

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

//@ts-ignore
export const useStore = create<IStore>((set, get) => {
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  const config: IIkuConfig = initialConfig // set backup config
  const view: IView = initialView // set default view

  const setMaterialSpecies = (nr: keyof typeof bedMaterials) => {
    setProduce((state) => {
      state.config.main.materialID = nr
    })
  }

  const setMaterialType = (type: number) => {
    const fallback = bedMaterials[get().config.main.materialID].fallback as keyof typeof bedMaterials
    const oldType = bedMaterials[get().config.main.materialID].type
    const fallbackType = bedMaterials[fallback].type
    const altMaterial = Object.keys(bedMaterials).find(
      (key) =>
        bedMaterials[key as keyof typeof bedMaterials].type === type &&
        Math.abs(bedMaterials[key as keyof typeof bedMaterials].thickness - bedMaterials[get().config.main.materialID].thickness) < 0.002
    ) as keyof typeof bedMaterials
    setProduce((state) => {
      if (type !== oldType && fallbackType === type) {
        state.config.main.materialID = fallback
      } else {
        state.config.main.materialID = altMaterial
      }
    })
  }

  const setMaterialTreatment = (treatmentID: number) => {
    setProduce((state) => {
      state.config.main.treatmentID = treatmentID
    })
  }

  const setHeadBoardID = (headBoardID: number) => {
    setProduce((state) => {
      state.config.main.headBoard.id = headBoardID
      if (headBoardID !== 0) {
        state.config.main.stackable = false
        state.config.main.frameFlush = false
        if (state.config.main.frameHeight < 0.1) state.config.main.frameHeight = 0.1
      }
      if (headBoardID > 1) {
        state.config.slatted.id = 'integrated'
        state.config.main.width = state.config.main.width > 1.2 ? 1.2 : state.config.main.width
        state.config.slatted.inset = 0.019
      }
    })
  }
  const setHeadBoardHeight = (height: number) => {
    setProduce((state) => {
      state.config.main.headBoard.height = height
    })
  }

  const setFrameHeight = (newHeight: number) => {
    setProduce((state) => {
      state.config.main.frameHeight = newHeight
      if (newHeight < 0.1) {
        state.config.main.frameFlush = true
        state.config.main.headBoard.id = 0
        if (state.config.main.stackable) {
          const slattedInset = state.config.slatted.inset
          const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
          state.config.slatted.id = 'integrated'
          state.config.slatted.inset = slattedInset - oldSlattedHeight + slattedModel['integrated'].height
          state.config.feet.id = 'bedRnd'
        }
      }
      if (newHeight < 0.07) {
        state.config.slatted.id = 'integrated'
        state.config.main.width = state.config.main.width > 1.2 ? 1.2 : state.config.main.width
      }
      if (newHeight < 0.07 && bedMaterials[state.config.main.materialID].thickness > 0.022) {
        state.config.main.materialID = bedMaterials[state.config.main.materialID].altMaterial as keyof typeof bedMaterials
      }
      if (newHeight >= 0.07 && bedMaterials[state.config.main.materialID].thickness < 0.022) {
        state.config.main.materialID = bedMaterials[state.config.main.materialID].altMaterial as keyof typeof bedMaterials
      }
    })
  }
  const setFrameFlush = (flush: boolean) => {
    setProduce((state) => {
      state.config.main.frameFlush = flush
      state.config.main.headBoard.id = 0
    })
  }
  const setLength = (newLength: number) => {
    setProduce((state) => {
      state.config.main.length = newLength
    })
  }
  const setWidth = (newWidth: number) => {
    setProduce((state) => {
      state.config.main.width = newWidth
    })
  }
  const setFootHeight = (newHeight: number) => {
    setProduce((state) => {
      state.config.feet.height = newHeight
    })
  }
  const setFootID = (id: keyof typeof footModel) => {
    setProduce((state) => {
      state.config.feet.id = id
      if (!footModel[id].heights.includes(state.config.feet.height)) {
        const newHeight = footModel[id].standardHeight || 0
        state.config.feet.height = footModel[id].heights[newHeight]
      }
      if (state.config.main.stackable) {
        const slattedInset = state.config.slatted.inset
        const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
        switch (id) {
          case 'bedRnd':
            state.config.slatted.id = 'integrated'
            state.config.slatted.inset = slattedInset - oldSlattedHeight + slattedModel['integrated'].height
            break
          case 'bedRct':
            state.config.slatted.id = 'standard'
            state.config.slatted.inset = slattedInset - oldSlattedHeight + slattedModel['standard'].height
            state.config.main.frameFlush = false
            state.config.main.frameHeight = state.config.main.frameHeight < 0.14 ? 0.14 : state.config.main.frameHeight
            break
        }
      }
    })
  }
  const setMattressVisibility = (visible: boolean) => {
    setProduce((state) => {
      state.view.mattress.visible = visible
    })
  }
  const setMattressHeight = (height: number) => {
    setProduce((state) => {
      state.config.mattress.height = height
    })
  }
  const setSlattedVisibility = (visible: boolean) => {
    setProduce((state) => {
      state.view.slatted.visible = visible
    })
  }
  const setSlattedID = (id: keyof typeof slattedModel) => {
    setProduce((state) => {
      const slattedInset = state.config.slatted.inset
      const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
      const newSlattedHeight = id === 'own' ? state.config.slatted.height : (slattedModel[id] as {height: number})?.height
      const newSlattedInset = slattedInset - oldSlattedHeight + newSlattedHeight
      state.config.slatted.id = id
      state.config.slatted.inset = newSlattedInset
      if (state.config.main.stackable && state.config.feet.id === 'bedRnd' && id !== 'integrated') {
        state.config.main.frameFlush = false
        state.config.main.frameHeight = state.config.main.frameHeight < 0.14 ? 0.14 : state.config.main.frameHeight
        state.config.feet.id = 'bedRct'
      }
    })
  }
  const setSlattedHeight = (height: number) => {
    setProduce((state) => {
      state.config.slatted.height = height
    })
  }
  const setSlattedInset = (height: number) => {
    setProduce((state) => {
      state.config.slatted.inset = height
    })
  }
  const setStackable = (stackable: boolean) => {
    setProduce((state) => {
      state.config.main.stackable = stackable
      state.config.main.headBoard.id = 0
      if (state.config.feet.id === 'bedRnd') {
        const slattedInset = state.config.slatted.inset
        const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
        const newSlattedHeight = 0
        const newSlattedInset = slattedInset - oldSlattedHeight + newSlattedHeight
        state.config.slatted.id = 'integrated'
        state.config.slatted.inset = newSlattedInset
      }
      if (state.config.feet.id === 'bedRct') {
        const slattedInset = state.config.slatted.inset
        const oldSlattedHeight = state.config.slatted.id !== 'own' ? slattedModel[state.config.slatted.id].height : state.config.slatted.height
        const newSlattedHeight = slattedModel['standard'].height
        const newSlattedInset = slattedInset - oldSlattedHeight + newSlattedHeight
        state.config.slatted.id = 'standard'
        state.config.slatted.inset = newSlattedInset
        state.config.main.frameFlush = false
        state.config.main.frameHeight = state.config.main.frameHeight < 0.14 ? 0.14 : state.config.main.frameHeight
      }
      state.config.main.width = state.config.main.width > 1.2 ? 1.2 : state.config.main.width
    })
  }
  const setStacked = (stacked: boolean) => {
    setProduce((state) => {
      state.view.stackable.stacked = stacked
    })
  }

  const getClearConfig = () => {
    return {...get().config}
  }

  return {
    config,
    view,
    uri: '',
    savedConfig: '',
    setMaterialType: setMaterialType,
    setMaterialSpecies: setMaterialSpecies,
    setMaterialTreatment: setMaterialTreatment,
    setFrameHeight: setFrameHeight,
    setFrameFlush: setFrameFlush,
    setLength: setLength,
    setWidth: setWidth,
    setFootHeight: setFootHeight,
    setFootID: setFootID,
    setHeadBoardID: setHeadBoardID,
    setHeadBoardHeight: setHeadBoardHeight,
    setMattressVisibility: setMattressVisibility,
    setMattressHeight: setMattressHeight,
    setSlattedVisibility: setSlattedVisibility,
    setSlattedId: setSlattedID,
    setSlattedHeight: setSlattedHeight,
    setSlattedInset: setSlattedInset,
    setStackable: setStackable,
    setStacked: setStacked,
    clearConfig: getClearConfig,
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('IkuStore', useStore)
}
