import {baseUrl, bedMaterials} from '../store/datamodels'
import {IkuPrice} from 'products/iku/utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors} from 'utils/datamodels'
import {IkuBoardList} from '../partLists/boardList'
import {IkuFootList} from '../partLists/footList'
import {IkuSlattedList} from '../partLists/slattedList'
import {IkuAccBoardList} from '../partLists/accBoardList'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'

export const IkuPriceList = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(Object.fromEntries(Object.entries(bedMaterials).filter(([key]) => bedMaterials[key as keyof typeof bedMaterials].thickness > 0.022)))
  const materials = ['m24Ah', 'm24Ki', 'm25SB', 's25Bu', 's25Ei', 's25Ki', 's25NmS', 's25NwS', 's25NoS', 'l25Ash']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof bedMaterials))

  const widths = [0.8, 1, 1.2, 1.4, 1.6, 1.8, 2]
  const surfaceNames = ['Aufpreis geseift', 'Aufpreis lackiert']

  let priceListIku7B: number[][] = []

  materials.forEach((material, i) => {
    priceListIku7B.push([])
    widths.forEach((width) => {
      const boardList = IkuBoardList(2, width, material as keyof typeof bedMaterials, 0.07, true, 0, 0.14)
      const accBoardList = IkuAccBoardList(0.07, 2, width, true, 0.05, 0.02, 'own', 0)
      const footList = IkuFootList(width, 2, 'bedRnd', 0.125, true, 'own', 0.05, 0.02, 0.07, false, 0)
      const slattedList = IkuSlattedList(0.14, 2, width, 'own', true, 0.05, 0.02, 0, material as keyof typeof bedMaterials)
      priceListIku7B[i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
    })
  })

  let priceListIku7BSurface: number[][] = []
  ;[2, 3].forEach((surface, i) => {
    priceListIku7BSurface.push([])
    widths.forEach((width) => {
      const boardList = IkuBoardList(2, width, 's25Ei', 0.07, true, 0, 0.14)
      const accBoardList = IkuAccBoardList(0.07, 2, width, true, 0.05, 0.02, 'own', 0)
      const footList = IkuFootList(width, 2, 'bedRnd', 0.125, true, 'own', 0.05, 0.02, 0.07, false, 0)
      const slattedList = IkuSlattedList(0.14, 2, width, 'own', true, 0.05, 0.02, 0, 's25Ei')
      const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
      const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
      const extraPrice = iPrice - oilPrice
      priceListIku7BSurface[i].push(extraPrice)
    })
  })

  let priceListIkuR: number[][][] = []
  const frameHeightsIkuR = [0.1, 0.14, 0.18, 0.22]
  let priceListIkuRSurface: number[][][] = []

  frameHeightsIkuR.forEach((frameHeight, j) => {
    priceListIkuR.push([])
    priceListIkuRSurface.push([])
    materials.forEach((material, i) => {
      priceListIkuR[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, material as keyof typeof bedMaterials, frameHeight, false, 0, frameHeight)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 0)
        const footList = IkuFootList(width, 2, 'bedRnd', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 0)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 0, material as keyof typeof bedMaterials)
        priceListIkuR[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIkuRSurface[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, 's25Ei', frameHeight, false, 0, frameHeight)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 0)
        const footList = IkuFootList(width, 2, 'bedRnd', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 0)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 0, 's25Ei')
        const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIkuRSurface[j][i].push(extraPrice)
      })
    })
  })

  let priceListIkuF: number[][][] = []
  const frameHeightsIkuF = [0.1, 0.14, 0.18, 0.22]
  let priceListIkuFSurface: number[][][] = []

  frameHeightsIkuF.forEach((frameHeight, j) => {
    priceListIkuF.push([])
    priceListIkuFSurface.push([])
    materials.forEach((material, i) => {
      priceListIkuF[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, material as keyof typeof bedMaterials, frameHeight, false, 0, frameHeight)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 0)
        const footList = IkuFootList(width, 2, 'bedRct', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 0)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 0, material as keyof typeof bedMaterials)
        priceListIkuF[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIkuFSurface[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, 's25Ei', frameHeight, false, 0, frameHeight)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 0)
        const footList = IkuFootList(width, 2, 'bedRct', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 0)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 0, 's25Ei')
        const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIkuFSurface[j][i].push(extraPrice)
      })
    })
  })

  let priceListIkuH: number[][][] = []
  const frameHeightsIkuH = [0.14, 0.18, 0.22]
  let priceListIkuHSurface: number[][][] = []

  frameHeightsIkuH.forEach((frameHeight, j) => {
    priceListIkuH.push([])
    priceListIkuHSurface.push([])
    materials.forEach((material, i) => {
      priceListIkuH[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, material as keyof typeof bedMaterials, frameHeight, false, 1, 0.46)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 1)
        const footList = IkuFootList(width, 2, 'bedRnd', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 1)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 1, material as keyof typeof bedMaterials)
        priceListIkuH[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIkuHSurface[j].push([])
      widths.forEach((width) => {
        const boardList = IkuBoardList(2, width, 's25Ei', frameHeight, false, 1, 0.46)
        const accBoardList = IkuAccBoardList(frameHeight, 2, width, false, 0.05, 0.02, 'own', 1)
        const footList = IkuFootList(width, 2, 'bedRnd', 0.125, false, 'own', 0.05, 0.02, frameHeight, false, 1)
        const slattedList = IkuSlattedList(frameHeight, 2, width, 'own', false, 0.05, 0.02, 1, 's25Ei')
        const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIkuHSurface[j][i].push(extraPrice)
      })
    })
  })

  let priceListIku10HH: number[][][] = []
  let priceListIku10HHSurface: number[][][] = []
  const lengthsIku10HH = [2, 2.4]
  const widthsIku10HH = [0.7, 0.8, 0.9, 1, 1.2]

  lengthsIku10HH.forEach((length, j) => {
    priceListIku10HH.push([])
    priceListIku10HHSurface.push([])
    materials.forEach((material, i) => {
      priceListIku10HH[j].push([])
      widthsIku10HH.forEach((width) => {
        const boardList = IkuBoardList(length, width, material as keyof typeof bedMaterials, 0.1, true, 2, 0.44)
        const accBoardList = IkuAccBoardList(0.1, length, width, true, 0.05, 0.02, 'integrated', 2)
        const footList = IkuFootList(width, length, 'bedRnd', 0.2, true, 'integrated', 0.05, 0.02, 0.1, false, 2)
        const slattedList = IkuSlattedList(0.1, length, width, 'integrated', true, 0.05, 0.02, 2, material as keyof typeof bedMaterials)
        priceListIku10HH[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIku10HHSurface[j].push([])
      widthsIku10HH.forEach((width) => {
        const boardList = IkuBoardList(length, width, 's25Ei', 0.1, true, 2, 0.44)
        const accBoardList = IkuAccBoardList(0.1, length, width, true, 0.05, 0.02, 'integrated', 2)
        const footList = IkuFootList(width, length, 'bedRnd', 0.2, true, 'integrated', 0.05, 0.02, 0.1, false, 2)
        const slattedList = IkuSlattedList(0.1, length, width, 'integrated', true, 0.05, 0.02, 2, 's25Ei')
        const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIku10HHSurface[j][i].push(extraPrice)
      })
    })
  })

  let priceListIku7Plus: number[][][] = []
  let priceListIku7PlusSurface: number[][][] = []
  const lengthsIku7Plus = [2, 2.2, 2.4]
  const widthsIku7Plus = [0.7, 0.8, 0.9, 1, 1.2]

  lengthsIku7Plus.forEach((length, j) => {
    priceListIku7Plus.push([])
    priceListIku7PlusSurface.push([])
    materials.forEach((material, i) => {
      priceListIku7Plus[j].push([])
      widthsIku7Plus.forEach((width) => {
        const boardList = IkuBoardList(length, width, material as keyof typeof bedMaterials, 0.07, true, 0, 0.14)
        const accBoardList = IkuAccBoardList(0.07, length, width, true, 0.05, 0.02, 'integrated', 0)
        const footList = IkuFootList(width, length, 'bedRnd', 0.125, true, 'integrated', 0.05, 0.02, 0.07, false, 0)
        const slattedList = IkuSlattedList(0.07, length, width, 'integrated', true, 0.05, 0.02, 0, material as keyof typeof bedMaterials)
        priceListIku7Plus[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIku7PlusSurface[j].push([])
      widthsIku7Plus.forEach((width) => {
        const boardList = IkuBoardList(length, width, 's25Ei', 0.07, true, 0, 0.14)
        const accBoardList = IkuAccBoardList(0.07, length, width, true, 0.05, 0.02, 'integrated', 0)
        const footList = IkuFootList(width, length, 'bedRnd', 0.125, true, 'integrated', 0.05, 0.02, 0.07, false, 0)
        const slattedList = IkuSlattedList(0.07, length, width, 'integrated', true, 0.05, 0.02, 0, 's25Ei')
        const iPrice = IkuPrice(localFactor.factor, 's25Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's25Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIku7PlusSurface[j][i].push(extraPrice)
      })
    })
  })

  //const materialsIkuS = Object.keys(Object.fromEntries(Object.entries(bedMaterials).filter(([key]) => bedMaterials[key as keyof typeof bedMaterials].thickness < 0.022)))
  const materialsIkuS = ['m18Ah', 'm18Ki', 'm18MW', 'm18CB', 'm18SB', 's19Bu', 's19Ei', 's19Ki', 's19NmS', 's19NwS', 's19NoS', 'l20Ash']
  const materialNamesIkuS = materialsIkuS.map((id) => getMaterialName(id as keyof typeof bedMaterials))

  let priceListIkuSPlus: number[][][] = []
  let priceListIkuSPlusSurface: number[][][] = []
  const lengthsIkuSPlus = [2, 2.2, 2.4]
  const widthsIkuSPlus = [0.7, 0.8, 0.9, 1, 1.2]

  lengthsIkuSPlus.forEach((length, j) => {
    priceListIkuSPlus.push([])
    priceListIkuSPlusSurface.push([])
    materialsIkuS.forEach((material, i) => {
      priceListIkuSPlus[j].push([])
      widthsIkuSPlus.forEach((width) => {
        const boardList = IkuBoardList(length, width, material as keyof typeof bedMaterials, 0.06, true, 0, 0.14)
        const accBoardList = IkuAccBoardList(0.06, length, width, true, 0.05, 0.02, 'integrated', 0)
        const footList = IkuFootList(width, length, 'bedRnd', 0.125, true, 'integrated', 0.05, 0.02, 0.07, false, 0)
        const slattedList = IkuSlattedList(0.06, length, width, 'integrated', true, 0.05, 0.02, 0, material as keyof typeof bedMaterials)
        priceListIkuSPlus[j][i].push(IkuPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, false, [...boardList, ...accBoardList], footList, slattedList).props.children)
      })
    })
    ;[2, 3].forEach((surface, i) => {
      priceListIkuSPlusSurface[j].push([])
      widthsIkuSPlus.forEach((width) => {
        const boardList = IkuBoardList(length, width, 's19Ei', 0.06, true, 0, 0.14)
        const accBoardList = IkuAccBoardList(0.06, length, width, true, 0.05, 0.02, 'integrated', 0)
        const footList = IkuFootList(width, length, 'bedRnd', 0.125, true, 'integrated', 0.05, 0.02, 0.07, false, 0)
        const slattedList = IkuSlattedList(0.06, length, width, 'integrated', true, 0.05, 0.02, 0, 's19Ei')
        const iPrice = IkuPrice(localFactor.factor, 's19Ei', surface, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const oilPrice = IkuPrice(localFactor.factor, 's19Ei', 0, false, [...boardList, ...accBoardList], footList, slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListIkuSPlusSurface[j][i].push(extraPrice)
      })
    })
  })

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>
      <h1>IKU 7B</h1>
      <PriceTable rows={materialNames} columns={widths} prices={priceListIku7B} />
      <PriceTable rows={surfaceNames} columns={widths} prices={priceListIku7BSurface} />
      {frameHeightsIkuR.map((frameHeight, i) => (
        <>
          <h1 key={i}>IKU {Math.round(frameHeight * 100)}R</h1>
          <PriceTable rows={materialNames} columns={widths} prices={priceListIkuR[i]} />
          <PriceTable rows={surfaceNames} columns={widths} prices={priceListIkuRSurface[i]} />
        </>
      ))}
      {frameHeightsIkuF.map((frameHeight, i) => (
        <>
          <h1 key={i}>IKU {Math.round(frameHeight * 100)}F</h1>
          <PriceTable rows={materialNames} columns={widths} prices={priceListIkuF[i]} />
          <PriceTable rows={surfaceNames} columns={widths} prices={priceListIkuFSurface[i]} />
        </>
      ))}
      {frameHeightsIkuH.map((frameHeight, i) => (
        <>
          <h1 key={i}>IKU {Math.round(frameHeight * 100)}H</h1>
          <PriceTable rows={materialNames} columns={widths} prices={priceListIkuH[i]} />
          <PriceTable rows={surfaceNames} columns={widths} prices={priceListIkuHSurface[i]} />
        </>
      ))}
      {lengthsIku10HH.map((length, i) => (
        <>
          <h1 key={i}>IKU 10HH</h1>
          <h2>Länge: {Math.round(length * 100)} cm</h2>
          <PriceTable rows={materialNames} columns={widthsIku10HH} prices={priceListIku10HH[i]} />
          <PriceTable rows={surfaceNames} columns={widthsIku10HH} prices={priceListIku10HHSurface[i]} />
        </>
      ))}
      <h1>IKU 7+</h1>
      {lengthsIku7Plus.map((length, i) => (
        <>
          <h2>Länge: {Math.round(length * 100)} cm</h2>
          <PriceTable rows={materialNames} columns={widthsIku7Plus} prices={priceListIku7Plus[i]} />
          <PriceTable rows={surfaceNames} columns={widthsIku7Plus} prices={priceListIku7PlusSurface[i]} />
        </>
      ))}
      <h1>IKU S+</h1>
      {lengthsIkuSPlus.map((length, i) => (
        <>
          <h2>Länge: {Math.round(length * 100)} cm</h2>
          <PriceTable rows={materialNamesIkuS} columns={widthsIkuSPlus} prices={priceListIkuSPlus[i]} />
          <PriceTable rows={surfaceNames} columns={widthsIkuSPlus} prices={priceListIkuSPlusSurface[i]} />
        </>
      ))}
    </>
  )
}
