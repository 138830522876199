import {produce} from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import create from 'zustand'
import {shelfMaterials, regalModel} from './datamodels'
import {initialConfig, IDigitalConfig} from './initialConfig'
import {initialView, IView} from './initialView'
import {getMinGrid, getMaxGrid} from 'utils/helpers'
import {createBoardSlice, IBoardSlice} from './slices/boardSlice'
import {createColumnSlice, IColumnSlice} from './slices/columnSlice'
import {createBackpanelSlice, IBackpanelSlice} from './slices/backpanelSlice'
import {createDrawerSlice, IDrawerSlice} from './slices/drawerSlice'
import {createFlapSlice, IFlapSlice} from './slices/flapSlice'
import {IFeetSlice, createFeetSlice} from './slices/feetSlice'

declare type IStoreActions = {} & IBoardSlice & IColumnSlice & IBackpanelSlice & IDrawerSlice & IFlapSlice & IFeetSlice

export type IStore = {
  config: IDigitalConfig
  view: IView
  uri: string
  savedConfig: IDigitalConfig
  setMaterialSpecies: (materialID: keyof typeof shelfMaterials) => void
  setWidth: (newWidth: number) => void
  setDepth: (depth: number) => void
  setGrid: (grid: number) => void
  toggleBoard: (index: number, board: number) => void
  clearConfig: () => IDigitalConfig
} & IStoreActions

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

//@ts-ignore
export const useStore = create<IStore>((set, get) => {
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  const config: IDigitalConfig = initialConfig // set backup config
  const view: IView = initialView // set default view

  const setMaterialSpecies = (nr: keyof typeof shelfMaterials) => {
    setProduce((state) => {
      state.config.main.materialID = nr
    })
  }

  const setDepth = (newDepth: number) => {
    setProduce((state) => {
      state.config.main.depth = newDepth
    })
  }

  const setWidth = (newWidth: number) => {
    const minGrid = getMinGrid(newWidth, regalModel.gridMax)
    const maxGrid = getMaxGrid(newWidth, regalModel.gridMin)
    const width = get().config.main.width
    const grid = get().config.main.grid
    const colSize = (width - shelfMaterials[get().config.main.materialID].thickness) / grid
    const preferredGrid = Math.round(newWidth / colSize)
    const alternativeGrid = maxGrid - preferredGrid > preferredGrid - minGrid ? minGrid : maxGrid
    const newGrid = maxGrid >= preferredGrid && preferredGrid >= minGrid ? preferredGrid : alternativeGrid //#TODO I think it should be >= -> also in UI Grid selector
    setProduce((state) => {
      state.config.main.width = newWidth
    })
    if (newGrid !== grid) setGrid(newGrid)
  }

  const setGrid = (newGrid: number) => {
    get().config.rows.list.forEach((_, index) => {
      const noBackPanel = !get().config.backpanels.list.find((backpanel) => backpanel.pos.y === index && backpanel.pos.x < newGrid)
      noBackPanel &&
        setProduce((state) => {
          state.config.backpanels.list.push({pos: {x: 0, y: index}, cover: 'compartment', face: 'back'})
        })
    })
    get().columns.checkMaxSpan(newGrid)
    setProduce((state) => {
      state.config.main.grid = newGrid
    })
  }

  const getClearConfig = () => {
    const backpanels = {...get().config.backpanels, list: get().backpanels.clearBackpanels()}
    const columns = {...get().config.columns, list: get().columns.clearColumns()}
    const drawers = {...get().config.drawers, list: get().drawers.clearDrawers()}
    const flaps = {...get().config.flaps, list: get().flaps.clearFlaps()}
    return {...get().config, columns: columns, backpanels: backpanels, drawers: drawers, flaps: flaps}
  }

  return {
    config,
    view,
    uri: '',
    savedConfig: '',
    setMaterialSpecies: setMaterialSpecies,
    setDepth: setDepth,
    setGrid: setGrid,
    setWidth: setWidth,
    clearConfig: getClearConfig,
    ...createBoardSlice(setProduce),
    ...createColumnSlice(setProduce, get),
    ...createBackpanelSlice(setProduce, get),
    ...createDrawerSlice(setProduce, get),
    ...createFlapSlice(setProduce, get),
    ...createFeetSlice(setProduce),
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('DigitalStore', useStore)
}
