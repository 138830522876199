import {ISetProduce, IStore} from '../store'
import {flapMaterials} from '../datamodels'
import {GetState} from 'zustand'
import {ITekturConfig} from '../initialConfig'
import {clearFlaps} from 'products/tektur/utils/helpers'

export interface IFlapSlice {
  flaps: {
    setFlap: (pos: {x: number; y: number}) => void
    setEdit: (edit: boolean) => void
    setMaterialID: (newMaterial: keyof typeof flapMaterials) => void
    setVisible: (show: boolean) => void
    clearFlaps: () => ITekturConfig['flaps']['list']
  }
}

export const createFlapSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const flaps = {
    setFlap: (pos: {x: number; y: number}) =>
      setProduce((store) => {
        const index = store.config.flaps.list.findIndex((flap) => flap.pos.x === pos.x && flap.pos.y === pos.y)
        index === -1 ? store.config.flaps.list.push({pos: pos, face: 'front'}) : store.config.flaps.list.splice(index, 1)
        for (let i = 0; i < store.config.drawers.list.length; i++) {
          const drawerIndex = store.config.drawers.list.findIndex((drawer) => drawer.pos.x === pos.x && Math.floor(drawer.pos.y) === pos.y)
          drawerIndex !== -1 && store.config.drawers.list.splice(drawerIndex, 1)
        }
      }),
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.flaps.edit = edit
      }),
    setMaterialID: (materialID: keyof typeof flapMaterials) =>
      setProduce((store) => {
        store.config.flaps.materialID = materialID
      }),
    setVisible: (visible: boolean) =>
      setProduce((store) => {
        store.view.flaps.visible = visible
      }),
    clearFlaps: () => {
      const clearedFlaps = clearFlaps(get().config.flaps.list, get().config.rows.list, get().config.main.grid, get().config.main.shelfTyp)
      return clearedFlaps
    },
  }
  return {
    flaps,
  }
}
