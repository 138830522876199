import {IStore, ISetProduce} from '../store'
import {slidingDoorMaterials} from '../datamodels'

export const createSlidingDoorSlice = (setProduce: ISetProduce<IStore>) => {
  const slidingDoors = {
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.slidingDoors.visible = show
      })
    },
    setMaterialID: (newMaterialID: keyof typeof slidingDoorMaterials): void =>
      setProduce((state: IStore) => {
        state.config.slidingDoors.materialID = newMaterialID
      }),
    setNrOfSlidingDoors: (newNrOfSlidingDoors: number): void =>
      setProduce((state: IStore) => {
        state.config.slidingDoors.nr = newNrOfSlidingDoors
      }),
    setInFront: (newInFront: 'left' | 'right' | 'middle'): void =>
      setProduce((state: IStore) => {
        state.config.slidingDoors.inFront = newInFront
      }),
  }

  return {
    slidingDoors,
  }
}
