import {PerspectiveControlButton, PerspectiveControlContainer} from 'common/perspectiveControlContainer/PerspectiveControlContainer'
import {useCommonStore} from 'common/Store/CommonStore'
import FrontIcn from './viewIcn-front@2x.png'
import FrontMIcn from './viewIcn-front-mattres@2x.png'
import IsoIcn from './viewIcn-iso@2x.png'
import SideIcn from './viewIcn-side@2x.png'
import {useStore} from 'products/iku/store/store'

export const PerspectiveControls = () => {
  const [setPerspective] = useCommonStore((store) => [store.setPerspective])
  const setMattressVisibility = useStore((store) => store.setMattressVisibility)

  return (
    <PerspectiveControlContainer>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('front')
          setMattressVisibility(false)
        }}
        iconSrc={FrontIcn}>
        Frontansicht ohne Matratze
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('front')
          setMattressVisibility(true)
        }}
        iconSrc={FrontMIcn}>
        Frontansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('iso')
        }}
        iconSrc={IsoIcn}>
        Schrägansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="front-btn"
        altText="Icon of shelf in front view"
        onClick={() => {
          setPerspective('side')
        }}
        iconSrc={SideIcn}>
        Seitenansicht
      </PerspectiveControlButton>
      <PerspectiveControlButton
        id="back-btn"
        altText="Icon of shelf in back view"
        onClick={() => {
          setPerspective('back')
        }}
        iconSrc={FrontIcn}>
        Rückansicht
      </PerspectiveControlButton>
    </PerspectiveControlContainer>
  )
}
