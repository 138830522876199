import {useStore} from 'products/dasregal/store/store'
import {shallow} from 'zustand/shallow'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'

export const ConnectedDasregalBoardList = () => {
  const [columns, height, depth, materialID, grid, boardList, backpanels, feetID, doors, flaps, drawers] = useStore(
    (state) => [
      state.config.columns.list,
      state.config.main.height,
      state.config.main.depth,
      state.config.main.materialID,
      state.config.main.grid,
      state.config.boards.list,
      state.config.backpanels,
      state.config.feet.id,
      state.config.doors.list,
      state.config.flaps.list,
      state.config.drawers.list,
    ],
    shallow
  )

  return DasregalBoardList(columns, height, depth, materialID, grid, boardList, backpanels, feetID, doors, flaps, drawers)
}

export const DasregalBoardList = (
  columns: any[],
  height: number,
  depth: number,
  materialID: keyof typeof shelfMaterials,
  grid: number,
  boardList: number[][],
  backpanels: {list: {pos: {x: number; y: number}; cover: 'full' | 'fullWidth' | 'compartment'; face: 'back' | 'front'}[]; materialID: keyof typeof backpanelMaterials},
  feetID: string,
  doors: {pos: {x: number; y: number}; size: {x: number; y: number}; side: 'left' | 'right'; face: 'front' | 'back'}[],
  flaps: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[],
  drawers: {pos: {x: number; y: number}; size: {x: number; y: number}; face: 'front' | 'back'}[]
) => {
  const thickness = shelfMaterials[materialID].thickness
  let startPos = thickness
  const totalWidth = columns.reduce((acc, col) => acc + col.width + thickness, thickness)
  const wallMounted = feetID === 'wallMounted'
  const gridsize = (height - thickness) / grid
  const backpanelThickness = backpanelMaterials[backpanels.materialID].thickness
  const boards = []

  if (height <= 1.6) {
    boards.push({key: 'bottom', xPos: 0, yPos: 0, zPos: 0, length: totalWidth, depth: depth, thickness: thickness, material: materialID, type: 'bottom'})
    boards.push({key: 'top', xPos: 0, yPos: height - thickness, zPos: 0, length: totalWidth, depth: depth, thickness: thickness, material: materialID, type: 'top'})
  }
  columns.forEach((el, index) => {
    startPos += el.width + thickness
    const fullBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.x === index
      })?.cover === 'full'
    const zPos = wallMounted
      ? regalModel.backpanelWallMountedInset + backpanelThickness
      : fullBackpanel
        ? backpanelThickness < 0.016
          ? regalModel.backpanelInset + backpanelThickness
          : 0.001 + backpanelThickness
        : 0
    const xPos = startPos - el.width - thickness
    if (height > 1.6) {
      boards.push({key: index + 'bottom', xPos: xPos, yPos: 0, zPos: 0, length: el.width, depth: depth, thickness: thickness, material: materialID, type: 'bottom'})
      boards.push({key: index + 'top', xPos: xPos, yPos: height - thickness, zPos: 0, length: el.width, depth: depth, thickness: thickness, material: materialID, type: 'top'})
    }
    boardList[index].forEach((i) => {
      if (i < grid) {
        const isInset =
          accHelper.acc_is_in_front({x: index, y: i}, {x: 1, y: 0}, doors, flaps, drawers) ||
          (accHelper.get_acc_width({x: index, y: i}, {x: 1, y: 0}, doors, flaps, drawers) === 1 && accHelper.get_acc_width_below({x: index, y: i}, {x: 1, y: 0}, doors, flaps, drawers) === 1)
        const adjustedDepth = depth - zPos - (isInset ? regalModel.frontInset : 0)
        boards.push({key: index + '.' + i, xPos: xPos, yPos: i * gridsize, zPos: zPos, length: el.width, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'board'})
      }
    })
  })

  return boards
}
