import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'
import {Fragment} from 'react'

export const ConnectedDoorSelectors = () => {
  const edit = useStore((store) => store.view.doors.edit)
  const [boards, doors, width, depth, grid, shelfMaterialID] = useStore(
    (store) => [store.config.rows.list, store.doors.clearDoors(), store.config.main.width, store.config.main.depth, store.config.main.grid, store.config.main.materialID],
    shallow
  )
  const shelfThickness = shelfMaterialModel[shelfMaterialID].thickness
  const editSize = useStore((store) => store.view.doors.editSize)
  const colSize = (width - shelfThickness * 2 + blueprint.shadowJoint) / grid
  let totalHeight = 0
  const doorsVariant = useStore((store) => store.view.doors.variant)
  const setDoorAt = useStore((store) => store.doors.clickedAt)

  return (
    <>
      {boards.map((board, y) => {
        //if (boards.length - editSize.y < y) return null
        totalHeight += board.height + shelfThickness
        const numOfDoors = Math.floor(grid - editSize.x + 1)
        const numOfBoards = boards.length
        const positiveDoors = numOfDoors > 0 ? numOfDoors : 0

        const selectorWidth = colSize * editSize.x - blueprint.shadowJoint
        const selectorHeight = boards.slice(y, y + editSize.y).reduce((acc, board) => acc + board.height + shelfThickness, -shelfThickness)
        let doorsPerBoard = 0
        let doorsPerNextBoard = 0
        let maxDoorSize = 0
        let maxDoorSizeNextBoard = 0
        for (let d = 0; d < doors.length; d++) {
          doorsPerBoard = doors[d].pos.y === y && doors[d].type === 1 ? doorsPerBoard + doors[d].size.x : doorsPerBoard
          doorsPerBoard = doors[d].pos.y === y - 1 && doors[d].size.y === 2 && doors[d].type === 1 ? doorsPerBoard + doors[d].size.x : doorsPerBoard
          doorsPerNextBoard = doors[d].pos.y === y + 1 && doors[d].type === 1 ? doorsPerNextBoard + doors[d].size.x : doorsPerNextBoard
          doorsPerNextBoard = doors[d].pos.y === y && doors[d].size.y === 2 && doors[d].type === 1 ? doorsPerNextBoard + doors[d].size.x : doorsPerNextBoard
          maxDoorSize = doors[d].pos.y === y && doors[d].size.x > maxDoorSize && doors[d].type === 1 ? doors[d].size.x : maxDoorSize
          maxDoorSize = doors[d].pos.y === y - 1 && doors[d].size.x > maxDoorSize && doors[d].size.y > 1 && doors[d].type === 1 ? doors[d].size.x : maxDoorSize
          maxDoorSizeNextBoard = doors[d].pos.y === y + 1 && doors[d].size.x > maxDoorSizeNextBoard && doors[d].type === 1 ? doors[d].size.x : maxDoorSizeNextBoard
        }
        let visible: boolean[] = []
        const offDoorsPossible = grid - doorsPerBoard - maxDoorSize
        const newMaxDoorSize = maxDoorSize > editSize.x ? maxDoorSize : editSize.x
        const offDoorsPossibleNextBoard = grid - doorsPerNextBoard - maxDoorSizeNextBoard
        for (let g = 0; g < grid; g++) {
          if ((offDoorsPossible >= editSize.x && (offDoorsPossibleNextBoard >= editSize.x || editSize.y === 1)) || doorsVariant === 0) {
            visible.push(true)
          } else {
            visible.push(false)
          }
        }
        for (let d = 0; d < doors.length; d++) {
          if (
            (doors[d].pos.y === y || (doors[d].pos.y === y - 1 && doors[d].size.y > 1) || (doors[d].pos.y === y + 1 && editSize.y > 1)) &&
            grid - doorsPerBoard + doors[d].size.x - editSize.x >= newMaxDoorSize
          ) {
            for (let j = 0; j < doors[d].size.x; j++) {
              visible[doors[d].pos.x + j] = true
            }
            for (let j = 1; j < editSize.x; j++) {
              visible[doors[d].pos.x - j] = true
            }
          }
        }
        for (let g = 0; g < grid; g++) {
          if (y === numOfBoards - 1 && editSize.y === 2) visible[g] = false
        }

        return [...Array(positiveDoors)].map(
          (_, x) =>
            edit &&
            visible[x] && (
              <Selector
                key={x}
                size={[selectorHeight, 0.003, selectorWidth]}
                xPos={x * colSize + shelfThickness}
                yPos={totalHeight - board.height}
                zPos={depth + 0.001 + x * 0.001}
                onClick={() => setDoorAt(editSize.x, editSize.y, {x, y})}
                rotation={[0, 0.5, 0.5]}
              />
            )
        )
      })}
    </>
  )
}
