import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const ApiFetch = () => {
  let {id} = useParams<{id: string}>()
  const fetchConfig = useCommonStore((store) => store.fetchConfig)
  const navigate = useNavigate()

  useEffect(() => {
    const callFetch = async () => {
      await fetchConfig(id as string).then((data) => {
        if (data) {
          const reference = data.reference
          window.history.pushState(null, '', `/`)
          navigate(`/${reference}`)
        } else {
          navigate(`/`)
        }
      })
    }
    callFetch()
  })

  return null
}

export default ApiFetch
