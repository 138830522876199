import {useStore} from '../store/store'
import {footModel, materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {bedModel} from '../store/datamodels'

export const ConnectedUnisonoFootList = () => {
  const [width, length, sideBoardWidth, foodBoardWidth, feetID, footHeight, slattedId, slattedInsetstore, frameHeight] = useStore((store) => [
    store.config.main.width,
    store.config.main.length,
    store.config.main.sideBoardWidth,
    store.config.main.footBoardWidth,
    store.config.feet.id,
    store.config.feet.height,
    store.config.slatted.id,
    store.config.slatted.inset,
    store.config.main.frameHeight,
  ])
  return UnisonoFootList(width, length, sideBoardWidth, foodBoardWidth, feetID, footHeight, slattedId, slattedInsetstore, frameHeight)
}

export const UnisonoFootList = (
  width: number,
  length: number,
  sideBoardWidth: number,
  foodBoardWidth: number,
  feetID: string,
  footHeight: number,
  slattedId: string,
  slattedInsetstore: number,
  frameHeight: number
) => {
  const frameLength = length + 0.05
  const frameWidth = width + 0.05

  const slattedInset = slattedInsetstore

  const footList = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel; rotation?: number}[]
  width > 1.4 &&
    footList.push({
      key: 'middleFoot',
      pos: [foodBoardWidth + length / 2, frameHeight - slattedInset - 0.001, sideBoardWidth + width / 2],
      size: [0.015, footHeight + frameHeight - slattedInset - 0.001, 0.015],
      material: 'solidBlack',
      type: 'rnd30',
    })
  if (slattedId === 'integrated' && width <= 1.2) {
    footList.push({key: 'foot1.1', pos: [0.24, 0, 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.2', pos: [frameLength - 0.24, 0, 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.3', pos: [0.24, 0, frameWidth - 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
    footList.push({key: 'foot1.4', pos: [frameLength - 0.24, 0, frameWidth - 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
    if (frameHeight < 0.08 && frameLength > 2.09) {
      footList.push({key: 'middlefoot.1', pos: [frameLength / 2, 0, 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
      footList.push({key: 'middlefoot.2', pos: [frameLength / 2, 0, frameWidth - 0.18], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: 'bedStackableRnd'})
    }
  } else {
    switch (feetID) {
      case 'bedRnd':
        footList.push({
          key: 'foot1',
          pos: [foodBoardWidth - bedModel.nominalThickness + 0.006, 0.003, sideBoardWidth - bedModel.nominalThickness + 0.006],
          size: [0.015, footHeight, 0.015],
          material: 'solidBlack',
          type: 'bedRnd',
        })
        footList.push({
          key: 'foot2',
          pos: [foodBoardWidth + length + bedModel.nominalThickness - 0.006, 0.003, sideBoardWidth - bedModel.nominalThickness + 0.006],
          size: [0.015, footHeight, 0.015],
          material: 'solidBlack',
          type: 'bedRnd',
          rotation: -0.5,
        })
        footList.push({
          key: 'foot3',
          pos: [foodBoardWidth - bedModel.nominalThickness + 0.006, 0.003, sideBoardWidth + width + bedModel.nominalThickness - 0.006],
          size: [0.015, footHeight, 0.015],
          material: 'solidBlack',
          type: 'bedRnd',
          rotation: 0.5,
        })
        footList.push({
          key: 'foot4',
          pos: [foodBoardWidth + length + bedModel.nominalThickness - 0.006, 0.003, sideBoardWidth + width + bedModel.nominalThickness - 0.006],
          size: [0.015, footHeight, 0.015],
          material: 'solidBlack',
          type: 'bedRnd',
          rotation: 1,
        })
        break
    }
  }
  return footList
}
