import './../overview.scss'
import dinadrei_1 from 'common/static/overview/dinadrei-1.jpg'
import dinadrei_2 from 'common/static/overview/dinadrei-2.jpg'
import dinadrei_3 from 'common/static/overview/dinadrei-3.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedDinadreiOverView() {
  return <ProductOverView title={'dinadrei'} products={dinadrei} withTitle />
}

export const dinadrei = [
  {img: <img className="overview-image" src={dinadrei_1} alt="Dinadrei" />, to: 'get/dinadrei-1'},
  {img: <img className="overview-image" src={dinadrei_2} alt="Dinadrei" />, to: 'get/dinadrei-2'},
  {img: <img className="overview-image" src={dinadrei_3} alt="Dinadrei" />, to: 'get/dinadrei-3'},
]
