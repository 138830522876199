import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'
import {Fragment} from 'react'

export const ConnectedColumnSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, columns, depth, width, grid] = useStore((store) => [store.config.rows, store.rows.clearColumns(), store.config.main.depth, store.config.main.width, store.config.main.grid], shallow)
  const thickness = shelfMaterialModel[useStore((store) => store.config.main.materialID)].thickness
  const gridSize = (width - thickness) / grid
  const toggleColumn = useStore((store) => store.rows.toggleBoard)
  let yPos = 0
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += board.height + thickness
          const visibleColumns = [...columns[y]]
          visibleColumns.push(0, grid)
          return [...Array(grid - 1)].map((_, x) => {
            const xPos = (x + 1) * gridSize
            const nextColumn = Math.min(...visibleColumns.filter((el) => el - 1 > x))
            const prevColumn = Math.max(...visibleColumns.filter((el) => el - 1 < x))
            const removable = (nextColumn - prevColumn) * gridSize < blueprint.maxSpan
            return (
              <Fragment key={x}>
                {removable && (
                  <Selector
                    key={y + '.' + x}
                    size={[board.height, thickness, depth - blueprint.frontInset]}
                    xPos={xPos}
                    yPos={yPos - board.height}
                    zPos={0}
                    onClick={() => toggleColumn(y, x + 1)}
                    rotation={[0, 0, 0.5]}
                    translate={[0.5, -0.5, 0.5]}
                  />
                )}
              </Fragment>
            )
          })
        })}
    </>
  )
}
