import {useStore} from 'products/metrum/store/store'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/metrum/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {IMetrumConfig} from '../store/initialConfig'

export const ConnectedMetrumBackpanelList = () => {
  const [columns, shelfHeight, shelfMaterialID, backpanelMaterialID] = useStore(
    useShallow((store) => [store.config.columns.list, store.config.main.height, store.config.main.materialID, store.config.backpanels.materialID])
  )
  return MetrumBackpanelList(columns, shelfHeight, shelfMaterialID, backpanelMaterialID)
}

export const MetrumBackpanelList = (
  columns: IMetrumConfig['columns']['list'],
  shelfHeight: IMetrumConfig['main']['height'],
  shelfMaterialID: IMetrumConfig['main']['materialID'],
  backpanelMaterialID: IMetrumConfig['backpanels']['materialID']
) => {
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const backpanels: {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []

  columns.forEach((el, i) => {
    const xPos = columns.slice(0, i).reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness) - regalModel.backpanelSideInset
    const zPos = 0.001
    const width = el.width + 2 * regalModel.backpanelSideInset
    const height = shelfHeight
    backpanels.push({key: i, xPos: xPos, yPos: 0, zPos: zPos, length: height, depth: width, thickness: backpanelThickness, material: backpanelMaterialID, type: 'backpanel'})
  })

  return backpanels
}
