import {Route, Routes} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {WidthModal, OwnValue, ShelfTypModal} from '../../../../components/material_interfaces/material_interfaces'
import * as lookup from 'products/kubatur/store/datamodels'
import {useStore} from '../../store/store'
import {regalModel, shelfTypes} from 'products/kubatur/store/datamodels'

export function ShelfStructureModals() {
  const {height, depth, columns, shelfTyp} = useStore(
    (store) => ({height: store.config.main.height, depth: store.config.main.depth, grid: store.config.main.grid, columns: store.config.columns.list, shelfTyp: store.config.main.shelfTyp}),
    shallow
  )
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const setHeight = useStore((store) => store.setHeight)
  const thickness = lookup.shelfMaterials[shelfMaterial].thickness
  const setDepth = useStore((store) => store.setDepth)
  const setColWidth = useStore((store) => store.columns.setWidth)
  const setShelfTyp = useStore((store) => store.setShelfTyp)
  const footHeight = useStore((store) => store.config.feet.height)
  const fullHeight = Math.round((height + footHeight) * 1000) / 1000
  const slidingDoorThickness = lookup.slidingDoorMaterials[useStore((store) => store.config.slidingDoors.materialID)].thickness
  const doorThickness = lookup.frontMaterials[useStore((store) => store.config.doors.materialID)].thickness
  const extraDepth = shelfTyp === 1 ? 2 * (slidingDoorThickness + regalModel.slidingDoorDistance + thickness) : doorThickness + regalModel.doorDistance
  const currentTyp = useStore((store) => store.config.main.shelfTyp)
  const typOptions = [...Object.keys(shelfTypes)].map((key) => {
    const id = parseInt(key) as keyof typeof shelfTypes
    return {id: id, name: shelfTypes[id].name}
  })

  return (
    <>
      <Routes>
        <Route
          path="height"
          element={
            <OwnValue
              title="Schrankhöhe"
              val={fullHeight * 1000}
              callback={(newVal: number) => setHeight(newVal / 1000)}
              min={regalModel.minHeight * 1000}
              max={lookup.shelfMaterials[shelfMaterial].maxWidth * 1000}
            />
          }
        />
        <Route
          path="depth"
          element={
            <OwnValue
              title="Schranktiefe"
              val={Math.round((depth + extraDepth) * 1000)}
              callback={(newVal: number) => setDepth(newVal / 1000 - extraDepth)}
              min={regalModel.minDepth * 1000}
              max={regalModel.maxDepth * 1000}
            />
          }
        />
        <Route
          path="cols/:colNum"
          element={
            <WidthModal
              title="Fachbreite"
              val={columns}
              defaultValues={[]}
              callback={(newVal: number, colNum: number) => setColWidth(newVal / 1000, colNum)}
              min={regalModel.minColumnWidth * 1000}
              max={regalModel.maxColumnWidth * 1000}
            />
          }
        />
        <Route path="typ" element={<ShelfTypModal title="Regaltyp" val={currentTyp} options={typOptions} callback={(newTyp: keyof typeof shelfTypes) => setShelfTyp(newTyp)} />} />
      </Routes>
    </>
  )
}
