import {materialModel, footModel as feets} from 'utils/datamodels'

export const bedModel = {
  minWidth: 0.7,
  maxWidth: 2.2,
  minLength: 1.6,
  maxLength: 2.7,
  nominalThickness: 0.025,
  minFrameHeight: 0.06,
  maxFrameHeight: 0.3,
  maxHeadBoardHeight: 0.7,
  standardHeadBoardHeight: 0.455,
  minSlattedHeight: 0.03,
  maxSlattedHeight: 0.15,
  integratedSlattedHeight: 0.009,
}

export const baseUrl = '/unisono'

export const headBoardModel = [
  {id: 0, name: 'ohne', short: ''},
  {id: 1, name: 'mit', short: 'H'},
]

export const bedMaterials = {
  m24Ah: materialModel['m24Ah'],
  m24Bi: materialModel['m24Bi'],
  m24Bu: materialModel['m24Bu'],
  m24Ei: materialModel['m24Ei'],
  m24Es: materialModel['m24Es'],
  m24Ki: materialModel['m24Ki'],
  m24aN: materialModel['m24aN'],
  m25SW: materialModel['m25SW'],
  m25SB: materialModel['m25SB'],
  s25Ah: materialModel['s25Ah'],
  s25Bu: materialModel['s25Bu'],
  s25Ei: materialModel['s25Ei'],
  s25Es: materialModel['s25Es'],
  s25Ki: materialModel['s25Ki'],
  s25NmS: materialModel['s25NmS'],
  s25NwS: materialModel['s25NwS'],
  s25NoS: materialModel['s25NoS'],

  l25Charcoal: materialModel['l25Charcoal'],
  l25Ash: materialModel['l25Ash'],
  l25Nero: materialModel['l25Nero'],
  l25Burgundy: materialModel['l25Burgundy'],
  l25Pewter: materialModel['l25Pewter'],
  l25Pearl: materialModel['l25Pearl'],
  l25Salsa: materialModel['l25Salsa'],
  l25Conifer: materialModel['l25Conifer'],
  l25Pebble: materialModel['l25Pebble'],
  l25Mushroom: materialModel['l25Mushroom'],
  l25Mauve: materialModel['l25Mauve'],
  l25Pistachio: materialModel['l25Pistachio'],
  l25SpringGreen: materialModel['l25SpringGreen'],
  l25Powder: materialModel['l25Powder'],
  l25Olive: materialModel['l25Olive'],
  l25OrangeBlast: materialModel['l25OrangeBlast'],
  l25SmokeyBlue: materialModel['l25SmokeyBlue'],
  l25Aquavert: materialModel['l25Aquavert'],
  l25IronGrey: materialModel['l25IronGrey'],
  l25Vapour: materialModel['l25Vapour'],
  l25MidnightBlue: materialModel['l25MidnightBlue'],
  l25Clay: materialModel['l25Clay'],
  l25Leather: materialModel['l25Leather'],
  l25Walnut: materialModel['l25Walnut'],
}

export const footModel = {
  bedRnd: feets.bedRnd,
}
