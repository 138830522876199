import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {blueprint, footModel, shelfMaterialModel} from 'products/analog/store/datamodels'

interface IBoardSelectors {
  list: {key: number; yPos: number; callback: () => void}[]
  thickness: number
  depth: number
  width: number
}

export const BoardSelectors = (props: IBoardSelectors) => {
  const selectorProps = {...props, length: props.width}
  return (
    <>
      {props.list.map((el, i) => {
        return <Selector {...selectorProps} size={[selectorProps.length, selectorProps.thickness, selectorProps.depth]} key={el.key} xPos={0} yPos={el.yPos} zPos={0} onClick={el.callback} />
      })}
    </>
  )
}

export const ConnectedBoardSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [boards, depth, width, footID] = useStore((store) => [store.config.rows, store.config.main.depth, store.config.main.width, store.config.feet.id], shallow)
  const thickness = shelfMaterialModel[useStore((store) => store.config.main.materialID)].thickness
  const totalHeight = boards.list.reduce((acc, board) => acc + board.height + thickness, thickness)
  const foot = footModel[footID]
  const maxShelfHeight = foot.maxShelfHeight || Infinity
  const deleteBoard = useStore((store) => store.rows.delete)
  const addBoard = useStore((store) => store.rows.create)
  const list = [{key: 0, yPos: totalHeight - thickness, callback: deleteBoard}]
  blueprint.colHeight.forEach((height, index) => {
    if (totalHeight + height < maxShelfHeight) {
      list.push({key: index + 1, yPos: totalHeight + height, callback: () => addBoard(height)})
    }
  })
  if (boards.list.length < 2) list.shift()
  return <>{visible && <BoardSelectors thickness={thickness} list={list} depth={depth} width={width} />}</>
}
