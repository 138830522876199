export const initialView = {
  mattress: {
    visible: true,
  },
  slatted: {
    visible: true,
  },
}

export type IView = typeof initialView
