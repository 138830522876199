import {useStore} from '../store/store'
import {materialModel, slattedModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'

export type IIkuSlattedList = {
  key: string
  size: IVector
  xPos: number
  yPos: number
  zPos: number
  type: keyof typeof slattedModel
  holePosition?: [number, number]
  nrOfSegments?: number
  material: keyof typeof materialModel
}[]

export const ConnectedIkuSlattedList = () => {
  const [frameHeight, length, width, slattedID, frameFlush, slattedHeight, slattedInsetstore, materialID, headBoardID] = useStore((store) => [
    store.config.main.frameHeight,
    store.config.main.length,
    store.config.main.width,
    store.config.slatted.id,
    store.config.main.frameFlush,
    store.config.slatted.height,
    store.config.slatted.inset,
    store.config.main.materialID,
    store.config.main.headBoard.id,
  ])
  return IkuSlattedList(frameHeight, length, width, slattedID, frameFlush, slattedHeight, slattedInsetstore, headBoardID, materialID)
}

export const IkuSlattedList = (
  frameHeight: number,
  length: number,
  width: number,
  slattedID: keyof typeof slattedModel,
  frameFlush: boolean,
  slattedHeight: number,
  slattedInsetstore: number,
  headBoardID: number,
  materialID: keyof typeof materialModel
) => {
  const thickness = materialModel[materialID].thickness
  const usableLength = frameFlush ? length - 2 * thickness : headBoardID > 1 ? length - thickness : length
  const frameWidth = frameFlush ? width - 2 * thickness : headBoardID > 1 ? width - thickness : width
  const usableWidth = frameWidth
  const nrOfSlats = usableWidth > (slattedID === 'integrated' ? 1.2 : 1.4) ? 2 : 1
  const slattedWidth = slattedID === 'integrated' ? (nrOfSlats === 1 ? usableWidth + 0.018 : usableWidth / 2 - 0.001) : Math.floor((usableWidth / nrOfSlats) * 10) / 10
  const slattedLength = slattedID === 'integrated' ? (nrOfSlats === 1 ? usableLength + 0.018 : usableLength - 0.02) : Math.floor(usableLength * 10) / 10
  const slattedInset = frameFlush ? (slattedID === 'integrated' ? 0.01 + 0.009 : slattedHeight + 0.01) : slattedInsetstore
  const xPos = (length + (frameFlush ? 0 : 0.05)) / 2 - slattedLength / 2
  const middleBoardInBetween = nrOfSlats === 2 && usableWidth - 2 * slattedWidth > 0.04
  const widthOfSideBoards = Math.min(Math.floor(((usableWidth - nrOfSlats * slattedWidth - (middleBoardInBetween ? 0.04 : 0)) / 2) * 100) / 100, 0.04)
  const zPos1 = 0.025 + widthOfSideBoards + (usableWidth - 2 * widthOfSideBoards - (middleBoardInBetween ? 0.02 : 0)) / nrOfSlats / 2 - slattedWidth / 2
  const zPos2 = frameWidth - 0.025 - widthOfSideBoards - (usableWidth - 2 * widthOfSideBoards - (middleBoardInBetween ? 0.02 : 0)) / nrOfSlats / 2 - slattedWidth / 2
  const nrOfSegments = Math.floor((slattedLength - 2 * 0.2) / 0.15)

  const slattedList: IIkuSlattedList = []

  slattedList.push({
    key: 'slatted1',
    size: [slattedLength, slattedHeight, slattedWidth],
    xPos: xPos,
    yPos: frameHeight - slattedInset,
    zPos: zPos1,
    type: slattedID,
    holePosition: [0.2, 0.15],
    nrOfSegments: nrOfSegments,
    material: 'm9Bi',
  })
  if (nrOfSlats === 2) {
    slattedList.push({
      key: 'slatted2',
      size: [slattedLength, slattedHeight, slattedWidth],
      xPos: xPos,
      yPos: frameHeight - slattedInset,
      zPos: zPos2,
      type: slattedID,
      holePosition: [0.2, 0.15],
      nrOfSegments: nrOfSegments,
      material: 'm9Bi',
    })
  }

  return slattedList
}
