import {Fragment} from 'react'
import './icon.scss'

export const DoorIcon = (props: {x: number; y: number; maxXSize: number; ySize: number}) => {
  return (
    <table>
      <tbody>
        {[...Array(Math.max(2, props.ySize))].map((_, y) => {
          return (
            <tr key={y}>
              {[...Array(Math.max(4, props.maxXSize * 2))].map((_, x) => {
                return (
                  <Fragment key={x}>
                    {props.x > x && props.y > y && <th className="black" key={x} />}
                    {(props.x <= x || props.y <= y) && <th key={x} />}
                  </Fragment>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
