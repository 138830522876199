import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {ConnectedMetrum} from '../threeComponents/Metrum'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {ConnectedMetrumDrawerList} from '../partLists/drawerList'
import {ConnectedMetrumBoardList} from '../partLists/boardList'
import {ConnectedMetrumColumnList} from '../partLists/columnList'
import {ConnectedMetrumTabelarList} from '../partLists/tabelarList'
import {ConnectedMetrumBackpanelList} from '../partLists/backpanelList'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'
import {ConnectedMetrumDoorList} from '../partLists/doorList'
import {ConnectedMetrumBottomBoardList} from '../partLists/bottomBoardList'

export default function ConnectedMetrumPrintView() {
  const footHeight = useStore((store) => store.config.feet.height)

  const drawerPartList = ConnectedMetrumDrawerList()
    .map((drawer) => Object.values(drawer.parts).map((el) => el))
    .flat() as any[]
  const partList = [
    ...ConnectedMetrumBoardList(),
    ...ConnectedMetrumColumnList(),
    ...ConnectedMetrumTabelarList(),
    ...ConnectedMetrumBackpanelList(),
    ...ConnectedMetrumDoorList(),
    ...ConnectedMetrumBottomBoardList(),
    ...drawerPartList,
  ]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)

  return (
    <PrintView reference="metrum" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedMetrum doors={true} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedMetrum doors={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne ohne Türen'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'back'}>
          <ConnectedMetrum doors={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht hinten'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}
