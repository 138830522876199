export const initialView = {
  backpanels: {
    selectorGroup: 0,
    visible: true,
    variant: 0,
    edit: false,
  },
  boards: {
    edit: false,
  },
  colums: {
    edit: false,
  },
  slidingDoors: {
    visible: true,
  },
  doors: {
    edit: false,
    variant: 0,
    visible: true,
    size: 7,
  },
  drawers: {
    variant: 0.5,
    visible: true,
    grainDirection: 0,
    edit: false,
  },

  clothingRails: {
    edit: false,
  },
  tabelars: {
    edit: false,
  },
  feet: {},
}

export type IView = typeof initialView
