import {frameModel, shapeModel, tableMaterials} from './datamodels'

export const initialConfig = {
  main: {
    size: 0.4,
    height: 0.47,
    materialID: 's25Ei' as keyof typeof tableMaterials,
    treatmentID: 0,
    frameID: 'L' as keyof typeof frameModel,
    shapeID: 'R' as keyof typeof shapeModel,
  },
}

export type IEuklidConfig = typeof initialConfig
