import {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'
import {shelfMaterials} from '../store/datamodels'
import {useStore} from '../store/store'
import {ConnectedColumns} from './columns/Columns'
import {ConnectedBackpanels} from './backpanels/Backpanels'
import {ConnectedBoards} from './boards/Boards'
import {ConnectedBoardSelectors} from './boards/BoardSelectors'
import {ConnectedClothingRails} from './clothingRails/ClothingRails'
import {ConnectedColumnSelectors} from './columns/ColumnSelectors'
import {ConnectedDimensionControls} from './dimensioncontrols/DimensionControls'
import {ConnectedDoors} from './doors/Doors'
import {ConnectedDoorSelectors} from './doors/DoorSelectors'
import {ConnectedDrawers} from './drawers/Drawers'
import {ConnectedDrawerSelectors} from './drawers/DrawerSelectors'
import {ConnectedFeet} from './feet/Feet'
import {ConnectedTabelars} from './tabelars/Tabelars'
import {ConnectedTabelarSelectors} from './tabelars/TabelarSelectors'
import {ConnectedClothingRailSelectors} from './clothingRails/ClothingRailSelectors'

export const Metrum = (props: any) => {
  const {doors = true} = props
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())

  useLayoutEffect(() => {
    fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
  })

  return (
    <group ref={fullGroupRef}>
      <ConnectedColumns />
      <ConnectedColumnSelectors />
      <ConnectedBoards />
      <ConnectedBoardSelectors />
      <ConnectedBackpanels />
      <ConnectedTabelars />
      <ConnectedTabelarSelectors />
      {doors && <ConnectedDoors />}
      <ConnectedDoorSelectors />
      <ConnectedDrawers />
      <ConnectedDrawerSelectors />
      <ConnectedClothingRails />
      <ConnectedClothingRailSelectors />
      <ConnectedFeet />
      <ConnectedDimensionControls />
    </group>
  )
}

export const ConnectedMetrum = (props: any) => {
  const columns = useStore((store) => store.config.columns.list, shallow)
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const params = {
    width: columns.reduce((acc, col) => acc + col.width + thickness, thickness),
    depth: useStore((store) => store.config.main.depth),
    footHeight: useStore((store) => store.config.feet.height),
    doors: props.doors,
  }

  return <Metrum {...params} />
}
