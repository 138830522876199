import shallow from 'zustand/shallow'
import {ConnectedDigitalPrice} from 'products/digital/utils/price'
import {useStore} from '../store/store'
import {localFactors} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {shelfMaterials, footModel, regalModel} from '../store/datamodels'
import {ConnectedDigitalBoardList} from '../partLists/boardList'
import {ConnectedDigitalColumnList} from '../partLists/columnList'
import {ConnectedDigitalBackpanelList} from '../partLists/backpanelList'
import {ConnectedDigitalDrawerList} from '../partLists/drawerList'
import {ConnectedDigitalFlapList} from '../partLists/flapList'
import {getMaterialName} from 'utils/helpers'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [shelfMaterialID, boards, feet, uri] = useStore((state) => [state.config.main.materialID, state.config.rows.list, state.config.feet, state.uri], shallow)
  const shelfMaterial = shelfMaterials[shelfMaterialID]
  const shelfMaterialName = getMaterialName(shelfMaterialID)
  const width = Math.round(useStore((state) => state.config.main.width) * 1000) / 1000
  const depth = Math.round(useStore((state) => state.config.main.depth) * 1000) / 1000
  const footHeight = useStore((state) => state.config.feet.height)
  const fullHeight = Math.round((boards.reduce((acc, board) => acc + board.height, shelfMaterial.thickness) + footHeight) * 1000)
  const footRange = footModel[feet.id].range || [0, 0]
  const fussWand =
    footModel[feet.id].name + ' ' + Math.round(feet.height * 1000) + ' mm (' + Math.round((feet.height + footRange[0]) * 1000) + '-' + Math.round((feet.height + footRange[1]) * 1000) + ' mm)'

  const partList = () => {
    const drawerPartList = ConnectedDigitalDrawerList()
      .map((drawer) => Object.values(drawer.parts).map((el) => el))
      .flat() as any[]
    const flapPartList = ConnectedDigitalFlapList()
      .map((flap) => Object.values(flap.parts).map((el) => el))
      .flat() as any[]
    const partList = [...ConnectedDigitalBoardList(), ...ConnectedDigitalColumnList(), ...ConnectedDigitalBackpanelList(), ...drawerPartList, ...flapPartList]
    return partList
  }

  let nrColumns = 0
  let nrBoards = 0
  let nrBackpanels = 0
  let nrDrawers = 0
  let nrFlaps = 0

  partList().forEach((part) => {
    if (part.type === 'column') nrColumns++
    if (part.type === 'middleColumn') nrColumns++
    if (part.type === 'board') nrBoards++
    if (part.type === 'bottom') nrBoards++
    if (part.type === 'top') nrBoards++
    if (part.type === 'backpanel') nrBackpanels++
    if (part.type === 'drawerFront') nrDrawers++
    if (part.type === 'flapFront') nrFlaps++
  })

  return (
    <div className="description">
      <div className="print_title">Digital</div>
      <div className="sub_title">
        <a href={'/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Abmessungen</div>
          {width * 1000} mm x {depth * 1000} mm x {fullHeight} mm
          <div className="sub_title">lichte Fachhöhen</div>
          {boards.map((board, i) => {
            const width = (board.height - shelfMaterial.thickness - regalModel.steelBeamThickness) * 1000
            if (i + 1 < boards.length) {
              return <span key={i}>{Math.round(width)} mm, </span>
            } else {
              return <span key={i}>{Math.round(width)} mm</span>
            }
          })}
          <div className="sub_title">Fuss / Wandmontage</div>
          {fussWand}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div> {nrColumns} Seiten</div>
          <div> {nrBoards} Regalböden</div>
          <div> {nrBackpanels} Rückwände, </div>
          <div className="sub_title">Ausstattung</div>
          <div> {nrDrawers} Schubläden, Front </div>
          <div> {nrFlaps} Klappen </div>
          <div className="price_title">Preis </div>
          <div className="price">
            <ConnectedDigitalPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}
