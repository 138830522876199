import {forwardRef, useEffect, useRef} from 'react'
import * as THREE from 'three'
import {BoxGeometry, CylinderGeometry} from 'three'
import {footModel} from 'utils/datamodels'
import {IVector} from './FiberBoard'

export const Foot = (props: {pos: IVector; material: THREE.Material; size: IVector; type: keyof typeof footModel; rotation?: number}) => {
  const {type} = props

  switch (type) {
    case 's23':
      return <RoundFoot {...props} />
    case 'u308':
      return <FootU308 {...props} />
    case 'rnd30':
      return <RoundFoot {...props} />
    case 'rct16':
      return <RectFoot {...props} />
    case 'shv':
      return <FootSHV {...props} />
    case 'gn832':
      return <FootGN832 {...props} />
    case 'gn840':
      return <FootGN840 {...props} />
    case 'castor43':
      return <Castor43 {...props} />
    case 'bedRnd':
      return <FootBedRnd {...props} />
    case 'bedRct':
      return <FootBedRct {...props} />
    case 'bedStackAbleRnd':
      return <RoundFoot {...props} />
    default:
      return <RoundFoot {...props} />
  }
}
const RectFoot = forwardRef((props: {pos: IVector; material: THREE.Material; size: IVector}, ref: any) => {
  const {pos, material, size} = props
  const geoRef = useRef<BoxGeometry>(null!)
  useEffect(() => {
    geoRef?.current?.translate(0, -0.5, 0)
  }, [])

  return (
    <mesh ref={ref} position={pos || [0, 0, 0]} material={material} scale={size || [1, 1, 1]}>
      <boxGeometry ref={geoRef} args={[1, 1, 1]} />
      <meshStandardMaterial color={'black'} />
    </mesh>
  )
})

const RoundFoot = forwardRef((props: {pos: IVector; material: THREE.Material; size: IVector}, ref: any) => {
  const {pos, material, size} = props
  const geoRef = useRef<CylinderGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, -0.5, 0)
  }, [])

  return (
    <mesh ref={ref} position={pos || [0, 0, 0]} material={material} scale={size || [1, 1, 1]}>
      <cylinderGeometry ref={geoRef} args={[1, 1, 1, 20]} />
      <meshStandardMaterial color={'black'} />
    </mesh>
  )
})

const Castor43 = forwardRef((props: {pos: IVector; material: THREE.Material}, ref: any) => {
  const {pos, material} = props

  return (
    <group position={pos} rotation={[-Math.PI / 2, 0, 0]} ref={ref}>
      <mesh position={[0, 0, -0.035 / 2]} material={material} scale={[0.03, 0.01, 0.035]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
      <group position={[0, 0, -0.0255]}>
        <mesh position={[0, -0.015 / 2 - 0.013 / 2, 0]} material={material} scale={[0.0175, 0.013, 0.0175]}>
          <cylinderGeometry args={[1, 1, 1, 20]} />
          <meshStandardMaterial color={'#444'} />
        </mesh>
        <mesh position={[0, 0.013 / 2 + 0.015 / 2, 0]} material={material} scale={[0.0175, 0.013, 0.0175]}>
          <cylinderGeometry args={[1, 1, 1, 20]} />
          <meshStandardMaterial color={'#444'} />
        </mesh>
        <mesh position={[0, 0, 0]} material={material} scale={[0.006, 0.043, 0.006]}>
          <cylinderGeometry args={[1, 1, 1, 20]} />
          <meshStandardMaterial color={'#000'} />
        </mesh>
      </group>
    </group>
  )
})

const FootU308 = forwardRef((props: {pos: IVector; material: THREE.Material}, ref: any) => {
  const {pos, material} = props
  const geoRef = useRef<CylinderGeometry>(null)
  const geoRef2 = useRef<CylinderGeometry>(null)
  const geoRef3 = useRef<BoxGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, -0.5, 0)
    geoRef2.current?.translate(0, -0.5, 0)
    geoRef3.current?.translate(0, -0.5, 0)
  }, [])

  return (
    <>
      <mesh ref={ref} position={[pos[0], -0.038, pos[2]]} material={material} scale={[0.015, 0.005, 0.015]}>
        <cylinderGeometry ref={geoRef} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], -0.025, pos[2]]} material={material} scale={[0.004, 0.005, 0.004]}>
        <cylinderGeometry ref={geoRef2} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'darkgrey'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], 0, pos[2]]} material={material} scale={[0.03, 0.025, 0.019]}>
        <boxGeometry ref={geoRef3} args={[1, 1, 1]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
    </>
  )
})

const FootSHV = forwardRef((props: {pos: IVector; material: THREE.Material}, ref: any) => {
  const {pos, material} = props
  const geoRef = useRef<BoxGeometry>(null)
  const geoRef2 = useRef<CylinderGeometry>(null)
  const geoRef3 = useRef<BoxGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, -0.5, 0)
    geoRef2.current?.translate(0, -0.5, 0)
    geoRef3.current?.translate(0, -0.5, 0)
  }, [])

  return (
    <>
      <mesh ref={ref} position={[pos[0], -0.015, pos[2]]} material={material} scale={[0.012, 0.003, 0.018]}>
        <boxGeometry ref={geoRef3} args={[1, 1, 1]} />
        <meshStandardMaterial color={'black'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], -0.0135, pos[2]]} material={material} scale={[0.012, 0.0015, 0.018]}>
        <boxGeometry ref={geoRef} args={[1, 1, 1]} />
        <meshStandardMaterial color={'darkgrey'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], 0, pos[2]]} material={material} scale={[0.006, 0.0135, 0.006]}>
        <cylinderGeometry ref={geoRef2} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'darkgrey'} />
      </mesh>
    </>
  )
})

const FootGN832 = forwardRef((props: {pos: IVector; material: THREE.Material}, ref: any) => {
  const {pos, material} = props
  const geoRef = useRef<CylinderGeometry>(null)
  const geoRef2 = useRef<CylinderGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, 0.5, 0)
    geoRef2.current?.translate(0, 0.5, 0)
  }, [])

  return (
    <>
      <mesh ref={ref} position={[pos[0], -0.03, pos[2]]} material={material} scale={[0.004, 0.043, 0.004]}>
        <cylinderGeometry ref={geoRef} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], -0.03, pos[2]]} material={material} scale={[0.016, 0.005, 0.016]}>
        <cylinderGeometry ref={geoRef2} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
    </>
  )
})

const FootGN840 = forwardRef((props: {pos: IVector; material: THREE.Material}, ref: any) => {
  const {pos, material} = props
  const geoRef = useRef<CylinderGeometry>(null)
  const geoRef2 = useRef<CylinderGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, 0.5, 0)
    geoRef2.current?.translate(0, 0.5, 0)
  }, [])

  return (
    <>
      <mesh ref={ref} position={[pos[0], -0.025, pos[2]]} material={material} scale={[0.004, 0.0875, 0.004]}>
        <cylinderGeometry ref={geoRef} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>

      <mesh ref={ref} position={[pos[0], -0.025, pos[2]]} material={material} scale={[0.02, 0.004, 0.02]}>
        <cylinderGeometry ref={geoRef2} args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
    </>
  )
})

const FootBedRnd = forwardRef((props: {pos: IVector; material: THREE.Material; size: IVector; rotation?: number}, ref: any) => {
  const {pos, material, size} = props
  const geoRef = useRef<BoxGeometry>(null)
  useEffect(() => {
    geoRef.current?.translate(0, -0.5, 0)
  }, [])
  const rotation = props.rotation || 0

  var shape = new THREE.Shape()
  shape.moveTo(0.07, 0)
  shape.lineTo(0.393, 0)
  shape.lineTo(0.393, 0.027)
  shape.lineTo(0.027, 0.393)
  shape.lineTo(0, 0.393)
  shape.lineTo(0, 0.07)
  shape.lineTo(0.07, 0)

  var shape2 = new THREE.Shape()
  shape2.moveTo(0, 0)
  shape2.lineTo(0.5176, 0)
  shape2.lineTo(0.5176 - 0.2288, -0.032)
  shape2.lineTo(0.2288, -0.032)
  shape2.lineTo(0, 0)

  var extrudeSettings = {
    steps: 1,
    depth: 0.003,
    bevelEnabled: false,
  }

  return (
    <group position={pos} rotation={[0, rotation * Math.PI, 0]} ref={ref}>
      <mesh material={material} rotation={[0.5 * Math.PI, 0, 0]}>
        <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
      <mesh position={[0.02488, 0, 0.3908]} material={material} rotation={[0, 0.25 * Math.PI, 0]}>
        <extrudeGeometry attach="geometry" args={[shape2, extrudeSettings]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
      <mesh position={[0.183, -size[1] / 2 - 0.003, 0.183]} material={material} scale={[size[0], size[1], size[0]]}>
        <cylinderGeometry args={[1, 1, 1, 20]} />
        <meshStandardMaterial color={'black'} />
      </mesh>
    </group>
  )
})

const FootBedRct = forwardRef((props: {pos: IVector; material: THREE.Material; size: IVector}, ref: any) => {
  const {pos, material, size} = props
  const geoRef = useRef<BoxGeometry>(null!)
  useEffect(() => {
    geoRef?.current?.translate(0, -0.5, 0)
  }, [])

  return (
    <mesh ref={ref} position={pos || [0, 0, 0]} material={material} scale={size || [1, 1, 1]}>
      <boxGeometry ref={geoRef} args={[1, 1, 1]} />
      <meshStandardMaterial color={'black'} />
    </mesh>
  )
})
