import {useStore} from 'products/iku/store/store'
import {bedMaterials, headBoardModel} from '../store/datamodels'

export type IIkuBoardList = {
  key: number | string
  xPos: number
  yPos: number
  zPos: number
  length: number
  depth: number
  thickness: number
  mitreLeft: boolean
  mitreRight: boolean
  rotation: [number, number, number]
  material: keyof typeof bedMaterials
  type: string
}[]

export const ConnectedIkuBoardList = () => {
  const [length, width, materialID, frameHeight, frameFlush, headBoardID, headBoardHeight] = useStore((store) => [
    store.config.main.length,
    store.config.main.width,
    store.config.main.materialID,
    store.config.main.frameHeight,
    store.config.main.frameFlush,
    store.config.main.headBoard.id,
    store.config.main.headBoard.height,
  ])
  return IkuBoardList(length, width, materialID, frameHeight, frameFlush, headBoardID, headBoardHeight)
}

export const IkuBoardList = (length: number, width: number, materialID: keyof typeof bedMaterials, frameHeight: number, frameFlush: any, headBoardID: number, headBoardHeight: any) => {
  const thickness = bedMaterials[materialID].thickness
  const frameLength = frameFlush ? length : headBoardID > 1 && headBoardID < 4 ? length + thickness : length + 2 * thickness
  const frameWidth = frameFlush ? width : headBoardID > 1 ? width + thickness : width + 2 * thickness
  const isMaterialtypeLino = bedMaterials[materialID].type === 6
  const mitreJoints = frameHeight >= 0.07 ? [...headBoardModel[headBoardID].mitreJoints] : ['side', 'side', 'side', 'side']
  mitreJoints.forEach((joint, index) => {
    if (joint === true && isMaterialtypeLino) {
      mitreJoints[index] = 'side'
    } else {
      mitreJoints[index] = frameHeight >= 0.07 ? headBoardModel[headBoardID].mitreJoints[index] : 'side'
    }
  })

  const boards: IIkuBoardList = []

  boards.push({
    key: 'sideRight',
    xPos: mitreJoints[3] !== 'end' ? 0 : thickness,
    yPos: 0,
    zPos: frameWidth,
    length: frameLength - (mitreJoints[2] !== 'end' ? 0 : thickness) - (mitreJoints[3] !== 'end' ? 0 : thickness),
    depth: frameHeight,
    thickness: thickness,
    mitreLeft: mitreJoints[3] === true,
    mitreRight: mitreJoints[2] === true,
    rotation: [-0.5, 0, 0],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 'sideLeft',
    xPos: frameLength - (mitreJoints[1] !== 'end' ? 0 : thickness),
    yPos: 0,
    zPos: 0,
    length: frameLength - (mitreJoints[0] !== 'end' ? 0 : thickness) - (mitreJoints[1] !== 'end' ? 0 : thickness),
    depth: frameHeight + headBoardModel[headBoardID].back * headBoardHeight,
    thickness: thickness,
    mitreLeft: mitreJoints[1] === true,
    mitreRight: mitreJoints[0] === true,
    rotation: [0.5, 1, 0],
    material: materialID,
    type: 'bedSide',
  })
  boards.push({
    key: 3,
    xPos: 0,
    yPos: 0,
    zPos: mitreJoints[0] !== 'side' ? 0 : thickness,
    length: frameWidth - (mitreJoints[0] !== 'side' ? 0 : thickness) - (mitreJoints[3] !== 'side' ? 0 : thickness),
    depth: frameHeight + headBoardModel[headBoardID].left * headBoardHeight,
    thickness: thickness,
    mitreLeft: mitreJoints[0] === true,
    mitreRight: mitreJoints[3] === true,
    rotation: [-0.5, 0, -0.5],
    material: materialID,
    type: 'footBoard',
  })
  boards.push({
    key: 4,
    xPos: frameLength,
    yPos: 0,
    zPos: frameWidth - (mitreJoints[2] !== 'side' ? 0 : thickness),
    length: frameWidth - (mitreJoints[1] !== 'side' ? 0 : thickness) - (mitreJoints[2] !== 'side' ? 0 : thickness),
    depth: frameHeight + headBoardModel[headBoardID].right * headBoardHeight,
    thickness: thickness,
    mitreLeft: mitreJoints[2] === true,
    mitreRight: mitreJoints[1] === true,
    rotation: [0.5, 1, -0.5],
    material: materialID,
    type: 'headBoard',
  })
  return boards
}
