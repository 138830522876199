import {forwardRef} from 'react'
import * as THREE from 'three'
import {Euler} from 'three'
import {IVector} from 'common/threeComponents/FiberBoard'
import {useGLTF} from '@react-three/drei'

export interface IBrackets {
  xPos: number
  yPos: number
  zPos: number
  rotation?: IVector
}

export const Brackets = forwardRef((props: IBrackets, ref: any) => {
  const {xPos, yPos, zPos} = props
  const rotation = props.rotation ? new Euler(props.rotation[0] * Math.PI, props.rotation[1] * Math.PI, props.rotation[2] * Math.PI) : new Euler(0, 0, 0)

  const {nodes} = useGLTF('/brackets.gltf')
  const metalMaterial = new THREE.MeshStandardMaterial({color: 0xffffff, metalness: 0.5, roughness: 0.5})

  return (
    <group name="columnGroup" ref={ref} rotation={rotation} position={[xPos, yPos, zPos]}>
      {
        // @ts-ignore
        <mesh geometry={nodes.mesh_0_2.geometry} material={metalMaterial} position={[0, 0, 0]} scale={0.001} rotation={[0, 0, Math.PI]} />
      }
    </group>
  )
})
