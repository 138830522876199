import {useCommonStore} from 'common/Store/CommonStore'
import {MaterialSwitch, AccordionSizeSlider, AccordionListSelector} from 'components/material_interfaces/materialInterfaces'
import {bedModel} from 'products/bezwei/store/datamodels'
import {useStore} from 'products/bezwei/store/store'
import {slattedModel} from 'utils/datamodels'
import {getInsetOptions} from 'utils/helpers'

export function SlattedMattressView() {
  const [width, mattressHeight, frameHeight, slattedId] = useStore((store) => [store.config.main.width, store.config.mattress.height, store.config.main.frameHeight, store.config.slatted.id])

  const setMattressVisibility = useStore((store) => store.setMattressVisibility)
  const setMattressHeight = useStore((store) => store.setMattressHeight)
  const mattressVisibility = useStore((store) => store.view.mattress.visible)

  const setSlattedVisibility = useStore((store) => store.setSlattedVisibility)
  const slattedVisibility = useStore((store) => store.view.slatted.visible)
  const setSlattedId = useStore((store) => store.setSlattedId)
  const setSlattedHeight = useStore((store) => store.setSlattedHeight)
  const slattedHeightInStore = useStore((store) => store.config.slatted.height)
  const slattedHeight = slattedId !== 'own' ? slattedModel[slattedId].height : slattedHeightInStore
  const setSlattedInset = useStore((store) => store.setSlattedInset)
  const slattedInsetStore = useStore((store) => store.config.slatted.inset)
  const slattedInset = slattedInsetStore
  const usableWidth = width
  const nrOfSlats = usableWidth > (slattedId === 'integrated' ? 1.2 : 1.4) ? 2 : 1

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const slattedOptions = [...Object.values(slattedModel)]
  const insetOptions = getInsetOptions(frameHeight, slattedHeight, nrOfSlats)

  return (
    <>
      <MaterialSwitch onClick={setSlattedVisibility} title={'Lattenrost anzeigen'} value={slattedVisibility} />

      <AccordionSizeSlider
        disabled={!slattedVisibility || slattedId !== 'own'}
        options={[0.05]}
        min={bedModel.minSlattedHeight * 1000}
        max={bedModel.maxSlattedHeight * 1000}
        onClick={setSlattedHeight}
        title={'Lattenroststärke'}
        id={'slattedHeight'}
        value={slattedHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionListSelector id="slatted" options={slattedOptions} onClick={setSlattedId} title={'Lattenrosttyp'} value={slattedId} expanded={expanded} handleChange={handleChange} />
      <MaterialSwitch onClick={setMattressVisibility} title={'Matratze anzeigen'} value={mattressVisibility} />
      <AccordionSizeSlider
        disabled={!mattressVisibility}
        options={[0.12, 0.14, 0.16, 0.18, 0.2, 0.22, 0.24, 0.26, 0.28, 0.3]}
        min={bedModel.minFrameHeight * 1000}
        max={bedModel.maxFrameHeight * 1000}
        onClick={setMattressHeight}
        title={'Matratzenstärke'}
        id={'mattressHeight'}
        value={mattressHeight * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={insetOptions}
        min={insetOptions[0] * 1000 - 10}
        max={insetOptions[insetOptions.length - 1] * 1000 + 10}
        onClick={setSlattedInset}
        title={'Einlegetiefe ' + (slattedId === 'integrated' ? 'Matratze' : 'Lattenrost und Matratze')}
        id={'inset'}
        value={slattedInset * 1000}
        clickRange={10}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
