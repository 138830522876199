import {accHelper, clearDrawers} from 'utils/helpers'
import {IStore, ISetProduce} from '../store'
import {frontMaterials} from '../datamodels'
import {GetState} from 'zustand'

export const createDrawerSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const drawers = {
    setDrawer: function (pos: {x: number; y: number}, size: {x: number; y: number}, inside: boolean = true): void {
      const tabelars = get().config.tabelars.list
      const columns = get().config.columns.list
      const grid = get().config.main.grid
      const height = get().config.main.height
      setProduce((state: IStore) => {
        const clearedDrawerArray = state.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, size, drawer.size)) return false
          return true
        })
        if (!state.config.drawers.list.find((drawer) => accHelper.same_is_at_position(pos, drawer.pos, size, drawer.size))) {
          clearedDrawerArray.push({pos, size, face: 'front', inside: inside})
        }
        const newDrawers = clearDrawers(clearedDrawerArray, tabelars, columns, state.config.boards.list, grid, height) as {
          pos: {x: number; y: number}
          size: {x: number; y: number}
          face: 'back' | 'front'
          inside: boolean
        }[]
        state.config.drawers.list = newDrawers
        const clearedDoorArray = state.config.doors.list.filter((door) => {
          if (accHelper.is_overlapping_with_other(door.pos, pos, door.size, size) && inside === false) return false

          return true
        })
        state.config.doors.list = clearedDoorArray
      })
    },
    setMaterialID: (newMaterialID: keyof typeof frontMaterials): void =>
      setProduce((state: IStore) => {
        state.config.drawers.materialID = newMaterialID
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.drawers.variant = variant
      })
    },
    setGrainDirection: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.drawers.grainDirection = variant
      })
    },
    setEditDrawers: (edit: boolean): void =>
      setProduce((state: IStore) => {
        state.view.drawers.edit = edit
      }),
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.drawers.visible = show
      })
    },
  }

  return {
    drawers,
  }
}
