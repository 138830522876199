import {GetState} from 'zustand'
import {regalModel} from '../datamodels'
import {ISetProduce, IStore} from '../store'

export interface IColumnSlice {
  columns: {
    setEdit: (edit: boolean) => void
    delete: () => void
    toggleSubColumn: (xPos: number, yPos: number) => void
    create: () => void
    setWidth: (width: number, colNum: number) => void
  }
}

export const createColumnSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const columns = {
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.colums.edit = edit
      }),
    create: () =>
      setProduce((S: IStore) => {
        const newColumn = {width: regalModel.newColumWidth, face: 'front' as 'front' | 'back' | 'side'}
        let newSubColumns = []
        for (let j = 0; j < S.config.main.grid; j++) {
          newSubColumns.push([])
        }
        const newBoards = []
        if (S.config.boards.list.length < S.config.columns.list.length + 1) {
          for (let i = 1; i < S.config.main.grid - 1; i++) {
            i % 2 === 0 && newBoards.push(i)
          }
          S.config.boards.list.push(newBoards)
        }
        S.config.columns.list.push(newColumn)
      }),
    delete: () =>
      setProduce((store: IStore) => {
        store.config.columns.list.pop()
      }),
    toggleSubColumn: (xPos: number, yPos: number) => {
      const subColumns = get().config.subColumns.list
      setProduce((store: IStore) => {
        const index = subColumns.findIndex((subColumn: {pos: {x: number; y: number}}) => subColumn.pos.x === xPos && subColumn.pos.y === yPos)
        index === -1 ? store.config.subColumns.list.push({pos: {x: xPos, y: yPos}}) : store.config.subColumns.list.splice(index, 1)
      })
    },
    setWidth: (width: number, colNum: number) =>
      setProduce((store: IStore) => {
        store.config.columns.list[colNum].width = width
      }),
  }
  return {columns}
}
