import {localFactors} from 'utils/datamodels'
import {EuklidBoardList} from '../partLists/boardList'
import {baseUrl, frameModel, shapeModel, tableMaterials} from '../store/datamodels'
import {EuklidPrice} from '../utils/price'
import {useCommonStore} from 'common/Store/CommonStore'
import {Link} from 'react-router-dom'
import {PriceTable} from 'components/priceList/priceTable'

export const EuklidPriceList = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  const shapeIDs = ['R', 'Q']
  const frameIds = ['L', 'T', 'X']
  //const materials = Object.keys(tableMaterials).filter((id) => tableMaterials[id as keyof typeof tableMaterials].thickness === 0.03)
  const materials = ['s30Bu', 's30Ei', 's30Ki', 's30NmS', 's30NwS', 's30NoS']
  //const sideTableMaterials = Object.keys(tableMaterials).filter((id) => tableMaterials[id as keyof typeof tableMaterials].thickness === 0.025)
  const sideTableMaterials = ['s25Bu', 's25Ei', 's25Ki', 's25NmS', 's25NwS', 's25NoS']
  // get Names of SideTableMaterials
  const sideTableMaterialNames = sideTableMaterials.map((id) => tableMaterials[id as keyof typeof tableMaterials].name)
  // get Names of Materials
  const materialNames = materials.map((id) => tableMaterials[id as keyof typeof tableMaterials].name)

  const surfaceNames = ['Aufpreis geseift', 'Aufpreis lackiert']

  const sideTableWidths = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
  const sideTableHeights = [0.33, 0.4, 0.47]
  let sideTablePriceList: number[][][][][] = []
  let sideTablePriceListSurface: number[][][][][] = []

  shapeIDs.forEach((shapeID, n) => {
    sideTablePriceList.push([])
    sideTablePriceListSurface.push([])
    frameIds.forEach((frameID, k) => {
      sideTablePriceList[n].push([])
      sideTablePriceListSurface[n].push([])
      sideTableHeights.forEach((height, j) => {
        sideTablePriceList[n][k].push([])
        sideTablePriceListSurface[n][k].push([])
        sideTableMaterials.forEach((material, i) => {
          sideTablePriceList[n][k][j].push([])
          sideTableWidths.forEach((size) => {
            const partList = EuklidBoardList(size, height, material as keyof typeof tableMaterials, frameID as keyof typeof frameModel, shapeID as keyof typeof shapeModel)
            const price = Math.round(Math.round(EuklidPrice(localFactor.factor, material as keyof typeof tableMaterials, treatmentID, partList) / 10) * 10)
            sideTablePriceList[n][k][j][i].push(price)
          })
        })
        ;[2, 3].forEach((surface, i) => {
          sideTablePriceListSurface[n][k][j].push([])
          sideTableWidths.forEach((size) => {
            const partList = EuklidBoardList(size, height, 's25Ei', frameID as keyof typeof frameModel, shapeID as keyof typeof shapeModel)
            const IPrice = EuklidPrice(localFactor.factor, 's25Ei', surface, partList)
            const OilPrice = EuklidPrice(localFactor.factor, 's25Ei', 0, partList)
            const extraPrice = Math.round(Math.round((IPrice - OilPrice) / 10) * 10)
            sideTablePriceListSurface[n][k][j][i].push(extraPrice)
          })
        })
      })
    })
  })

  const highTableWidths = [0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2]
  const highTableHeights = [1.1]
  let highTablePriceList: number[][][][] = []
  let highTablePriceListSurface: number[][][][] = []

  shapeIDs.forEach((shapeID, n) => {
    highTablePriceList.push([])
    highTablePriceListSurface.push([])
    highTableHeights.forEach((height, j) => {
      highTablePriceList[n].push([])
      highTablePriceListSurface[n].push([])
      materials.forEach((material, i) => {
        highTablePriceList[n][j].push([])
        highTableWidths.forEach((size) => {
          const partList = EuklidBoardList(size, height, material as keyof typeof tableMaterials, 'X', shapeID as keyof typeof shapeModel)
          const price = Math.round(Math.round(EuklidPrice(localFactor.factor, material as keyof typeof tableMaterials, treatmentID, partList)))
          highTablePriceList[n][j][i].push(price)
        })
      })
      ;[2, 3].forEach((surface, i) => {
        highTablePriceListSurface[n][j].push([])
        highTableWidths.forEach((size) => {
          const partList = EuklidBoardList(size, height, 's30Ei', 'X', shapeID as keyof typeof shapeModel)
          const IPrice = EuklidPrice(localFactor.factor, 's30Ei', surface, partList)
          const OilPrice = EuklidPrice(localFactor.factor, 's30Ei', 0, partList)
          const extraPrice = Math.round(Math.round((IPrice - OilPrice) / 10) * 10)
          highTablePriceListSurface[n][j][i].push(extraPrice)
        })
      })
    })
  })

  const tableWidths = [0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2]
  const tableHeights = [0.72]
  let tablePriceList: number[][][][] = []
  let tablePriceListSurface: number[][][][] = []

  shapeIDs.forEach((shapeID, n) => {
    tablePriceList.push([])
    tablePriceListSurface.push([])
    tableHeights.forEach((height, j) => {
      tablePriceList[n].push([])
      tablePriceListSurface[n].push([])
      materials.forEach((material, i) => {
        tablePriceList[n][j].push([])
        tableWidths.forEach((size) => {
          const partList = EuklidBoardList(size, height, material as keyof typeof tableMaterials, 'X', shapeID as keyof typeof shapeModel)
          const price = Math.round(Math.round(EuklidPrice(localFactor.factor, material as keyof typeof tableMaterials, treatmentID, partList)))
          tablePriceList[n][j][i].push(price)
        })
      })
      ;[2, 3].forEach((surface, i) => {
        tablePriceListSurface[n][j].push([])
        tableWidths.forEach((size) => {
          const partList = EuklidBoardList(size, height, 's30Ei', 'X', shapeID as keyof typeof shapeModel)
          const IPrice = EuklidPrice(localFactor.factor, 's30Ei', surface, partList)
          const OilPrice = EuklidPrice(localFactor.factor, 's30Ei', 0, partList)
          const extraPrice = Math.round(Math.round((IPrice - OilPrice) / 10) * 10)
          tablePriceListSurface[n][j][i].push(extraPrice)
        })
      })
    })
  })

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>
      <h1>Euklid Stehtisch</h1>
      {shapeIDs.map((shapeID, n) => (
        <>
          <h2>Euklid {shapeID}X</h2>
          {highTableHeights.map((height, j) => (
            <>
              <h4>Höhe: {height}</h4>
              <PriceTable key={j} rows={materialNames} columns={highTableWidths} prices={highTablePriceList[n][j]} />
              <PriceTable key={j} rows={surfaceNames} columns={highTableWidths} prices={highTablePriceListSurface[n][j]} />
            </>
          ))}
        </>
      ))}
      <h1>Euklid Sitztisch</h1>
      {shapeIDs.map((shapeID, n) => (
        <>
          <h2>Euklid {shapeID}X</h2>
          {tableHeights.map((height, j) => (
            <>
              <h4>Höhe: {height}</h4>
              <PriceTable key={j} rows={materialNames} columns={tableWidths} prices={tablePriceList[n][j]} />
              <PriceTable key={j} rows={surfaceNames} columns={tableWidths} prices={tablePriceListSurface[n][j]} />
            </>
          ))}
        </>
      ))}
      <h1>Euklid Beistelltisch</h1>
      {shapeIDs.map((shapeID, n) => (
        <>
          {frameIds.map((frameID, k) => (
            <>
              <h2>
                Euklid {shapeID}
                {frameID}
              </h2>
              {sideTableHeights.map((height, j) => (
                <>
                  <h4>Höhe: {height}</h4>
                  <PriceTable key={j} rows={sideTableMaterialNames} columns={sideTableWidths} prices={sideTablePriceList[n][k][j]} />
                  <PriceTable key={j} rows={surfaceNames} columns={sideTableWidths} prices={sideTablePriceListSurface[n][k][j]} />
                </>
              ))}
            </>
          ))}
        </>
      ))}
    </>
  )
}
