import {KubaturPrice} from '../utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors, API_DOMAIN} from 'utils/datamodels'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'
import {backpanelMaterials, baseUrl, frontMaterials, shelfMaterials, slidingDoorMaterials} from '../store/datamodels'
import {useState, useLayoutEffect, Fragment} from 'react'
import {KubaturBoardList} from '../partLists/boardList'
import {IKubaturConfig} from '../store/initialConfig'
import {KubaturDrawerList} from '../partLists/drawerList'
import {KubaturFootBarList, KubaturFootList} from '../partLists/footList'
import {useMemo} from 'react'
import {KubaturColumnList} from '../partLists/columnList'
import {KubaturBackpanelList} from '../partLists/backpanelList'
import {KubaturTabelarList} from '../partLists/tabelarList'
import {KubaturSubColumnList} from '../partLists/subColumnList'
import {KubaturDoorList} from '../partLists/doorList'
import {KubaturClothingRailList} from '../partLists/clothingRailList'
import {KubaturSlidingDoorList} from '../partLists/slidingDoorList'

export const KubaturPriceList = () => {
  let [unused, setConfig] = useState({} as IKubaturConfig)
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(shelfMaterials)
  const materials = ['m18MW', 'm18CB', 'm18SB', 'm18Ei', 's19Ei', 's19Ta', 's19Zi']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof shelfMaterials))

  const ids = useMemo(() => ['kubatur-2', 'kubatur-1', 'kubatur-3', 'kubatur-7', 'kubatur-10', 'kubatur-9', 'kubatur-8', 'kubatur-6', 'kubatur-4'], [])
  let configList: {id: string; config: IKubaturConfig}[] = useMemo(() => [], [])
  let priceListKubatur: number[][][] = useMemo(() => [], [])

  useLayoutEffect(() => {
    const fetchConfig = async (uri: string) => {
      const product = await fetch(`${API_DOMAIN}/product/${uri}`)
        .then((r) => r.json())
        .then((data) => {
          const config1 = JSON.parse(data.configuration)
          setConfig(config1)
          configList.push({id: uri, config: config1})
          return {config1}
        })
        .catch((err) => console.log(err))
      return product
    }
    ids.forEach((id) => fetchConfig(id))
  }, [])

  useLayoutEffect(() => {
    if (configList.length === ids.length) {
      ids.forEach((id, j) => {
        const foundConfig = configList.find((el) => el.id === id)
        priceListKubatur.push([])
        if (foundConfig) {
          const config = foundConfig.config
          // is the MainMaterial included in the shelfReference of the Accmaterial
          // if not, use the Accmaterial from the config
          // if yes, find the Accmaterial that includes the MainMaterial in its shelfReference
          const isRefBackpanelMaterial = backpanelMaterials[config.backpanels.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefDoorMaterial = frontMaterials[config.doors.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefSlidingDoorMaterial = slidingDoorMaterials[config.slidingDoors.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefDrawerMaterial = frontMaterials[config.drawers.materialID].shelfReference.includes(config.main.materialID as never)
          materials.forEach((material, i) => {
            const backpanelMaterial = isRefBackpanelMaterial
              ? Object.values(backpanelMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'mdf8W'
              : config.backpanels.materialID
            const doorMaterial = isRefDoorMaterial ? Object.values(frontMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'm18MW' : config.doors.materialID
            const slidingDoorMaterial = isRefSlidingDoorMaterial
              ? Object.values(slidingDoorMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'h2SWBC'
              : config.slidingDoors.materialID
            const drawerMaterial = isRefDrawerMaterial ? Object.values(frontMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'm18MW' : config.drawers.materialID
            const configModified = {
              ...config,
              main: {...config.main, materialID: material as keyof typeof shelfMaterials},
              backpanels: {...config.backpanels, materialID: backpanelMaterial as keyof typeof backpanelMaterials},
              doors: {...config.doors, materialID: doorMaterial as keyof typeof frontMaterials},
              slidingDoors: {...config.slidingDoors, materialID: slidingDoorMaterial as keyof typeof slidingDoorMaterials},
              drawers: {...config.drawers, materialID: drawerMaterial as keyof typeof frontMaterials},
            }
            priceListKubatur[j].push([])
            const partList = getKubaturPartList({...configModified, main: {...configModified.main, materialID: material as keyof typeof shelfMaterials}})
            const drawerList = KubaturDrawerList(
              configModified.drawers.list,
              configModified.doors,
              configModified.backpanels,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.boards.list,
              configModified.subColumns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.drawers.materialID,
              configModified.main.shelfTyp
            )
            const doorList = KubaturDoorList(
              configModified.doors.list,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.doors.materialID,
              configModified.main.shelfTyp
            )
            const footList = KubaturFootList(configModified.main.depth, configModified.columns.list, configModified.feet.id, configModified.feet.height, material as keyof typeof shelfMaterials)
            const clothingRailList = KubaturClothingRailList(
              configModified.clothingRails.list,
              configModified.main.height,
              configModified.main.depth,
              configModified.columns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.boards.list,
              configModified.subColumns.list,
              configModified.main.shelfTyp
            )
            priceListKubatur[j][i].push(
              KubaturPrice(localFactor.factor, material as keyof typeof shelfMaterials, treatmentID, partList, footList, clothingRailList, doorList, drawerList).props.children
            )
          })
        }
      })
    }
  }, [localFactor.factor, materials, priceListKubatur, configList, ids])

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>Kubatur</h1>
        {priceListKubatur.length === ids.length &&
          ids.map((id, i) => (
            <Fragment key={i}>
              <h2>{id}</h2>
              <PriceTable rows={materialNames} columns={['']} prices={priceListKubatur[i]} />
            </Fragment>
          ))}
      </>
    </>
  )
}

const getKubaturPartList = (configs: IKubaturConfig) => {
  const boardList = KubaturBoardList(
    configs.columns.list,
    configs.main.height,
    configs.main.depth,
    configs.main.materialID,
    configs.main.grid,
    configs.boards.list,
    configs.doors,
    configs.backpanels,
    configs.main.shelfTyp
  )
  const columnList = KubaturColumnList(configs.columns.list, configs.main.height, configs.main.depth, configs.main.materialID, configs.slidingDoors, configs.doors, configs.main.shelfTyp)
  const subColumnList = KubaturSubColumnList(
    configs.subColumns.list,
    configs.main.height,
    configs.main.depth,
    configs.columns.list,
    configs.boards.list,
    configs.backpanels,
    configs.doors,
    configs.drawers.list,
    configs.main.grid,
    configs.main.materialID,
    configs.main.shelfTyp
  )
  const tabelarList = KubaturTabelarList(
    configs.tabelars.list,
    configs.main.depth,
    configs.main.height,
    configs.columns.list,
    configs.boards.list,
    configs.subColumns.list,
    configs.doors,
    configs.main.grid,
    configs.main.materialID,
    configs.main.shelfTyp,
    configs.backpanels.materialID
  )
  const backpanelList = KubaturBackpanelList(configs.columns.list, configs.main.height, configs.main.materialID, configs.backpanels.materialID, configs.main.shelfTyp)

  const slidingDoorPartList = KubaturSlidingDoorList(configs.main.shelfTyp, configs.slidingDoors, configs.main.depth, configs.main.height, configs.columns.list, configs.main.materialID)
    .map((door) => Object.values(door.parts).map((el) => el))
    .flat() as any[]
  const footBarList = KubaturFootBarList(configs.main.depth, configs.columns.list, configs.main.materialID, configs.main.shelfTyp)
  const partList = [...boardList, ...columnList, ...backpanelList, ...tabelarList, ...subColumnList, ...footBarList, ...slidingDoorPartList]
  return partList
}
