import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/dasregal/store/store'
import {Fragment, useRef} from 'react'
import * as THREE from 'three'
import {materialModel} from 'utils/datamodels'
import shallow from 'zustand/shallow'
import {doorTypeModel, regalModel, shelfMaterials} from './../../store/datamodels'

interface IDoorSelectors {
  material?: THREE.Material
  thickness: number
  zPos: number
  doorList: {pos: {x: number; y: number}; size: {x: number; y: number}; xPos: number; yPos: number; height: number; width: number; side: 'left' | 'right'}[]
  callback: (pos: {x: number; y: number}, size: {x: number; y: number}, side: 'left' | 'right') => void
}

export const DoorSelectors = (props: IDoorSelectors) => {
  return (
    <>
      {props.doorList.map((el, i) => {
        return (
          <Selector
            key={i}
            size={[el.height, props.thickness, el.width]}
            rotation={[0, 0.5, 0.5]}
            onClick={() => props.callback(el.pos, el.size, el.side)}
            xPos={el.xPos}
            yPos={el.yPos}
            zPos={props.zPos}
          />
        )
      })}
    </>
  )
}

export const ConnectedDoorSelectors = () => {
  const visible = useStore((store) => store.view.doors.edit)
  const [columns, height, grid, depth] = useStore((store) => [store.config.columns, store.config.main.height, store.config.main.grid, store.config.main.depth], shallow)
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}))
  const thickness = materialModel[useStore((store) => store.config.doors.materialID)].thickness
  const gridSize = (height - thickness) / grid
  const variant = useStore((store) => store.view.doors.variant)
  const doorSize = useStore((store) => store.view.doors.size)
  const doorHeight = doorSize * gridSize - thickness
  const doorVariant = doorTypeModel.find((el) => el.id === variant) || doorTypeModel[0]
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  let startPos = shelfThickness
  const setDoor = useStore((store) => store.doors.setDoor)
  const doorMinSize = Math.min(...doorVariant.sizes)
  const doorMaxSize = Math.max(...doorVariant.sizes)

  return (
    <>
      {columns.list.map((el, i) => {
        let list = [] as IDoorSelectors['doorList']
        if (doorMinSize * el.width > regalModel.minDoorWidth || doorMaxSize * el.width < regalModel.maxDoorWidth) {
          for (let x = 0; x <= grid - doorSize; x++) {
            const yPos = thickness + x * gridSize
            for (let y = 0; y < doorVariant.sizes.length; y++) {
              if (doorVariant.sizes[y] * el.width > regalModel.minDoorWidth && doorVariant.sizes[y] * el.width < regalModel.maxDoorWidth) {
                const xPos = y === 0 ? startPos : startPos + doorVariant.sizes[y - 1] * el.width
                const posX = y === 0 ? i : i + doorVariant.sizes[y - 1]
                list.push({
                  pos: {x: posX, y: x},
                  size: {x: doorVariant.sizes[y], y: doorSize},
                  xPos: xPos,
                  yPos: yPos,
                  height: doorHeight,
                  width: el.width * doorVariant.sizes[y],
                  side: doorVariant.side[y],
                })
              }
            }
          }
        }
        startPos += el.width + thickness
        return (
          <Fragment key={i}>
            {visible && <DoorSelectors key={i} doorList={list} material={selectorMaterial.current} zPos={depth - thickness + 0.01} thickness={thickness} callback={setDoor} />}
          </Fragment>
        )
      })}
    </>
  )
}
