import {useStore} from 'products/kubatur/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionListSelector, AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useEffect, useState} from 'react'
import {doorTypeModel, frontMaterials, shelfMaterials} from 'products/kubatur/store/datamodels'

export function Doors() {
  const doors = useStore((store) => store.view.doors)

  const [height, grid, doorMaterial] = useStore((store) => [store.config.main.height, store.config.main.grid, store.config.doors.materialID])
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const thickness = shelfMaterials[shelfMaterial].thickness

  const setVariant = useStore((store) => store.doors.setVariant)
  const setMaterialID = useStore((store) => store.doors.setMaterialID)
  const setEditSize = useStore((store) => store.doors.setSize)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(frontMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof frontMaterials)}
    })

  let doorSize = doors.size
  const doorHeights = []
  for (let i = 1; i <= grid; i++) {
    doorHeights.push({id: i, name: Math.round(1000 * (((height - thickness) / grid) * i + thickness)) + ' mm'})
  }

  doorSize = doorSize > doorHeights.length ? doorHeights.length : doorSize

  useEffect(() => {
    setEditSize(doorSize)
  })

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={doorMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector options={doorTypeModel} onClick={setVariant} title={'Typ'} value={doors.variant} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector options={doorHeights} onClick={setEditSize} title={'Türhöhe'} value={doorSize} expanded={expanded} handleChange={handleChange} last />
    </>
  )
}
