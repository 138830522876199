import {Board} from 'common/threeComponents/FiberBoard'
import * as THREE from 'three'

export const ConnectedShadowBox = (props: {length: number; width: number; xPos?: number; yPos?: number}) => {
  const {length, width, xPos, yPos} = props
  const material = new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, opacity: 0, depthWrite: false})

  return (
    <>
      <Board size={[length, 0.01, width]} xPos={xPos || 0} yPos={yPos || 0} zPos={0} material={material} />
    </>
  )
}
