import './../overview.scss'
import kubatur_1 from 'common/static/overview/kubatur-1.jpg'
import kubatur_2 from 'common/static/overview/kubatur-2.jpg'
import kubatur_3 from 'common/static/overview/kubatur-3.jpg'
import kubatur_4 from 'common/static/overview/kubatur-4.jpg'
import kubatur_6 from 'common/static/overview/kubatur-6.jpg'
import kubatur_7 from 'common/static/overview/kubatur-7.jpg'
import kubatur_8 from 'common/static/overview/kubatur-8.jpg'
import kubatur_9 from 'common/static/overview/kubatur-9.jpg'
import kubatur_11 from 'common/static/overview/kubatur-11.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedKubaturOverView() {
  return <ProductOverView title={'kubatur'} products={kubatur} />
}

export const kubatur = [
  {img: <img className="overview-image" src={kubatur_2} alt="Kubatur" />, to: 'get/kubatur-2'},
  {img: <img className="overview-image" src={kubatur_1} alt="Kubatur" />, to: 'get/kubatur-1'},
  {img: <img className="overview-image" src={kubatur_3} alt="Kubatur" />, to: 'get/kubatur-3'},
  {img: <img className="overview-image" src={kubatur_7} alt="Kubatur" />, to: 'get/kubatur-7'},
  {img: <img className="overview-image" src={kubatur_11} alt="Kubatur" />, to: 'get/kubatur-10'},
  {img: <img className="overview-image" src={kubatur_9} alt="Kubatur" />, to: 'get/kubatur-9'},
  {img: <img className="overview-image" src={kubatur_8} alt="Kubatur" />, to: 'get/kubatur-8'},
  {img: <img className="overview-image" src={kubatur_6} alt="Kubatur" />, to: 'get/kubatur-6'},
  {img: <img className="overview-image" src={kubatur_4} alt="Kubatur" />, to: 'get/kubatur-4'},
]
