import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider, AccordionSlider} from 'components/material_interfaces/materialInterfaces'
import {regalModel, shelfMaterials} from 'products/metrum/store/datamodels'
import {useStore} from 'products/metrum/store/store'
import {materialTreatments} from 'utils/datamodels'
import {getMaterialName, getMaxGrid, getMinGrid} from 'utils/helpers'

export function ShelfStructure() {
  const [shelfMaterial, treatmentID, grid, height, depth] = useStore((store) => [
    store.config.main.materialID,
    store.config.main.treatmentID,
    store.config.main.grid,
    store.config.main.height,
    store.config.main.depth,
  ])
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const maxColGrid = Math.round(((height - thickness) / (grid * (Math.round((height - thickness) / (grid * 0.065)) + 1))) * 1000)
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setHeight = useStore((store) => store.setHeight)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)
  const materialHasTreatment = shelfMaterials[shelfMaterial].treatment
  const footHeight = useStore((store) => store.config.feet.height)
  const extraHeight = height < 1.57 ? footHeight + regalModel.shadowJoint + thickness : footHeight
  const fullHeight = Math.round((height + extraHeight) * 1000) / 1000

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(shelfMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof shelfMaterials) + ', ' + Math.round(material.thickness * 1000) + 'mm'}
  })

  const treatmentOptions = [...Object.values(materialTreatments)]

  const minGrid = getMinGrid(height, regalModel.gridMax)
  const maxGrid = getMaxGrid(height, regalModel.gridMin)

  const gridOptions = []
  for (let i = minGrid; i <= maxGrid; i++) {
    const gridSize = (height - thickness) / i
    gridOptions.push({id: i, name: i + `x${Math.round(gridSize * 1000)}`, longName: i + ` x ${Math.round(gridSize * 1000)} mm`})
  }
  return (
    <>
      <AccordionMaterialSelector id="material" options={materialOptions} onClick={setShelfMaterialSpecies} title={'Material'} value={shelfMaterial} expanded={expanded} handleChange={handleChange} />
      {materialHasTreatment && (
        <AccordionListSelector id="treatment" options={treatmentOptions} onClick={setMaterialTreatment} title={'Oberfläche'} value={treatmentID} expanded={expanded} handleChange={handleChange} />
      )}
      <AccordionSizeSlider
        min={regalModel.minHeight * 1000}
        max={shelfMaterials[shelfMaterial].maxWidth * 1000}
        onClick={setHeight}
        title={'Regalhöhe'}
        id={'height'}
        value={fullHeight * 1000}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        min={regalModel.minDepth * 1000}
        max={regalModel.maxDepth * 1000}
        onClick={setDepth}
        title={'Regaltiefe'}
        id={'depth'}
        value={depth * 1000}
        clickRange={20}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSlider
        id={'grid'}
        options={gridOptions}
        min={minGrid - 0.3}
        max={maxGrid + 0.3}
        onClick={setGrid}
        title={'Grobraster (Feinraster: ' + maxColGrid + 'mm)'}
        value={grid}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
