import {baseUrl, bedMaterials} from '../store/datamodels'
import {BezweiPrice} from 'products/bezwei/utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors} from 'utils/datamodels'
import {BezweiBoardList} from '../partLists/boardList'
import {BezweiSlattedList} from '../partLists/slattedList'
import {BezweiAccBoardList} from '../partLists/accBoardList'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'

export const BezweiPriceList = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(bedMaterials)
  const materials = ['m24Ah', 'm24Ki', 'm25SB', 's25Bu', 's25Ei', 's25Ki', 's25NmS', 's25NwS', 's25NoS', 'l25Ash']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof bedMaterials))

  const widths = [0.8, 1, 1.2, 1.4, 1.6, 1.8, 2]

  let priceListBezwei: number[][][] = []
  const numberOfStorageplaces = [0, 1, 2]

  numberOfStorageplaces.forEach((nrstorageplaces, j) => {
    priceListBezwei.push([])
    materials.forEach((material, i) => {
      priceListBezwei[j].push([])
      widths.forEach((width) => {
        const boardList = BezweiBoardList(2, width, material as keyof typeof bedMaterials, 0.18, 0.15, nrstorageplaces)
        const accBoardList = BezweiAccBoardList(0.18, 2, width, 0.02, 'own', nrstorageplaces)
        const slattedList = BezweiSlattedList(0.18, 2, width, 'own', 0.05, 0.02, nrstorageplaces)
        priceListBezwei[j][i].push(BezweiPrice(localFactor.factor, material as keyof typeof bedMaterials, treatmentID, [...boardList, ...accBoardList], slattedList).props.children)
      })
    })
  })

  let priceListBezweiSurfaces: number[][][] = []

  numberOfStorageplaces.forEach((nrstorageplaces, j) => {
    priceListBezweiSurfaces.push([])
    ;[2, 3].forEach((surface, i) => {
      priceListBezweiSurfaces[j].push([])
      widths.forEach((width) => {
        const boardList = BezweiBoardList(2, width, 's25Ei' as keyof typeof bedMaterials, 0.18, 0.15, nrstorageplaces)
        const accBoardList = BezweiAccBoardList(0.18, 2, width, 0.02, 'own', nrstorageplaces)
        const slattedList = BezweiSlattedList(0.18, 2, width, 'own', 0.05, 0.02, nrstorageplaces)
        const iPrice = BezweiPrice(localFactor.factor, 'm24Ei', surface, [...boardList, ...accBoardList], slattedList).props.children
        const oilPrice = BezweiPrice(localFactor.factor, 'm24Ei', 0, [...boardList, ...accBoardList], slattedList).props.children
        const extraPrice = iPrice - oilPrice
        priceListBezweiSurfaces[j][i].push(extraPrice)
      })
    })
  })

  const surfaceNames = ['Aufpreis geseift', 'Aufpreis lackiert']

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>
      {numberOfStorageplaces.map((nrstorageplaces, i) => (
        <>
          <h1 key={i}>BEZWEI mit {nrstorageplaces} Ablagefach</h1>
          <PriceTable rows={materialNames} columns={widths} prices={priceListBezwei[i]} />
          <PriceTable rows={surfaceNames} columns={widths} prices={priceListBezweiSurfaces[i]} />
        </>
      ))}
    </>
  )
}
