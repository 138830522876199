import {useStore} from 'products/tektur/store/store'
import {regalModel, shelfMaterials, drawerFrontMaterials} from 'products/tektur/store/datamodels'
import {materialModel, drawerLengths} from 'utils/datamodels'
import {IDrawer} from 'common/threeComponents/DrawerFCNew'
import {getDrawerParts} from 'utils/helpers'
import {useShallow} from 'zustand/react/shallow'
import {ITekturConfig} from '../store/initialConfig'

export const ConnectedTekturDrawerList = () => {
  const [drawerList, backpanels, depth, shelfWidth, boards, grid, shelfMaterialID, drawerMaterial, shelfType] = useStore(
    useShallow((store) => [
      store.drawers.clearDrawers(),
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.width,
      store.config.rows.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.drawers.materialID,
      store.config.main.shelfTyp,
    ])
  )
  return TekturDrawerList(drawerList, backpanels, depth, shelfWidth, boards, grid, shelfMaterialID, drawerMaterial, shelfType)
}

export const TekturDrawerList = (
  drawerList: ITekturConfig['drawers']['list'],
  backpanels: ITekturConfig['backpanels'],
  depth: ITekturConfig['main']['depth'],
  shelfWidth: ITekturConfig['main']['width'],
  boards: ITekturConfig['rows']['list'],
  grid: ITekturConfig['main']['grid'],
  shelfMaterialID: ITekturConfig['main']['materialID'],
  drawerMaterial: ITekturConfig['drawers']['materialID'],
  shelfType: ITekturConfig['main']['shelfTyp']
) => {
  const bodyMaterialID = shelfMaterials[shelfMaterialID].drawerInnerMaterial as keyof typeof materialModel
  const thickness = shelfMaterials[shelfMaterialID].thickness
  const shadowJoint = regalModel.shadowJoint
  const gridSize = shelfType === 2 ? (shelfWidth - thickness) / grid : (shelfWidth - 2 * thickness - shadowJoint) / grid
  const shelfInnerWidth = (shelfWidth - thickness - 2 * regalModel.sideInset) / grid - thickness
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const leftInset = 0.006
  const drawers: IDrawer[] = []

  drawerList.forEach((drawer, j) => {
    const yPosIndex = Math.floor(drawer.pos.y)
    const frontThickness = drawerFrontMaterials[drawerMaterial].thickness
    const fullBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.y === yPosIndex
      })?.cover === 'full'
    const isBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.x === drawer.pos.x && obj.pos.y === yPosIndex
      }) || fullBackpanel
    const backpanelInset = isBackpanel ? (backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    const drawerDepth = Math.max(...drawerLengths.filter((l) => l < depth - 0.001 - frontThickness - 0.013 - 0.008 - backpanelInset)) + frontThickness
    if (drawerDepth === -Infinity) return null
    const height = drawer.size.y * (boards[yPosIndex].height - shadowJoint) - shadowJoint
    const oneDrawerInMiddle =
      grid % 2 === 0 &&
      (drawerList.findIndex((obj) => obj.pos.x + 1 === grid / 2 && Math.floor(obj.pos.y) === yPosIndex) !== -1 ||
        drawerList.findIndex((obj) => obj.pos.x === grid / 2 && Math.floor(obj.pos.y) === yPosIndex) !== -1) &&
      (drawer.pos.x + 1 === grid / 2 || drawer.pos.x === grid / 2)
    const widthShelfType1 =
      drawer.pos.x + 1 < grid / 2 || drawer.pos.x > grid / 2 || oneDrawerInMiddle
        ? shelfInnerWidth + thickness - shadowJoint
        : grid % 2 === 0
          ? (shelfInnerWidth * 2 + thickness - 3 * shadowJoint) / 2
          : shelfInnerWidth - 2 * shadowJoint
    console.log(shelfInnerWidth)
    const physicalWidth = shelfType === 2 ? shelfInnerWidth - 2 * shadowJoint : widthShelfType1
    const width = shelfType === 2 ? gridSize - 2 * shadowJoint - thickness : gridSize - shadowJoint
    const bodyHeight = Math.floor((height - 0.015 - 0.0175) * 200) / 200
    const topInset = height - bodyHeight - 0.015 + (shadowJoint - 0.003) / 2
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + thickness, thickness + shadowJoint) + (drawer.pos.y - yPosIndex) * (boards[yPosIndex].height - shadowJoint)
    const xPos = thickness + drawer.pos.x * gridSize + shadowJoint
    const bottomMaterialID = 'mdf8W' as keyof typeof materialModel
    const drawerParts = getDrawerParts({
      width: physicalWidth,
      height: height,
      depth: drawerDepth,
      shelfInnerWidth: shelfInnerWidth,
      bodyHeight: bodyHeight,
      frontMaterialID: drawerMaterial,
      bodyMaterialID: bodyMaterialID,
      bottomMaterialID: bottomMaterialID,
    })
    drawers.push({
      key: drawer.pos.x + '-' + drawer.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: depth - 0.001,
      width: width,
      height: height,
      depth: drawerDepth,
      leftInset: leftInset,
      topInset: topInset,
      parts: drawerParts,
      type: 'drawer',
    })
  })

  return drawers
}
