import React, {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import {useStore} from '../store/store'
import {ConnectedBoards} from './boards/Boards'
import {ConnectedColumns} from './columns/Columns'
import {ConnectedBackpanels} from './backpanels/Backpanels'
import {ConnectedTabelars} from './tabelars/Tabelars'
import {ConnectedTabelarSelectors} from './tabelars/TabelarSelectors'
import {ConnectedDrawers} from './drawers/Drawers'
import {ConnectedDrawerSelectors} from './drawers/DrawerSelectors'
import {ConnectedBoardSelectors} from './boards/BoardSelectors'
import {ConnectedColumnSelectors} from './columns/ColumnSelectors'
import {ConnectedBackpanelSelectors} from './backpanels/BackpanelSelectors'
import {ConnectedDoors} from './doors/Doors'
import {ConnectedDoorSelectors} from './doors/DoorSelectors'
import {ConnectedFeet} from './feet/Feet'
import {ConnectedDimensionControls} from './dimensioncontrols/DimensionControls'

export const Analog = (props: any) => {
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())

  useLayoutEffect(() => {
    fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
  })

  return (
    <group name="RegalFiberGroup" ref={fullGroupRef}>
      <ConnectedBoards />
      <ConnectedBoardSelectors />
      <ConnectedColumns />
      <ConnectedColumnSelectors />
      <ConnectedBackpanels />
      <ConnectedBackpanelSelectors />
      <ConnectedTabelars />
      <ConnectedTabelarSelectors />
      <ConnectedDrawers />
      <ConnectedDrawerSelectors />
      {props.doorsVisible && <ConnectedDoors />}
      <ConnectedDoorSelectors />
      <ConnectedFeet />
      <ConnectedDimensionControls />
    </group>
  )
}

export const ConnectedAnalog = (props: any) => {
  const connectedProps = {
    depth: useStore((store) => store.config.main.depth),
    footHeight: useStore((store) => store.config.feet.height),
    width: useStore((store) => store.config.main.width),
    doorsVisible: useStore((store) => store.view.doors.visible),
    ...props,
  }

  return <Analog {...connectedProps} />
}
