import {Outlet} from 'react-router-dom'
import {useStore} from '../../../store/store'
import {AccordionElement, ParentButton, MenuBox} from 'components/material_interfaces/materialInterfaces'
import {ShelfStructure} from '../../Shelf/ShelfStructure'
import {Doors} from '../../Doors/Doors'
import {Drawers} from '../../Drawers/Drawers'
import {Backpanels} from '../../Backpanels/Backpanels'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect} from 'react'
import {Contact} from 'components/contact/Contact'

export const IndexView = () => {
  const [expanded, setExpanded] = useCommonStore((state) => [state.expandedFirst, state.setExpandedFirst])
  const extended = useCommonStore((state) => state.extended)

  const setDoorVisibility = useStore((store) => store.doors.setVisible)
  const setDrawerVisibility = useStore((store) => store.drawers.setVisible)
  const setEditDoors = useStore((store) => store.doors.setEditDoors)
  const setEditDrawers = useStore((store) => store.drawers.setEditDrawers)
  const setEditBoards = useStore((store) => store.setEditBoards)
  const setEditColumns = useStore((store) => store.columns.setEdit)
  const setEditStructure = (edit: boolean) => {
    setEditBoards(edit)
    setEditColumns(edit)
  }
  const setEditTabelars = useStore((store) => store.tabelar.setEditTabelars)
  const setEditClothingRails = useStore((store) => store.clothingRails.setEditClothingRails)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    switch (expanded) {
      case 'structure':
        setDoorVisibility(!expanded)
        setDrawerVisibility(true)
        setEditDoors(false)
        setEditDrawers(false)
        setEditTabelars(false)
        setEditClothingRails(false)
        setEditStructure(expanded === 'structure')
        break
      case 'tabelars':
        setDoorVisibility(!expanded)
        setDrawerVisibility(true)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        setEditClothingRails(false)
        setEditTabelars(expanded === 'tabelars')
        break
      case 'doors':
        setDoorVisibility(true)
        setDrawerVisibility(true)
        setEditDoors(expanded === 'doors')
        setEditDrawers(false)
        setEditStructure(false)
        setEditClothingRails(false)
        setEditTabelars(false)
        break
      case 'drawers':
        setDoorVisibility(true)
        setDrawerVisibility(true)
        setEditDoors(false)
        setEditDrawers(expanded === 'drawers')
        setEditStructure(false)
        setEditClothingRails(false)
        setEditTabelars(false)
        break
      case 'clothingRails':
        setDoorVisibility(!expanded)
        setDrawerVisibility(true)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        setEditClothingRails(expanded === 'clothingRails')
        setEditTabelars(false)
        break
      case 'back':
        setDoorVisibility(!expanded)
        setDrawerVisibility(!expanded)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        setEditClothingRails(false)
        setEditTabelars(false)
        break
      case 'feet':
      default:
        setDoorVisibility(true)
        setDrawerVisibility(true)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        setEditClothingRails(false)
        setEditTabelars(false)
        break
    }
    return () => {
      setDoorVisibility(true)
      setDrawerVisibility(true)
      setEditDoors(false)
      setEditDrawers(false)
      setEditStructure(false)
      setEditClothingRails(false)
      setEditTabelars(false)
    }
  }, [expanded])

  return (
    <>
      <MenuBox>
        <AccordionElement id="structure" title="Schrankkorpus" expanded={expanded === 'structure'} handleChange={handleChange}>
          <ShelfStructure />
        </AccordionElement>
        <AccordionElement id="tabelars" title="Fachböden" expanded={expanded === 'tabelars'} handleChange={handleChange} />
        <AccordionElement id="doors" title="Türen" expanded={expanded === 'doors'} handleChange={handleChange}>
          <Doors />
        </AccordionElement>
        <AccordionElement id="drawers" title="Schubladen" expanded={expanded === 'drawers'} handleChange={handleChange}>
          <Drawers />
        </AccordionElement>
        <AccordionElement id="clothingRails" title="Kleiderstangen" expanded={expanded === 'clothingRails'} handleChange={handleChange} />
        <AccordionElement id="back" title="Rückwände" expanded={expanded === 'back'} handleChange={handleChange}>
          <Backpanels />
        </AccordionElement>
        <ParentButton to="print">Drucken</ParentButton>
        {extended && <ParentButton to="pricelist">Preisliste</ParentButton>}
        <ParentButton to="saveload">Speichern & Laden</ParentButton>
        <AccordionElement id="contact" title="Sonderanfertigung / Frage" expanded={expanded === 'contact'} handleChange={handleChange}>
          <Contact />
        </AccordionElement>
      </MenuBox>
      <Outlet />
    </>
  )
}
