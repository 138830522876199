import {DasregalPrice} from '../utils/price'
import {PriceTable} from 'components/priceList/priceTable'
import {useCommonStore} from 'common/Store/CommonStore'
import {localFactors, API_DOMAIN} from 'utils/datamodels'
import {Link} from 'react-router-dom'
import {getMaterialName} from 'utils/helpers'
import {backpanelMaterials, baseUrl, frontMaterials, shelfMaterials} from '../store/datamodels'
import {useState, useLayoutEffect, Fragment} from 'react'
import {DasregalBoardList} from '../partLists/boardList'
import {IRegalConfig} from '../store/initialConfig'
import {DasregalDrawerList} from '../partLists/drawerList'
import {DasregalFootList} from '../partLists/footList'
import {useMemo} from 'react'
import {DasregalColumnList} from '../partLists/columnList'
import {DasregalBackpanelList} from '../partLists/backpanelList'
import {DasregalTabelarList} from '../partLists/tabelarList'
import {DasregalSubColumnList} from '../partLists/subColumnList'
import {DasregalFlapList} from '../partLists/flapList'
import {DasregalDoorList} from '../partLists/doorList'

export const DasregalPriceList = () => {
  let [unused, setConfig] = useState({} as IRegalConfig)
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = 0
  //const materials = Object.keys(shelfMaterials)
  const materials = ['m18Ei', 'm18aN', 'm18MW', 'm18CB', 'm18SB', 's19Bu', 's19Ei', 's19Ki', 's19NmS', 's19NwS', 's19NoS']
  const materialNames = materials.map((id) => getMaterialName(id as keyof typeof shelfMaterials))

  const ids = useMemo(
    () => ['dasregal-1', 'dasregal-2', 'dasregal-3', 'dasregal-5', 'dasregal-4', 'dasregal-13', 'dasregal-7', 'dasregal-8', 'dasregal-9', 'dasregal-12', 'dasregal-11', 'dasregal-10'],
    []
  )
  let configList: {id: string; config: IRegalConfig}[] = useMemo(() => [], [])
  let priceListDasregal: number[][][] = useMemo(() => [], [])

  useLayoutEffect(() => {
    const fetchConfig = async (uri: string) => {
      const product = await fetch(`${API_DOMAIN}/product/${uri}`)
        .then((r) => r.json())
        .then((data) => {
          const config1 = JSON.parse(data.configuration)
          setConfig(config1)
          configList.push({id: uri, config: config1})
          return {config1}
        })
        .catch((err) => console.log(err))
      return product
    }
    ids.forEach((id) => fetchConfig(id))
  }, [])

  useLayoutEffect(() => {
    if (configList.length === ids.length) {
      ids.forEach((id, j) => {
        const foundConfig = configList.find((el) => el.id === id)
        priceListDasregal.push([])
        if (foundConfig) {
          const config = foundConfig.config
          // is the MainMaterial included in the shelfReference of the Accmaterial
          // if not, use the Accmaterial from the config
          // if yes, find the Accmaterial that includes the MainMaterial in its shelfReference
          const isRefBackpanelMaterial = backpanelMaterials[config.backpanels.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefDoorMaterial = frontMaterials[config.doors.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefDrawerMaterial = frontMaterials[config.drawers.materialID].shelfReference.includes(config.main.materialID as never)
          const isRefFlapMaterial = frontMaterials[config.flaps.materialID].shelfReference.includes(config.main.materialID as never)
          materials.forEach((material, i) => {
            const backpanelMaterial = isRefBackpanelMaterial
              ? Object.values(backpanelMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'mdf8W'
              : config.backpanels.materialID
            const doorMaterial = isRefDoorMaterial ? Object.values(frontMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'm18MW' : config.doors.materialID
            const drawerMaterial = isRefDrawerMaterial ? Object.values(frontMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'm18MW' : config.drawers.materialID
            const flapMaterial = isRefFlapMaterial ? Object.values(frontMaterials).find((el) => el.shelfReference.includes(material as never))?.id || 'm18MW' : config.flaps.materialID
            const configModified = {
              ...config,
              main: {...config.main, materialID: material as keyof typeof shelfMaterials},
              backpanels: {...config.backpanels, materialID: backpanelMaterial as keyof typeof backpanelMaterials},
              doors: {...config.doors, materialID: doorMaterial as keyof typeof frontMaterials},
              drawers: {...config.drawers, materialID: drawerMaterial as keyof typeof frontMaterials},
              flaps: {...config.flaps, materialID: flapMaterial as keyof typeof frontMaterials},
            }
            priceListDasregal[j].push([])
            const partList = getDasregalPartList({...configModified, main: {...configModified.main, materialID: material as keyof typeof shelfMaterials}})
            const drawerList = DasregalDrawerList(
              configModified.drawers.list,
              configModified.flaps.list,
              configModified.doors.list,
              configModified.backpanels,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.boards.list,
              configModified.subColumns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.drawers.materialID,
              configModified.feet.id,
              0
            )
            const doorList = DasregalDoorList(
              configModified.doors.list,
              configModified.flaps.list,
              configModified.drawers.list,
              configModified.main.depth,
              configModified.main.height,
              configModified.columns.list,
              configModified.main.grid,
              material as keyof typeof shelfMaterials,
              configModified.doors.materialID
            )
            const footList = DasregalFootList(configModified.main.depth, configModified.columns.list, configModified.feet.id, configModified.feet.height, material as keyof typeof shelfMaterials)
            priceListDasregal[j][i].push(DasregalPrice(localFactor.factor, treatmentID, configModified.feet.id === 'wallMounted', partList, footList, doorList, drawerList).props.children)
          })
        }
      })
    }
  }, [localFactor.factor, materials, priceListDasregal, configList, ids])

  return (
    <>
      <div className="print_controls">
        <Link to={baseUrl} className="p_button">
          Zurück
        </Link>
      </div>

      <>
        <h1>DASREGAL</h1>
        {priceListDasregal.length === ids.length &&
          ids.map((id, i) => (
            <Fragment key={i}>
              <h2>{id}</h2>
              <PriceTable rows={materialNames} columns={['']} prices={priceListDasregal[i]} />
            </Fragment>
          ))}
      </>
    </>
  )
}

const getDasregalPartList = (configs: IRegalConfig) => {
  const boardList = DasregalBoardList(
    configs.columns.list,
    configs.main.height,
    configs.main.depth,
    configs.main.materialID,
    configs.main.grid,
    configs.boards.list,
    configs.backpanels,
    configs.feet.id,
    configs.doors.list,
    configs.flaps.list,
    configs.drawers.list
  )
  const columnList = DasregalColumnList(configs.columns.list, configs.main.height, configs.main.depth, configs.main.materialID, configs.feet.id, configs.flaps.list)
  const subColumnList = DasregalSubColumnList(
    configs.subColumns.list,
    configs.main.height,
    configs.main.depth,
    configs.columns.list,
    configs.boards.list,
    configs.backpanels.list,
    configs.doors.list,
    configs.drawers.list,
    configs.flaps.list,
    configs.main.grid,
    configs.main.materialID,
    configs.feet.id,
    configs.backpanels.materialID
  )
  const tabelarList = DasregalTabelarList(
    configs.tabelars.list,
    configs.main.depth,
    configs.main.height,
    configs.columns.list,
    configs.boards.list,
    configs.subColumns.list,
    configs.main.grid,
    configs.backpanels.list,
    configs.feet.id,
    configs.doors.list,
    configs.drawers.list,
    configs.flaps.list,
    configs.main.materialID,
    configs.backpanels.materialID
  )
  const backpanelList = DasregalBackpanelList(
    configs.backpanels.list,
    configs.columns.list,
    configs.boards.list,
    configs.subColumns.list,
    configs.main.height,
    configs.main.materialID,
    configs.backpanels.materialID,
    configs.main.grid,
    configs.feet.id
  )
  const flapPartList = DasregalFlapList(
    configs.flaps.list,
    configs.doors.list,
    configs.drawers.list,
    configs.backpanels,
    configs.main.depth,
    configs.main.height,
    configs.columns.list,
    configs.boards.list,
    configs.main.grid,
    configs.main.materialID,
    configs.flaps.materialID,
    configs.feet.id
  )
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]

  const partList = [...boardList, ...columnList, ...backpanelList, ...tabelarList, ...subColumnList, ...flapPartList]
  return partList
}
