import {forwardRef} from 'react'
import * as THREE from 'three'
import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from './Materials'

export interface ISlatted {
  size: IVector
  xPos: number
  yPos: number
  zPos: number
}

export const Slatted = forwardRef((props: ISlatted, ref: any) => {
  const {size, xPos, yPos, zPos} = props
  const material1 = new THREE.MeshStandardMaterial({color: 0x666666, metalness: 0, roughness: 20})
  const material2 = new THREE.MeshStandardMaterial({color: 0x333333, metalness: 0, roughness: 20})
  const materialObject = MaterialSingleton.getMaterials()
  const materialMPXBirch = [materialObject['1']?.big, materialObject['1']?.big]
  const materialSolidBirch = [materialObject['12']?.big, materialObject['12']?.big]

  const slatLength = size[2] - 0.08
  const nrOfSlats = Math.floor((Math.floor((size[0] - 0.2) / 0.06) - 1) / 2) * 2
  const slatGrid = (size[0] - 0.1 - 0.05 - 0.035) / nrOfSlats

  var shape = new THREE.Shape()
  shape.moveTo(-slatLength / 2, 0)
  shape.quadraticCurveTo(0, 0.05, slatLength / 2, 0)
  shape.lineTo(slatLength / 2, 0.01)
  shape.quadraticCurveTo(0, 0.06, -slatLength / 2, 0.01)
  shape.lineTo(-slatLength / 2, 0)

  var extrudeSettings = {
    steps: 1,
    depth: 0.035,
    bevelEnabled: false,
  }

  return (
    <group name="slatted" ref={ref} position={[xPos, yPos, zPos]}>
      <Board size={[size[1], 0.03, size[0] - 0.03]} xPos={0.015} yPos={0} zPos={0.01} material={materialMPXBirch[1]} rotation={[0, 0.5, 0.5]} />
      <Board size={[size[1], 0.03, size[0] - 0.03]} xPos={0.015} yPos={0} zPos={size[2] - 0.01 - 0.03} material={materialMPXBirch[1]} rotation={[0, 0.5, 0.5]} />
      <Board size={[0.05, 0.03, size[2] - 0.08]} xPos={0.025} yPos={size[1] / 2 - 0.015} zPos={0.01 + 0.03} material={materialSolidBirch[1]} rotation={[0, 0, 0]} />
      <Board size={[0.05, 0.03, size[2] - 0.08]} xPos={size[0] - 0.05 - 0.025} yPos={size[1] / 2 - 0.015} zPos={0.01 + 0.03} material={materialSolidBirch[1]} rotation={[0, 0, 0]} />
      {[...Array(nrOfSlats)].map((_, i) => {
        return (
          <mesh
            ref={ref}
            key={i}
            position={[size[0] / 2 - ((nrOfSlats - 1) / 2) * slatGrid - 0.035 / 2 + i * slatGrid, size[1] - 0.02, size[2] / 2]}
            material={materialSolidBirch[1]}
            rotation={[0, Math.PI / 2, 0]}>
            <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} />
          </mesh>
        )
      })}
      <Board size={[0.015, 0.03, nrOfSlats * slatGrid]} xPos={size[0] / 2} yPos={size[1] + 0.01} zPos={size[2] / 2} material={material2} rotation={[0, 0.5, 0.5]} translate={[0, 0, 0]} />
      {[...Array(nrOfSlats / 2)].map((_, i) => {
        return (
          <group key={i}>
            <Board
              size={[0.02, 0.03, slatGrid + 0.04]}
              xPos={size[0] / 2 - ((nrOfSlats - 1) / 2) * slatGrid - 0.035 / 2 - 0.0025 + i * slatGrid * 2}
              yPos={size[1] - 0.02}
              zPos={0.01 + 0.03}
              material={material1}
              rotation={[0, 0.5, 0.5]}
            />
            <Board
              size={[0.02, 0.03, slatGrid + 0.04]}
              xPos={size[0] / 2 - ((nrOfSlats - 1) / 2) * slatGrid - 0.035 / 2 - 0.0025 + i * slatGrid * 2}
              yPos={size[1] - 0.02}
              zPos={size[2] - 0.01 - 0.03 - 0.03}
              material={material1}
              rotation={[0, 0.5, 0.5]}
            />
          </group>
        )
      })}
    </group>
  )
})
