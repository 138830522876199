import './../overview.scss'
import unisono_1 from 'common/static/overview/unisono-1.jpg'
import unisono_2 from 'common/static/overview/unisono-2.jpg'
import {ProductOverView} from '../ProductOverView'

export function ConnectedUnisonoOverView() {
  return <ProductOverView title={'unisono'} products={unisono} withTitle />
}

export const unisono = [
  {img: <img className="overview-image" src={unisono_1} alt="Unisono" />, to: 'get/unisono-1'},
  {img: <img className="overview-image" src={unisono_2} alt="Unisono H" />, to: 'get/unisono-2'},
]
