import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials, frontMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {FlapFC} from 'common/threeComponents/FlapFC'
import {ConnectedDasregalFlapList} from 'products/dasregal/partLists/flapList'

export const ConnectedFlaps = () => {
  const flapList = ConnectedDasregalFlapList()
  const visible = useStore((store) => store.view.flaps.visible)
  if (flapList.length > 0) {
    const frontmaterialID = flapList[0].parts.flapFront.material
    const frontMaterialReference = frontMaterials[frontmaterialID].matReference
    var materialObject = MaterialSingleton.getMaterials()
    var frontMat = materialObject[frontMaterialReference].small_1 || materialObject[frontMaterialReference]
  }
  return (<>
    {visible &&
      flapList.map((flap, j) => {
        if (flap.parts.flapShelf) {
          const shelfMaterialID = flap.parts.flapShelf.material
          const shelfMaterialReference = shelfMaterials[shelfMaterialID].matReference
          var shelfMat = materialObject[shelfMaterialReference].small_1 || materialObject[shelfMaterialReference]
        }
        return (
          // <FlapFC {...flap} scene={scene} material={frontMat} thickness={flap.parts.flapFront.thickness} flapShelfThickness={flap.parts.flapShelf?.thickness} flapShelfMaterial={shelfMat} />
          (<FlapFC {...flap} material={frontMat} thickness={flap.parts.flapFront.thickness} flapShelfThickness={flap.parts.flapShelf?.thickness} flapShelfMaterial={shelfMat} />)
        );
      })}
  </>);
}
