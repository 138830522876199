import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {ConnectedTekturBoardList} from '../../partLists/boardList'
import {shelfMaterials} from 'products/tektur/store/datamodels'

export const ConnectedBoards = () => {
  const boardList = ConnectedTekturBoardList()
  const materialID = boardList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]
  return (
    <>
      {boardList.map((board) => {
        const size = [board.length, board.thickness, board.depth] as IVector
        return <Board {...board} size={size} material={material} />
      })}
    </>
  )
}
