import {useStore} from 'products/kubatur/store/store'
import {getMaterialName} from 'utils/helpers'
import {AccordionListSelector, AccordionMaterialSelector} from 'components/material_interfaces/materialInterfaces'
import {useState} from 'react'
import {slidingDoorMaterials, slidingDoorTypeModel} from 'products/kubatur/store/datamodels'

export function SlidingDoors() {
  const nrOfSlidingDoors = useStore((store) => store.config.slidingDoors.nr)
  const inFront = useStore((store) => store.config.slidingDoors.inFront)

  const [shelfMaterial, slidingDoorMaterial] = useStore((store) => [store.config.main.materialID, store.config.slidingDoors.materialID, store.config.slidingDoors])

  const setNr = useStore((store) => store.slidingDoors.setNrOfSlidingDoors)
  const setInFront = useStore((store) => store.slidingDoors.setInFront)
  const setVariant = (nr: number, inFront: 'left' | 'right' | 'middle') => {
    setNr(nr)
    setInFront(inFront)
  }
  const setMaterialID = useStore((store) => store.slidingDoors.setMaterialID)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const materialOptions = [...Object.values(slidingDoorMaterials)]
    .filter((material) => material.shelfReference.includes(shelfMaterial as never) || material.shelfReference.length === 0)
    .map((material) => {
      return {...material, name: getMaterialName(material.id as keyof typeof slidingDoorMaterials)}
    })

  const selected = slidingDoorTypeModel.find((model) => model.inFront === inFront && model.nr === nrOfSlidingDoors)?.id || 0

  return (
    <>
      <AccordionMaterialSelector options={materialOptions} onClick={setMaterialID} title={'Material'} value={slidingDoorMaterial} expanded={expanded} handleChange={handleChange} />
      <AccordionListSelector
        options={slidingDoorTypeModel}
        onClick={(id: number) => setVariant(slidingDoorTypeModel[id].nr, slidingDoorTypeModel[id].inFront)}
        title={'Größe'}
        value={selected}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
    </>
  )
}
