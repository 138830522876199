import * as THREE from 'three'
import {Board, Selector} from 'common/threeComponents/FiberBoard'
import {useCallback, useRef} from 'react'
import {useFrame} from '@react-three/fiber'

export interface ISlidingDoor {
  length: number
  depth: number
  thickness: number
  doorMaterial: THREE.Material | THREE.Material[]
  innerMaterial: THREE.Material | THREE.Material[]
  xPos: number
  yPos: number
  zPos: number
  move: number
}

export const SlidingDoorFC = (props: ISlidingDoor) => {
  const {length, depth, thickness, doorMaterial, innerMaterial, xPos, yPos, zPos} = props
  const isActive = useRef(false)
  const doorRef = useRef<THREE.Group>(new THREE.Group())
  const coreMaterial = new THREE.MeshBasicMaterial({color: 0x888888})
  const deltaXY = 0.001
  const deltaZ = 0.0005

  const onClick = useCallback(() => {
    isActive.current = !isActive.current
  }, [])

  function lerp(x: number, y: number, a: number) {
    const r = (1 - a) * x + a * y
    return Math.abs(x - y) < 0.001 ? y : r
  }

  useFrame((state, delta) => {
    doorRef.current.position.x = lerp(doorRef.current.position.x, isActive.current ? props.move : 0, (1 / Math.abs(props.move)) * 0.05)
  })

  return (
    <>
      <group name="slidingDoor" ref={doorRef}>
        <Board key={'front'} {...props} size={[length, thickness, depth]} material={doorMaterial} rotation={[0.5, 0.5, 0]} translate={[0.5, -0.5, 0.5]} />
        <Board
          key={'front-core'}
          {...props}
          size={[length + deltaXY * 2, thickness - deltaZ * 2, depth + deltaXY * 2]}
          xPos={xPos - deltaXY}
          yPos={yPos - deltaXY}
          zPos={zPos - deltaZ}
          material={coreMaterial}
          rotation={[0.5, 0.5, 0]}
          translate={[0.5, -0.5, 0.5]}
        />
        <Board key={'base'} {...props} size={[length, 0.019, depth - 0.06]} xPos={xPos + 0.03} zPos={zPos - thickness} material={innerMaterial} rotation={[0.5, 0.5, 0]} translate={[0.5, -0.5, 0.5]} />
        <Selector
          {...props}
          size={[length, thickness, depth]}
          material={new THREE.MeshBasicMaterial({alphaTest: 2})}
          rotation={[0, 0.5, 0.5]}
          translate={[0.5, -0.5, 0.5]}
          onClick={onClick}
          cursor={'ew-resize'}
          // onPointerOut={onMouseOut}
        />
      </group>
    </>
  )
}
