import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {Board} from 'common/threeComponents/FiberBoard'
import {ConnectedDinadreiAccBoardList} from 'products/dinadrei/partLists/accBoardList'
import {materialModel} from 'utils/datamodels'

export const ConnectedAccBoards = () => {
  const accBoardList = ConnectedDinadreiAccBoardList()
  if (accBoardList.length === 0) return null
  const materialID = accBoardList[0].material
  const materialReference = materialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]

  return (
    <>
      {accBoardList.map((board) => {
        return <Board {...board} size={[board.length, board.thickness, board.depth]} material={material} />
      })}
    </>
  )
}
