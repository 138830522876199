import {useLayoutEffect, useRef} from 'react'
import * as THREE from 'three'
import {useStore} from '../store/store'
import {ConnectedBoards} from './boards/Boards'
import {ConnectedBrackets} from './brackets/Brackets'
import {bedModel} from '../store/datamodels'
import {ConnectedSlatteds} from './slatted/Slatted'
import {ConnectedMattress} from './mattress/Mattress'
import {ConnectedAccBoards} from './boards/AccBoards'
import {ConnectedShadowBox} from 'common/threeComponents/ShadowBox'

export const Dinadrei = (props: any) => {
  const [mattressVisibility, slattedVisibility] = useStore((store) => [store.view.mattress.visible, store.view.slatted.visible])
  const {mattress = mattressVisibility, slatted = slattedVisibility} = props
  const fullGroupRef = useRef<THREE.Group>(new THREE.Group())

  useLayoutEffect(() => {
    fullGroupRef.current.position.set(-props.width / 2, props.footHeight, -props.depth / 2)
  })

  return (
    <>
      <group ref={fullGroupRef}>
        <ConnectedBoards />
        <ConnectedAccBoards />
        <ConnectedBrackets />
        {slatted && <ConnectedSlatteds />}
        {mattress && <ConnectedMattress />}
        <ConnectedShadowBox length={props.width as number} width={props.depth as number} />
      </group>
    </>
  )
}

export const ConnectedDinadrei = (props: any) => {
  const width = useStore((store) => store.config.main.length)
  const depth = useStore((store) => store.config.main.width)
  const sideBoardWidth = useStore((store) => store.config.main.sideBoardWidth)
  const params = {
    width: width + 2 * bedModel.nominalThickness,
    depth: depth + 2 * sideBoardWidth,
    footHeight: useStore((store) => store.config.main.footHeight),
    ...props,
  }

  return <Dinadrei {...params} />
}
