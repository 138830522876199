import {materialModel, materialTreatments, w, localFactors} from './../../../utils/datamodels' // @Todo check with Matthias if we can use global workprices
import {ConnectedDasregalBoardList} from '../partLists/boardList'
import {ConnectedDasregalColumnList} from '../partLists/columnList'
import {ConnectedDasregalSubColumnList} from '../partLists/subColumnList'
import {ConnectedDasregalTabelarList} from '../partLists/tabelarList'
import {doorPrice, drawerPrice, price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {useStore} from '../store/store'
import {ConnectedDasregalBackpanelList} from '../partLists/backpanelList'
import {ConnectedDasregalDoorList} from '../partLists/doorList'
import {ConnectedDasregalDrawerList} from '../partLists/drawerList'
import {ConnectedDasregalFlapList} from '../partLists/flapList'
import {ConnectedDasregalFootList} from '../partLists/footList'
import {footModel} from '../store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'

export const ConnectedDasregalPrice = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)].factor
  const treatmentID = useStore((state) => state.config.main.treatmentID)
  const wallMounted = useStore((state) => state.config.feet.id) === 'wallMounted'
  const partList = getPartList()
  const footList = ConnectedDasregalFootList()
  const doorList = ConnectedDasregalDoorList()
  const drawerList = ConnectedDasregalDrawerList()
  return DasregalPrice(localFactor, treatmentID, wallMounted, partList, footList, doorList, drawerList)
}

export const DasregalPrice = (
  localFactor: number,
  treatmentID: number,
  wallMounted: boolean,
  partList: any[],
  footList: {
    key: string
    pos: IVector
    size: IVector
    material: keyof typeof materialModel
    type: keyof typeof footModel
  }[],
  doorList: any[],
  drawerList: any[]
) => {
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = treatment.setup
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  let priceDoors = 0
  let priceDrawers = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice, wallMounted)
    priceCNC += getCNCPrice(part)
  })
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })

  doorList.forEach((door) => {
    priceDoors += doorPrice(door, treatmentPrice).totalPrice
  })

  drawerList.forEach((drawer) => {
    priceDrawers += drawerPrice(drawer, treatmentPrice).totalPrice
  })

  const price = Math.round(localFactor * 10 * Math.round((w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceFeet + priceDoors + priceDrawers)) / 10))
  return <>{price}</>
}

const getPartList = () => {
  const flapPartList = ConnectedDasregalFlapList()
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]
  const partList = [
    ...ConnectedDasregalBoardList(),
    ...ConnectedDasregalColumnList(),
    ...ConnectedDasregalSubColumnList(),
    ...ConnectedDasregalTabelarList(),
    ...ConnectedDasregalBackpanelList(),
    ...flapPartList,
  ]
  return partList
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'column':
    case 'middleColumn':
    case 'subColumn':
      const nrKnapp = 4 + (part.depth > 0.4 ? Math.ceil((part.depth - 0.4) / 0.2) : 0)
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrKnapp * w.KnappBeschlag]})
      break
    case 'door':
      const nrDoorHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 0.8) / 0.4) : 0)
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrDoorHinges * w.Topfband]})
      break
    case 'drawerFront':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [w.BeschlagSK]})
      break
    case 'flapFront':
      const nrFlapHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 0.8) / 0.4) : 0)
      const nrFlapHolders = part.depth < 0.8 ? 1 : 2
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrFlapHinges * w.Klappenband, nrFlapHolders * w.SeilzugKlappenHalter]})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number, wallMounted?: boolean) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'bottom':
    case 'board':
    case 'top':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [wallMounted ? w.WandRegalBoden : 0]})
      break
    case 'column':
    case 'middleColumn':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [wallMounted ? w.WandRegalSeite : 0]})
      break
    case 'door':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitTuer]})
      break
    case 'flapFront':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitKlappe]})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'top':
    case 'bottom':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
    case 'board':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 2, nrToolChanges: 3, nrGrooves: 3, format: true, bevel: true})
      break
    case 'column':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 20 + 4 * Math.floor((part.length - 0.25) / 64), nrGrooves: 3, format: true, bevel: true})
      break
    case 'middleColumn':
    case 'subColumn':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 2, nrToolChanges: 4, nrDrills: 40 + 2 * 4 * Math.floor((part.length - 0.25) / 64), nrGrooves: 6, format: true, bevel: true})
      break
    case 'tabelar':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, format: true, bevel: true})
      break
    case 'backpanel':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, format: true, bevel: true})
      break
    case 'flapFront':
      const nrFlapHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 0.8) / 0.4) : 0)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, format: true, bevel: true, rest: [nrFlapHinges * w.CNCZeitKlappenband]})
      break
    case 'flapShelf':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
  }
  return price
}
