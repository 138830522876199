import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider, AccordionSlider, ListButton} from 'components/material_interfaces/materialInterfaces'
import {regalModel, shelfMaterials, shelfTyp} from 'products/tektur/store/datamodels'
import {useStore} from 'products/tektur/store/store'
import {getMinGrid, getGridOptions} from 'products/tektur/utils/helpers'
import {materialTreatments} from 'utils/datamodels'
import {getMaterialName, getMaxGrid} from 'utils/helpers'
import shallow from 'zustand/shallow'

export function ShelfStructure() {
  const {width, depth, grid} = useStore((store) => ({width: store.config.main.width, depth: store.config.main.depth, grid: store.config.main.grid}), shallow)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const materialTreatment = useStore((store) => store.config.main.treatmentID)
  const materialHasTreatment = shelfMaterials[shelfMaterial].treatment
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setWidth = useStore((store) => store.setWidth)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const materialOptions = [...Object.values(shelfMaterials)].map((material) => {
    return {...material, name: getMaterialName(material.id as keyof typeof shelfMaterials) + ', ' + Math.round(material.thickness * 1000) + 'mm'}
  })

  const treatmentOptions = [...Object.values(materialTreatments)]

  const shelfTypName = shelfTyp[useStore((store) => store.config.main.shelfTyp)].name
  const material = shelfMaterials[useStore((store) => store.config.main.materialID)]
  const minGrid = getMinGrid(width, regalModel.gridMax)
  const maxGrid = getMaxGrid(width, regalModel.gridMin)
  const gridOptions = getGridOptions(width, depth, material)

  const gridOptions1 = []
  for (let i = minGrid; i <= maxGrid; i++) {
    const gridSize = (width - shelfMaterials[shelfMaterial].thickness) / i
    gridOptions1.push({id: i, name: i + `x${Math.round(gridSize * 1000)}`, longName: i + ` x ${Math.round(gridSize * 1000)} mm`})
  }

  return (
    <>
      <AccordionMaterialSelector id="material" options={materialOptions} onClick={setShelfMaterialSpecies} title={'Material'} value={shelfMaterial} expanded={expanded} handleChange={handleChange} />
      {materialHasTreatment && (
        <AccordionListSelector
          id="treatment"
          options={treatmentOptions}
          onClick={setMaterialTreatment}
          title={'Oberfläche'}
          value={materialTreatment}
          expanded={expanded}
          handleChange={handleChange}
        />
      )}
      <AccordionSizeSlider
        min={regalModel.minWidth * 1000}
        max={shelfMaterials[shelfMaterial].maxWidth * 1000}
        onClick={setWidth}
        id="width"
        title={'Regalbreite'}
        value={width * 1000}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        options={regalModel.depthList}
        min={regalModel.minDepth * 1000}
        max={regalModel.maxDepth * 1000}
        onClick={setDepth}
        id="depth"
        title={'Regaltiefe'}
        value={depth * 1000}
        clickRange={20}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSlider
        options={gridOptions1}
        min={minGrid - 0.3}
        max={maxGrid + 0.3}
        onClick={setGrid}
        id="grid"
        title={'Belastung: max. ' + gridOptions[grid - minGrid].maxLoad + ' kg / Fach'}
        value={grid}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <ListButton title="Regaltyp" val={shelfTypName} to="structure/typ"></ListButton>
    </>
  )
}
