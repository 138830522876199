import {useStore} from 'products/metrum/store/store'
import {shelfMaterials} from 'products/metrum/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {IMetrumConfig} from '../store/initialConfig'

export const ConnectedMetrumBottomBoardList = () => {
  const [columns, depth, materialID] = useStore(useShallow((store) => [store.config.columns.list, store.config.main.depth, store.config.main.materialID]))
  return MetrumBottomBoardList(columns, depth, materialID)
}

export const MetrumBottomBoardList = (columns: IMetrumConfig['columns']['list'], depth: IMetrumConfig['main']['depth'], materialID: IMetrumConfig['main']['materialID']) => {
  const thickness = shelfMaterials[materialID].thickness
  let xPos = thickness / 2 + 0.0005
  const baseList: {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []

  columns.forEach((el, index) => {
    const zPos = 0.006
    const adjustedDepth = depth - 0.006 - 0.028
    baseList.push({key: index, xPos: xPos, yPos: -thickness, zPos: zPos, length: el.width + thickness - 0.001, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'bottomBoard'})
    xPos += el.width + thickness
  })

  return baseList
}
