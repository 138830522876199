import {IRow, IPos} from '../products/analog'
import {regalModel} from 'products/dasregal/store/datamodels'
import {drawerSideInset, materialModel, materialTypes, partNames} from './datamodels'
import {IPartList} from 'components/print/PrintView'

export const getMinGrid = (width: number, gridMax: number) => Math.ceil(width / gridMax)
export const getMaxGrid = (width: number, gridMin: number) => Math.floor(width / gridMin)

type IAccPosSize = {
  pos: IPos
  size: IPos
}
type IAccPosSizeFace = {
  pos: IPos
  size: IPos
  face?: 'front' | 'back'
}
type IColumn = {
  width: number
}
//Helper Functions ++++++++++++++++++++++++++++++++++++++++++++++++
export const accHelper = {
  // is accessoire overlapping with another?
  is_overlapping_with_other: (thisPos: IPos, otherPos: IPos, thisSize: IPos, otherSize: IPos) => {
    const thisPosX = Math.round(thisPos.x * 1000) / 1000
    const thisPosY = Math.round(thisPos.y * 1000) / 1000
    const thisSizeX = Math.round(thisSize.x * 1000) / 1000
    const thisSizeY = Math.round(thisSize.y * 1000) / 1000
    const otherPosX = Math.round(otherPos.x * 1000) / 1000
    const otherPosY = Math.round(otherPos.y * 1000) / 1000
    const otherSizeX = Math.round(otherSize.x * 1000) / 1000
    const otherSizeY = Math.round(otherSize.y * 1000) / 1000
    const overlappingX = otherPosX + otherSizeX - thisPosX > 0.02 && thisPosX + thisSizeX - otherPosX > 0.02
    const overlappingY = otherPosY + otherSizeY - thisPosY > 0.01 && thisPosY + thisSizeY - otherPosY > 0.01
    return overlappingX && overlappingY
  },
  // is there a accessoire of same size at position ? -> if so delete otherwise replace
  same_is_at_position: (thisPos: IPos, otherPos: IPos, thisSize: {x: number; y: number}, otherSize: {x: number; y: number}, ...params: number[] | string[]) => {
    const thisPosX = Math.round(thisPos.x * 1000) / 1000
    const thisPosY = Math.round(thisPos.y * 1000) / 1000
    const thisSizeX = Math.round(thisSize.x * 1000) / 1000
    const thisSizeY = Math.round(thisSize.y * 1000) / 1000
    const otherPosX = Math.round(otherPos.x * 1000) / 1000
    const otherPosY = Math.round(otherPos.y * 1000) / 1000
    const otherSizeX = Math.round(otherSize.x * 1000) / 1000
    const otherSizeY = Math.round(otherSize.y * 1000) / 1000
    var same = true
    for (let i = 0; i < params.length / 2; i = i + 2) {
      same = params[i] === params[i + 1]
    }
    return thisPosX === otherPosX && thisPosY === otherPosY && thisSizeX === otherSizeX && thisSizeY === otherSizeY && same
  },
  // is there a accessoire in front of this position?
  acc_is_in_front: (thisPos: IPos, thisSize: IPos, ...params: any[]) => {
    let accIsInFront = false
    for (let j = 0; j < params.length; j++) {
      for (let i = 0; i < params[j].length; i++) {
        if (accHelper.is_overlapping_with_other(thisPos, params[j][i].pos, thisSize, params[j][i].size)) {
          accIsInFront = true
        }
      }
    }
    return accIsInFront
  },
  // is there a accessoire in front of this position?
  get_acc_beside: (thisPos: IPos, thisSize: IPos, ...params: any[]) => {
    let accHeightBeside = 0
    let accHeightBesideLeft = 0
    let accHeightBesideRight = 0
    let accBeside = undefined as unknown as IAccPosSize
    let accBesideLeft = undefined as unknown as IAccPosSize
    let accBesideRight = undefined as unknown as IAccPosSize
    const startListLeft = [] as number[]
    const startListRight = [] as number[]
    const endListLeft = [] as number[]
    const endListRight = [] as number[]
    params.forEach((accList) => {
      accList.forEach((acc: {pos: {x: any; y: number}; size: {x: number; y: number}}) => {
        const overlappingY = acc.pos.y + acc.size.y - thisPos.y > 0.01 && thisPos.y + thisSize.y - acc.pos.y > 0.01
        const sameColumn = Math.floor(acc.pos.x) === Math.floor(thisPos.x)
        const onLeftSide = acc.pos.x + acc.size.x === thisPos.x
        const onRightSide = acc.pos.x === thisPos.x + thisSize.x
        if (overlappingY && sameColumn && (onLeftSide || onRightSide)) {
          accHeightBeside += Math.min(thisPos.y + thisSize.y, acc.pos.y + acc.size.y) - Math.max(thisPos.y, acc.pos.y)
          if (onLeftSide) {
            accHeightBesideLeft += Math.min(thisPos.y + thisSize.y, acc.pos.y + acc.size.y) - Math.max(thisPos.y, acc.pos.y)
            accBesideLeft = acc
            startListLeft.push(acc.pos.y)
            endListLeft.push(acc.pos.y + acc.size.y)
          }
          if (onRightSide) {
            accHeightBesideRight += Math.min(thisPos.y + thisSize.y, acc.pos.y + acc.size.y) - Math.max(thisPos.y, acc.pos.y)
            accBesideRight = acc
            startListRight.push(acc.pos.y)
            endListRight.push(acc.pos.y + acc.size.y)
          }
          accBeside = acc
        }
      })
    })
    const end = accBeside ? accHelper.get_acc_above(accBeside, ...params).pos.y + accHelper.get_acc_above(accBeside, ...params).size.y : undefined
    const endLeft = accBesideLeft ? accHelper.get_acc_above(accBesideLeft, ...params).pos.y + accHelper.get_acc_above(accBesideLeft, ...params).size.y : undefined
    const endRight = accBesideRight ? accHelper.get_acc_above(accBesideRight, ...params).pos.y + accHelper.get_acc_above(accBesideRight, ...params).size.y : undefined
    const start = accBeside ? accHelper.get_acc_below(accBeside, ...params).pos.y : undefined
    const startLeft = accBesideLeft ? accHelper.get_acc_below(accBesideLeft, ...params).pos.y : undefined
    const startRight = accBesideRight ? accHelper.get_acc_below(accBesideRight, ...params).pos.y : undefined
    return {
      height: accHeightBeside,
      start: start,
      end: end,
      heightLeft: accHeightBesideLeft,
      startLeft: startLeft,
      endLeft: endLeft,
      heightRight: accHeightBesideRight,
      startRight: startRight,
      endRight: endRight,
      startListLeft: startListLeft,
      endListLeft: endListLeft,
      startListRight: startListRight,
      endListRight: endListRight,
    }
  },
  // get total width of accessoires at position
  get_acc_width: (thisPos: IPos, thisSize: IPos, ...params: any[]) => {
    let accWidth = 0
    const thisPosRounded = {x: Math.round(thisPos.x * 100) / 100, y: Math.round(thisPos.y * 100) / 100}
    const thisSizeRounded = {x: Math.round(thisSize.x * 100) / 100, y: Math.round(thisSize.y * 100) / 100}
    for (let j = 0; j < params.length; j++) {
      for (let z = 0; z < params[j].length; z++) {
        const paramPosRounded = {x: Math.round(params[j][z].pos.x * 100) / 100, y: Math.round(params[j][z].pos.y * 100) / 100}
        const paramSizeRounded = {x: Math.round(params[j][z].size.x * 100) / 100, y: Math.round(params[j][z].size.y * 100) / 100}
        const overlappingX = paramPosRounded.x + paramSizeRounded.x - thisPosRounded.x > 0.02 && thisPosRounded.x + thisSizeRounded.x - paramPosRounded.x > 0.02
        const overlappingY = paramPosRounded.y + paramSizeRounded.y - thisPosRounded.y > 0.01 && thisPosRounded.y + thisSizeRounded.y - paramPosRounded.y > 0.01
        const samePosY = paramPosRounded.y === thisPosRounded.y || overlappingY
        if (overlappingX && samePosY) {
          const overlappingWidth = Math.min(thisPos.x + thisSize.x, params[j][z].pos.x + params[j][z].size.x) - Math.max(thisPos.x, params[j][z].pos.x)
          accWidth += overlappingWidth
        }
      }
    }
    return accWidth
  },
  // get width below of accessoires at position
  get_acc_width_below: (thisPos: IPos, thisSize: IPos, ...params: any[]) => {
    let accWidth = 0
    const thisPosRounded = {x: Math.round(thisPos.x * 100) / 100, y: Math.round(thisPos.y * 100) / 100}
    const thisSizeRounded = {x: Math.round(thisSize.x * 100) / 100, y: Math.round(thisSize.y * 100) / 100}
    for (let j = 0; j < params.length; j++) {
      for (let z = 0; z < params[j].length; z++) {
        const paramPosRounded = {x: Math.round(params[j][z].pos.x * 100) / 100, y: Math.round(params[j][z].pos.y * 100) / 100}
        const paramSizeRounded = {x: Math.round(params[j][z].size.x * 100) / 100, y: Math.round(params[j][z].size.y * 100) / 100}
        const overlappingX = paramPosRounded.x + paramSizeRounded.x - thisPosRounded.x > 0.02 && thisPosRounded.x + thisSizeRounded.x - paramPosRounded.x > 0.02
        const overlappingY = paramPosRounded.y + paramSizeRounded.y - thisPosRounded.y > 0.01 && thisPosRounded.y + thisSizeRounded.y - paramPosRounded.y > 0.01
        const accTopIsPosY = paramPosRounded.y + paramSizeRounded.y === thisPosRounded.y || overlappingY
        if (overlappingX && accTopIsPosY) {
          const overlappingWidth = Math.min(thisPos.x + thisSize.x, params[j][z].pos.x + params[j][z].size.x) - Math.max(thisPos.x, params[j][z].pos.x)
          accWidth += overlappingWidth
        }
      }
    }
    return accWidth
  },
  // accessoire is in column
  is_in_column(accPosX: number, columnNumber: number) {
    return accPosX < columnNumber
  },
  // other drawer is above this drawer
  is_acc_above(acc: IAccPosSize, otherAcc: IAccPosSize) {
    const accPosRounded = {x: Math.round(acc.pos.x * 100) / 100, y: Math.round(acc.pos.y * 100) / 100}
    const accSizeRounded = {x: Math.round(acc.size.x * 100) / 100, y: Math.round(acc.size.y * 100) / 100}
    const otherAccPosRounded = {x: Math.round(otherAcc.pos.x * 100) / 100, y: Math.round(otherAcc.pos.y * 100) / 100}
    const otherAccSizeRounded = {x: Math.round(otherAcc.size.x * 100) / 100, y: Math.round(otherAcc.size.y * 100) / 100}
    const overlappingX = otherAccPosRounded.x + otherAccSizeRounded.x - accPosRounded.x > 0.02 && accPosRounded.x + accSizeRounded.x - otherAccPosRounded.x > 0.02
    return overlappingX && Math.round((acc.pos.y + acc.size.y) * 100) / 100 === Math.round(otherAcc.pos.y * 100) / 100
  },
  // other drawer is below this drawer
  is_acc_below(acc: IAccPosSize, otherAcc: IAccPosSize) {
    const accPosRounded = {x: Math.round(acc.pos.x * 100) / 100, y: Math.round(acc.pos.y * 100) / 100}
    const accSizeRounded = {x: Math.round(acc.size.x * 100) / 100, y: Math.round(acc.size.y * 100) / 100}
    const otherAccPosRounded = {x: Math.round(otherAcc.pos.x * 100) / 100, y: Math.round(otherAcc.pos.y * 100) / 100}
    const otherAccSizeRounded = {x: Math.round(otherAcc.size.x * 100) / 100, y: Math.round(otherAcc.size.y * 100) / 100}
    const overlappingX = otherAccPosRounded.x + otherAccSizeRounded.x - accPosRounded.x > 0.02 && accPosRounded.x + accSizeRounded.x - otherAccPosRounded.x > 0.02
    return overlappingX && Math.round(acc.pos.y * 100) / 100 === Math.round((otherAcc.pos.y + otherAcc.size.y) * 100) / 100
  },
  // get drawer above this drawer, return this drawer if no drawer above
  get_acc_above(drawer: IAccPosSize, ...accLists: IAccPosSize[][]) {
    const accList = accLists.reduce((acc, val) => acc.concat(val), [])
    let drawerAbove = drawer
    const endList: number[] = []
    accList.forEach((_) => {
      drawerAbove =
        accList.find((otherDrawer) => accHelper.is_acc_above(drawerAbove, otherDrawer)) !== undefined
          ? (accList.find((otherDrawer) => {
              if (accHelper.is_acc_above(drawerAbove, otherDrawer)) {
                endList.push(otherDrawer.pos.y + otherDrawer.size.y)
                return otherDrawer
              } else {
                return false
              }
            }) as IAccPosSize)
          : drawerAbove
    })
    return {...drawerAbove, endList: endList}
  },
  // get drawer below this drawer, return this drawer if no drawer below
  get_acc_below(drawer: IAccPosSize, ...accLists: IAccPosSize[][]) {
    const accList = accLists.reduce((acc, val) => acc.concat(val), [])
    let drawerBelow = drawer
    const startList: number[] = []
    accList.forEach((_) => {
      drawerBelow =
        accList.find((otherDrawer) => accHelper.is_acc_below(drawerBelow, otherDrawer)) !== undefined
          ? (accList.find((otherDrawer) => {
              if (accHelper.is_acc_below(drawerBelow, otherDrawer)) {
                startList.push(otherDrawer.pos.y + otherDrawer.size.y)
                return otherDrawer
              } else {
                return false
              }
            }) as IAccPosSize)
          : drawerBelow
    })
    return {...drawerBelow, startList: startList}
  },
  // get subColumnList
  get_SubColumn_List(pos: {x: number; y: number}, columns: {width: number}[], boards: number[][], subColumns: {pos: IPos}[]) {
    const minSubColumn = regalModel.minSubColumnWidth / columns[pos.x].width
    const maxSubColumn = 1 - minSubColumn
    var posY = Math.max(...boards[pos.x].filter((y) => y <= pos.y), 0)
    const subColumnList = subColumns.reduce((a: number[], e) => {
      Math.floor(e.pos.x) === pos.x && e.pos.y === posY && minSubColumn < e.pos.x - Math.floor(e.pos.x) && e.pos.x - Math.floor(e.pos.x) < maxSubColumn && a.push(e.pos.x - Math.floor(e.pos.x))
      return a
    }, [])
    subColumnList.push(0, 1)
    subColumnList.sort((a, b) => a - b)
    return subColumnList
  },
  is_Backpanel_at_pos(pos: IPos, backpanels: any[], boards: number[][]) {
    const xIndex = Math.floor(pos.x)
    const yIndex = Math.abs(Math.round(pos.y) - pos.y) < 0.01 ? Math.round(pos.y) : Math.floor(pos.y)
    let relPosY = Math.max(...boards[xIndex].filter((y) => y <= yIndex))
    const fullBackpanel =
      backpanels.find((obj) => {
        return obj.pos.x === xIndex
      })?.cover === 'full'
    const isBackpanel =
      backpanels.find((obj) => {
        return (obj.pos.x === xIndex && obj.pos.y === relPosY) || (Math.floor(obj.pos.x) === xIndex && obj.pos.y === relPosY && obj.cover === 'fullWidth')
      }) || fullBackpanel
    return isBackpanel
  },
}

export function clearDrawers(drawerList: IAccPosSizeFace[], tabelars: {pos: {x: number; y: number[]}}[], columns: IColumn[], boards: number[][], grid: number, height: number) {
  const maxCols = grid * (Math.round((height - regalModel.thickness) / (grid * 0.065)) + 1)
  let newDrawers = drawerList.filter((drawer) => {
    if (drawer.pos.x < columns.length) {
      let returnVal = false
      const isTabelar = tabelars.filter((el) => el.pos.x === drawer.pos.x && el.pos.y.includes((maxCols / grid) * (drawer.pos.y + 1))).length > 0
      const isBoardAbove = Math.round(drawer.pos.y + drawer.size.y) === grid ? true : boards[Math.floor(drawer.pos.x)].includes(Math.round(drawer.pos.y + drawer.size.y))
      for (let i = 0; drawerList.length > i; i++) {
        if (accHelper.is_acc_above(drawer, drawerList[i]) || (Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.1 && (isBoardAbove || isTabelar))) {
          returnVal = true
          break
        }
      }
      return returnVal
    } else {
      return true
    }
  })
  for (let i = 0; newDrawers.length > i; i++) {
    // eslint-disable-next-line no-loop-func
    newDrawers = newDrawers.filter((drawer) => {
      if (drawer.pos.x < columns.length) {
        let returnVal = false
        const isTabelar = tabelars.filter((el) => el.pos.x === drawer.pos.x && el.pos.y.includes((maxCols / grid) * (drawer.pos.y + 1))).length > 0
        const isBoardAbove = Math.round(drawer.pos.y + drawer.size.y) === grid ? true : boards[Math.floor(drawer.pos.x)].includes(Math.round(drawer.pos.y + drawer.size.y))
        for (let j = 0; newDrawers.length > j; j++) {
          if (accHelper.is_acc_above(drawer, newDrawers[j]) || (Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.1 && (isBoardAbove || isTabelar))) {
            returnVal = true
            break
          }
        }
        return returnVal
      } else {
        return true
      }
    })
  }
  return newDrawers
}

export const calcShelfHeight = (boards: IRow[], boardThickness: number, footHeight: number) => Math.round((calcBoardsHeight(boards, boardThickness) + boardThickness + footHeight) * 1000) / 1000
export const calcBoardsHeight = (boards: IRow[], boardThickness: number) => boards.reduce((acc, board) => acc + board.height + boardThickness, 0)

export const degreeToRadians = (degree: number) => degree * (Math.PI / 180)

export const getMaterialName = (materialID: keyof typeof materialModel) => {
  const material = materialModel[materialID]
  const materialName = material.type !== 1 ? materialTypes[material.type].name + ' ' + material.name : material.name + ' ' + materialTypes[material.type].name
  return materialName
}

export const getShortMaterialName = (materialID: keyof typeof materialModel) => {
  const material = materialModel[materialID]
  const materialTypeName = materialTypes[material.type].shortName || materialTypes[material.type].name
  //@ts-ignore
  const speciesName = material.shortName || material.name
  const materialName = (materialTypeName + ' ' + speciesName).trim()
  return materialName
}

export const getDrawerParts = (params: {
  width: number
  physicalWidth?: number
  height: number
  depth: number
  bodyHeight: number
  shelfInnerWidth: number
  frontMaterialID: keyof typeof materialModel
  bodyMaterialID: keyof typeof materialModel
  bottomMaterialID: keyof typeof materialModel
  grainDir?: string
}) => {
  const {width, height, depth, bodyHeight, shelfInnerWidth, frontMaterialID, bodyMaterialID, bottomMaterialID, grainDir = 'horizontal'} = params
  const frontWidth = grainDir === 'horizontal' ? width : height
  const frontHeight = grainDir === 'horizontal' ? height : width
  const frontThickness = materialModel[frontMaterialID].thickness
  const bodyThickness = materialModel[bodyMaterialID].thickness
  //@ts-ignore
  const hasInsideFront = materialModel[frontMaterialID].insideFront || false
  const sideLength = hasInsideFront ? depth - frontThickness : depth - frontThickness - bodyThickness
  const front = {length: frontWidth, depth: frontHeight, thickness: frontThickness, material: frontMaterialID as keyof typeof materialModel, type: 'drawerFront'}
  const sideLeft = {length: sideLength, depth: bodyHeight, thickness: bodyThickness, material: bodyMaterialID, type: 'drawerSide'}
  const sideRight = {length: sideLength, depth: bodyHeight, thickness: bodyThickness, material: bodyMaterialID, type: 'drawerSide'}
  const back = {
    length: hasInsideFront ? shelfInnerWidth - 2 * drawerSideInset : shelfInnerWidth - 2 * drawerSideInset + 2 * bodyThickness,
    depth: bodyHeight,
    thickness: bodyThickness,
    material: bodyMaterialID,
    type: 'drawerBack',
  }
  const bottom = {
    length: shelfInnerWidth - 2 * drawerSideInset + 0.0095,
    depth: hasInsideFront ? depth - frontThickness - 2 * bodyThickness + 0.0095 : depth - frontThickness - bodyThickness + 0.0095,
    thickness: materialModel[bottomMaterialID].thickness,
    material: bottomMaterialID,
    type: 'drawerBottom',
  }
  if (hasInsideFront) {
    const insideFront = {length: shelfInnerWidth - 2 * drawerSideInset, depth: bodyHeight, thickness: bodyThickness, material: bodyMaterialID, type: 'drawerInsideFront'}
    return {front: front, insideFront: insideFront, sideLeft: sideLeft, sideRight: sideRight, back: back, bottom: bottom}
  } else {
    return {front: front, sideLeft: sideLeft, sideRight: sideRight, back: back, bottom: bottom}
  }
}

export const getReducedPartList = (partList: any[]) => {
  const reducedPartList: IPartList = []
  partList.forEach((part) => {
    const physicalLength = part.physicalLength || part.length
    const length = Math.round(physicalLength * 10000) / 10
    const physicalDepth = part.physicalDepth || part.depth
    const depth = Math.round(physicalDepth * 10000) / 10
    const physicalThickness = part.physicalThickness || part.thickness
    const thickness = Math.round(physicalThickness * 10000) / 10
    const partName = partNames[part.type as keyof typeof partNames] || part.type
    if (reducedPartList.some((el) => el.länge === length && el.breite === depth && el.bezeichnung === partName && el.stärke === thickness)) {
      const expart = reducedPartList.find((el) => el.länge === length && el.breite === depth && el.stärke === thickness && el.bezeichnung === partName)
      if (expart) expart.stk += 1
    } else {
      reducedPartList.push({
        material: getShortMaterialName(part.material),
        bezeichnung: partName,
        bemerkung: '',
        länge: length,
        breite: depth,
        stärke: thickness,
        stk: 1,
        ZuschnittX: length + 4,
        ZuschnittY: depth + 4,
      })
    }
  })
  reducedPartList.sort((a, b) => b.stärke - a.stärke || b.länge - a.länge || b.breite - a.breite)
  return reducedPartList
}

export const getInsetOptions = (frameHeight: number, slattedHeight: number, nrOfSlats: number) => {
  const usableHeight = frameHeight - 0.005 - slattedHeight - (nrOfSlats - 1) * 0.02
  const nrOfOptions = Math.max(Math.floor(usableHeight / 0.016), 1)
  const options = [...Array(nrOfOptions)].map((_, i) => Math.round((frameHeight - (0.005 + (nrOfOptions - i) * 0.016)) * 1000) / 1000)
  return options
}
