import {useStore} from 'products/tektur/store/store'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/tektur/store/datamodels'
import {useShallow} from 'zustand/react/shallow'
import {ITekturConfig} from '../store/initialConfig'

export const ConnectedTekturTabelarList = () => {
  const [tabelarList, backpanels, flaps, boards, shelfWidth, shelfDepth, shelfMaterialID, backpanelMaterialID, grid, shelfTyp] = useStore(
    useShallow((store) => [
      store.tabelars.clearTabelars(),
      store.config.backpanels,
      store.config.flaps,
      store.config.rows.list,
      store.config.main.width,
      store.config.main.depth,
      store.config.main.materialID,
      store.config.backpanels.materialID,
      store.config.main.grid,
      store.config.main.shelfTyp,
    ])
  )
  return TekturTabelarList(tabelarList, backpanels, flaps, boards, shelfWidth, shelfDepth, shelfMaterialID, backpanelMaterialID, grid, shelfTyp)
}

export const TekturTabelarList = (
  tabelarList: ITekturConfig['tabelars']['list'],
  backpanels: ITekturConfig['backpanels'],
  flaps: ITekturConfig['flaps'],
  boards: ITekturConfig['rows']['list'],
  shelfWidth: ITekturConfig['main']['width'],
  shelfDepth: ITekturConfig['main']['depth'],
  shelfMaterialID: ITekturConfig['main']['materialID'],
  backpanelMaterialID: ITekturConfig['backpanels']['materialID'],
  grid: ITekturConfig['main']['grid'],
  shelfTyp: ITekturConfig['main']['shelfTyp']
) => {
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: 'tabelar'}[] = []

  tabelarList.forEach((tabelar) => {
    const yPosIndex = Math.floor(tabelar.pos.y)
    const innerYPos = tabelar.pos.y - yPosIndex
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + shelfThickness, 0) + (boards[yPosIndex].height + shelfThickness) * innerYPos
    const fullBackpanel = backpanels.list.find((backpanel) => backpanel.pos.y === yPosIndex && backpanel.cover === 'full')
    const isBackpanel = fullBackpanel || backpanels.list.find((backpanel) => backpanel.pos.x === tabelar.pos.x && backpanel.pos.y === Math.floor(tabelar.pos.y))
    const isInset = isBackpanel ? 1 : 0
    const frontInset = shelfTyp === 2 ? 0 : regalModel.frontInset
    const isFlapinFront = flaps.list.find((flap) => flap.pos.x === tabelar.pos.x && flap.pos.y === Math.floor(tabelar.pos.y))
    const adjustedDepth = shelfDepth - frontInset - (backpanelThickness + regalModel.backpanelInset) * isInset - (isFlapinFront ? 0.015 : 0)
    const zPos = (backpanelThickness + regalModel.backpanelInset) * isInset
    const width = gridSize - shelfThickness
    const xPos = tabelar.pos.x * gridSize + shelfThickness
    tabelars.push({
      key: tabelar.pos.x + '-' + tabelar.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: zPos,
      length: width,
      depth: adjustedDepth,
      thickness: shelfThickness,
      material: shelfMaterialID,
      type: 'tabelar',
    })
  })

  return tabelars
}
