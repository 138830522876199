import {Selector} from 'common/threeComponents/FiberBoard'
import {shelfMaterials} from 'products/dasregal/store/datamodels'
import {useStore} from 'products/dasregal/store/store'
import {useRef} from 'react'
import * as THREE from 'three'
import shallow from 'zustand/shallow'

interface IBoardSelectors {
  material: THREE.Material
  depth: number
  xPos: number
  col: number
  width: number
  thickness: number
  boardList: number[]
}

export const BoardSelectors = (props: IBoardSelectors) => {
  const toggleBoard = useStore((store) => store.toggleBoard)
  return (
    <>
      {props.boardList.map((el, i) => {
        //material now not passed
        return <Selector size={[props.width, props.thickness, props.depth]} key={i} xPos={props.xPos} yPos={el} zPos={0} onClick={() => toggleBoard(props.col, i + 1)} />
      })}
    </>
  )
}

export const ConnectedBoardSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [columns, depth, height, grid] = useStore((store) => [store.config.columns, store.config.main.depth, store.config.main.height, store.config.main.grid], shallow)
  const selectorMaterial = useRef(new THREE.MeshBasicMaterial({color: 0x0000ff, transparent: true, depthWrite: false}))
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness

  let startPos = 0
  return (
    <>
      {columns.list.map((el, i) => {
        startPos += el.width + thickness
        let boardList = []
        const gridsize = (height - thickness) / grid
        for (let j = 0; j < grid - 1; j++) {
          boardList.push((j + 1) * gridsize)
        }
        const xPos = startPos - el.width
        if (visible) return <BoardSelectors key={i} xPos={xPos} width={el.width} col={i} depth={depth} thickness={thickness} boardList={boardList} material={selectorMaterial.current} />
        return null
      })}
    </>
  )
}
